import React, { ReactNode, useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { makeStyles } from 'tss-react/mui';
import { Link, NavLink as RouterNavLink, useLocation } from "react-router-dom";
import { useAuthTokens } from "../../context/auth";
import TopNavBarMenu from "../TopNavBarMenu/TopNavBarMenu";
import DescriptionIcon from '@mui/icons-material/Description';
import KeyboardIcon from '@mui/icons-material/Keyboard';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PlansIcon from '@mui/icons-material/Payment';
import CustomerIcon from '@mui/icons-material/People';

interface navLinkType {
    key: string;
    text: string;
    href: string;
    secure: boolean;
    superOnly: boolean;
    id: string;
    icon: ReactNode;
}

const useStyles = makeStyles()(() => ({
    header: {
        height: "72px",
        marginBottom: "20px",
    },
    logo: {
        textAlign: "left",
        fontStyle: 'italic',
        fontWeight: 700,
        fontFamily: '"Roboto-BoldItalic", "Roboto", "Helvetica", "Arial", sans-serif',
        width: "250px",
        textIndent: "40px",
        marginTop: "5px",
        lineHeight: '74px',
        color: "#ffffff"
    },
    logo_img: {
        position: "absolute",
        fontSize: '24px',
    },
    logo_span: {
        fontFamily: '"Roboto-Italic", "Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 400,
    },
    topNavButton: {
        size: "18px",
        border: "inherit",
        margin: "0 auto",
        height: "74px",
        width: "120px",
    },
    activeTopNavButton: {
        size: "18px",
        borderBottom: "4px solid #fff",
        height: "74px",
        width: "120px",
        display: "inline-flex",
    },
    toolbar: {
        display: "flex",
    },
    label: {
        flexDirection: 'column'
    },
    icon: {
        fontSize: '32px !important',
        marginBottom: "20px",
    }
}));

const navLinks: navLinkType[] = [
    { key: "1", text: "TESTS", href: "/manage-tests", secure: true, superOnly: false, id: "btn_topnav_tests", icon: <KeyboardIcon></KeyboardIcon> },
    { key: "2", text: "RESULTS", href: "/testresults", secure: true, superOnly: false, id: "btn_topnav_results", icon: <DescriptionIcon></DescriptionIcon> },
    { key: "3", text: "ACCOUNT", href: "/account", secure: true, superOnly: false, id: "btn_topnav_account", icon: <AccountBalanceIcon></AccountBalanceIcon> },
    { key: "4", text: "PLANS", href: "/plans", secure: true, superOnly: false, id: "btn_topnav_plans", icon: <PlansIcon></PlansIcon> },
    { key: "5", text: "CUSTOMERS", href: "/customer-accounts", secure: true, superOnly: true, id: "btn_topnav_customer_accounts", icon: <CustomerIcon></CustomerIcon> },
];

const TopNavBar = () => {
    const { classes } = useStyles();
    const authTokens = useAuthTokens();
    const [show, setShow] = useState(false);
    const location = useLocation();

    const adminLoginButton = () => {
        if (location.pathname !== "/login") {
            return navButton({
                key: "0",
                href: "/login",
                text: "Admin Login",
                secure: false,
                superOnly: false,
                id: "btn_topnav_login",
                icon: <AccountCircleIcon></AccountCircleIcon>
            }, "0 0 0 auto")
        } else {
            return "";
        }
    }

    useEffect(() => {
        setShow(location.pathname === "/test" || location.pathname === "/select" || location.pathname === "/migrated" || location.pathname === "/start" ? false : true);
    }, [location]);

    const displayDesktop = () => {
        return (
            <Toolbar className={classes.toolbar} >
                {appLogo()}
                <div style={{ display: "inline-flex", width: "100%" }}>
                    {getTopNavButtons()}
                    {authTokens ? (
                        <TopNavBarMenu />
                    ) : (
                        <>
                            {adminLoginButton()}
                        </>
                    )}
                </div>
            </Toolbar>
        );
    };

    const appLogo = () => {
        return (
            <Link to={"/"} style={{ cursor: "pointer", textDecoration: 'none' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <img alt="TypingTest Pro logo" src="/assets/img/logo.svg" className={classes.logo_img} />
                    <Typography variant="h6" component="h1" className={classes.logo}>
                        TypingTest<span className={classes.logo_span}> Pro</span>
                    </Typography>
                </Box>
            </Link>
        );
    };

    const navButton = (props: navLinkType, mrg?: string) => {
        return (
            <RouterNavLink
                key={props.key}
                to={props.href}
                className={({ isActive }) => isActive ? classes.activeTopNavButton : ''}
                style={{ margin: mrg, textDecoration: "none" }}
            >
                <Button
                    color="primary"
                    className={classes.topNavButton}
                    id={props.id}
                    classes={{ text: classes.label }}
                    disableRipple={true}
                >
                    {props.icon}
                    {props.text}
                </Button>
            </RouterNavLink >
        );
    };

    const getTopNavButtons = () => {
        return navLinks.map(({ key, text, href, secure, superOnly, id, icon }) => {
            if (authTokens?.superAdmin && superOnly) {
                return navButton({ key, text, href, secure, superOnly, id, icon });
            }
            if (!superOnly) {
                return (
                    <React.Fragment key={key}>
                        {authTokens ? (
                            <>{navButton({ key, text, href, secure, superOnly, id, icon })}</>
                        ) : (
                            <>{secure ? <></> : <>{navButton({ key, text, href, secure, superOnly, id, icon })}</>}</>
                        )}
                    </React.Fragment>
                );
            }

        });
    };

    return (
        <>
            {show ? (
                <header className={classes.header}>
                    <AppBar color="secondary" style={{ height: "72px" }} elevation={0}>{displayDesktop()}</AppBar>
                    <Toolbar />
                </header>
            ) : null}
        </>
    );
};

export default TopNavBar;