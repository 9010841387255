import { useEffect, useState } from "react";
import Iframe from "react-iframe";
import type { PracticeData, TestStatus } from "../TestStepThree/TestStepThree";
import * as wizardCss from "../../theme/wizardStyle";
import { theme } from "../../theme/Theme";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import ErrorIcon from "@mui/icons-material/Error";
import DoneIcon from "@mui/icons-material/Done";
import { makeStyles } from 'tss-react/mui';
import {
    CandidateProps,
    CandidateTestData,
    GetDataForTestResponse, TestResultProps,
    useGetCandidate,
    useGetCandidateTestData, useGetTestResult
} from "../../hooks/useApi";
import { getLogger } from "../../utils/logger";
import { useFeature } from "flagged";
import SubSteps from "../TestWizard/SubStepsEnum";

const log = getLogger();

const PASS = "Passed"
const FAIL = "Not passed"
const INCOMPLETE = "Incomplete"

const useStyles = makeStyles()((theme) => ({
    root: {
        width: "100%",
    },
    button: {
        marginRight: theme.spacing(1),
    },
    cardTestRoot: {
        minWidth: 400,
        maxWidth: 400,
        minHeight: 400,
        maxHeight: 400,
        alignContent: "center",
    },
    iFrameBox: {
        position: "relative",
        margin: "-22px 0 0 5px",
        backgroundImage: "url(./assets/img/loader_bg.png)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "840px 478px",
        backgroundPosition: "14px 6px",
        width: "870px",
        overflow: "hidden",
    },
    iFrame: {
        display: "block",
        zIndex: 10,
    },
    progress: {
        position: "absolute",
        display: "flex",
        flexDirection: "column",
        top: "238px",
        left: "428px",
        color: theme.palette.text.primary,
        fontSize: "30px",
        fontWeight: 500,
        width: "110px",
        height: "106px",
        transform: "translate(-50%, -50%)",
        '& .MuiCircularProgress-root': {
            color: theme.palette.text.primary,
            margin: "0 auto 10px",
        },

    },
    centerArea: {
        width: "50%",
        paddingLeft: 20,
    },
    attemptBox: {
        width: "max-content",
        height: 37,
        border: "3px solid gray",
        borderRadius: 6,
        marginTop: "4px",
        marginBottom: "15px",
        display: "inline-block",
        marginLeft: "15px",
    },
    attemptText: {
        lineHeight: "33px",
        margin: "0 5px",
        textAlign: "center",
    },
    rightArea: {
        position: "relative",
        width: "25%",
        height: 143,
        color: theme.palette.text.secondary,
        borderLeft: "2px solid #ADBAFD",
        fontSize: "16px",
        lineHeight: "58px",
        textAlign: "right",
        paddingLeft: 30,
        marginTop: 50,
    },
}));
export const DEFAULT_EXIT_LINK = "https://www.typingtestpro.com/exitlink.html";
const DEFAULT_SECONDS_FOR_REDIRECT = 10;

const getTestStatus = (useLimits: boolean, showLimits: boolean, status: TestStatus): string => {
    if (useLimits && showLimits) {
        if (status === "PASS") {
            return PASS;
        } else if (status === "FAIL") {
            return FAIL;
        } else {
            return INCOMPLETE;
        }
    } else {
        return "";
    }
};

const getCorrectedWPM = (wpm: number | string, hideFractional: boolean) => {
    const wpmNumber = Number(wpm);
    if (hideFractional && !Number.isNaN(wpmNumber)) {
        return Math.floor(wpmNumber);
    }
    return wpm;
};

interface GenerateRedirectUrlArguments {
    getTestStatus: typeof getTestStatus;
    useLimits: boolean;
    showLimits: boolean;
    typingTestData: PracticeData;
    candidateInformation: CandidateProps;
    testInfo: GetDataForTestResponse;
    hideFractional: boolean;
    resultInfo?: TestResultProps;
}

const generateRedirectUrl = ({
    getTestStatus,
    useLimits,
    showLimits,
    typingTestData,
    candidateInformation,
    testInfo,
    resultInfo,
    hideFractional,
}: GenerateRedirectUrlArguments): string => {
    const status = getTestStatus(useLimits, showLimits, typingTestData.status);
    const urlStr = status === PASS ? testInfo.exitPassUrl.title : testInfo.exitFailUrl.title;
    let url;
    try {
        url = new URL(urlStr);
    } catch (err) {
        log.debug(err);
        url = new URL(DEFAULT_EXIT_LINK);
    }
    if (testInfo.disableExitParameters) {
        const pathStatus = status === PASS ? "pass" : "fail";
        const year = new Date().getFullYear();
        const month = new Date().getMonth() >= 9 ? (new Date().getMonth() + 1) : "0" + (new Date().getMonth() + 1);
        const day = new Date().getDate() < 10 ? "0" + new Date().getDate() : new Date().getDate();
        const date = year + "-" + month + "-" + day;

        const errorCount = typingTestData.err ? String(typingTestData.err) : (resultInfo ? String(resultInfo.errorCount) : 'undefined');

        url.searchParams.set('firstname', candidateInformation.firstName);
        url.searchParams.set('lastname', candidateInformation.lastName);
        url.searchParams.set('email', candidateInformation.email);
        url.searchParams.set('nwpm', String(getCorrectedWPM(typingTestData.nwpm, hideFractional)));
        url.searchParams.set('gwpm', String(getCorrectedWPM(typingTestData.gwpm, hideFractional)));
        url.searchParams.set('err', errorCount);
        url.searchParams.set('acc', typingTestData.acc);
        url.searchParams.set('status', pathStatus);
        url.searchParams.set('date', date);
    }
    return url.toString();
};

export type TestData = {
    acc: string;
    nwpm: string;
    gwpm: string;
    ncpm: string;
    gcpm: string;
    dur: string;
    time: string;
    err: string;
    hits: string;
};

export type TestSessionData = {
    attemptsUsed: number;
};

export interface TestStepFourProps {
    handleNext: () => void;
    handleBack: () => void;
    handleNextAttempt: () => void;
    updateCandidateTestData: (candidateTestData: CandidateTestData) => void;
    testData: TestSessionData;
    testId: string;
    textId: string;
    candidateId: string;
    setTypingTestData: (data: PracticeData) => void;
    typingTestData: PracticeData;
    getTestStatus: (netWpm: string, accuracy: string) => TestStatus;
    onEmailResultsChange: (checked: boolean) => void;
    emailResults: boolean;
    candidateTestData: CandidateTestData;
    subStep: SubSteps;
    setSubStep: (subStep: SubSteps) => void;
    useCustomText: boolean;
    handleNewAttempt: () => void;
    initTestResult: (onInitReady: () => void) => void;
    testInfo: GetDataForTestResponse;
    updateBestResult: (netWpm: number, accuracy: number) => void;
    showLimits: boolean;
    useLimits: boolean;
    setupSessionStorage: () => void;
}

const TestStepFour = (props: TestStepFourProps) => {
    const { classes } = useStyles();
    const { classes: wizardClasses } = wizardCss.wizardStyles();
    const [secondsForRedirect, setSecondsForRedirect] = useState<number>(DEFAULT_SECONDS_FOR_REDIRECT)
    const [resultInfo, setResultInfo] = useState<TestResultProps>()
    const [activateRedirect, setActivateRedirect] = useState<boolean>(false);
    const [paramsState, setParamsState] = useState<boolean>(false);
    const [candidateInformation, setCandidateInformation] = useState<CandidateProps>({
        email: "",
        firstName: "",
        lastName: ""
    })
    const [,] = useState(() => {
        sessionStorage.setItem("testId", props.testId);
        sessionStorage.setItem("user", props.candidateId);
        if (process.env.REACT_APP_BACKEND_ADDRESS) {
            sessionStorage.setItem("url", process.env.REACT_APP_BACKEND_ADDRESS);
        }
    });
    const hideFractional = useFeature('s_hide_fractional') as boolean;

    const getResultInformation = (): void => {
        const resultId = sessionStorage.getItem("resultId");
        if (resultId) {
            useGetTestResult(resultId, setResultInfo);
        }
    }

    useEffect(() => {
        useGetCandidateTestData(props.testId, props.candidateId, props.updateCandidateTestData);
    }, []);

    useEffect(() => {
        const handler = (event: MessageEvent) => {
            if (event.origin !== window.location.origin) {
                log.debug(`handler() - expected event.origin: ${window.location.origin}`);
                log.debug(`handler() - but got event.origin: ${event.origin}`);
                return;
            }
            console.log(event)
            try {
                if (event.data && typeof event.data === "string") {
                    const eventData = JSON.parse(event.data);
                    log.debug(`test step 4 handler() - eventData: ${eventData}`);
                    if (eventData.acc) {
                        log.debug(`test step 4 handler() - saving to typing test data`);
                        props.setTypingTestData({
                            acc: eventData.acc,
                            nwpm: eventData.nwpm,
                            gwpm: eventData.gwpm,
                            ncpm: eventData.ncpm,
                            gcpm: eventData.gcpm,
                            dur: eventData.dur,
                            time: eventData.time,
                            err: eventData.err,
                            hits: eventData.hits,
                            status: props.getTestStatus(eventData.nwpm, eventData.acc)
                        });
                        props.updateBestResult(+eventData.nwpm, +eventData.acc);
                        props.setSubStep(SubSteps.SubStepFour);
                    }
                }
            } catch (error) {
                log.error(`handler() - ${error}`);
            }
        };

        window.addEventListener("message", handler);

        return () => window.removeEventListener("message", handler);
    }, []);

    const getStatus = () => {
        let status = "Completed";
        if (props.useLimits && props.showLimits) {
            status = getTestStatus(props.useLimits, props.showLimits, props.typingTestData.status);
        }

        return (
            <>{status}</>
        )
    };

    const getPassLimitsDiv = () => {
        if (props.useLimits && props.showLimits && props.testInfo.minNetWpm > 0 && props.testInfo.minAccuracy > 0) {
            return (
                <div>
                    <div
                        className={wizardClasses.labelSpan}
                        style={{ width: "30%", height: "90px" }}
                    >
                        {getPassLimitsTitle()}
                    </div>
                    <div className={wizardClasses.titleSpan}>{getPassLimits()}</div>
                </div>
            )
        } else {
            return (
                <div style={{ height: "40px" }} />
            )
        }
    }

    const getPassLimitsTitle = () => {
        if (props.useLimits && props.showLimits) {
            return (
                <>
                    Pass limits
                </>
            )
        } else {
            return (
                <></>
            )
        }
    }

    const getPassLimits = () => {
        if (props.useLimits && props.showLimits) {
            return (
                <Box sx={{ marginTop: "10px" }}>
                    <Typography>{props.testInfo.minNetWpm}&nbsp;WPM net speed</Typography>
                    <Typography>{props.testInfo.minAccuracy}% accuracy</Typography>
                </Box>
            )
        } else {
            return (
                <></>
            )
        }
    };

    const [isAppletBack, setIsAppletBack] = useState(true);

    const [timerStarted, setTimerStarted] = useState(false);

    useEffect(() => {
        getResultInformation();
    }, [props.typingTestData]);

    const startTimer = () => {
        if (!timerStarted) {
            log.debug(`starting timer`);
            setTimeout(() => {
                log.debug(`timeout`);
                setIsAppletBack(false);
            }, 15 * 1000);
            setTimerStarted(true);
        }
    }

    const [attemptCount, setAttemptCount] = useState(0);

    const [,] = useState(() => {
        const { maxAttempts } = props.testInfo;
        const { attemptsLeft } = props.candidateTestData;
        if (attemptsLeft === -1) {
            setAttemptCount(1);
        } else if (maxAttempts !== attemptsLeft) {
            setAttemptCount(maxAttempts - attemptsLeft + 1);
        } else {
            setAttemptCount(props.testData.attemptsUsed + 1);
        }
    });

    const startTimerForRedirect = (generateRedirectUrlArguments: GenerateRedirectUrlArguments) => {
        let seconds = secondsForRedirect;
        if (!paramsState) {
            getResultInformation()
            useGetCandidate(props.candidateId, setCandidateInformation);
            setParamsState(true)
        }
        if (seconds > 0) {
            setTimeout(() => {
                seconds = seconds - 1;
                setSecondsForRedirect(seconds)
            }, 1000)
        } else {
            if (!activateRedirect) {
                setActivateRedirect(true)
                window.location.href = generateRedirectUrl(generateRedirectUrlArguments);
            }
        }
        return null;
    }
    switch (props.subStep) {
        case SubSteps.SubStepOne: {
            return (
                <Container className={wizardClasses.mainContainer}>
                    <Typography variant="h1" component="h1">Take the Typing Test</Typography>
                    <Box display="flex">

                        <div className={wizardClasses.centerArea} style={{ marginTop: (props.useLimits && props.showLimits) ? "0px" : "45px" }} >
                            <div>
                                <div className={wizardClasses.labelSpan} style={{ minHeight: "62px", width: "30%" }}>Attempt </div>
                                <div className={classes.attemptBox}>
                                    <Typography className={classes.attemptText}>
                                        Attempt: {attemptCount} of {props.testInfo.maxAttempts}
                                    </Typography>
                                </div>
                            </div>
                            <div>
                                <div className={wizardClasses.labelSpan} style={{ width: "30%" }}>Duration</div>
                                <div className={wizardClasses.titleSpan}>{props.testInfo.duration} min </div>
                            </div>
                            {getPassLimitsDiv()}

                            <div>
                                <div className={(props.useLimits && props.showLimits) ? "" : wizardClasses.labelSpan} style={{ width: "30%" }}></div>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={props.emailResults}
                                            color="secondary"
                                            onChange={(e, checked) => {
                                                props.onEmailResultsChange(checked);
                                            }}
                                        />
                                    }
                                    label="Receive test results by email"
                                    style={{ marginLeft: (props.useLimits && props.showLimits) ? "calc(30% + 10px)" : "0px" }}
                                />
                            </div>
                        </div>

                        <div className={classes.rightArea}>
                            <ErrorIcon style={{ fontSize: 40, color: theme.palette.primary.contrastText, marginLeft: -5, float: "left" }} />
                            <br />
                            <Typography variant="subtitle1" style={{ marginTop: -12 }}>
                                <span style={{ color: theme.palette.primary.contrastText }}>IMPORTANT!</span> Skipping or closing the test before time is up counts as an attempt.
                            </Typography>
                        </div>
                    </Box>
                    <div className={wizardClasses.buttonContainer}>
                        <Button className={wizardClasses.button} color="secondary" onClick={props.handleBack} id="btn_back" >
                            Back
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                                props.initTestResult(() => {
                                    props.setupSessionStorage();
                                    props.setSubStep(SubSteps.SubStepTwo);
                                    setAttemptCount((prev) => prev + 1);
                                });
                            }}
                            className={wizardClasses.button}
                            id="btn_start"
                        >
                            START THE TEST
                        </Button>
                    </div>
                </Container >
            )
        }
        case SubSteps.SubStepTwo: {
            startTimer();
            return (
                <Container className={wizardClasses.mainContainer} style={{ display: "flex", marginTop: "47px", position: "relative" }}>
                    <Typography variant="h1" component="h1" style={{ textAlign: "left", margin: "-27px 47px 0 0", width: "120px" }}>Typing <br />Test</Typography>
                    <Box className={classes.iFrameBox}>
                        <Iframe
                            url="/index2.html?gt=pro&sessionParams=true"
                            width="870px"
                            height="491x"
                            id="appletIframe"
                            display="block"
                            className={classes.iFrame}
                            position="relative"
                            frameBorder={0}
                            ariaHidden={true}
                            onLoad={() => {
                                if (document) {
                                    const iframeElement = document.getElementById("appletIframe");
                                    if (iframeElement) {
                                        iframeElement.focus();
                                    }
                                }
                            }}
                        />
                        <Box className={classes.progress} style={{ display: isAppletBack ? "flex" : "none" }}>
                            <CircularProgress style={{ width: "50px", height: "50px" }} />
                            <span style={{ display: "block" }}>Loading</span>
                        </Box>
                    </Box>
                    <div className={wizardClasses.buttonContainer} style={{ left: "85px", bottom: "20px" }}>
                        <Button className={wizardClasses.button} color="secondary"
                            onClick={() => {
                                props.setSubStep(SubSteps.SubStepThree);
                            }}
                            id="btn_interrupt" >
                            INTERRUPT
                        </Button>
                        <Typography style={{ marginLeft: "10px" }} variant="subtitle1">Interrupted test<br /> is counted as an<br /> attempt</Typography>
                    </div>
                </Container>
            );
        }
        case SubSteps.SubStepThree: {
            return (
                <Container className={wizardClasses.mainContainer}>
                    <Typography variant="h1" component="h1">Test Attempt Interrupted</Typography>
                    <Box display="flex">

                        <div className={wizardClasses.centerArea}>
                            <div>
                                <div className={wizardClasses.labelSpan} style={{ minHeight: "62px", width: "30%" }}>Attempt </div>
                                <div className={classes.attemptBox}>
                                    <Typography className={classes.attemptText}>
                                        Attempt: {props.testData.attemptsUsed} of {props.testInfo.maxAttempts}
                                    </Typography>
                                </div>
                            </div>
                            <div><div className={wizardClasses.labelSpan} style={{ width: "30%" }}>Gross Speed</div><div className={wizardClasses.titleSpan}>... </div></div>
                            <div><div className={wizardClasses.labelSpan} style={{ width: "30%" }}>Accuracy</div><div className={wizardClasses.titleSpan}>... </div></div>
                            <div><div className={wizardClasses.labelSpan} style={{ width: "30%" }}> Net Speed</div><div className={wizardClasses.titleSpan}>... </div></div>
                            <div><div className={wizardClasses.labelSpan} style={{ width: "30%" }}>Status</div><div className={wizardClasses.titleSpan}>Test Interrupted </div></div>
                        </div>
                    </Box>
                    <div className={wizardClasses.buttonContainer} style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                        {props.candidateTestData.attemptsLeft === 0 || props.testData.attemptsUsed === props.testInfo.maxAttempts ? null : (
                            <>
                                <Button className={wizardClasses.button} color="secondary" onClick={props.handleBack} id="btn_back" >
                                    Back
                                </Button>

                                <Button
                                    color="secondary"
                                    variant="contained"
                                    onClick={() => {
                                        props.handleNewAttempt();
                                    }}
                                    className={wizardClasses.button}
                                    id="btn_new_attempt"
                                >
                                    New Attempt
                                </Button>
                            </>
                        )}
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => { props.setSubStep(SubSteps.SubStepOne); props.handleNext(); }}
                            className={wizardClasses.button}
                            id="btn_done"
                            startIcon={<DoneIcon />}
                        >
                            Finish
                        </Button>
                    </div>
                </Container>
            )
        }
        case SubSteps.SubStepFour: {
            const netWpm = getCorrectedWPM(props.typingTestData.nwpm, hideFractional);
            const gWpm = getCorrectedWPM(props.typingTestData.gwpm, hideFractional);
            return (
                <Container className={wizardClasses.mainContainer}>
                    <Typography variant="h1" component="h1">Your Test Result</Typography>
                    <Box display="flex">
                        <div className={wizardClasses.centerArea}>
                            <div>
                                <div className={wizardClasses.labelSpan} style={{ minHeight: "62px", width: "30%" }}>Attempt </div>
                                <div className={classes.attemptBox}>
                                    <Typography className={classes.attemptText}>
                                        Attempt: {props.testData.attemptsUsed} of {props.testInfo.maxAttempts}
                                    </Typography>
                                </div>
                            </div>
                            <div><div className={wizardClasses.labelSpan} style={{ width: "30%" }}>Gross Speed</div><div className={wizardClasses.titleSpan}>{gWpm}&nbsp;WPM</div></div>
                            <div><div className={wizardClasses.labelSpan} style={{ width: "30%" }}>Accuracy</div><div className={wizardClasses.titleSpan}>{props.typingTestData.acc}%</div></div>
                            <div><div className={wizardClasses.labelSpan} style={{ width: "30%" }}>Net Speed</div><div id="netWpm" className={wizardClasses.titleSpan}>{netWpm}</div>&nbsp;WPM</div>
                            <div><div className={wizardClasses.labelSpan} style={{ width: "30%" }}>Status</div><div className={wizardClasses.titleSpan}>{getStatus()}</div></div>
                        </div>
                    </Box>
                    <div className={wizardClasses.buttonContainer} style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                        {
                            (
                                (props.testInfo.exitPassUrl.enabled && props.getTestStatus(props.typingTestData.nwpm, props.typingTestData.acc) === 'PASS') ||
                                (props.testInfo.exitFailUrl.enabled && props.getTestStatus(props.typingTestData.nwpm, props.typingTestData.acc) === 'FAIL')
                            ) ? (
                                <Typography style={{ color: "#6f6666" }}>
                                    {startTimerForRedirect({
                                        getTestStatus,
                                        candidateInformation,
                                        showLimits: props.showLimits,
                                        useLimits: props.useLimits,
                                        testInfo: props.testInfo,
                                        typingTestData: props.typingTestData,
                                        resultInfo,
                                        hideFractional,
                                    })}
                                    Important! Please do not close this web page! You will be redirected in <span style={{ color: "#000000", fontWeight: 600 }}>{secondsForRedirect}</span> seconds. Please click
                                    <a
                                        href={generateRedirectUrl({
                                            getTestStatus,
                                            candidateInformation,
                                            showLimits: props.showLimits,
                                            useLimits: props.useLimits,
                                            testInfo: props.testInfo,
                                            typingTestData: props.typingTestData,
                                            resultInfo,
                                            hideFractional,
                                        })}
                                    >
                                        {' '}
                                        here
                                        {' '}
                                    </a>
                                    to go forward now.
                                </Typography>
                            ) : (
                                <>
                                    {props.candidateTestData.attemptsLeft === 0 || props.testData.attemptsUsed === props.testInfo.maxAttempts ? null : (
                                        <>
                                            <Button className={wizardClasses.button} color="secondary" onClick={props.handleBack} id="btn_back" >
                                                Back
                                            </Button>

                                            <Button
                                                color="secondary"
                                                variant="contained"
                                                onClick={() => {
                                                    props.handleNewAttempt();
                                                }}
                                                className={wizardClasses.button}
                                                id="btn_new_attempt"
                                            >
                                                New Attempt
                                            </Button>
                                        </>
                                    )}
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={() => {
                                            if (props.testInfo.exitPassUrl.enabled && getTestStatus(props.useLimits, props.showLimits, props.typingTestData.status) === PASS) {
                                                window.location.href = generateRedirectUrl({
                                                    getTestStatus,
                                                    candidateInformation,
                                                    showLimits: props.showLimits,
                                                    useLimits: props.useLimits,
                                                    testInfo: props.testInfo,
                                                    typingTestData: props.typingTestData,
                                                    resultInfo,
                                                    hideFractional,
                                                });
                                            } else if (props.testInfo.exitFailUrl.enabled && getTestStatus(props.useLimits, props.showLimits, props.typingTestData.status) === FAIL) {
                                                window.location.href = generateRedirectUrl({
                                                    getTestStatus,
                                                    candidateInformation,
                                                    showLimits: props.showLimits,
                                                    useLimits: props.useLimits,
                                                    testInfo: props.testInfo,
                                                    typingTestData: props.typingTestData,
                                                    resultInfo,
                                                    hideFractional,
                                                });
                                            } else {
                                                props.setSubStep(SubSteps.SubStepOne);
                                                props.handleNext();
                                            }
                                        }}
                                        className={wizardClasses.button}
                                        id="btn_done"
                                        startIcon={<DoneIcon />}
                                    >
                                        Finish
                                    </Button>
                                </>
                            )
                        }
                    </div>
                </Container>
            )
        }
        default: {
            const netWpm = getCorrectedWPM(props.typingTestData.nwpm, hideFractional);
            const gWpm = getCorrectedWPM(props.typingTestData.gwpm, hideFractional);
            return (
                <Container className={wizardClasses.mainContainer}>
                    <Typography variant="h1" component="h1">Unknown test step</Typography>
                    <Box display="flex">

                        <div className={wizardClasses.centerArea}>
                            <div>
                                <div className={wizardClasses.labelSpan} style={{ minHeight: "62px", width: "30%" }}>Attempt </div>
                                <div className={classes.attemptBox}>
                                    <Typography className={classes.attemptText}>
                                        Attempt: {props.testData.attemptsUsed + 1} of {props.testInfo.maxAttempts}
                                    </Typography>
                                </div>
                            </div>
                            <div><div className={wizardClasses.labelSpan} style={{ width: "30%" }}>Gross Speed</div><div className={wizardClasses.titleSpan}>{gWpm}&nbsp;WPM</div></div>
                            <div><div className={wizardClasses.labelSpan} style={{ width: "30%" }}>Accuracy</div><div className={wizardClasses.titleSpan}>{props.typingTestData.acc}%</div></div>
                            <div><div className={wizardClasses.labelSpan} style={{ width: "30%" }}> Net Speed</div><div id="netWpm" className={wizardClasses.titleSpan}>{netWpm}&nbsp;WPM</div></div>
                            <div><div className={wizardClasses.labelSpan} style={{ width: "30%" }}>Status</div><div className={wizardClasses.titleSpan}>{getStatus()}</div></div>
                        </div>
                    </Box>
                    <div className={wizardClasses.buttonContainer} style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                        <Button className={wizardClasses.button} color="secondary" onClick={props.handleBack} id="btn_back" >
                            Back
                        </Button>

                        <Button
                            color="secondary"
                            variant="contained"
                            onClick={() => { props.setSubStep(SubSteps.SubStepOne); props.handleNextAttempt(); }}
                            className={wizardClasses.button}
                            id="btn_start"
                        >
                            Next Attempt
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => { props.setSubStep(SubSteps.SubStepOne); props.handleNext(); }}
                            className={wizardClasses.button}
                            id="btn_done"
                            startIcon={<DoneIcon />}
                            style={{ position: "absolute", right: 40 }}
                        >
                            Finish
                        </Button>
                    </div>
                </Container>
            )
        }
    };
};

export default TestStepFour;
