import Typography from "@mui/material/Typography";
import { NetWpm } from "../../hooks/useApi";
import { useTableCellStyles } from "../../theme/tableCellStyles";

interface ResultTableCellNetSpeedProps {
    hideFractional: boolean;
    netWpmObj?: NetWpm;
}

const ResultTableCellNetSpeed = ({
    netWpmObj,
    hideFractional,
}: ResultTableCellNetSpeedProps) => {
    const { classes } = useTableCellStyles();

    if (!netWpmObj) return null;

    let num = String(parseInt(netWpmObj.netWpm));
    let add = "." + (parseFloat(netWpmObj.netWpm) % 1 * 10).toFixed(0);
    let wpm = "WPM";
    let cls = netWpmObj?.passed ? classes.passText : classes.errText;

    if (netWpmObj.netWpm === "n/a") {
        num = "-";
        add = "";
        wpm = "";
        cls = classes.naText;
    }

    return (
        <div>
            <Typography align="left" style={{ fontSize: "24px" }} className={cls}>
                <span style={{ display: 'flex', alignItems: 'first baseline' }}>
                    {num}
                    {!hideFractional && <span style={{ fontSize: "16px" }} >{add}</span>}
                </span>
            </Typography>

            <Typography className={cls}>{wpm}</Typography>
        </div>
    );
};

export default ResultTableCellNetSpeed;
