import { createContext, useContext } from "react";
import { getLogger } from "../utils/logger";
import { getAccountDomainFromTestLink } from "../utils/accountDomain";
import { useNavigate } from "react-router-dom";
import { useGetAuthTokenData } from "../hooks/useApi";

const log = getLogger();

export interface CandidatesExceeded {
    openDialog: boolean;
    exceeded: boolean;
    error?: boolean;
    warn?: boolean;
}

export type AuthTokens = {
    email: string;
    status: string;
    confirmMigrating?: boolean;
    superAdmin?: boolean;
    companyName?: string;
    accountDomain?: string;
    adminId?: string;
    candidatesExceeded?: CandidatesExceeded;
};

export type ContextProps = {
    authTokens: AuthTokens;
    setAuthTokens(tokens: AuthTokens): void;
};

export const AuthContext = createContext<Partial<ContextProps>>({});

export function useAuth(): Partial<ContextProps> {
    return useContext(AuthContext);
}

export function useAuthTokens(): AuthTokens | null {
    const { authTokens } = useAuth();

    if (authTokens) {
        if (authTokens.email !== "") {
            const superAdmin = authTokens.superAdmin ? authTokens.superAdmin : undefined;
            const companyName = authTokens.companyName ? authTokens.companyName : undefined;
            const accountDomain = authTokens.accountDomain ? authTokens.accountDomain : undefined;
            return {
                email: authTokens.email,
                status: authTokens.status,
                adminId: authTokens.adminId,
                superAdmin,
                companyName,
                accountDomain,
            };
        }
    }
    log.debug(`useAuthTokens() - debug: not authenticated`);
    return null;
}

interface FlagsContextProps {
    features: string[];
    setFeatures: (features: string[]) => void;
}

export const FlagsContext = createContext<FlagsContextProps>({ features: [], setFeatures: () => {} });

export const useFlags = () => {
    return useContext(FlagsContext);
};

interface SetTokenAndFeaturesProps {
    doNotNavigateOnError?: boolean;
    pathToNavigateOnSuccess?: string;
    finallyCallback?: () => void;
}

export const useSetTokenAndFeatures = () => {
    const { setAuthTokens } = useAuth();
    const { setFeatures } = useFlags();
    const navigate = useNavigate();

    const setTokenAndFeatures = ({
        doNotNavigateOnError,
        pathToNavigateOnSuccess,
        finallyCallback,
    }: SetTokenAndFeaturesProps = {}) => {
        useGetAuthTokenData(
            (data) => {
                if (setAuthTokens) {
                    setAuthTokens(data);
                }
                if (setFeatures) {
                    setFeatures((data.flags || "").split(","));
                }
                if (pathToNavigateOnSuccess) {
                    navigate(pathToNavigateOnSuccess);
                }
                if (finallyCallback) {
                    finallyCallback();
                }
            },
            (errorData) => {
                if (setAuthTokens) {
                    setAuthTokens({ email: "", status: "" });
                }
                if (setFeatures) {
                    setFeatures((errorData.flags || "").split(","));
                }
                if (!doNotNavigateOnError) {
                    const accountDomain = getAccountDomainFromTestLink();
                    if (accountDomain.length > 0 && location.pathname !== "/login") {
                        navigate("/select");
                    } else {
                        navigate("/login");
                    }
                }
                if (finallyCallback) {
                    finallyCallback();
                }
            },
        );
    };

    return { setTokenAndFeatures };
};
