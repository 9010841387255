import React, { useState } from "react";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { SelectChangeEvent } from "@mui/material";

import { AllPlansWithUserPlanProps, useChangeAccountPlan } from "../../hooks/useApi";

interface ChangePLanSelectProps {
    accountId: string,
    updateSubscription: () => void,
    options: AllPlansWithUserPlanProps[]
}

const ChangePlanSelect = ({ accountId, options, updateSubscription }: ChangePLanSelectProps) => {
    const MenuItemComponent = MenuItem;
    const accountPlan = options.find((elem) => elem.selected);
    const initValue = accountPlan ? accountPlan.planId : -1;
    const [defaultValue, setDefaultValue] = useState<number>(accountPlan ? accountPlan.planId : -1);
    const [enableSave, setEnableSave] = useState<boolean>(false);

    const handleChange = (event: SelectChangeEvent<number>): void => {
        if (typeof event.target.value === "number") {
            setDefaultValue(event.target.value);
            event.target.value === initValue ? setEnableSave(false) : setEnableSave(true)
        }
    };

    const updateEnableForSave = (): void => {
        setEnableSave(false)
    }

    const handleSubmit = (): void => {
        useChangeAccountPlan(
            accountId,
            defaultValue,
            updateSubscription,
        );
        updateEnableForSave()
    };

    return (
        <>
            <Select
                id={`select-plan_${accountId}`}
                value={defaultValue}
                style={{ marginRight: 0, width: "250px" }}
                onChange={handleChange}>
                {options.map((plan, index) => (
                    <MenuItemComponent
                        key={`plan_option_${index}`}
                        value={plan.planId}
                    >
                        {plan.name}
                    </MenuItemComponent>
                ))}
            </Select>

            <Button
                style={{ marginLeft: "10px" }}
                color="secondary"
                type={"button"}
                variant="contained"
                aria-label="save"
                disabled={!enableSave}
                onClick={handleSubmit}
            >
                Save
            </Button>
        </>
    );
};

export default ChangePlanSelect;