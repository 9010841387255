import React, { useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import CopyFile from "@mui/icons-material/FileCopy";
import { makeStyles } from 'tss-react/mui';
import { useGetTestDataForCopy, usePostNewTest } from "../../hooks/useApi";
import {
    ATTEMPTS_MAX_VALUE,
    checkForInvalidCharacters,
    convertFormDataToApi,
    updateToFormData
} from "../TestCard/TestCard";
import { getLogger } from "../../utils/logger";
import TestSettings, { TestSettingsFormValues } from "../TestSettings/TestSettings";
import { FIRST_TEXT } from "../TestsHeader/TestsHeader";

const log = getLogger();

export interface CopyTestProps {
    cardIndex: number;
    testId: string;
    onTestAdded: () => void;
    setError: (status: string) => void;
}

const useStyles = makeStyles()(() => ({
    iconButton: {
        padding: "8px"
    }
}));

const CopyTest = ({ cardIndex, testId, onTestAdded, setError }: CopyTestProps) => {
    const { classes } = useStyles();
    const initialDefaultData: TestSettingsFormValues = {
        name: "",
        duration: 5,
        attempts: 5,
        limitsInUse: false,
        shownToCandidate: false,
        netWpm: 0,
        accuracy: 0,
        note: "",
        noteEnabled: false,
        infoFieldOneTitle: "",
        infoFieldOneEnabled: false,
        infoFieldTwoTitle: "",
        infoFieldTwoEnabled: false,
        sendResultsByEmail: false,
        resultRecipients: [],
        selectedResultRecipients: [],
        testText: "",
        textSelectionOptions: [],
        selectedText: FIRST_TEXT,
        customText: "",
        useCustomText: false,
        selectedTextContent: "",
        migratedResultEmails: "",
        exitPassUrlState: false,
        exitFailUrlState: false,
        exitPassUrl: "",
        exitFailUrl: "",
        newAttemptsAllowed: -1,
        accessCodeEnabled: false,
        accessCode: ""
    };
    const [initialData, setInitialData] = useState<TestSettingsFormValues>(initialDefaultData);
    const [openCopy, setOpenCopy] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>()

    const handleClose = (): void => {
        setOpenCopy(false);
        setErrorMessage(undefined)
    };

    const handleError = (status: string) => {
        switch (status) {
            case "ERROR_ATTEMPTS_MAX_LIMIT":
                setErrorMessage(`Maximum attempts is ${ATTEMPTS_MAX_VALUE}`)
                break;
            case "ERROR_TEST_NAME_ALREADY_EXIST":
                setErrorMessage(`Test name must be unique. Please choose a different name.`)
                break;
            default:
                return setErrorMessage("Unknown error");

        }
    }

    const handleCloseAfterCopy = (values: TestSettingsFormValues) => {
        try {
            let invalidCharacters = "";
            const formData = convertFormDataToApi(values, true);

            if (values.useCustomText) {
                invalidCharacters = checkForInvalidCharacters(values.customText);
            }

            if (invalidCharacters === "") {
                usePostNewTest(
                    {
                        ...formData
                    },
                    (response) => {
                        log.debug(`onSubmit() - response: ${JSON.stringify(response, null, 2)}`);
                        handleClose();
                        onTestAdded();
                        setInitialData(initialDefaultData);
                    },
                    (status) => {
                        handleError(status);
                    }
                );
            }
        } catch (error) {
            log.error(`onSubmit() - error: ${JSON.stringify(error, null, 2)}`);
        }
    };

    return <>
        <Tooltip title="Copy test">
            <IconButton
                color="secondary"
                aria-label="copy test"
                component="span"
                id={`copy_test_btn_${cardIndex}`}
                className={classes.iconButton}
                onClick={() => {
                    log.debug(`COPY clicked`);
                    useGetTestDataForCopy({ testId }, (data) => {
                        updateToFormData(data, setInitialData, true);
                        setOpenCopy(true);
                    }, setError);
                }}
            >
                <CopyFile />
            </IconButton>
        </Tooltip>

        <TestSettings
            errorMessage={errorMessage}
            open={openCopy}
            handleClose={handleClose}
            initialValues={initialData}
            handleCloseAfterEdit={handleCloseAfterCopy}
        />

    </>;
};

export default CopyTest;