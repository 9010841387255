import { useEffect, useState, useRef } from 'react';

function useDebounce<T>(value: T, delay?: number): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay || 500);

    return () => {
      clearTimeout(timer);
    }
  }, [value, delay]);

  return debouncedValue;
}

export default useDebounce;


function useDebounce2<T>(value: T, delay?: number): [T, (value: T) => void] {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);
  const timer = useRef<ReturnType<typeof setTimeout> | null>(null);

  const setDebounced = (value: T) => {
    if (timer.current) {
      clearTimeout(timer.current);
      timer.current = null;
    }
    timer.current = setTimeout(() => setDebouncedValue(value), delay || 500);
  }

  return [debouncedValue, setDebounced];
}

export { useDebounce2 };