import { useEffect, useState } from "react";
import { DueDateInformationProps, useGetSubscriptionDueDate } from "./useApi";

const subscriptionInitialState: DueDateInformationProps = {
    subscriptionIsAvailable: { available: true, messageType: "" },
    candidatesExceeded: { exceeded: false, openDialog: false },
};

const useSubscriptionInfo = () => {
    const [subscriptionInfo, setSubscriptionInfo] = useState<DueDateInformationProps>(subscriptionInitialState);

    useEffect(() => {
        useGetSubscriptionDueDate((data: DueDateInformationProps) => {
            setSubscriptionInfo(data);
        });
    }, []);

    return subscriptionInfo;
};

export default useSubscriptionInfo;
