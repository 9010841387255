import { ChangeEvent, ReactElement, useEffect, useRef, useState } from "react";
import {
    AllPlansWithUserPlanProps, getAccountsParams,
    GetAllAccountsData, OrderBy, SortByForCustomers, useChangeAccountSubscriptionDate,
    useDeleteAccount,
    useGetAllAccountsData,
    usePostSelectAccount
} from "../../hooks/useApi";
import ChangePlanSelect from "../ChangePlanSelect/ChangePlanSelect";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import Input from "@mui/material/Input";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import { makeStyles } from 'tss-react/mui';
import Checkbox from "@mui/material/Checkbox";
import Table from "@mui/material/Table";
import { theme } from "../../theme/Theme";
import { TableHead, TablePagination, Tooltip, Typography } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import JumpToPage from "../JumpToPage/JumpToPage";
import { useAuth, useSetTokenAndFeatures } from "../../context/auth";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import CheckBox from "@mui/material/Checkbox";
import SearchIcon from "@mui/icons-material/Search";
import { Link } from "react-router-dom";
import ArrowDown from "@mui/icons-material/ArrowDownward";
import ArrowUp from "@mui/icons-material/ArrowUpward";
import { useNavigate } from "react-router-dom";
import { useDebounce2 } from "../../hooks/useDebounce";
import { red, yellow } from "@mui/material/colors";

const DEFAULT_SORTBY = "company";
const DEFAULT_ORDERBY = "desc";
const FIRST_PAGE = 0;

const sortData = {
    company: "company",
    email: "email",
    createdAt: "createdAt",
    lastUsed: "lastUsed",
    candidateMonth: "candidateMonth",
    plan: "plan",
    validUntil: "validUntil",
};

const enGbYyMmDdFormatter = new Intl.DateTimeFormat("en-GB", {
    year: "numeric",
    month: "short",
    day: "numeric"
});

const enUsHhMmFormatter = new Intl.DateTimeFormat("en-US", {
    hour: "numeric",
    minute: "2-digit"
});

interface TableDataProps {
    company: string;
    contact: string;
    accountId: string;
    createdAt: string;
    email: string;
    lastUsed: string;
    billing: string;
    validUntil: Date;
    planInformation: AllPlansWithUserPlanProps[];
    candidatesThisMonth: number;
    fastSpringLegacyId: string;
    expired: boolean;
    exceeded: boolean;
}

interface SelectPlanProps {
    [key: string]: AllPlansWithUserPlanProps[]
}

const useStyles = makeStyles()({
    mainSection: {
        paddingLeft: "40px",
        paddingRight: "40px"
    },
    title: {
        fontFamily: "'Lexend', sans-serif;",
        textAlign: "left",
        display: "block",
        fontSize: "34px",
        margin: "8px 0 20px"
    },
    paginationSection: {
        display: "flex",
        justifyContent: "space-between",
        paddingLeft: "25px",
        paddingRight: "25px",
        paddingTop: "10px"
    },
    noDataParentSection: {
        paddingLeft: "25px",
        paddingRight: "25px",
        paddingTop: "10px",
        borderBottom: "1px solid #e0e0e0",
        textAlign: "center",
        cursor: "pointer",
        "&:hover": {
            background: "#ececec"
        }
    },
    tableBottomSection: {
        background: "#ffffff"
    },
    emptyDataSection: {
        background: "#ffffff",
        paddingLeft: "25px",
        paddingRight: "25px",
        paddingTop: "10px"
    },
    customPagination: {
        float: "right"
    },
    sortIconStyle: {
        marginLeft: "6px",
        fontSize: "19px"
    },
    table: {
        width: "100%",
        background: "#ffffff",
        border: "1px solid #e9e9e9"
    },
    tableHeaderTitle: {
        color: "#4c4c4c",
        cursor: "pointer",
    },
    flex: {
        display: "flex",
        justifyContent: "start",
        alignItems: "center"
    },
    profileImage: {
        color: "#7a7a7a",
        fontSize: "50px"
    },
    textBold: {
        marginBottom: "0px",
        color: "#7a7a7a",
        fontSize: "17px",
        fontWeight: 800
    },
    textNormal: {
        marginBottom: "0px",
        color: "#7a7a7a",
        fontSize: "17px"
    },
    spaceFromLeft: {
        marginLeft: "10px"
    },
    textStyle: {
        marginBottom: "0px",
        color: "#7a7a7a",
        fontSize: "17px"
    },
    testStatusSection: {
        width: "auto",
        border: "2px solid #49cea1",
        textAlign: "center",
        padding: "6px 0px",
        borderRadius: "15px"
    },
    arrowSection: {
        marginLeft: "10px"
    },
    tableRow: {
        cursor: "pointer",
        "&:hover": {
            background: "#f9f9f9"
        }
    },
    passText: {
        color: theme.palette.primary.dark
    },
    naText: {
        color: theme.palette.text.secondary,
        marginLeft: "30px",
        fontSize: "24px"
    },
    loading: {
        height: "50px",
        marginTop: "1px",
        display: "flex",
        alignItems: "center",
        marginBottom: "3px",
        background: "#ffffff"
    },
    loadingSvg: {
        margin: "0 auto",
        display: "block",
        color: "#3F51B5"
    },
    errText: {
        color: theme.palette.error.main
    },
    fieldSmall: {
        fontSize: "16px",
        color: theme.palette.primary.dark
    },
    statusPassed: {
        width: "114px",
        height: "27px",
        border: "2px solid #02BA7C",
        borderRadius: "40px",
        color: "#02BA7C"
    },
    statusFail: {
        width: "114px",
        height: "27px",
        border: "2px solid #E0789A",
        borderRadius: "40px",
        color: "#E0789A"
    },
    statusIncomplete: {
        width: "114px",
        height: "27px",
        border: "2px solid #ADADAD",
        borderRadius: "40px",
        color: "#ADADAD"
    },
    statusNotStarted: {
        width: "114px",
        height: "27px",
        color: "#ADADAD"
    },
    statusSpan: {
        lineHeight: "27px",
        display: "block",
        textAlign: "center",

        fontSize: "14px",
        fontWeight: 700,
        marginTop: "-1px"
    },
    actionsArea: {
        background: "#ffffff",
        padding: "12px",
        display: "flex",
        justifyContent: "space-between"
    },
    deleteButton: {
        padding: "5px 40px"
    },
    showAccountsWithoutAdmin: {
        padding: "5px 40px",
        height: "40px"
    },
    clearButton: {
        padding: "5px 15px",
        marginLeft: "15px"
    },
    searchInput: {
        borderBottom: '1px solid #3F51B5',
        marginLeft: "15px",
        width: "320px"
    },
    changeDateDialog: {
        padding: "0px 60px"
    }
});

interface GetNoticeableColors {
    expired: boolean;
    exceeded: boolean;
}

const getNoticeableColors = ({ expired, exceeded }: GetNoticeableColors) => {
    let backgroundColor = 'transparent';
    let color = 'initial';

    if (expired) {
        backgroundColor = red[100];
    } else if (exceeded) {
        backgroundColor = yellow[100];
    }

    if (expired && exceeded) {
        color = red[900];
    }

    return { backgroundColor, color };
}

const initialShowTrialPlans = { touched: false, enabled: true };
const initialSearchParameters = {
    searchByEmail: false,
    search: '',
    showTrialPlans: initialShowTrialPlans,
}

const AccountList = () => {
    const { classes } = useStyles();
    const navigate = useNavigate()
    const { authTokens } = useAuth();
    const [plans, setPlans] = useState<SelectPlanProps>({});
    const [editingValidDate, setEditingValidDate] = useState<boolean>(false);
    const [page, setPage] = useState(FIRST_PAGE);
    const incomingPage = useRef<number>(page);
    const [newValidDate, setNewValidDate] = useState<string>();
    const [changingValidDateInformation, setChangingValidDateInformation] = useState<{
        currentDate: string,
        accountId: string
    }>();
    const [loading, setLoading] = useState<boolean>(true);
    const [selectAll, setSelectAll] = useState<boolean>(false);
    const [size, setSize] = useState(10);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [checked, setChecked] = useState<{ [key: string]: boolean }>();
    const [isDelete, setIsDelete] = useState<boolean>(false);
    const [count, setCount] = useState(0);
    const [searchByCompanyNameValue, setSearchByCompanyNameValue] = useState("");
    const [searchByEmailValue, setSearchByEmailValue] = useState("");
    const [sorting, setSorting] = useState<{ sortBy: SortByForCustomers; orderBy: OrderBy }>({
        sortBy: DEFAULT_SORTBY,
        orderBy: DEFAULT_ORDERBY
    });
    const [searchParameters, setSearchParameters] = useDebounce2(initialSearchParameters, 300);
    const [tableData, setTableData] = useState<TableDataProps[]>([{
        company: "",
        contact: "",
        accountId: "",
        createdAt: "",
        lastUsed: "",
        email: "",
        billing: "",
        validUntil: new Date(),
        planInformation: [],
        candidatesThisMonth: 0,
        fastSpringLegacyId: "",
        expired: false,
        exceeded: false,
    }]);
    const { setTokenAndFeatures } = useSetTokenAndFeatures();

    const updateData = (searchPage: number): void => {
        const {
            searchByEmail,
            showTrialPlans,
            search,
        } = searchParameters;

        setLoading(true);
        setIsDelete(false);
        setSelectAll(false);
        setChecked({})
        incomingPage.current = searchPage;
        const searchData: getAccountsParams = {
            page: searchPage,
            size: size,
            searchByEmail: searchByEmail,
            ...sorting
        };
        if (search && search.length >= 1) {
            searchData.search = encodeURIComponent(search.trim());
        }

        if (!showTrialPlans.enabled) {
            searchData.hideTP = 1;
        }
        useGetAllAccountsData(
            searchData,
            initAccountData,
            setCount
        );
    };

    const handlePageChange = (event: any, newPage: number) => {
        setPage(newPage);
    };

    const handleRowsChange = (event: any): void => {
        setSize(parseInt(event.target.value, 10));
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const updateSorting = (sortByRowName: SortByForCustomers): void => {
        let order: OrderBy = DEFAULT_ORDERBY;
        if (sorting.sortBy === sortByRowName) {
            if (sorting.orderBy === DEFAULT_ORDERBY) {
                order = "asc";
            }
        };
        setSorting({
            sortBy: sortByRowName,
            orderBy: order
        });
    };

    const initAccountData = (data: GetAllAccountsData[]): void => {
        const newData: { tableData: TableDataProps[], plans: SelectPlanProps, checked: typeof checked } = data.reduce((result, account) => {
            if (account.companyName !== authTokens?.companyName) {
                result.checked = {
                    ...result.checked,
                    [account.accountId]: false,
                }
            };

            result.plans[account.accountId] = account.planInformation;

            const newTableData = {
                company: account.companyName,
                contact: account.lastName + " " + account.firstName,
                accountId: account.accountId,
                createdAt: enGbYyMmDdFormatter.format(new Date(account.createdAt.toString())) + " " + enUsHhMmFormatter.format(new Date(account.createdAt.toString())),
                lastUsed: account.lastUsed ? enGbYyMmDdFormatter.format(new Date(account.lastUsed.toString())) + " " + enUsHhMmFormatter.format(new Date(account.lastUsed.toString())) : "",
                billing: account.billing,
                email: account.email,
                validUntil: account.validUntil,
                planInformation: account.planInformation,
                candidatesThisMonth: account.candidatesThisMonth,
                fastSpringLegacyId: account.fastSpringLegacyId,
                expired: account.expired,
                exceeded: account.exceeded,
            };

            result.tableData.push(newTableData);
            return result;
        }, { tableData: [] as TableDataProps[], plans: {} as SelectPlanProps, checked });

        setChecked(newData.checked)
        setPlans(newData.plans);
        setTableData(newData.tableData);
        setLoading(false);
    };

    useEffect(() => {
        if (incomingPage.current !== page) {
            updateData(page);
            incomingPage.current = page;
        }
    }, [page]);

    useEffect(() => {
        updateData(incomingPage.current);
    }, [size, rowsPerPage, sorting, searchParameters]);

    const checkRow = (accountId: string) => {
        setChecked((prev) => {
            return {
                ...prev,
                [accountId]: checked ? !checked[accountId] : true
            };
        });
    };

    const deleteAccounts = (): void => {
        if (checked) {
            const accounts = Object.keys(checked).map((accountId) => {
                if (checked[accountId]) {
                    return accountId;
                }
                return null;
            }).filter(account => account);
            useDeleteAccount(accounts, () => updateData(page));
        };
    };

    const changeDate = (): void => {
        if (changingValidDateInformation && changingValidDateInformation.accountId && newValidDate) {
            useChangeAccountSubscriptionDate(changingValidDateInformation.accountId, newValidDate, () => updateData(page));
            closeDialogForChangeDate();
        };
    };

    const closeDialogForChangeDate = (): void => {
        setChangingValidDateInformation({
            currentDate: "",
            accountId: "",
        });
        setEditingValidDate(false);
    };

    const handleSelectAll = () => {
        if (checked) {
            Object.keys(checked).map((account) => {
                setChecked((prev) => {
                    return {
                        ...prev,
                        [account]: !selectAll
                    };
                });
            });
            setSelectAll(!selectAll);
        };
    };

    const sortIconsState = (cellName: string): ReactElement => {
        if (sorting.sortBy === cellName) {
            return <>
                {sorting.orderBy === DEFAULT_ORDERBY ?
                    <ArrowDown className={classes.sortIconStyle} />
                    :
                    <ArrowUp className={classes.sortIconStyle} />
                }
            </>;
        };
        return <></>;
    };

    const customizeValidDate = (value: string, accountId: string, fastSpringLegacyId: string) => {
        const year = new Date(value).getFullYear();
        const month = new Date(value).getMonth() >= 9 ? (new Date(value).getMonth() + 1) : "0" + (new Date(value).getMonth() + 1);
        const day = new Date(value).getDate() < 10 ? "0" + new Date(value).getDate() : new Date(value).getDate();
        const validUntil = year + "-" + month + "-" + day;
        return (
            <>
                <Tooltip title={fastSpringLegacyId || ""}>
                    <input
                        type={"date"}
                        style={{
                            padding: "5px 17px",
                            border: "none",
                            background: "transparent",
                            borderBottom: "1px solid #b1b1b1",
                            textAlign: "center"
                        }}
                        disabled={true}
                        value={validUntil}
                    />
                </Tooltip>
                <Button
                    style={{ marginLeft: "10px" }}
                    color="secondary"
                    type={"button"}
                    variant="contained"
                    aria-label="save"
                    onClick={() => {
                        setEditingValidDate(true);
                        setChangingValidDateInformation({
                            currentDate: validUntil,
                            accountId: accountId
                        });
                    }}
                >
                    CHANGE
                </Button>
            </>
        );
    };

    const getCheckedCompany = (): string => {
        if (checked) {
            const checkedAccountId = Object.keys(checked);
            let companyName = "";
            checkedAccountId.forEach(accountId => {
                if (checked[accountId]) {
                    const company = tableData.find(data => data.accountId === accountId);
                    if (company) {
                        companyName = company.company;
                    };
                };
            });
            return companyName;
        };
        return "";
    };

    const handleResetPage = () => {
        incomingPage.current = FIRST_PAGE;
        setPage(FIRST_PAGE);
    };

    const handleChangeShowTrialPlans = (_: any, check: boolean) => {
        handleResetPage();
        setSearchParameters({ ...searchParameters, showTrialPlans: { touched: true, enabled: check } });
    };

    const handleResetFilters = () => {
        setSearchByEmailValue("");
        setSearchByCompanyNameValue("");
        setSearchParameters(initialSearchParameters);
        handleResetPage();
    };

    const handleChangeCompanyName = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setSearchByCompanyNameValue(value);
        handleResetPage();
        if (value) {
            setSearchByEmailValue('');
            setSearchParameters({
                ...searchParameters,
                searchByEmail: false,
                search: value,
            });
        } else {
            setSearchParameters({
                ...searchParameters,
                search: '',
            });
        }
    };

    const handleChangeEmail = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value.trim();
        setSearchByEmailValue(value);
        handleResetPage();
        if (value) {
            setSearchByCompanyNameValue('');
            setSearchParameters({
                ...searchParameters,
                searchByEmail: true,
                search: value,
            })
        } else {
            setSearchParameters({
                ...searchParameters,
                searchByEmail: false,
                search: '',
            });
        }
    };

    return (
        <>
            <div className={classes.mainSection}>
                <Box display="flex" style={{ justifyContent: "space-between", alignItems: "center" }}>
                    <Typography variant="h1" color="secondary" className={classes.title}>Customer Accounts</Typography>
                    <Button
                        className={classes.showAccountsWithoutAdmin}
                        type="submit"
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                            navigate("/customer-accounts-without-admin")
                        }}
                    >
                        Show All Accounts Without Admins
                    </Button>
                </Box>
                <div className={classes.actionsArea}>
                    <div>
                        <SearchIcon style={{ color: "3F51B5" }} />
                        <Input
                            onChange={handleChangeCompanyName}
                            value={searchByCompanyNameValue}
                            id="search-by-company-name"
                            disableUnderline
                            placeholder={"Company"}
                            className={classes.searchInput}
                        />

                        <Input
                            onChange={handleChangeEmail}
                            value={searchByEmailValue}
                            id="search-by-email"
                            disableUnderline
                            placeholder={"Email"}
                            className={classes.searchInput}
                        />


                        <FormControlLabel
                            sx={{ margin: '0 12px' }}
                            color="secondary"
                            control={
                                <CheckBox
                                    color="secondary"
                                    checked={searchParameters.showTrialPlans.enabled}
                                    onChange={handleChangeShowTrialPlans}
                                />
                            }
                            label={
                                <Typography sx={{ margin: 0 }}>
                                    Show Trial Plans
                                </Typography>
                            }
                        />

                        <Button id="clear_button"
                            className={classes.clearButton}
                            variant="contained"
                            color="secondary"
                            disabled={!searchByEmailValue.length && !searchByCompanyNameValue.length && !searchParameters.showTrialPlans.touched && page === 0}
                            onClick={handleResetFilters}
                        >
                            Clear
                        </Button>

                    </div>

                    <Button id="submit_button"
                        className={classes.deleteButton}
                        type="submit"
                        disabled={!checked || Object.values(checked).filter(element => element).length === 0}
                        variant="contained"
                        color="secondary"
                        onClick={() => setIsDelete(true)}
                    >
                        Delete Company
                    </Button>
                </div>
                <Table className={classes.table} aria-label="admins table">

                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableHeaderTitle} >
                                <Typography style={{ fontWeight: 600 }}>
                                    <Checkbox
                                        checked={selectAll}
                                        onChange={handleSelectAll}
                                        name="select_all"
                                        id="select_all"
                                        color="secondary"
                                    />
                                    <span onClick={() => updateSorting(sortData.company as SortByForCustomers)}>Company</span>
                                    {sortIconsState(sortData.company)}
                                </Typography>
                            </TableCell>
                            <TableCell className={classes.tableHeaderTitle} onClick={() => updateSorting(sortData.email as SortByForCustomers)}>
                                <Typography style={{ fontWeight: 600 }}>
                                    Email
                                    {sortIconsState(sortData.email)}
                                </Typography>
                            </TableCell>
                            <TableCell className={classes.tableHeaderTitle} onClick={() => updateSorting(sortData.createdAt as SortByForCustomers)}>
                                <Typography style={{ fontWeight: 600 }}>
                                    Created At
                                    {sortIconsState(sortData.createdAt)}
                                </Typography>
                            </TableCell>
                            <TableCell className={classes.tableHeaderTitle} onClick={() => updateSorting(sortData.lastUsed as SortByForCustomers)}>
                                <Typography style={{ fontWeight: 600 }}>
                                    Last Used
                                    {sortIconsState(sortData.lastUsed)}
                                </Typography>
                            </TableCell>
                            <TableCell className={classes.tableHeaderTitle} onClick={() => updateSorting(sortData.candidateMonth as SortByForCustomers)}>
                                <Typography style={{ fontWeight: 600 }}>
                                    Candidate/Mo
                                    {sortIconsState(sortData.candidateMonth)}
                                </Typography>
                            </TableCell>
                            <TableCell className={classes.tableHeaderTitle}>
                                <Typography style={{ fontWeight: 600 }}>Plan</Typography>
                            </TableCell>
                            <TableCell className={classes.tableHeaderTitle} onClick={() => updateSorting(sortData.validUntil as SortByForCustomers)}>
                                <Typography style={{ fontWeight: 600 }}>
                                    Valid Until
                                    {sortIconsState(sortData.validUntil)}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {tableData && plans && !loading && (
                            tableData.map((account) => {
                                const { backgroundColor, color } = getNoticeableColors({ expired: account.expired, exceeded: account.exceeded });
                                const selected = Boolean(authTokens && authTokens.companyName === account.company);
                                return (
                                    <TableRow
                                        key={account.accountId}
                                        sx={{
                                            backgroundColor: backgroundColor,
                                            '& td': {
                                                color,
                                            },
                                            borderLeft: selected ? '1px solid black' : 'none'
                                        }}>
                                        <TableCell className={classes.tableHeaderTitle}>
                                            <Typography>
                                                {
                                                    !(authTokens && authTokens.companyName === account.company) ? (
                                                        <Checkbox
                                                            checked={checked ? checked[account.accountId] : false}
                                                            onChange={() => checkRow(account.accountId)}
                                                            name={`select_${account.accountId}`}
                                                            id={`select_${account.accountId}`}
                                                            color="secondary"
                                                        />
                                                    ) : (
                                                        <Checkbox
                                                            checked={false}
                                                            disabled={true}
                                                            color="secondary"
                                                        />
                                                    )
                                                }

                                                <Link to={""} onClick={() => {
                                                    usePostSelectAccount({ accountId: account.accountId }, () => {
                                                        setTokenAndFeatures({ doNotNavigateOnError: true, pathToNavigateOnSuccess: '/account' });
                                                    });
                                                }}>
                                                    {account.company}
                                                </Link>
                                            </Typography>
                                        </TableCell>
                                        <TableCell className={classes.tableHeaderTitle}>
                                            <Typography>{account.email}</Typography>
                                        </TableCell>
                                        <TableCell className={classes.tableHeaderTitle}>
                                            <Typography>{account.createdAt}</Typography>
                                        </TableCell>
                                        <TableCell className={classes.tableHeaderTitle}>
                                            <Typography>{account.lastUsed}</Typography>
                                        </TableCell>
                                        <TableCell className={classes.tableHeaderTitle}>
                                            <Typography>{account.candidatesThisMonth}</Typography>
                                        </TableCell>
                                        <TableCell className={classes.tableHeaderTitle}>
                                            {plans[account.accountId] && (
                                                <ChangePlanSelect
                                                    accountId={account.accountId}
                                                    options={plans[account.accountId]}
                                                    updateSubscription={() => updateData(page)}
                                                />
                                            )}
                                        </TableCell>
                                        <TableCell className={classes.tableHeaderTitle}>
                                            <Typography>
                                                {customizeValidDate(account.validUntil.toString(), account.accountId, account.fastSpringLegacyId)}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                );
                            })
                        )}
                    </TableBody>

                </Table>

                {loading && (
                    <div className={classes.loading}>
                        <CircularProgress size="30px" className={classes.loadingSvg} />
                    </div>
                )}

                <div className={classes.tableBottomSection}>
                    {
                        !tableData?.length && (
                            <div className={classes.noDataParentSection}>
                                <p>No rows</p>
                            </div>
                        )
                    }
                    <div className={classes.paginationSection}>
                        <div>
                            <p>{count} participant</p>
                        </div>
                        <div style={{ display: "flex", float: "right" }}>
                            <JumpToPage
                                count={count}
                                page={incomingPage.current}
                                rowsPerPage={rowsPerPage}
                                changePage={handlePageChange}
                            />
                            <TablePagination
                                component="div"
                                count={count}
                                page={incomingPage.current}
                                rowsPerPage={rowsPerPage}
                                onPageChange={handlePageChange}
                                rowsPerPageOptions={[10, 25, 50, 75, 100]}
                                onRowsPerPageChange={handleRowsChange}
                                className={classes.customPagination}
                            />
                        </div>
                    </div>
                </div>

                <Dialog
                    open={isDelete}
                    onClose={() => setIsDelete(false)}
                    aria-labelledby="delete-account"
                    aria-describedby="delete-account-description"
                >
                    <DialogTitle id="delete-account" style={{ textAlign: "center" }}>
                        Delete {checked && Object.values(checked).filter(element => element).length} account(s)
                    </DialogTitle>
                    <DialogContent style={{ width: "600px", textAlign: "center" }}>
                        <DialogContentText id="delete-account-description">
                            {checked && Object.values(checked).filter(element => element).length > 1
                                ? `Are you sure you want to delete the selected accounts with all their tests and candidates?`
                                : `Are you sure you want to delete account ${getCheckedCompany()} with all tests and candidates?`
                            }
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions style={{ margin: "0 auto 20px auto" }}>
                        <Button id="delete_accounts" color="secondary" variant="contained" onClick={deleteAccounts}>
                            Yes
                        </Button>
                        <Button color="secondary" variant="outlined" onClick={() => setIsDelete(false)}>
                            No
                        </Button>
                    </DialogActions>
                </Dialog>


                <Dialog
                    open={editingValidDate}
                    onClose={closeDialogForChangeDate}
                    aria-labelledby="edit-valid-date"
                >
                    <DialogTitle id="edit-valid-date" style={{ textAlign: "center" }}>
                        Change date
                    </DialogTitle>
                    <DialogContent className={classes.changeDateDialog} style={{ textAlign: "center" }}>
                        <TextField
                            id="date"
                            label="Set new date"
                            type="date"
                            defaultValue={changingValidDateInformation?.currentDate}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={(event) => {
                                setNewValidDate(event.target.value)
                            }}
                        />
                    </DialogContent>
                    <DialogActions style={{ margin: "25px auto 20px auto" }}>
                        <Button id="delete_accounts" color="secondary" variant="contained" onClick={changeDate}>
                            Ok
                        </Button>
                        <Button color="secondary" variant="outlined" onClick={closeDialogForChangeDate}>
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>

            </div>
        </>
    );
};

export default AccountList;
