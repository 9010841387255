import Grid from "@mui/material/Grid";
import { Theme } from "@mui/material/styles";
import { makeStyles } from 'tss-react/mui';
import React from "react";
import TestCard, { TestCardProps } from "../TestCard/TestCard";

const useStyles = makeStyles()((theme: Theme) => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(1),
    },
})
);

export interface TestCardContainerProps {
    cards: TestCardProps[];
}

const TestCardContainer = (props: TestCardContainerProps) => {
    const { classes } = useStyles();

    return (
        <div className={classes.root}>
            <Grid container spacing={3} direction="row" justifyContent="flex-start" alignItems="flex-start">
                {props.cards.map((card) => (
                    <Grid key={card.testId} item xs={12} sm={6} md={6} lg={6} xl={6}>
                        <TestCard {...card} />
                    </Grid>
                ))}
            </Grid>
        </div>
    );
};

export default TestCardContainer;
