import log, { LogLevelDesc, RootLogger } from 'loglevel';

export const getLogger = (): RootLogger => {
    const level = Number(localStorage.getItem('ttprodebug') || -1);

    if (level >= 0 && level <= 5) {
        log.setLevel(level as LogLevelDesc, false);
    } else {
        log.setLevel(log.levels.SILENT, false);
    }

    return log;
}