import React, { ReactElement, useEffect, useState } from "react";
import {
    getAccountsParams,
    GetAllAccountsWithoutAdminData,
    OrderBy,
    SortByForCustomers,
    useDeleteAccount,
    useGetAllAccountsDataWithoutAdmin, usePostAdminInvitationForAccountWithoutAdmin
} from "../../hooks/useApi";
import { CircularProgress, Button, Box } from "@mui/material";
import { makeStyles } from 'tss-react/mui';
import Checkbox from "@mui/material/Checkbox";
import Table from "@mui/material/Table";
import { theme } from "../../theme/Theme";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import Typography from "@mui/material/Typography";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import JumpToPage from "../JumpToPage/JumpToPage";
import { useAuthTokens } from "../../context/auth";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import ArrowDown from "@mui/icons-material/ArrowDownward";
import ArrowUp from "@mui/icons-material/ArrowUpward";
import { useNavigate } from "react-router-dom";
import { Email } from "@mui/icons-material";
import TextField from "@mui/material/TextField";
import * as Yup from "yup";

const DEFAULT_SORTBY = "company";
const DEFAULT_ORDERBY = "desc";

const sortData = {
    company: "company",
    email: "email",
    createdAt: "createdAt",
    lastUsed: "lastUsed",
    candidateMonth: "candidateMonth",
    plan: "plan",
    validUntil: "validUntil",
};

const enGbYyMmDdFormatter = new Intl.DateTimeFormat("en-GB", {
    year: "numeric",
    month: "short",
    day: "numeric"
});

const enUsHhMmFormatter = new Intl.DateTimeFormat("en-US", {
    hour: "numeric",
    minute: "2-digit"
});

interface TableDataPropsWithoutAdmin {
    company: string;
    contact: string;
    accountId: string;
    createdAt: string;
    lastUsed: string;
    candidatesThisMonth: number;
}

const useStyles = makeStyles()({
    mainSection: {
        paddingLeft: "40px",
        paddingRight: "40px"
    },
    title: {
        fontFamily: "'Lexend', sans-serif;",
        textAlign: "left",
        display: "block",
        fontSize: "34px",
        margin: "8px 0 20px"
    },
    paginationSection: {
        display: "flex",
        justifyContent: "space-between",
        paddingLeft: "25px",
        paddingRight: "25px",
        paddingTop: "10px"
    },
    noDataParentSection: {
        paddingLeft: "25px",
        paddingRight: "25px",
        paddingTop: "10px",
        borderBottom: "1px solid #e0e0e0",
        textAlign: "center",
        cursor: "pointer",
        "&:hover": {
            background: "#ececec"
        }
    },
    tableBottomSection: {
        background: "#ffffff"
    },
    emptyDataSection: {
        background: "#ffffff",
        paddingLeft: "25px",
        paddingRight: "25px",
        paddingTop: "10px"
    },
    customPagination: {
        float: "right"
    },
    sortIconStyle: {
        marginLeft: "6px",
        fontSize: "19px"
    },
    table: {
        width: "100%",
        background: "#ffffff",
        border: "1px solid #e9e9e9"
    },
    tableHeaderTitle: {
        color: "#4c4c4c",
        cursor: "pointer",
    },
    flex: {
        display: "flex",
        justifyContent: "start",
        alignItems: "center"
    },
    profileImage: {
        color: "#7a7a7a",
        fontSize: "50px"
    },
    textBold: {
        marginBottom: "0px",
        color: "#7a7a7a",
        fontSize: "17px",
        fontWeight: 800
    },
    textNormal: {
        marginBottom: "0px",
        color: "#7a7a7a",
        fontSize: "17px"
    },
    spaceFromLeft: {
        marginLeft: "10px"
    },
    textStyle: {
        marginBottom: "0px",
        color: "#7a7a7a",
        fontSize: "17px"
    },
    testStatusSection: {
        width: "auto",
        border: "2px solid #49cea1",
        textAlign: "center",
        padding: "6px 0px",
        borderRadius: "15px"
    },
    arrowSection: {
        marginLeft: "10px"
    },
    tableRow: {
        cursor: "pointer",
        "&:hover": {
            background: "#f9f9f9"
        }
    },
    passText: {
        color: theme.palette.primary.dark
    },
    naText: {
        color: theme.palette.text.secondary,
        marginLeft: "30px",
        fontSize: "24px"
    },
    loading: {
        height: "50px",
        marginTop: "1px",
        display: "flex",
        alignItems: "center",
        marginBottom: "3px",
        background: "#ffffff"
    },
    loadingSvg: {
        margin: "0 auto",
        display: "block",
        color: "#3F51B5"
    },
    errText: {
        color: theme.palette.error.main
    },
    fieldSmall: {
        fontSize: "16px",
        color: theme.palette.primary.dark
    },
    statusPassed: {
        width: "114px",
        height: "27px",
        border: "2px solid #02BA7C",
        borderRadius: "40px",
        color: "#02BA7C"
    },
    statusFail: {
        width: "114px",
        height: "27px",
        border: "2px solid #E0789A",
        borderRadius: "40px",
        color: "#E0789A"
    },
    statusIncomplete: {
        width: "114px",
        height: "27px",
        border: "2px solid #ADADAD",
        borderRadius: "40px",
        color: "#ADADAD"
    },
    statusNotStarted: {
        width: "114px",
        height: "27px",
        color: "#ADADAD"
    },
    statusSpan: {
        lineHeight: "27px",
        display: "block",
        textAlign: "center",

        fontSize: "14px",
        fontWeight: 700,
        marginTop: "-1px"
    },
    actionsArea: {
        background: "#ffffff",
        padding: "12px",
        display: "flex",
        justifyContent: "right"
    },
    plusIcon: {
        width: "20px",
        height: "20px"
    },
    deleteButton: {
        padding: "5px 40px"
    },
    showAccountsWithoutAdmin: {
        padding: "5px 40px",
        height: "40px"
    },
    clearButton: {
        padding: "5px 15px",
        marginLeft: "15px"
    },
    searchInput: {
        borderBottom: '1px solid #3F51B5',
        marginLeft: "15px",
        width: "320px"
    },
    inviteTitle: {
        paddingBottom: "10px",
        "& .MuiTypography-root": {
            fontFamily: "'Lexend', sans-serif;",
            fontSize: "34px"
        },
        "& svg": {
            width: "60px",
            height: "48px"
        }
    },
    boxField: {
        marginTop: "15px",
        marginBottom: "15px"
    },
    inputField: {
        height: "35px",
        lineHeight: "35px",
        color: theme.palette.primary.dark
    },
});

const emailValidationSchema = Yup.string()
    .email("Email must be a valid email")
    .required("Email is required")
    .max(100, "Email should be no longer than 100 characters");

const AccountListWithoutAdmin = () => {
    const { classes } = useStyles();
    const authTokens = useAuthTokens();
    const navigate = useNavigate();
    const [page, setPage] = useState(0);
    const [isInvite, setIsInvite] = useState(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [selectAll, setSelectAll] = useState<boolean>(false)
    const [size, setSize] = useState(10);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [checked, setChecked] = useState<{ [key: string]: boolean }>()
    const [isDelete, setIsDelete] = useState<boolean>(false)
    const [count, setCount] = useState(0);
    const [activeAccountId, setActiveAccountId] = useState<string>();
    const [invitationEmail, setInvitationEmail] = useState<string>("")
    const [invitationEmailIsValid, setInvitationEmailIsValid] = useState<boolean>(true)
    const [touched, setTouched] = useState<boolean>(false)
    const [validationErrorMessage, setValidationErrorMessage] = useState<string>("")
    const [sorting, setSorting] = useState<{ sortBy: SortByForCustomers; orderBy: OrderBy }>({
        sortBy: DEFAULT_SORTBY,
        orderBy: DEFAULT_ORDERBY
    });
    const handleCloseInvite = () => {
        setIsInvite(false);
        setInvitationEmail("");
        setTouched(false);
        setValidationErrorMessage("");
        setInvitationEmailIsValid(true);
    };

    const [tableData, setTableData] = useState<TableDataPropsWithoutAdmin[]>([{
        company: "",
        contact: "",
        accountId: "",
        createdAt: "",
        lastUsed: "",
        candidatesThisMonth: 0,
    }]);

    const updateData = (): void => {
        setLoading(true);
        setIsDelete(false);
        setSelectAll(false);
        setChecked({})
        const searchData: getAccountsParams = {
            page: page,
            size: size,
            ...sorting
        };
        useGetAllAccountsDataWithoutAdmin(
            searchData,
            initAccountData,
            setCount
        );
    };

    const handlePageChange = (event: any, newPage: number) => {
        setPage(newPage);
    };

    const handleRowsChange = (event: any): void => {
        setSize(parseInt(event.target.value, 10));
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const updateSorting = (sortByRowName: SortByForCustomers): void => {
        let order: OrderBy = DEFAULT_ORDERBY;
        if (sorting.sortBy === sortByRowName) {
            if (sorting.orderBy === DEFAULT_ORDERBY) {
                order = "asc";
            }
        };
        setSorting({
            sortBy: sortByRowName,
            orderBy: order
        });
    };

    const initAccountData = (data: GetAllAccountsWithoutAdminData[]): void => {
        const newTableData: TableDataPropsWithoutAdmin[] = data.map((account) => {
            if (account.companyName !== authTokens?.companyName) {
                setChecked((prev) => {
                    return {
                        ...prev,
                        [account.accountId]: false
                    };
                });
            };
            return {
                company: account.companyName,
                contact: account.lastName + " " + account.firstName,
                accountId: account.accountId,
                createdAt: enGbYyMmDdFormatter.format(new Date(account.createdAt.toString())) + " " + enUsHhMmFormatter.format(new Date(account.createdAt.toString())),
                lastUsed: account.lastUsed ? enGbYyMmDdFormatter.format(new Date(account.lastUsed.toString())) + " " + enUsHhMmFormatter.format(new Date(account.lastUsed.toString())) : "",
                candidatesThisMonth: account.candidatesThisMonth
            };
        });
        setTableData(newTableData);
        setLoading(false);
    };

    useEffect(() => {
        updateData();
    }, [page, size, rowsPerPage, sorting]);

    const checkRow = (accountId: string) => {
        setChecked((prev) => {
            return {
                ...prev,
                [accountId]: checked ? !checked[accountId] : true
            };
        });
    }

    const deleteAccounts = (): void => {
        if (checked) {
            const accounts = Object.keys(checked).map((accountId) => {
                if (checked[accountId]) {
                    return accountId;
                }
                return null;
            }).filter(account => account)
            useDeleteAccount(accounts, updateData);
        }
    }

    const handleSelectAll = () => {
        if (checked) {
            Object.keys(checked).map((account) => {
                setChecked((prev) => {
                    return {
                        ...prev,
                        [account]: !selectAll
                    };
                });
            });
            setSelectAll(!selectAll);
        }
    }

    const sortIconsState = (cellName: string): ReactElement => {
        if (sorting.sortBy === cellName) {
            return <>
                {sorting.orderBy === DEFAULT_ORDERBY ?
                    <ArrowDown className={classes.sortIconStyle} />
                    :
                    <ArrowUp className={classes.sortIconStyle} />
                }
            </>;
        };
        return <></>;
    };

    const getCheckedCompany = (): string => {
        if (checked) {
            const checkedAccountId = Object.keys(checked);
            let companyName = "";
            checkedAccountId.forEach(accountId => {
                if (checked[accountId]) {
                    const company = tableData.find(data => data.accountId === accountId)
                    if (company) {
                        companyName = company.company;
                    }
                }
            });
            return companyName;
        }
        return "";
    }

    const handleChangeInvitationEmail = (email: string): void => {
        setInvitationEmail(email);
        emailValidationSchema.validate(email)
            .then(() => {
                setInvitationEmailIsValid(true);
            }).catch((validateError) => {
                setInvitationEmailIsValid(false);
                setValidationErrorMessage(validateError.errors);
            });
        console.log(email)
    }

    const sendInvitation = (): void => {
        if (activeAccountId) {
            usePostAdminInvitationForAccountWithoutAdmin(invitationEmail, activeAccountId, updateData)
        }
        handleCloseInvite()
    }

    return (
        <>
            <div className={classes.mainSection}>
                <Box display="flex" style={{ justifyContent: "space-between", alignItems: "center" }}>
                    <Typography variant="h1" color="secondary" className={classes.title}>Accounts Without Emails</Typography>
                    <Button
                        className={classes.showAccountsWithoutAdmin}
                        type="submit"
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                            navigate("/customer-accounts")
                        }}
                    >
                        Show All Accounts
                    </Button>
                </Box>
                <div className={classes.actionsArea}>
                    <Button id="submit_button"
                        className={classes.deleteButton}
                        type="submit"
                        disabled={!checked || Object.values(checked).filter(element => element).length === 0}
                        variant="contained"
                        color="secondary"
                        onClick={() => setIsDelete(true)}
                    >
                        Delete Company
                    </Button>
                </div>
                <Table className={classes.table} aria-label="admins table">

                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableHeaderTitle} >
                                <Typography style={{ fontWeight: 600 }}>
                                    <Checkbox
                                        checked={selectAll}
                                        onChange={handleSelectAll}
                                        name="select_all"
                                        id="select_all"
                                        color="secondary"
                                    />
                                    <span onClick={() => updateSorting(sortData.company as SortByForCustomers)}>Company</span>
                                    {sortIconsState(sortData.company)}
                                </Typography>
                            </TableCell>
                            <TableCell
                                className={classes.tableHeaderTitle}
                            >
                                <Typography style={{ fontWeight: 600 }}>
                                    Email
                                </Typography>
                            </TableCell>
                            <TableCell
                                className={classes.tableHeaderTitle}
                                onClick={() => updateSorting(sortData.createdAt as SortByForCustomers)}
                            >
                                <Typography style={{ fontWeight: 600 }}>
                                    Created At
                                    {sortIconsState(sortData.createdAt)}
                                </Typography>
                            </TableCell>
                            <TableCell
                                className={classes.tableHeaderTitle}
                                onClick={() => updateSorting(sortData.lastUsed as SortByForCustomers)}
                            >
                                <Typography style={{ fontWeight: 600 }}>
                                    Last Used
                                    {sortIconsState(sortData.lastUsed)}
                                </Typography>
                            </TableCell>
                            <TableCell
                                className={classes.tableHeaderTitle}
                                onClick={() => updateSorting(sortData.candidateMonth as SortByForCustomers)}
                            >
                                <Typography style={{ fontWeight: 600 }}>
                                    Candidate/Mo
                                    {sortIconsState(sortData.candidateMonth)}
                                </Typography>
                            </TableCell>
                            <TableCell className={classes.tableHeaderTitle}>
                                <Typography style={{ fontWeight: 600 }}>
                                    Actions
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {tableData && !loading && (
                            tableData.map((account) => {
                                return (
                                    <TableRow
                                        key={account.accountId}
                                    >
                                        <TableCell className={classes.tableHeaderTitle}>
                                            <Typography>
                                                <Checkbox
                                                    checked={checked ? checked[account.accountId] : false}
                                                    onChange={() => checkRow(account.accountId)}
                                                    name={`select_${account.accountId}`}
                                                    id={`select_${account.accountId}`}
                                                    color="secondary"
                                                />
                                                <span>{account.company}</span>
                                            </Typography>
                                        </TableCell>
                                        <TableCell className={classes.tableHeaderTitle}>
                                            <Typography>------------------</Typography>
                                        </TableCell>
                                        <TableCell className={classes.tableHeaderTitle}>
                                            <Typography>{account.createdAt}</Typography>
                                        </TableCell>
                                        <TableCell className={classes.tableHeaderTitle}>
                                            <Typography>{account.lastUsed}</Typography>
                                        </TableCell>
                                        <TableCell className={classes.tableHeaderTitle}>
                                            <Typography>{account.candidatesThisMonth}</Typography>
                                        </TableCell>
                                        <TableCell className={classes.tableHeaderTitle}>
                                            <Button id="btn_invite" color="secondary" variant="contained"
                                                startIcon={<img className={classes.plusIcon} alt=""
                                                    src="./assets/img/plus.svg" />}
                                                onClick={() => {
                                                    setActiveAccountId(account.accountId)
                                                    setIsInvite(true)
                                                }}>
                                                Invite
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                );
                            })
                        )}
                    </TableBody>

                </Table>

                {loading && (
                    <div className={classes.loading}>
                        <CircularProgress size="30px" className={classes.loadingSvg} />
                    </div>
                )}

                <div className={classes.tableBottomSection}>
                    {
                        !tableData?.length && (
                            <div className={classes.noDataParentSection}>
                                <p>No rows</p>
                            </div>
                        )
                    }
                    <div className={classes.paginationSection}>
                        <div>
                            <p>{count} participant</p>
                        </div>
                        <div style={{ display: "flex", float: "right" }}>
                            <JumpToPage
                                count={count}
                                page={page}
                                rowsPerPage={rowsPerPage}
                                changePage={handlePageChange}
                            />

                            <TablePagination
                                component="div"
                                count={count}
                                page={page}
                                rowsPerPage={rowsPerPage}
                                onPageChange={handlePageChange}
                                rowsPerPageOptions={[10, 25, 50, 75, 100]}
                                onRowsPerPageChange={handleRowsChange}
                                className={classes.customPagination}
                            />
                        </div>
                    </div>
                </div>

                <Dialog
                    open={isDelete}
                    onClose={() => setIsDelete(false)}
                    aria-labelledby="delete-account"
                    aria-describedby="delete-account-description"
                >
                    <DialogTitle id="delete-account" style={{ textAlign: "center" }}>
                        Delete {checked && Object.values(checked).filter(element => element).length} account(s)
                    </DialogTitle>
                    <DialogContent style={{ width: "600px", textAlign: "center" }}>
                        <DialogContentText id="delete-account-description">
                            {checked && Object.values(checked).filter(element => element).length > 1
                                ? `Are you sure you want to delete the selected accounts with all their tests and candidates?`
                                : `Are you sure you want to delete account ${getCheckedCompany()} with all tests and candidates?`
                            }
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions style={{ margin: "0 auto 20px auto" }}>
                        <Button id="delete_accounts" color="secondary" variant="contained" onClick={deleteAccounts}>
                            Yes
                        </Button>
                        <Button color="secondary" variant="outlined" onClick={() => setIsDelete(false)}>
                            No
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={isInvite}
                    onClose={handleCloseInvite}
                    aria-labelledby="invite-dialog-title"
                    aria-describedby="invite-dialog-description"

                >
                    <Box style={{ padding: "5px 10px 25px" }}>
                        <DialogTitle id="invite-dialog-title" className={classes.inviteTitle}><Email />{"Invite a New Admin"}
                        </DialogTitle>
                        <DialogContent>
                            <TextField
                                margin="dense"
                                name="email"
                                id="inviteEmail"
                                label="Email"
                                value={invitationEmail}
                                type="text"
                                fullWidth
                                helperText={
                                    invitationEmail.length && touched && !invitationEmailIsValid
                                        ? validationErrorMessage
                                        : ""
                                }
                                variant="outlined"
                                color="secondary"
                                error={invitationEmail.length && !invitationEmailIsValid && touched ? true : false}
                                onChange={(event) => handleChangeInvitationEmail(event.target.value)}
                                onBlur={() => setTouched(true)}
                                className={classes.boxField}
                                InputProps={{ classes: { input: classes.inputField } }}
                            />
                            <Box style={{ display: "flex", justifyContent: "center", gap: "20px" }}>
                                <Button onClick={handleCloseInvite} color="secondary" autoFocus>
                                    Cancel
                                </Button>
                                <Button onClick={sendInvitation} id="btn_send_invitation" disabled={!invitationEmailIsValid || !invitationEmail.length} color="secondary"
                                    variant="contained">
                                    SEND INVITATION
                                </Button>
                            </Box>
                        </DialogContent>
                    </Box>
                </Dialog>

            </div>
        </>
    );
};

export default AccountListWithoutAdmin;
