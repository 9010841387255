import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { makeStyles } from 'tss-react/mui';
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import RefreshIcon from "@mui/icons-material/Refresh";
import IconButton from "@mui/material/IconButton";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useFeature } from "flagged";
import { Link } from "react-router-dom";
import { ALL_RESULTS_URL, URL_TEXT_COLOR } from "../UserSearch/UserSearch";
import Loading from "../Loading/Loading";

const useStyles = makeStyles()((theme) => ({
    mainBox: {
        marginLeft: "40px",
        marginBottom: "40px",
        display: 'flex',
        alignItems: 'center',
    },
    header: {
        fontFamily: "'Lexend', sans-serif;",
        margin: 0,
    },
    testSelect: {
        fontSize: "29px",
        color: theme.palette.primary.dark,
        marginLeft: "15px",
        fontFamily: "'Lexend', sans-serif;",
        marginTop: "4px"
    },
    menuSelect: {
        fontSize: "29px",
        color: theme.palette.primary.dark
    },
    selected: {
        background: "none !important"
    },
    icon: {
        fontSize: "30px"
    },
    displayAll: {
        marginRight: "50px",
        padding: "7px 25px",
        height: "45px",
        fontSize: "15px"
    },
    filterSection: {
        marginRight: "50px",
        fontSize: "20px",
        color: URL_TEXT_COLOR
    }
}));

interface TestResultHeaderData {
    testId: string;
    testName: string;
    active: boolean;
}

export interface TestResultHeaderProps {
    headerData: TestResultHeaderData[];
    selectedTestId: string;
    onTestSelected: (testId: string) => void;
    onRefreshTests: () => void;
    loading: boolean;
}

const TestResultHeader = ({
    headerData,
    selectedTestId,
    onTestSelected,
    onRefreshTests,
    loading,
}: TestResultHeaderProps) => {
    const { classes } = useStyles();
    const showIcons = useFeature("d_ai_s");
    const handleChange = (event: SelectChangeEvent<string>) => {
        console.log(`handleChange() - new value: ${event.target.value}`);
        onTestSelected(event.target.value as string);
    };

    const getTestName = (testName: string, active: boolean): string => {
        if (active) {
            return testName;
        } else {
            return testName + " (inactive)";
        }
    };

    return (
        <Box display="flex" style={{ justifyContent: "space-between", alignItems: "baseline" }}>
            <Box display="flex" className={classes.mainBox}>
                <Typography variant="h1" color="secondary" className={classes.header}>Results</Typography>
                <Select
                    labelId="select-test-label"
                    id="select-test"
                    value={selectedTestId}
                    variant="standard"
                    onChange={handleChange}
                    className={classes.testSelect}
                    disableUnderline
                    classes={{ select: classes.selected }}
                >
                    {headerData.map(({ testId, testName, active }, index) => (
                        <MenuItem key={index} value={testId} className={classes.menuSelect}
                            classes={{ selected: classes.selected }}>
                            {getTestName(testName, active)}
                        </MenuItem>
                    ))}
                </Select>

                {showIcons ? (
                    <IconButton
                        sx={{ marginTop: '4px' }}
                    >
                        <InfoOutlinedIcon className={classes.icon} />
                    </IconButton>) : (null)
                }

                <IconButton
                    aria-label={`refresh tests`}
                    component="span"
                    id={`refresh_tests`}
                    onClick={onRefreshTests}
                    sx={{ marginTop: '4px', marginRight: '16px' }}
                >
                    <RefreshIcon className={classes.icon} />
                </IconButton>
                <Loading show={loading} delay={1000} />
            </Box>

            <div className={classes.filterSection}>
                <span style={{ fontWeight: 700 }}>FILTERED RESULTS</span>
                <span> | </span>
                <Link style={{ color: URL_TEXT_COLOR }} to={ALL_RESULTS_URL}>
                    <span>DISPLAY ALL RESULTS</span>
                </Link>
            </div>

        </Box>
    );
};

export default TestResultHeader;
