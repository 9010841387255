import React, { useEffect, useState } from "react";
import Iframe from "react-iframe";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

import { makeStyles } from 'tss-react/mui';
import * as wizardCss from "../../theme/wizardStyle";
import DoneIcon from '@mui/icons-material/Done';
import { useFeature } from "flagged";
import SubSteps from "../TestWizard/SubStepsEnum";
import usePreloadGame from "../../hooks/usePreloadGame";

const useStyles = makeStyles()((theme) => ({
    root: {
        width: "100%",
    },
    button: {
        marginRight: theme.spacing(1),
    },
    cardRoot: {
        minWidth: 400,
        maxWidth: 400,
        minHeight: 400,

        maxHeight: 400,
        alignContent: "center",
    },
    centerImg: {
        width: 500,
        height: 282,
        margin: "15px 0 0 20px",
        borderRadius: 10,
        backgroundImage: "url(./assets/img/applet.png)",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
    },
    iFrameBox: {
        position: "relative",
        margin: "-22px 0 0 5px",
        backgroundImage: "url(./assets/img/loader_bg.png)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "840px 478px",
        backgroundPosition: "14px 6px",
        width: "870px",
        overflow: "hidden",
    },
    iFrame: {
        display: "block",
        zIndex: 10,
    },
    progress: {
        position: "absolute",
        display: "flex",
        flexDirection: "column",
        top: "238px",
        left: "428px",
        color: theme.palette.text.primary,
        fontSize: "30px",
        fontWeight: 500,
        width: "110px",
        height: "106px",
        transform: "translate(-50%, -50%)",
        '& .MuiCircularProgress-root': {
            color: theme.palette.text.primary,
            margin: "0 auto 10px",
        },

    },
    attemptBox: {
        width: "max-content",
        height: 37,
        border: "3px solid gray",
        borderRadius: 6,
        marginTop: "4px",
        marginBottom: "15px",
        display: "inline-block",
        marginLeft: "15px",
    },
    attemptText: {
        lineHeight: "33px",
        margin: "0 5px",
        textAlign: "center",
    },
}));

export enum StepThreeSubSteps {
    InstructionStep = 0,
    PracticeStep,
    PracticeCompletedStep,
}

export type TestStatus = "PASS" | "FAIL" | "NOT_STARTED";

export type PracticeData = {
    acc: string;
    nwpm: string;
    gwpm: string;
    ncpm: string;
    gcpm: string;
    dur: string;
    time: string;
    err: string;
    hits: string;
    status: TestStatus;
};

export interface TestStepThreeProps {
    handleNext: () => void;
    handleBack: () => void;
    practiceData: PracticeData;
    resetPracticeData: () => void;
    setPracticeData: (data: PracticeData) => void;
    getTestStatus: (netWpm: string, accuracy: string) => TestStatus;
    subStep: SubSteps;
    setSubStep: (subStep: SubSteps) => void;
    setupSessionStorage: () => void;
}

const TestStepThree = (props: TestStepThreeProps) => {
    const { classes } = useStyles();
    const { classes: wizardClasses } = wizardCss.wizardStyles();
    const hideFractional = useFeature('s_hide_fractional');

    usePreloadGame();

    useEffect(() => {
        const handler = (event: MessageEvent) => {
            if (event.origin !== window.location.origin) {
                console.log(`handler() - expected event.origin: ${window.location.origin}`);
                console.log(`handler() - but got event.origin: ${event.origin}`);
                return;
            }

            try {
                if (event.data && typeof event.data === "string") {
                    const eventData = JSON.parse(event.data);
                    console.log(`test step 3 handler() - eventData: ${eventData}`);
                    if (eventData.acc) {
                        console.log(`test step 3 handler() - saving to practice test data`);
                        props.setPracticeData({
                            acc: eventData.acc,
                            nwpm: eventData.nwpm,
                            gwpm: eventData.gwpm,
                            ncpm: eventData.ncpm,
                            gcpm: eventData.gcpm,
                            dur: eventData.dur,
                            time: eventData.time,
                            err: eventData.err,
                            hits: eventData.hits,
                            status: props.getTestStatus(eventData.nwpm, eventData.acc)
                        });
                        props.setSubStep(SubSteps.SubStepFour);
                    }
                }
            } catch (error) {
                console.log(`handler() - ${error}`);
            }
        };

        window.addEventListener("message", handler);

        return () => window.removeEventListener("message", handler);
    }, []);

    const [isAppletBack, setIsAppletBack] = useState(true);

    switch (props.subStep) {
        case SubSteps.SubStepOne: {
            return (
                <Container className={wizardClasses.mainContainer}>
                    <Typography variant="h1" component="h1">Take a One Minute Practice Test</Typography>
                    <Box display="flex">
                        <div className={wizardClasses.leftArea} style={{ width: "30%" }}>
                            <Typography variant="subtitle1" style={{ textAlign: "right", marginTop: 50 }}>First, take a one minute <br /> practice test to understand <br />how the typing test <br />works. You can redo this<br /> practice test as many times<br /> as you want to.</Typography>
                        </div>
                        <div>
                            <div className={classes.centerImg}></div>
                        </div>
                    </Box>
                    <div className={wizardClasses.buttonContainer}>
                        <Button className={wizardClasses.button} color="secondary" onClick={props.handleBack} id="btn_back" >
                            Back
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                                props.setupSessionStorage();
                                props.setSubStep(SubSteps.SubStepTwo);
                            }}
                            className={wizardClasses.button}
                            id="btn_start"
                        >
                            START PRACTICE TEST
                        </Button>
                        <Button className={wizardClasses.button} color="secondary" onClick={props.handleNext} id="btn_next" >
                            SKIP PRACTICE
                        </Button>
                    </div>
                </Container >
            )
            break;
        }
        case SubSteps.SubStepTwo: {
            setTimeout(() => {
                setIsAppletBack(false);
            }, 15 * 1000);
            return (
                <Container className={wizardClasses.mainContainer} style={{ display: "flex", marginTop: "47px", position: "relative" }}>
                    <Typography variant="h1" component="h1" style={{ textAlign: "left", margin: "-27px 47px 0 0", width: "120px" }}>Practice<br />Test</Typography>
                    <Box className={classes.iFrameBox}>
                        <Iframe
                            url="/index2.html?gt=pro&sessionParams=true"
                            width="870px"
                            height="491x"
                            id="appletIframe"
                            className={classes.iFrame}
                            position="relative"
                            frameBorder={0}
                            ariaHidden={true}
                            onLoad={() => {
                                if (document) {
                                    const iframeElement = document.getElementById("appletIframe");
                                    if (iframeElement) {
                                        iframeElement.focus();
                                    }
                                }
                            }}
                        />

                        <Box className={classes.progress} style={{ display: isAppletBack ? "flex" : "none" }}>
                            <CircularProgress style={{ width: "50px", height: "50px" }} />
                            <span style={{ display: "block" }}>Loading</span>
                        </Box>
                    </Box>
                    <div className={wizardClasses.buttonContainer} style={{ left: "85px" }}>
                        <Button className={wizardClasses.button} color="secondary"
                            onClick={() => {
                                props.setSubStep(SubSteps.SubStepThree);
                            }}
                            id="btn_interrupt" >
                            SKIP PRACTICE
                        </Button>
                    </div>
                </Container>
            );
        }
        case SubSteps.SubStepThree: {
            return (
                <Container className={wizardClasses.mainContainer}>
                    <Typography variant="h1" component="h1">Practice Test Interrupted</Typography>
                    <Box display="flex">

                        <div className={wizardClasses.centerArea}>
                            <div><div className={wizardClasses.labelSpan} style={{ width: "30%" }}>Gross Speed</div><div className={wizardClasses.titleSpan}>... </div></div>
                            <div><div className={wizardClasses.labelSpan} style={{ width: "30%" }}>Accuracy</div><div className={wizardClasses.titleSpan}>... </div></div>
                            <div><div className={wizardClasses.labelSpan} style={{ width: "30%" }}> Net Speed</div><div className={wizardClasses.titleSpan}>... </div></div>
                            <div><div className={wizardClasses.labelSpan} style={{ width: "30%" }}>Status</div><div className={wizardClasses.titleSpan}>Practice Test Interrupted </div></div>
                        </div>
                    </Box>
                    <div className={wizardClasses.buttonContainer} style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                        <Button
                            color="secondary"
                            onClick={props.handleBack}
                            className={wizardClasses.button}
                            id="btn_back"
                        >
                            Back
                        </Button>

                        <Button
                            color="secondary"
                            onClick={() => {
                                props.setSubStep(SubSteps.SubStepOne);
                            }}
                            className={wizardClasses.button}
                            id="btn_new_attempt"
                            autoFocus
                        >
                            Practice Again
                        </Button>

                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => { props.setSubStep(SubSteps.SubStepOne); props.handleNext(); }}
                            className={wizardClasses.button}
                            id="btn_done"
                            startIcon={<DoneIcon />}
                        >
                            Finish Practicing
                        </Button>
                    </div>
                </Container>
            )
        }
        default: {
            const netWpm = hideFractional && !Number.isNaN(Number(props.practiceData.nwpm)) ? Math.round(Number(props.practiceData.nwpm)) : props.practiceData.nwpm;
            const gWpm = hideFractional && !Number.isNaN(Number(props.practiceData.gwpm)) ? Math.round(Number(props.practiceData.gwpm)) : props.practiceData.gwpm;
            return (
                <Container className={wizardClasses.mainContainer}>
                    <Typography variant="h1" component="h1">Practice Test Completed</Typography>
                    <Box display="flex">

                        <div className={wizardClasses.centerArea}>
                            <div><div className={wizardClasses.labelSpan} style={{ width: "30%" }}>Gross Speed</div><div className={wizardClasses.titleSpan}>{gWpm} WPM</div></div>
                            <div><div className={wizardClasses.labelSpan} style={{ width: "30%" }}>Accuracy</div><div className={wizardClasses.titleSpan}>{props.practiceData.acc}%</div></div>
                            <div><div className={wizardClasses.labelSpan} style={{ width: "30%" }}> Net Speed</div><div id="netWpm" className={wizardClasses.titleSpan}>{netWpm} WPM</div></div>
                            <div><div className={wizardClasses.labelSpan} style={{ width: "30%" }}>Status</div><div className={wizardClasses.titleSpan}>Test Completed</div></div>
                        </div>
                    </Box>
                    <div className={wizardClasses.buttonContainer}>
                        <Button
                            color="secondary"
                            onClick={() => {
                                props.setupSessionStorage();
                                props.setSubStep(SubSteps.SubStepTwo);
                            }}
                            className={wizardClasses.button}
                            id="btn_start"
                        >
                            PRACTICE AGAIN
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                                props.setSubStep(SubSteps.SubStepOne);
                                props.handleNext();
                            }}
                            className={wizardClasses.button}
                            id="btn_next"
                        >
                            Next
                        </Button>
                    </div>
                </Container>
            )
        }
    }
};

export default TestStepThree;
