import { useState, useEffect } from "react";

const getSessionStorageOrDefaultKey = (key: string, defaultValue: string) => {
  const stored = sessionStorage.getItem(key);
  if (!stored) {
    return defaultValue;
  }
  return stored;
}

const useSessionStorage = (key: string, defaultValue: string): [string, (value: string) => void] => {
  const [value, setValue] = useState(getSessionStorageOrDefaultKey(key, defaultValue));

  useEffect(() => {
    sessionStorage.setItem(key, value);
  }, [key, value]);
  return [value, setValue];
}

const clearSessionStorageKey = (key: string): void => {
  sessionStorage.removeItem(key);
}

export { useSessionStorage, clearSessionStorageKey };