import Box from "@mui/material/Box";
import { makeStyles } from 'tss-react/mui';
import Button from "@mui/material/Button";
import { UpgradeSubscriptionResponse, useUpgradeSubscription } from "../../hooks/useApi";
import { UpgradedStatus } from "../../pages/Plans/Plans";

const useStyles = makeStyles()({
    buyButtons: {
        color: "#ffffff!important",
        textDecoration: "none",
        fontWeight: 600,
        fontSize: "1.125rem",
        backgroundColor: "#3F51B5",
        margin: "0 auto",
        padding: "7px 0px",
        display: "block",
        width: "260px",
    },
});

interface PlanCardButtonProps {
    planId: number;
    purchaseLink: string;
    legacy: boolean;
    remind_renew_expiry: boolean;
    displayPrice: number;
    testsPerMonth: number;
    updateUpgradedStatus: (data: Partial<UpgradedStatus>) => void;
    subscriptionCanNotBeChanged: boolean;
    currentPlanPrice?: number;
    currentPlanTestsPerMonth?: number;
    currentPlanId?: number;
    fsLegacyManageLink?: string;
    currentPlanIsActive?: boolean;
    fastSpringLegacyId?: string;
}

const PlanCardButton = ({
    planId,
    currentPlanId,
    legacy,
    remind_renew_expiry,
    displayPrice,
    testsPerMonth,
    subscriptionCanNotBeChanged,
    currentPlanPrice,
    currentPlanTestsPerMonth,
    fsLegacyManageLink,
    currentPlanIsActive,
    fastSpringLegacyId,
    purchaseLink,
    updateUpgradedStatus,
}: PlanCardButtonProps) => {
    const { classes } = useStyles();

    const isCurrentPlan = currentPlanId === planId;

    const handleUpDateSubscription = () => {
        updateUpgradedStatus({ fetching: true });
        useUpgradeSubscription(
            { newPlanId: planId },
            (data: UpgradeSubscriptionResponse) => {
                updateUpgradedStatus({
                    fetching: false,
                    completed: true,
                    status: 'ok',
                    data: {
                        activeStatus: data.activeStatus,
                        nextPeriodDate: data.nextPeriodDate,
                        newPlanName: data.newPlanName,
                    },
                });
            },
            (status: string) => {
                localStorage.setItem('canNotBeChanged', Date.now().toString());
                updateUpgradedStatus({
                    fetching: false,
                    completed: true,
                    status,
                });
            },
        );
    };

    if (currentPlanIsActive && isCurrentPlan) {
        return (
            <span
                style={{
                    color: "#00000042",
                    textDecoration: "none",
                    fontWeight: 600,
                    width: "70%",
                    fontSize: "1.125rem",
                    backgroundColor: "#0000001f",
                    margin: "0 auto",
                    padding: "7px 70px"
                }}
            >
                BUY NOW
            </span>
        )
    }

    const isUpgradeOrDowngrade = !subscriptionCanNotBeChanged && legacy && !remind_renew_expiry && currentPlanPrice && currentPlanTestsPerMonth && currentPlanIsActive;

    if (isUpgradeOrDowngrade) {
        let isUpgrade = false;
        if (displayPrice > currentPlanPrice && testsPerMonth >= currentPlanTestsPerMonth) {
            isUpgrade = true;
        }

        return (
            <Button
                variant="contained"
                color="secondary"
                onClick={handleUpDateSubscription}
            >
                {isUpgrade ? 'Upgrade' : 'Downgrade'}
            </Button>
        );
    }

    return (
        <div>
            <Box>
                {!currentPlanIsActive && isCurrentPlan
                    && (
                        <a
                            className={classes.buyButtons}
                            href={fsLegacyManageLink}
                            target="_blank">
                            MANAGE SUBSCRIPTION
                        </a>
                    )
                }
                <a
                    className={classes.buyButtons}
                    style={{ marginTop: "10px" }}
                    href={`${purchaseLink}?referrer=${fastSpringLegacyId}`}
                    target="_blank">
                    BUY NOW
                </a>
            </Box>
        </div>
    );
};

export default PlanCardButton;
