import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { makeStyles } from 'tss-react/mui';
import { Link } from "react-router-dom";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { usePostForgotPassword } from "../../hooks/useApi";
import { Form, Formik, FormikProps } from "formik";
import * as Yup from "yup";
import DialogFormTitle from "../DialogFormTitle/DialogFormTitle";

const useStyles = makeStyles()((theme) => ({
  dialogMain: {
    width: "412px",
    margin: "60px auto",
    padding: "15px 25px",
    background: "white",
    WebkitBoxShadow: "5px 5px 15px 5px rgba(0,0,0,0.33)",
  },
  textField: {
    width: "100%",
    height: "57px",
    borderRadius: "4px",
    marginBottom: "20px",
    "& .MuiFormLabel-root": {
      color: theme.palette.warning.main + "!important"
    },
    "& .MuiInputBase-input": {
      color: theme.palette.primary.dark + "!important"
    },
    "& .Mui-disabled": {
      color: theme.palette.warning.main + "!important"
    },
  },
  submitBtn: {
    display: "block",
    margin: "20px auto",

  },
  link: {
    textAlign: "left",
  },
  appleFont: {
    fontFamily: "apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
  }
}));

interface RequestPasswordResetFormValues {
  email: string;
}

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Email is required")
    .required("Email is required")
    .max(100, "Email should be no longer than 100 characters"),
});

const RequestPasswordReset = () => {
  const { classes } = useStyles();
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [emailSent, setEmailSent] = useState(false);

  const getMessage = () => {
    if (!isError) {
      return (
        <Typography component={'span'} className={classes.appleFont}>{emailSent ? <><p>Email sent!</p><p>Please check your email for further instructions to reset your password.</p></> : "Enter your email address and we'll send you a link to reset your password."}</Typography>
      );
    } else {
      return (
        <Typography>{errorMessage}</Typography>
      );
    }
  }

  return (
    <Formik
      initialValues={{ email: "" }}
      onSubmit={({ email }) => {
        usePostForgotPassword({ email }, (data) => {
          console.log(`onData() - ${JSON.stringify(data)}`);
          setIsError(false);
          setEmailSent(true);
        },
          (error) => {
            console.log(`onError() - ${error}`);
            setErrorMessage("Please try again later");
            setIsError(true);
          });
      }}
      validationSchema={validationSchema}
      enableReinitialize={true}
    >
      {(props: FormikProps<RequestPasswordResetFormValues>) => {
        const { isValid, values, touched, errors, handleBlur, handleChange } = props;

        const isResetButtonDisabled = (): boolean => {
          if (isValid && values.email !== "") {
            return false;
          }
          return true;
        };

        return (
          <div>
            <Form>
              <div className={classes.dialogMain}>
                <DialogFormTitle
                  Icon={AccountCircle}
                  text="Reset Your Password"
                  fontSize={30}
                />
                {getMessage()}
                <TextField
                  name="email"
                  id="email"
                  label="Email address"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  variant="outlined"
                  color="secondary"
                  className={classes.textField}
                  style={{ marginTop: "10px" }}
                  helperText={
                    errors.email && touched.email
                      ? errors.email
                      : ""
                  }
                  error={errors.email && touched.email ? true : false}
                />

                <Button id="submit_button"
                  className={classes.submitBtn}
                  type="submit"
                  disabled={isResetButtonDisabled()}
                  variant="contained"
                  color="secondary"
                >
                  Send password reset email
                </Button>
                <div>
                  <Link to="/login" className={classes.link}>
                    <Typography variant="body1" className={classes.appleFont}>Back to Login page</Typography>
                  </Link>
                </div>
              </div>
            </Form>
          </div >
        );
      }}
    </Formik >
  );
};

export default RequestPasswordReset;
