import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import LogOut from "../Logout/Logout";
import { useAuthTokens } from "../../context/auth";
import Input from "@mui/material/Input";
import Divider from "@mui/material/Divider";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";
import { makeStyles } from 'tss-react/mui';
import Typography from "@mui/material/Typography";
import { useLocation, useNavigate } from "react-router-dom";
import { useFeature } from "flagged";
import AccountSettings from "../AccountSettings/AccountSettings";
import {
    GetAccountAdminsData,
    PatchAccountAdminParams,
    useGetAccountAdmins,
    useGetUserInformation
} from "../../hooks/useApi";

const SEARCH_FOR_ALL_TESTS = "/testresults/for-all-tests/all";
const SEARCH_CANDIDATE = "/testresults/for-all-tests/";

const useStyles = makeStyles()((theme) => ({
    topNavButton: {
        size: "18px",
        height: "72px",
        minWidth: "120px",
        marginLeft: "0px"
    },
    label: {
        flexDirection: "column",
        textTransform: "none"
    },
    icon: {
        fontSize: "32px !important",
        marginBottom: "20px"
    },
    companyName: {
        position: "relative",
        height: "72px",
        marginRight: theme.spacing(2),
        paddingBottom: "12px",
        display: "flex",
        marginLeft: "auto"
    },
    companyNameText: {
        display: "-webkit-box",
        overflow: " hidden",
        textOverflow: "ellipsis",
        WebkitLineClamp: 2,
        WebkitBoxOrient: "vertical",
        marginTop: "auto"
    },
    searchParentSection: {
        height: "inherit",
        marginRight: theme.spacing(2)
    },
    searchSection: {
        height: "44px",
        display: "flex",
        paddingBottom: "12px",
        maxWidth: "210px",
        background: "#6876c5",
        marginTop: "13px",
        borderBottom: "1px solid #8d98d3",
        paddingLeft: "10px",
        paddingRight: "10px"
    },
    searchInput: {
        position: "relative",
        bottom: "0",
        height: "44px",
        paddingLeft: "5px",
        paddingRight: "5px",
        color: "#ffffff"
    },
    searchIcon: {
        marginTop: "10px",
        cursor: "pointer"
    },
}));

const TopNavBarMenu = () => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const authTokens = useAuthTokens();
    const location = useLocation();
    const { classes } = useStyles();
    const navigate = useNavigate();
    const anchorRef = React.useRef<HTMLButtonElement>(null);
    const [admins, setAdmins] = useState<GetAccountAdminsData[]>([]);
    const [currentUser, setCurrentUser] = useState<PatchAccountAdminParams>();
    const [isShowSettings, setIsShowSettings] = useState<boolean>(false);
    const searchRef = useRef({ value: "" });
    const [searchValue, setSearchValue] = useState<string>("");
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const refToSearchPage = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        setSearchValue(event.target.value);
        if (event.target.value.length > 1) navigate("/testresults/for-all-tests/" + event.target.value);
    };

    const showMenuItem = useFeature("d_mi_s");

    useEffect(() => {
        useGetAccountAdmins(setAdmins);
        setIsShowSettings(false);
    }, []);

    useEffect(() => {
        if (location.pathname === SEARCH_FOR_ALL_TESTS || location.pathname.search(SEARCH_CANDIDATE) === -1)
            setSearchValue("");
    }, [location.pathname]);

    return <>
        {authTokens && (
            <>
                <div className={classes.companyName}>
                    <div className={classes.searchParentSection}>
                        <div className={classes.searchSection}>
                            <SearchIcon className={classes.searchIcon} />
                            <Input
                                ref={searchRef}
                                id="search-test-by-user-parameter"
                                onChange={(event) => {
                                    refToSearchPage(event)
                                }}
                                value={searchValue}
                                onKeyDown={(event) => {
                                    if (event.key === "Enter") navigate("/testresults/for-all-tests/" + event.currentTarget.value)
                                    setSearchValue(event.currentTarget.value)
                                }}
                                disableUnderline
                                placeholder={"Search Candidates"}
                                className={classes.searchInput}
                            />
                        </div>
                    </div>
                    {location.pathname !== "/customer-accounts" && (
                        <Typography className={classes.companyNameText}>{authTokens.companyName}</Typography>
                    )}
                </div>

                <Button
                    ref={anchorRef}
                    aria-controls="top-nav-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                    id="top-nav-dropdown"
                    color="primary"
                    className={classes.topNavButton}
                    classes={{ text: classes.label }}
                >
                    <AccountCircleIcon />
                    {authTokens.email}
                </Button>
                <Menu
                    id="top-nav-menu"
                    anchorEl={anchorRef.current}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    {showMenuItem ? (<div><MenuItem onClick={handleClose}>Profile</MenuItem>
                        <Divider /></div>) : null}
                    <MenuItem onClick={handleClose}>
                        <Typography
                            onClick={async () => {
                                setIsShowSettings(true);
                                authTokens?.adminId && (await useGetUserInformation(authTokens?.adminId, setCurrentUser));
                            }}
                            className={classes.companyNameText}>
                            Settings
                        </Typography>
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            // do nothing
                        }}
                        disableRipple
                    >
                        <LogOut />
                    </MenuItem>
                </Menu>
                {
                    currentUser && currentUser.adminId && (
                        <AccountSettings
                            modalShowState={isShowSettings}
                            isOpen={setIsShowSettings}
                            adminId={currentUser.adminId}
                        />
                    )
                }
            </>
        )}
    </>;
};

export default TopNavBarMenu;
