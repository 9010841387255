import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import {
    useEffect,
    useState,
} from "react";
import * as yup from 'yup';
import { DueDateInformationProps, useGetAccountInfo, useUpdateAutoLoginSettings, useUpdateDisableExitParameters, useUpdateExternalLink } from "../../hooks/useApi";
import AccountIntegrationsUpdateExternalUrlDialog from "./AccountIntegrationsUpdateExternalUrlDialog";
import { useFeature } from "flagged";
import { useSetTokenAndFeatures } from "../../context/auth";

interface AccountIntegrationsProps {
    subscriptionIsAvailable: DueDateInformationProps["subscriptionIsAvailable"];
    allowIntegrations: boolean;
}

const externalLinkUrlSchema = yup.string().required().url();

export interface ExternalUrlData {
    value: string;
    error: string;
}

const checkUrlIsValid = (url: string) => {
    return { isValid: externalLinkUrlSchema.isValidSync(url) };
};

const AccountIntegrations = ({ subscriptionIsAvailable, allowIntegrations }: AccountIntegrationsProps) => {
    const [externalLink, setExternalLink] = useState({ enabled: false, url: '' });
    const [exitParametersDisabled, setExitParametersDisabled] = useState(false);
    const [externalUrlData, setExternalUrlData] = useState<ExternalUrlData>({ value: '', error: '' });
    const [externalUrlEnabled, setExternalUrlEnabled] = useState<boolean>(false);
    const [externalUrlDialogOpen, setExternalUrlDialogOpen] = useState(false);
    const { setTokenAndFeatures } = useSetTokenAndFeatures();

    const defaultAutoLogin = useFeature('s_auto_login') as boolean;
    const [autoLogin, setAutoLogin] = useState(defaultAutoLogin);

    useEffect(() => {
        useGetAccountInfo(
            (data) => {
                if (data) {
                    const { disableExitParameters, externalLink: externalLinkFromServer } = data;
                    setExitParametersDisabled(disableExitParameters);
                    if (externalLinkFromServer) {
                        setExternalLink({ enabled: externalLinkFromServer.enabled, url: externalLinkFromServer.url });
                        setExternalUrlData({ value: externalLinkFromServer.url, error: '' });
                        setExternalUrlEnabled(externalLinkFromServer.enabled);
                    }
                }
            },
            () => { }
        );
    }, []);

    const changeDisableExitParameters = () => {
        const newExitParametersDisabled = !exitParametersDisabled;
        useUpdateDisableExitParameters(
            newExitParametersDisabled,
            (success) => {
                if (success) {
                    setExitParametersDisabled(newExitParametersDisabled);
                }
            }
        );
    };

    const handleChangeExternalUrl = (url: string) => {
        const { isValid } = checkUrlIsValid(url);
        if (!isValid) {
            setExternalUrlData({ value: url, error: 'Please type a valid url' });
        } else {
            setExternalUrlData({ value: url, error: '' });
        }
    };

    const handleOpenExternalUrlDialog = () => {
        setExternalUrlDialogOpen(true);
    };

    const handleCloseExternalUrlDialog = () => {
        setExternalUrlDialogOpen(false);
    };

    const handleCancelExternalUrlDialogChanges = () => {
        setExternalUrlData({ value: externalLink.url, error: '' });
        setExternalUrlEnabled(externalLink.enabled);
        setExternalUrlDialogOpen(false);
    };

    const handleEnableExternalUrl = (_: any, enabled: boolean) => {
        if (enabled) {
            handleOpenExternalUrlDialog();
        }
        setExternalUrlEnabled(enabled);
        useUpdateExternalLink(
            { enabled: false },
            () => {

            }
        );
    };

    const handleClickOnUrlInput = () => {
        handleOpenExternalUrlDialog();
    };

    const handleUpdateAutoLogin = (_: any, enabled: boolean) => {
        useUpdateAutoLoginSettings(
            enabled,
            () => {
                setAutoLogin(enabled);
                setTokenAndFeatures({ doNotNavigateOnError: true });
            },
            () => {

            }
        );
    };

    let disabledReason = '';
    if (!allowIntegrations) {
        disabledReason = 'Locked in this plan';
    } else if (!subscriptionIsAvailable.available) {
        disabledReason = 'Your Plan has expired';
    }

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
            padding: '16px 20px',
            width: '100%'
        }}
        >
            <Tooltip title={disabledReason}>
                <FormControlLabel
                    sx={{
                        margin: 0,
                        width: '50%',
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                    control={
                        <Switch
                            id="autoLogin"
                            name="autoLogin"
                            onChange={handleUpdateAutoLogin}
                            checked={autoLogin}
                            color="secondary"
                            disabled={!allowIntegrations}
                        />
                    }
                    label={
                        <Typography color={autoLogin ? 'textPrimary' : 'textSecondary'}>
                            Enable Automatic Candidate Login using URL parameters
                        </Typography>
                    }
                    labelPlacement="start"
                />
            </Tooltip>
            <Tooltip title={disabledReason}>
                <FormControlLabel
                    sx={{
                        margin: 0,
                        width: '50%',
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                    control={
                        <Switch
                            onChange={changeDisableExitParameters}
                            checked={exitParametersDisabled}
                            color="secondary"
                            disabled={!allowIntegrations}
                        />
                    }
                    label={
                        <Typography color={exitParametersDisabled ? 'textPrimary' : 'textSecondary'}>
                            Include Candidate Information into Exit Link URL Parameters
                        </Typography>
                    }
                    labelPlacement="start"
                />
            </Tooltip>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '50%',
                }}
            >
                <Tooltip title={disabledReason}>
                    <TextField
                        label="Result Link - Send Typing Test Results to External Web Server"
                        fullWidth
                        margin="dense"
                        variant="outlined"
                        color="secondary"
                        value={externalUrlData.value}
                        onClick={handleClickOnUrlInput}
                        inputProps={{
                            readOnly: true
                        }}
                        InputLabelProps={{ shrink: Boolean(externalUrlData.value) }}
                        disabled={Boolean(disabledReason)}
                    />
                </Tooltip>
                <Tooltip title={disabledReason}>
                    <Switch
                        color="secondary"
                        type="checkbox"
                        aria-label="state"
                        onChange={handleEnableExternalUrl}
                        checked={externalUrlEnabled}
                        disabled={!allowIntegrations}
                    />
                </Tooltip>
                <AccountIntegrationsUpdateExternalUrlDialog
                    open={externalUrlDialogOpen}
                    onClose={handleCloseExternalUrlDialog}
                    onCancel={handleCancelExternalUrlDialogChanges}
                    externalUrlData={externalUrlData}
                    externalUrlEnabled={externalUrlEnabled}
                    onUrlChange={handleChangeExternalUrl}
                    onExternalUrlEnabledChange={setExternalUrlEnabled}
                />
            </Box>
        </Box>
    );
}

export default AccountIntegrations;
