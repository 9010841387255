import { makeStyles } from "tss-react/mui";
import Container from "@mui/material/Container";

import RequestPasswordReset from "../../components/RequestPasswordReset/RequestPasswordReset";
import { useLocation } from "react-router-dom";
import DoPasswordReset from "../../components/DoPasswordReset/DoPasswordReset";

const useStyles = makeStyles()(() => ({
  main: {
    backgroundImage: "url(./assets/img/login_back.png)",
    backgroundRepeat: "no-repeat",
    backgroundPositionX: "center",
    width: "100%",
    height: "calc(100vh - 272px)",
    backgroundSize: "contain",
    minHeight: "530px",
  },
}));

const PasswordReset = () => {
  const { classes } = useStyles();

  const useQuery = () => {
    const location = useLocation();
    return new URLSearchParams(location.search);
  };
  const query = useQuery();
  const token = query.get("token");
  const email = query.get("email");

  const getDialog = () => {
    if (token !== null && email !== null) {
      return <DoPasswordReset email={email} token={token} />;
    } else {
      return <RequestPasswordReset />;
    }
  }

  return (
    <Container className={classes.main}>
      {getDialog()}
    </Container >
  );
};


export default PasswordReset;
