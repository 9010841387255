import { createTheme } from '@mui/material/styles';

const whiteBack = "#F7F7F7";
const white = "#fff";
const textBlue = "#3F51B5";
const textGrey = "#7A7A7A";
const textBlack = "#000000";
const textDisabled = "#C0BEBEDE";
const textError = "#d32f2f";

// declare module '@mui/styles/defaultTheme' {
//     // eslint-disable-next-line @typescript-eslint/no-empty-interface
//     interface DefaultTheme extends Theme { }
// }

const theme = createTheme({
    palette: {
        primary: {
            main: `${whiteBack}`,
            light: `${white}`,
            dark: `${textBlack}`,
            contrastText: `${textBlue}`,
        },

        secondary: {
            main: `${textBlue}`,
            contrastText: `${white}`,
        },

        background: {
            default: `${whiteBack}`,
            paper: `${white}`,
        },

        text: {
            primary: `${textBlue}`,
            secondary: `${textGrey}`,
        },

        error: {
            main: `${textError}`
        },

        warning: {
            main: `${textDisabled}`,
        }, 
        info: {
            main: `${textBlack}`,
        },
    },

    typography: {
        h1: {
            fontSize: "34px",
            fontWeight: 400,
            textAlign: "center",
            margin: "30px 0",
        },
        subtitle1:{
            fontSize: 16,
            fontWeight: 400,
            textAlign: "left",
            color: `${textBlack}`,
        }
    },
    components: {
        MuiAppBar: {
          styleOverrides: {
            colorDefault: {
              backgroundColor: textBlue
            },
            colorSecondary: {
              backgroundColor: textBlue
            }
          }
        },
        MuiStepper: {
            styleOverrides: {
                root: {
                    margin: '16px'
                }
            }
        },
    }
});

export { theme };
