import { ReactElement, useEffect, useRef, useState } from "react";
import clsx from "clsx";
import Table from "@mui/material/Table";
import Box from "@mui/material/Box";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { makeStyles } from 'tss-react/mui';
import ArrowForward from "@mui/icons-material/ArrowForward";
import ArrowUp from "@mui/icons-material/ArrowUpward";
import ArrowDown from "@mui/icons-material/ArrowDownward";
import {
    CandidateBestResultData,
    GetCandidateTestResultsData,
    GetCsvTestResultsData,
    GetCsvTestResultsResponse,
    getStatus,
    OrderBy,
    SortBy,
    useDeleteResult,
    useGetCandidateTestResults,
    useTestSearchByUserItem
} from "../../hooks/useApi";
import { getLogger } from "../../utils/logger";
import { ResultTableData, ResultTableRowData } from "../TestResultTable/TestResultTable";
import CandidateDetails from "../CandidateDetails/CandidateDetails";
import JumpToPage from "../JumpToPage/JumpToPage";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import CsvDownload from "../CsvDownload/CsvDownload";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
import EmailIcon from "@mui/icons-material/Email";
import useDebounce from "../../hooks/useDebounce";
import TrialPlanExceededDialog from "../TrialPlanExceededDialog/TrialPlanExceededDialog";
import ResultTableCellNetSpeed from "../ResultTableCellNetSpeed/ResultTableCellNetSpeed";
import { useFeature } from "flagged";
import ResultTableCellAccuracy from "../ResultTableCellAccuracy/ResultTableCellAccuracy";
import ResultTableCellStatus from "../ResultTableCellStatus/ResultTableCellStatus";

const log = getLogger();

const DEFAULT_SORTBY = "date";
const DEFAULT_ORDERBY = "desc";
const DEBOUNCE_INTERVAL_IN_MS = 400;
const sortData = {
    fulName: "name",
    status: "status",
    netSpeed: "netWpm",
    accuracy: "accuracy",
    date: "date",
    attempts: "attempts",
    hits: "hits",
    errorCount: "errorCount",
    lastResultDate: "lastResultDate",
};

export interface UsersResultProps {
    searchItem: string | undefined,
    resultCount: (count: number) => void
}

const useStyles = makeStyles()({
    mainSection: {
        paddingLeft: "40px",
        paddingRight: "40px"
    },
    paginationSection: {
        display: "flex",
        justifyContent: "space-between",
        paddingLeft: "25px",
        paddingRight: "25px",
        paddingTop: "10px"
    },
    noDataParentSection: {
        paddingLeft: "25px",
        paddingRight: "25px",
        paddingTop: "10px",
        borderBottom: "1px solid #e0e0e0",
        textAlign: "center",
        cursor: "pointer",
        "&:hover": {
            background: "#ececec"
        }
    },
    tableBottomSection: {
        background: "#ffffff"
    },
    emptyDataSection: {
        background: "#ffffff",
        paddingLeft: "25px",
        paddingRight: "25px",
        paddingTop: "10px"
    },
    customPagination: {
        float: "right"
    },
    sortIconStyle: {
        marginLeft: "6px",
        fontSize: "19px"
    },
    table: {
        width: "100%",
        background: "#ffffff",
        border: "1px solid #e9e9e9"
    },
    tableHeaderTitle: {
        color: "#4c4c4c",
    },
    tableHeaderTitleSortable: {
        cursor: "pointer"
    },
    flex: {
        display: "flex",
        justifyContent: "start",
        alignItems: "center"
    },
    emailIcon: {
        color: "#7a7a7a",
        fontSize: "32px",
        "&:hover": {
            color: "#3F51B5"
        }
    },
    textBold: {
        marginBottom: "0px",
        color: "#7a7a7a",
        fontSize: "17px",
        fontWeight: 800
    },
    textNormal: {
        marginBottom: "0px",
        color: "#7a7a7a",
        fontSize: "17px"
    },
    spaceFromLeft: {
        marginLeft: "10px"
    },
    textStyle: {
        marginBottom: "0px",
        color: "#7a7a7a",
        fontSize: "17px"
    },
    testStatusSection: {
        width: "auto",
        border: "2px solid #49cea1",
        textAlign: "center",
        padding: "6px 0px",
        borderRadius: "15px"
    },
    arrowSection: {
        marginLeft: "10px"
    },
    tableRow: {
        cursor: "pointer",
        "&:hover": {
            background: "#f9f9f9"
        }
    },
    downloadIcon: {
        cursor: "pointer",
        color: "#7A7A7A"
    },
    downloadIconSection: {
        "& :hover": {
            color: "#3F51B5"
        }
    },
    dataExportTopSection: {
        background: "#ffffff",
        padding: "10px 18px",
        display: "flex",
        justifyContent: "right"
    },
    loadingSvg: {
        color: "#3f51b5",
        left: "0",
        right: "0",
        margin: "0 auto",
        display: "block"
    }
});

const filterForCsv = (data: ResultTableData) => {
    const filteredData: GetCsvTestResultsData[] = data.map(element => {
        return {
            firstName: element.fullNameForCsv.split(",")[0],
            lastName: element.fullNameForCsv.split(",")[1],
            email: element.emailForCsv,
            status: element.status,
            netWpm: Number(element.netWpmForCsv),
            netWpmToAvgPercentage: Number(element.netWpmToAvgPercentage),
            accuracy: Number(element.accuracyForCsv),
            grossWpm: Number(element.grossWpm),
            hits: element.hits ? element.hits : 0,
            errorCount: element.errorCount ? element.errorCount : 0,
            createdAt: element.createdAt,
            attempts: element.attempts,
            info1: element.info1ForCsv,
            info2: element.info2ForCsv,
            testName: element.testName
        };
    });
    return filteredData;
};

const getFieldValueBasedOnStatus = (status: string, fieldValue: string | number): string => {
    if (status === "INCOMPLETE" || status === "NOT STARTED" || (!fieldValue && fieldValue !== 0)) {
        return "n/a";
    } else {
        return "" + fieldValue;
    }
};

const createBestTestResultTable = (candidates: CandidateBestResultData[]): ResultTableData => {
    if (candidates === null) {
        log.debug(`createBestTestResultTable() - candidates is null`);
        return [];
    }
    const table: (ResultTableRowData | null)[] = candidates.map((candidate, index) => {
        if (candidate === null) {
            log.debug(`createBestTestResultTable() - candidate at index ${index} is null`);
            const row: ResultTableRowData = {
                emailForCsv: "",
                fullNameForCsv: "",
                netWpmForCsv: "",
                accuracyForCsv: "",
                info1ForCsv: "",
                info2ForCsv: "",
                candidate: { fullName: "", email: "" },
                status: "NOT PASSED",
                netWpm: { netWpm: "", passed: false, resultId: "" },
                netWpmToAvgPercentage: "",
                accuracy: { accuracy: "0%", passed: false },
                grossWpm: "",
                createdAt: new Date(Date.now()).toString(),
                lastResultDate: new Date(Date.now()).toString(),
                attempts: 0,
                info: { info1: "", info2: "" },
                testName: "",
                testId: "",
                hits: 0,
                errorCount: 0
            };
            return row;
        }
        const status = getStatus(candidate.status);
        try {
            const row: ResultTableRowData = {
                emailForCsv: candidate.email,
                fullNameForCsv: candidate.lastName + ", " + candidate.firstName,
                netWpmForCsv: "" + candidate.netWpm,
                accuracyForCsv: "" + candidate.accuracy,
                info1ForCsv: candidate.infoFieldOneValue,
                info2ForCsv: candidate.infoFieldTwoValue,
                candidate: { fullName: candidate.lastName + ", " + candidate.firstName, email: candidate.email },
                status: getStatus(candidate.status),
                netWpm: {
                    netWpm: getFieldValueBasedOnStatus(status, candidate.netWpm),
                    passed: candidate.netWpmPassed,
                    resultId: ""
                },
                netWpmToAvgPercentage: getFieldValueBasedOnStatus(status, candidate.netWpmToAvgPercentage + "%"),
                accuracy: {
                    accuracy: getFieldValueBasedOnStatus(status, candidate.accuracy),
                    passed: candidate.accuracyPassed
                },
                grossWpm: getFieldValueBasedOnStatus(status, candidate.grossWpm),
                createdAt: candidate.createdAt,
                lastResultDate: candidate.lastResultDate,
                attempts: candidate.attempts,
                info: { info1: candidate.infoFieldOneValue, info2: candidate.infoFieldTwoValue },
                testName: candidate.testName ? candidate.testName : "-",
                testId: candidate.testId ? candidate.testId : "",
                hits: candidate.hits ? candidate.hits : 0,
                errorCount: candidate.errorCount ? candidate.errorCount : 0
            };
            return row;
        } catch (error) {
            log.debug(`createBestTestResultTable() - error: ${error}`);
            return null;
        }
    });
    const tableFiltered = table.filter(t => Boolean(t)) as ResultTableRowData[];
    return tableFiltered;
};

const enUsYyMmDdFormatter = new Intl.DateTimeFormat("en-GB", {
    year: "numeric",
    month: "short",
    day: "numeric"
});

const enUsHhMmFormatter = new Intl.DateTimeFormat("en-US", {
    hour: "numeric",
    minute: "2-digit"
});

const convertDate = (value: string): { formattedYyMmDd: string, formattedHhMm: string } => {
    const date = new Date(value);
    const formattedYyMmDd = enUsYyMmDdFormatter.format(date);
    const formattedHhMm = enUsHhMmFormatter.format(date).toLowerCase();
    return { formattedYyMmDd: formattedYyMmDd, formattedHhMm: formattedHhMm };
};

const createCandidateDetails = (candidate: GetCandidateTestResultsData): GetCandidateTestResultsData => {
    const testResults: GetCandidateTestResultsData["testResults"] = [];

    for (let index = 0; index < candidate.testResults.length; index++) {
        const status = getStatus(candidate.testResults[index].status, candidate.useLimits);
        const testResult = {
            resultId: candidate.testResults[index].resultId,
            createdAt: candidate.testResults[index].createdAt,
            status: status,
            netWpm: {
                netWpm: getFieldValueBasedOnStatus(status, candidate.testResults[index].netWpm.netWpm),
                passed: candidate.testResults[index].netWpm.passed,
                resultId: candidate.testResults[index].resultId
            },
            netWpmToAvgPercentage: getFieldValueBasedOnStatus(status, candidate.testResults[index].netWpmToAvgPercentage),
            accuracy: {
                accuracy: getFieldValueBasedOnStatus(status, candidate.testResults[index].accuracy.accuracy),
                passed: candidate.testResults[index].accuracy.passed
            },
            grossWpm: getFieldValueBasedOnStatus(status, candidate.testResults[index].grossWpm),
            errorCount: candidate.testResults[index].errorCount,
            hits: candidate.testResults[index].hits
        };
        testResults.push(testResult);
    }
    const details = {
        testName: candidate.testName,
        testId: candidate.testId,
        useLimits: candidate.useLimits,
        candidateId: candidate.candidateId,
        firstName: candidate.firstName,
        lastName: candidate.lastName,
        status: getStatus(candidate.status, candidate.useLimits),
        email: candidate.email,
        infoFieldOneTitle: candidate.infoFieldOneTitle,
        infoFieldOneValue: candidate.infoFieldOneValue,
        infoFieldOneEnabled: candidate.infoFieldOneEnabled,
        infoFieldTwoTitle: candidate.infoFieldTwoTitle,
        infoFieldTwoValue: candidate.infoFieldTwoValue,
        infoFieldTwoEnabled: candidate.infoFieldTwoEnabled,
        attempts: candidate.attempts,
        testResults: testResults,
        resultsInOtherTests: candidate.resultsInOtherTests
    };
    return details;
};

const UserSearchTable = ({ searchItem, resultCount }: UsersResultProps) => {
    const { classes } = useStyles();
    const [data, setData] = useState<ResultTableData>();
    const [csvData, setCsvData] = useState<GetCsvTestResultsResponse["results"]>([]);
    const [activeTestId, setActiveTestId] = useState<string>("");
    const [page, setPage] = useState(0);
    const [size, setSize] = useState(10);
    const [count, setCount] = useState(0);
    const [sorting, setSorting] = useState<{ sortBy: SortBy; orderBy: OrderBy }>({
        sortBy: DEFAULT_SORTBY,
        orderBy: DEFAULT_ORDERBY
    });
    const [trialPlanExceeded, setTrialPlanExceeded] = useState(true);
    const [trialPlanExceededDialogState, setTrialPlanExceededDialogState] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [disableRowClick, setDisableRowClick] = useState<boolean>(false);
    const csvLinkRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);
    const [showCandidateDetails, setShowCandidateDetails] = useState(false);
    const debouncedSearchTerm = useDebounce<string | undefined>(searchItem, DEBOUNCE_INTERVAL_IN_MS);
    const hideFractional = useFeature('s_hide_fractional') as boolean;
    const [candidateDetails, setCandidateDetails] = useState<GetCandidateTestResultsData>(
        {
            testName: "",
            testId: "",
            useLimits: false,
            candidateId: "",
            firstName: "",
            lastName: "",
            status: "",
            email: "",
            infoFieldOneTitle: "",
            infoFieldOneValue: "",
            infoFieldOneEnabled: false,
            infoFieldTwoTitle: "",
            infoFieldTwoValue: "",
            infoFieldTwoEnabled: false,
            attempts: 0,
            testResults: [],
            resultsInOtherTests: []
        }
    );

    const displayCandidateDetails = (email: string, testId: string) => {

        if (trialPlanExceeded) {
            setTrialPlanExceededDialogState(true);
        } else {
            log.debug(`displayCandidateDetails() - ${email}`);
            setActiveTestId(testId);
            useGetCandidateTestResults({ testId: testId, email }, (details) => {
                log.debug(`displayCandidateDetails() - ${JSON.stringify(details, null, 2)}`);
                const newDetails: GetCandidateTestResultsData = createCandidateDetails(details);
                setCandidateDetails(newDetails);
                setShowCandidateDetails(true);
            });
        }
    };

    const handlePageChange = (event: any, newPage: number) => {
        setPage(newPage);
    };

    const handleRowsChange = (event: any): void => {
        setSize(parseInt(event.target.value, 10));
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const updateTableData = (): void => {
        useTestSearchByUserItem(
            { search: searchItem, page: page, size: size, ...sorting },
            (data, totalCandidates, trialPlanExceededState) => {
                const table = createBestTestResultTable(data);
                setData(table);
                resultCount(totalCandidates);
                setCount(totalCandidates);
                setTrialPlanExceeded(trialPlanExceededState);
                setCsvData(filterForCsv(table));
            }, () => {
                log.debug(`useTestSearchByUserItem() - dont find result`);
            }
        );
    };

    useEffect(() => {
        if (!debouncedSearchTerm || (debouncedSearchTerm && debouncedSearchTerm?.length > 1)) {
            updateTableData();
        }
    }, [page, size, sorting, debouncedSearchTerm]);

    const updateSorting = (sortByRowName: SortBy): void => {
        let order: OrderBy = DEFAULT_ORDERBY;
        if (sorting.sortBy === sortByRowName) {
            if (sorting.orderBy === DEFAULT_ORDERBY) {
                order = "asc";
            }
        }
        setSorting({
            sortBy: sortByRowName,
            orderBy: order
        });
    };

    const deleteTestResult = (resultId: string) => {
        log.debug(`deleteResult() - deleting ${resultId}`);
        useDeleteResult({ resultId }, () => {
            useGetCandidateTestResults({ testId: activeTestId, email: candidateDetails.email }, (details) => {
                log.debug(`deleteTestResult() - ${JSON.stringify(details, null, 2)}`);
                const newDetails: GetCandidateTestResultsData = createCandidateDetails(details);
                setCandidateDetails(newDetails);
                setShowCandidateDetails(true);
            });
        });
    };

    const sortIconsState = (cellName: string): ReactElement => {
        if (sorting.sortBy === cellName) {
            return <>
                {sorting.orderBy === DEFAULT_ORDERBY ?
                    <ArrowDown className={classes.sortIconStyle} />
                    :
                    <ArrowUp className={classes.sortIconStyle} />
                }
            </>;
        }
        return <></>;
    };

    const downloadCSV = () => {
        csvLinkRef?.current?.link.click();
    };

    return (
        <>
            <div className={classes.mainSection}>
                <div className={classes.dataExportTopSection}>
                    <Box>
                        <Tooltip title=" Download All Results (csv)">
                            <Box display={"flex"} style={{ alignItems: "center", marginLeft: "20px" }}>
                                <div className={classes.downloadIconSection}>
                                    <CloudDownloadIcon
                                        className={classes.downloadIcon}
                                        onClick={() => {
                                            downloadCSV();
                                        }} />
                                </div>
                                <a style={{ marginLeft: "10px" }} href={"#"} onClick={() => downloadCSV()}>Export
                                    Data</a>
                            </Box>
                        </Tooltip>
                    </Box>
                </div>
                {data && (
                    <Table className={classes.table} aria-label="admins table" id={"all_candidates_table"}>
                        <TableHead>
                            <TableRow>
                                <TableCell onClick={() => updateSorting(sortData.fulName as SortBy)}
                                    className={clsx(classes.tableHeaderTitle, classes.tableHeaderTitleSortable)}>
                                    <Typography>
                                        Candidate
                                        {sortIconsState(sortData.fulName)}
                                    </Typography>
                                </TableCell>
                                <TableCell className={classes.tableHeaderTitle}>
                                    <Typography>Test</Typography>
                                </TableCell>
                                <TableCell onClick={() => updateSorting(sortData.status as SortBy)}
                                    className={clsx(classes.tableHeaderTitle, classes.tableHeaderTitleSortable)}>
                                    <Typography>
                                        Status
                                        {sortIconsState(sortData.status)}
                                    </Typography>
                                </TableCell>
                                <TableCell onClick={() => updateSorting(sortData.netSpeed as SortBy)}
                                    className={clsx(classes.tableHeaderTitle, classes.tableHeaderTitleSortable)}>
                                    <Typography>
                                        Net Speed
                                        {sortIconsState(sortData.netSpeed)}
                                    </Typography>
                                </TableCell>
                                <TableCell onClick={() => updateSorting(sortData.accuracy as SortBy)}
                                    className={clsx(classes.tableHeaderTitle, classes.tableHeaderTitleSortable)}>
                                    <Typography>
                                        Accuracy
                                        {sortIconsState(sortData.accuracy)}
                                    </Typography>
                                </TableCell>
                                <TableCell onClick={() => updateSorting(sortData.date as SortBy)}
                                    className={clsx(classes.tableHeaderTitle, classes.tableHeaderTitleSortable)}>
                                    <Typography>
                                        Best Result
                                        {sortIconsState(sortData.date)}
                                    </Typography>
                                </TableCell>

                                <TableCell onClick={() => updateSorting(sortData.lastResultDate as SortBy)}
                                    className={clsx(classes.tableHeaderTitle, classes.tableHeaderTitleSortable)}>
                                    <Typography>
                                        Last Use
                                        {sortIconsState(sortData.lastResultDate)}
                                    </Typography>
                                </TableCell>


                                <TableCell onClick={() => updateSorting(sortData.attempts as SortBy)}
                                    className={clsx(classes.tableHeaderTitle, classes.tableHeaderTitleSortable)}>
                                    <Typography>
                                        Attempts
                                        {sortIconsState(sortData.attempts)}
                                    </Typography>
                                </TableCell>

                                <TableCell onClick={() => updateSorting(sortData.hits as SortBy)}
                                    className={clsx(classes.tableHeaderTitle, classes.tableHeaderTitleSortable)}>
                                    <Typography>
                                        Hits
                                        {sortIconsState(sortData.hits)}
                                    </Typography>
                                </TableCell>
                                <TableCell onClick={() => updateSorting(sortData.errorCount as SortBy)}
                                    className={clsx(classes.tableHeaderTitle, classes.tableHeaderTitleSortable)}>
                                    <Typography>
                                        Error Count
                                        {sortIconsState(sortData.errorCount)}
                                    </Typography>
                                </TableCell>


                                <TableCell className={classes.tableHeaderTitle}>
                                    <Typography>Custom Fields</Typography>
                                </TableCell>
                                <TableCell className={classes.tableHeaderTitle}>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data?.map(({
                                emailForCsv: email,
                                fullNameForCsv: firstName,
                                info1ForCsv: infoFieldOneValue,
                                createdAt,
                                lastResultDate,
                                info2ForCsv: infoFieldTwoValue,
                                testName,
                                status,
                                accuracy,
                                netWpm,
                                attempts,
                                hits,
                                errorCount,
                                testId
                            }, index) => (
                                <TableRow id={"row_" + index} key={email + "_" + testId} onClick={() => {
                                    if (!disableRowClick) {
                                        displayCandidateDetails(email, testId ? testId : "");
                                    }
                                }}
                                    className={classes.tableRow}>
                                    <TableCell component="th" scope="row">
                                        <div className={classes.flex}>
                                            <div>
                                                <Link
                                                    title="Send Email"
                                                    to='#'
                                                    onClick={(e) => {
                                                        if (email) {
                                                            window.location.href = `mailto:${email}`;
                                                        }
                                                        e.preventDefault();
                                                    }}
                                                >
                                                    <EmailIcon className={classes.emailIcon}
                                                        onMouseOver={() => {
                                                            setDisableRowClick(true);
                                                        }} onMouseOut={() => {
                                                            setDisableRowClick(false);
                                                        }} />
                                                </Link>
                                            </div>
                                            <div className={classes.spaceFromLeft}>
                                                <p className={classes.textBold}>{firstName}</p>
                                                <p className={classes.textNormal}>{email}</p>
                                            </div>
                                        </div>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <div>
                                            <p className={classes.textStyle}>{testName}</p>
                                        </div>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <ResultTableCellStatus status={status} />
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <ResultTableCellNetSpeed netWpmObj={netWpm} hideFractional={hideFractional} />
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <ResultTableCellAccuracy accuracyObj={accuracy} />
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <p className={classes.textBold}>{createdAt ? convertDate(createdAt.toString()).formattedYyMmDd : "-"}</p>
                                        <p className={classes.textNormal}>{createdAt && convertDate(createdAt.toString()).formattedHhMm}</p>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <p className={classes.textBold}>{lastResultDate ? convertDate(lastResultDate).formattedYyMmDd : "-"}</p>
                                        <p className={classes.textNormal}>{lastResultDate && convertDate(lastResultDate).formattedHhMm}</p>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <p className={classes.textBold}>{attempts}</p>
                                    </TableCell>

                                    <TableCell component="th" scope="row">
                                        <p className={classes.textBold}>{hits}</p>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <p className={classes.textBold}>{errorCount}</p>
                                    </TableCell>


                                    <TableCell>
                                        <div className={classes.flex}>
                                            <div>
                                                <p className={classes.textNormal}>{infoFieldOneValue.length ? infoFieldOneValue : "-"}</p>
                                                <p className={classes.textNormal}>{infoFieldTwoValue}</p>
                                            </div>
                                        </div>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <div className={classes.arrowSection}>
                                            <ArrowForward style={{ fontSize: "32px", color: "#7a7a7a" }} />
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                )}
                <div className={classes.tableBottomSection}>
                    {
                        !data?.length && (
                            <div className={classes.noDataParentSection}>
                                <p>No rows</p>
                            </div>
                        )
                    }
                    <div className={classes.paginationSection}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <span>{data?.length} candidate(s)</span>
                            <Tooltip title=" Download All Results (csv)">
                                <Box display={"flex"} style={{ alignItems: "center", marginLeft: "20px" }}>
                                    <div className={classes.downloadIconSection}>
                                        <CloudDownloadIcon
                                            className={classes.downloadIcon}
                                            onClick={() => {
                                                downloadCSV();
                                            }} />
                                    </div>
                                    <a style={{ marginLeft: "10px" }} href={"#"} onClick={() => downloadCSV()}>Export
                                        Data</a>
                                </Box>
                            </Tooltip>
                        </div>

                        <div style={{ display: "flex", float: "right" }}>
                            <JumpToPage
                                count={count}
                                page={page}
                                rowsPerPage={rowsPerPage}
                                changePage={handlePageChange}
                            />

                            <TablePagination
                                component="div"
                                count={count}
                                page={page}
                                rowsPerPage={rowsPerPage}
                                onPageChange={handlePageChange}
                                rowsPerPageOptions={[10, 25, 50, 75, 100]}
                                onRowsPerPageChange={handleRowsChange}
                                className={classes.customPagination}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <CandidateDetails
                open={showCandidateDetails}
                handleClose={() => {
                    setShowCandidateDetails(false);
                }}
                candidateDetails={candidateDetails}
                setCandidateDetails={setCandidateDetails}
                candidateUpdated={() => {
                    return "";
                }}
                deleteResult={deleteTestResult}
                showCandidateInAnotherTest={() => {
                    return "";
                }}
                testId={activeTestId}
                createCandidateDetails={createCandidateDetails}
            />
            <CsvDownload csvLink={csvLinkRef} csvData={csvData} testName={true} />
            <TrialPlanExceededDialog trialPlanExceededDialogState={trialPlanExceededDialogState} setTrialPlanExceededDialogState={setTrialPlanExceededDialogState} />
        </>
    );
};

export default UserSearchTable;