import { createContext } from "react";
import { CandidatesExceeded } from "./auth";

interface CommonContextProps {
    candidatesExceeded: CandidatesExceeded;
    setCandidatesExceeded: (c: CandidatesExceeded) => void;
}

export const CommonContext = createContext<CommonContextProps>({
    candidatesExceeded: { exceeded: false, openDialog: false },
    setCandidatesExceeded: () => {},
});
