import Typography from "@mui/material/Typography";
import { useTableCellStyles } from "../../theme/tableCellStyles";

interface ResultTableCellVsAvgProps {
    value: string;
}

const ResultTableCellVsAvg = ({
    value,
}: ResultTableCellVsAvgProps) => {
    const { classes } = useTableCellStyles();

    const valueNum = parseInt(value);
    let plusPrefix = "";
    if (valueNum >= 0) {
        plusPrefix = "+"
    }
    const cls = (String(value) === "n/a") ? classes.naText : classes.smallCell;
    const val = (String(value) === "n/a") ? "-" : String(value);

    return (
        <Typography color="textSecondary" className={cls}> {plusPrefix}{val}</ Typography>
    );
};

export default ResultTableCellVsAvg;
