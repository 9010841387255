import {
    useEffect,
    useState,
    useContext,
    AnimationEvent,
    Dispatch,
    SetStateAction,
} from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { makeStyles } from 'tss-react/mui';
import Container from "@mui/material/Container";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuth, useSetTokenAndFeatures } from "../../context/auth";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { ensureAccountDomainInUse, getAccountDomainFromTestLink } from "../../utils/accountDomain";
import { usePostLogin } from "../../hooks/useApi";
import { useFeature } from "flagged";
import { getLogger } from "../../utils/logger";
import { CommonContext } from "../../context/common";
import DialogFormTitle from "../DialogFormTitle/DialogFormTitle";

const log = getLogger();

const useStyles = makeStyles()((theme) => ({
    main: {
        backgroundImage: "url(./assets/img/login_back.png)",
        backgroundRepeat: "no-repeat",
        backgroundPositionX: "center",
        width: "100%",
        height: "calc(100vh - 272px)",
        backgroundSize: "contain",
        minHeight: "530px"
    },
    dialogMain: {
        width: "412px",
        margin: "60px auto",
        padding: "15px 25px",
        background: "white",
        WebkitBoxShadow: "5px 5px 15px 5px rgba(0,0,0,0.33)"
    },
    titleConfirmed: {
        display: "inline-block",
        fontSize: "20px",
        color: theme.palette.info.main,
        marginBottom: "8px"
    },
    textField: {
        width: "100%",
        height: "57px",
        borderRadius: "4px",
        marginBottom: "20px",
        "& .MuiFormLabel-root": {
            color: theme.palette.warning.main + "!important"
        },
        "& .MuiInputBase-input": {
            color: theme.palette.primary.dark + "!important"
        },
        "& .Mui-disabled": {
            color: theme.palette.warning.main + "!important"
        }
    },
    submitBtn: {
        display: "block",
        margin: "20px auto"

    },
    link: {
        textAlign: "left"
    },
    forgotLink: {
        textAlign: "right",
        fontFamily: "apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'"

    },
    appleFont: {
        fontFamily: "apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'"
    }
}));

const LoginForm = () => {
    const { classes } = useStyles();
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [accountDomain, setAccountDomain] = useState("");
    const { setAuthTokens } = useAuth();
    const { setTokenAndFeatures } = useSetTokenAndFeatures();
    const { setCandidatesExceeded } = useContext(CommonContext);
    const navigate = useNavigate();
    const [autoFilledEmail, setAutoFilledEmail] = useState(false);
    const [autoFilledPassword, setAutoFilledPassword] = useState(false);

    const showRegisterLink = useFeature("d_rg_s");

    const useQuery = () => {
        const location = useLocation();
        return new URLSearchParams(location.search);
    };
    const query = useQuery();
    const emailConfirmed = query.get("email_confirmed");
    const resetSuccess = query.get("reset_success");
    const invitationAccepted = query.get("invitation_accepted");
    const invitationExpired = query.get("invitation_not_found");

    useEffect(() => {
        const currentAccountDomain = getAccountDomainFromTestLink();
        log.debug(`useEffect() - accountDomain: ${currentAccountDomain}`);
        setAccountDomain(currentAccountDomain);
    }, []);

    const postLogin = () => {
        usePostLogin({ email, password, accountDomain }, (data) => {
            log.debug(`onData() - ${JSON.stringify(data)}`);
            if (setAuthTokens) {
                setAuthTokens(data);
                setTokenAndFeatures();
                if (data.superAdmin === true) {
                    navigate('/customer-accounts');
                } else {
                    navigate('/manage-tests');
                }
            }
            ensureAccountDomainInUse(data.accountDomain);
            if (data?.candidatesExceeded) {
                let openDialog = false;
                if (data.candidatesExceeded.exceeded) {
                    openDialog = true;
                }
                setCandidatesExceeded({ ...data.candidatesExceeded, openDialog });
            }
        },
            (error) => {
                log.debug(`onError() - ${error}`);
                if (error === "NOT_ALLOWED") {
                    setErrorMessage("Access denied");
                } else if (error === "MIGRATED_USER_NOT_CONFIRMED") {
                    setErrorMessage(`Great news! Your account has been migrated to the new version. Please check your email for a password reset message and follow the instructions. Once you reset your password, you can log in.`);
                } else if (error === "EXTERNAL_USER") {
                    setErrorMessage(`Congratulations! Your new account has been successfully created. An email containing your password has been sent to you.`);
                } else if (error === "ACCOUNT_NOT_FOUND") {
                    setErrorMessage("Account does not exist, please check your login link.");
                } else {
                    setErrorMessage("The username or password provided were incorrect");
                }
                setIsError(true);
            });
    };

    const handleAnimationStart = (setSetter: Dispatch<SetStateAction<boolean>>) => (e: AnimationEvent<HTMLInputElement>) => {
        try {
            // eslint-disable-next-line
            // @ts-ignore
            const autoFilledWebkit = !!e.target?.matches("*:-webkit-autofill");
            if (e.animationName === "mui-auto-fill") {
                setSetter(autoFilledWebkit);
            }

            if (e.animationName === "mui-auto-fill-cancel") {
                setSetter(autoFilledWebkit);
            }
        } catch {
            // do nothing
        }
    };

    return (
        <Container className={classes.main}>
            <div className={classes.dialogMain}>
                {emailConfirmed ?
                    <div><Typography className={classes.titleConfirmed}>Your email has been confirmed</Typography>
                    </div> : <></>}
                {resetSuccess ? <div><Typography className={classes.titleConfirmed}>Your password has been changed
                    successfully</Typography></div> : <></>}
                {invitationAccepted ?
                    <div><Typography className={classes.titleConfirmed}>User account created, continue by logging
                        in</Typography></div> : <></>}
                {invitationExpired ?
                    <div><Typography className={classes.titleConfirmed}>Invitation has expired or account is already
                        created</Typography></div> : <></>}
                <DialogFormTitle
                    Icon={AccountCircle}
                    text="Admin Login"
                />
                <TextField
                    name="email"
                    id="email"
                    label="Email address"
                    value={email}
                    onChange={(e) => {
                        setEmail(e.target.value);
                    }}
                    onKeyDown={(event) => {
                        if (event.key === "Enter") {
                            postLogin();
                        }
                    }}
                    variant="outlined"
                    color="secondary"
                    className={classes.textField}
                    style={{ marginTop: "10px" }}
                    InputLabelProps={((email || autoFilledEmail) ? { shrink: Boolean(email) || autoFilledEmail } : {})}
                    InputProps={{
                        onAnimationStart: handleAnimationStart(setAutoFilledEmail)
                    }}
                />

                <TextField
                    name="pass"
                    id="password"
                    label="Password"
                    value={password}
                    onChange={(e) => {
                        setPassword(e.target.value);
                    }}
                    onKeyDown={(event) => {
                        if (event.key === "Enter") {
                            postLogin();
                        }
                    }}
                    type="password"
                    variant="outlined"
                    color="secondary"
                    className={classes.textField}
                    InputLabelProps={((password || autoFilledPassword) ? { shrink: Boolean(password) || autoFilledPassword } : {})}
                    InputProps={{
                        onAnimationStart: handleAnimationStart(setAutoFilledPassword)
                    }}
                />
                <Button id="submit_button"
                    className={classes.submitBtn}
                    onClick={postLogin}
                    variant="contained"
                    color="secondary"
                >
                    Login
                </Button>
                <div>
                    <Box display="flex" width="100%">
                        {showRegisterLink ? (<Box flexGrow={1}>
                            <Link to="/register" className={classes.link}>
                                <Typography variant="body1" className={classes.appleFont}>Don't have an
                                    account?</Typography>
                            </Link>
                        </Box>) : null}
                        <Box>
                            <Link to="/reset" className={classes.forgotLink}>
                                <Typography variant="body1" className={classes.forgotLink}>Forgot password?</Typography>
                            </Link>
                        </Box>
                    </Box>
                </div>
                {isError ? <div>{errorMessage}</div> : null}
            </div>
        </Container>
    );
};

export default LoginForm;
