import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { useState } from "react";
import { useUpdateFlagsSettings } from "../../hooks/useApi";
import { useFeature } from "flagged";
import { useSetTokenAndFeatures } from "../../context/auth";

const AdvancedSettings = () => {
    const defaultShowOnlyWholeNumber = useFeature('s_hide_fractional') as boolean;
    const defaultOneResult = useFeature('s_one_result') as boolean;
    const [showOnlyWholeNumber, setShowOnlyWholeNumber] = useState(defaultShowOnlyWholeNumber);
    const [oneResult, setOneResult] = useState(defaultOneResult);
    const { setTokenAndFeatures } = useSetTokenAndFeatures();

    const changeShowOnlyWholeNumber = () => {
        const newShowOnlyWholeNumber = !showOnlyWholeNumber;
        useUpdateFlagsSettings({ showOnlyWholeNumber: newShowOnlyWholeNumber }, (success) => {
            if (success) {
                setShowOnlyWholeNumber(newShowOnlyWholeNumber);
                setTokenAndFeatures({ doNotNavigateOnError: true });
            }
        })
    };

    const changeShowOnlyOneResult = () => {
        const showOnlyOneResult = !oneResult;
        useUpdateFlagsSettings({ showOnlyOneResult: showOnlyOneResult }, (success) => {
            if (success) {
                setOneResult(showOnlyOneResult);
                setTokenAndFeatures({ doNotNavigateOnError: true });
            }
        })
        setOneResult(showOnlyOneResult);
    };

    return (
        <Box padding="16px 20px" sx={{ display: "flex", flexDirection: "column", width: '100%', gap: "20px" }}>
            <FormControlLabel
                sx={{
                    width: '50%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    margin: 0,
                }}
                control={
                    <Switch
                        onChange={changeShowOnlyWholeNumber}
                        checked={showOnlyWholeNumber}
                        color="secondary"
                    />
                }
                label={
                    <Typography color={showOnlyWholeNumber ? 'textPrimary' : 'textSecondary'}>
                        Display Words Per Minute (WPM) as Whole Numbers Only
                    </Typography>
                }
                labelPlacement="start"
            />
            <FormControlLabel
                sx={{
                    width: '50%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    margin: 0,
                }}
                control={
                    <Switch
                        onChange={changeShowOnlyOneResult}
                        checked={oneResult}
                        color="secondary"
                    />
                }
                label={
                    <Typography color={oneResult ? 'textPrimary' : 'textSecondary'}>
                        Printable Typing Certificate - Show Highest Test Result Only
                    </Typography>
                }
                labelPlacement="start"
            />
        </Box>
    );
};

export default AdvancedSettings;
