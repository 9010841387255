import Button from "@mui/material/Button";
import { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { makeStyles } from 'tss-react/mui';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { PostNewTestParams, useGetNewTemplate, usePostNewTest } from "../../hooks/useApi";
import TestSettings, { TestSettingsFormValues } from "../TestSettings/TestSettings";
import { checkForInvalidCharacters, convertFormDataToApi, updateToFormData } from "../TestCard/TestCard";
import { getLogger } from "../../utils/logger";

const log = getLogger();

export const FIRST_TEXT = 1;

const useStyles = makeStyles()((theme) => ({
    header: {
        fontFamily: "'Lexend', sans-serif;",
        margin: "8px 0 0 0",
        textAlign: "left",
        display: "inline-block",
        marginBottom: "30px"
    },
    count: {
        color: theme.palette.text.secondary,
        fontSize: "29px"
    },
    button: {
        marginLeft: "30px",
        width: "121px",
        height: "36px",
        marginTop: "-15px"
    }
}));

export interface TestsHeaderProps {
    handleAdd: () => void;
    testCount: number;
    onTestAdded: () => void;
    setError: (status: string) => void
}

const TestsHeader = (props: TestsHeaderProps) => {
    const { classes } = useStyles();
    const [open, setOpen] = useState(false);
    const [openInvalidCharacters, setOpenInvalidCharacters] = useState(false);
    const [invalidCharacters, setInvalidCharacters] = useState("");
    const [errorMessage, setErrorMessage] = useState<string>()
    const handleCloseInvalidCharacters = () => {
        setOpenInvalidCharacters(false);
    };

    const handleError = (status: string) => {
        switch (status) {
            case "ERROR_TEST_NAME_ALREADY_EXIST":
                setErrorMessage(`Test name must be unique. Please choose a different name.`)
                break;
            default:
                return setErrorMessage("Unknown error");

        }
    }

    const initialFormData: PostNewTestParams = {
        name: "",
        active: false,
        duration: "5",
        attempts: "5",
        limits: {
            inUse: false,
            shownToCandidate: false,
            netWpm: "0",
            accuracy: "0"
        },
        textSelection: {
            options: [],
            selected: FIRST_TEXT
        },
        customText: "",
        useCustomText: false,
        note: {
            text: "",
            enabled: false
        },
        infoFieldOne: {
            enabled: false,
            title: ""
        },
        infoFieldTwo: {
            enabled: false,
            title: ""
        },
        sendResultsByEmail: false,
        resultRecipients: [],
        textId: "",
        migratedResultEmails: "",
        exitPassUrl: {
            enabled: false,
            title: ""
        },
        exitFailUrl: {
            enabled: false,
            title: ""
        },
        newAttemptsAllowed: -1,
        accessCode: {
            enabled: false,
            title: ""
        },
    };

    const initialDefaultData: TestSettingsFormValues = {
        name: "",
        duration: 5,
        attempts: 5,
        limitsInUse: false,
        shownToCandidate: false,
        netWpm: 0,
        accuracy: 0,
        note: "",
        noteEnabled: false,
        infoFieldOneTitle: "",
        infoFieldOneEnabled: false,
        infoFieldTwoTitle: "",
        infoFieldTwoEnabled: false,
        sendResultsByEmail: false,
        resultRecipients: [],
        selectedResultRecipients: [],
        testText: "",
        textSelectionOptions: [],
        selectedText: FIRST_TEXT,
        customText: "",
        useCustomText: false,
        selectedTextContent: "",
        migratedResultEmails: "",
        exitPassUrlState: false,
        exitFailUrlState: false,
        exitPassUrl: "",
        exitFailUrl: "",
        newAttemptsAllowed: -1,
        accessCodeEnabled: false,
        accessCode: "",
    };
    const [initialData, setInitialData] = useState<TestSettingsFormValues>(initialDefaultData);

    const [tempData, setTempData] = useState<PostNewTestParams>(initialFormData);

    const handleCloseAfterEdit = (values: TestSettingsFormValues) => {
        try {
            let invalidCharacters = "";
            const formData = convertFormDataToApi(values, true);

            if (values.useCustomText) {
                invalidCharacters = checkForInvalidCharacters(values.customText);
                if (invalidCharacters !== "") {
                    setInvalidCharacters(invalidCharacters);
                    setTempData(formData);
                    setOpenInvalidCharacters(true);
                }
            }

            if (invalidCharacters === "") {
                usePostNewTest(
                    {
                        ...formData
                    },
                    (response) => {
                        log.debug(`onSubmit() - response: ${JSON.stringify(response, null, 2)}`);
                        handleClose();
                        props.onTestAdded();
                        setInitialData(initialDefaultData);
                    },
                    (status) => {
                        handleError(status);
                    }
                );
            }
        } catch (error) {
            log.error(`onSubmit() - error: ${JSON.stringify(error, null, 2)}`);
        }
    };

    const handleClickOpen = () => {
        useGetNewTemplate((apiData) => {
            updateToFormData(apiData, setInitialData);
            setOpen(true);
        }, props.setError);
    };

    const handleClose = () => {
        setOpen(false);
        setErrorMessage(undefined);
        useGetNewTemplate((data) => {
            updateToFormData(data, setInitialData);
        });
    };

    return (
        <Box>
            <Typography variant="h1" color="secondary" className={classes.header}>Typing Tests <span
                className={classes.count}>{props.testCount}</span></Typography>
            <Button
                className={classes.button}
                startIcon={<AddIcon />}
                variant="contained"
                onClick={handleClickOpen}
                id="btn_add_test"
                color="secondary"
            >
                ADD
            </Button>
            <Dialog
                open={openInvalidCharacters}
                onClose={handleCloseInvalidCharacters}
                aria-labelledby="invalid-characters-dialog-title"
                aria-describedby="invalid-characters-dialog-description"
            >
                <DialogTitle id="invalid-characters-dialog-title" style={{ textAlign: "center" }}>Non-supported
                    Characters Found</DialogTitle>
                <DialogContent>
                    <DialogContentText id="invalid-characters-dialog-description">
                        <p style={{ textAlign: "center" }}>Following characters are not supported by the test
                            applet:</p>
                        <p style={{ textAlign: "center" }}>{invalidCharacters}</p>
                        <p style={{ textAlign: "center" }}>Remove those before continuing.</p>
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ margin: "0 auto 20px auto" }}>
                    <Button onClick={() => {
                        usePostNewTest(
                            {
                                ...tempData
                            },
                            (response) => {
                                log.debug(`onSubmit() - response: ${JSON.stringify(response, null, 2)}`);
                                handleCloseInvalidCharacters();
                                handleClose();
                                props.onTestAdded();
                            }
                        );

                    }} id="close_invalid_characters" color="secondary" variant="contained">
                        No, continue anyway
                    </Button>
                    <Button onClick={handleCloseInvalidCharacters} color="secondary" autoFocus
                        id="cancel_invalid_characters">
                        Wait, I'll remove those
                    </Button>
                </DialogActions>
            </Dialog>
            <TestSettings
                errorMessage={errorMessage}
                open={open}
                handleClose={handleClose}
                initialValues={initialData}
                handleCloseAfterEdit={handleCloseAfterEdit}
            />
        </Box>
    );
};
export default TestsHeader;
