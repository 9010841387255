import Typography from "@mui/material/Typography";
import clsx from "clsx";
import { useTableCellStyles } from "../../theme/tableCellStyles";

interface ResultTableCellGrossSpeedProps {
    grossWpm: string | number;
}

const ResultTableCellGrossSpeed = ({
    grossWpm,
}: ResultTableCellGrossSpeedProps) => {
    const { classes } = useTableCellStyles();

    const isNa = String(grossWpm) === "n/a";
    return (
        <Typography
            className={clsx({
                [classes.naText]: isNa,
                [classes.smallCell]: !isNa,
                [classes.passText]: !isNa,
            })}
        >
            {isNa ? "-" : grossWpm + " WPM"}
        </Typography>
    );
};

export default ResultTableCellGrossSpeed;
