import { useEffect } from "react";
import * as Yup from "yup";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { makeStyles } from 'tss-react/mui';
import Container from "@mui/material/Container";
import { useNavigate, useLocation } from "react-router-dom";
import { useGetAdminInvitation, usePostAcceptAdminInvitation } from "../../hooks/useApi";
import { Field, Form, Formik, FormikProps } from "formik";
import VpnKey from "@mui/icons-material/VpnKey";
import DialogFormTitle from "../DialogFormTitle/DialogFormTitle";

const useStyles = makeStyles()((theme) => ({
  main: {
    backgroundImage: "url(./assets/img/login_back.png)",
    backgroundRepeat: "no-repeat",
    backgroundPositionX: "center",
    width: "100%",
    height: "calc(100vh - 272px)",
    backgroundSize: "contain",
    minHeight: "530px",
  },
  dialogMain: {
    width: "786px",
    margin: "60px auto",
    padding: "15px",
    WebkitBoxShadow: "5px 5px 15px 5px rgba(0,0,0,0.33)",
    background: "white",
  },
  textField: {
    width: "calc(50% - 20px)",
    height: "57px",
    borderRadius: "4px",
    "& .MuiFormLabel-root": {
      color: theme.palette.warning.main + "!important"
    },
    "& .MuiInputBase-input": {
      color: theme.palette.primary.dark + "!important"
    },
    "& .Mui-disabled": {
      color: theme.palette.warning.main + "!important"
    },
    margin: "10px 10px 10px",
  },
  infoText: {
    width: "calc(50% - 20px)",
    height: "20px",
    borderRadius: "4px",
    margin: "0px 10px 0px 10px",
  },
  infoEmail: {
    height: "20px",
    borderRadius: "4px",
    margin: "0px 10px 0px 10px",
  },
  submitBtn: {
    display: "block",
    margin: "20px auto",
  },
  link: {
    textAlign: "center",
  },
  darkColor: {
    color: theme.palette.primary.dark,
  },
}));

interface AcceptInvitationFormValues {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  confirmPassword: string;
  consent: boolean;
}

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required").min(1, "First name is missing").max(100, "First name should be no longer than 100 characters"),
  lastName: Yup.string().required("Last name is required").min(1, "Last name is missing").max(100, "Last name should be no longer than 100 characters"),
  password: Yup.string()
    .required("Password is required")
    .min(6, "Password is too short"),
  confirmPassword: Yup.string()
    .required("Confirm password is required")
    .min(6, "Confirm password is too short")
    .oneOf([Yup.ref("password")], "Passwords don't match!"),
  consent: Yup.boolean().required("Consent is required").oneOf([true], 'Consent needed')
});

const AcceptInvitationForm = () => {
  const { classes } = useStyles();

  const useQuery = () => {
    const location = useLocation();
    return new URLSearchParams(location.search);
  };
  const query = useQuery();
  const token = query.get("token");
  const email = query.get("email");
  const navigate = useNavigate();

  if (!token && !email) {
    console.log(`token and email not present`);
    navigate("/");
  }

  useEffect(() => {
    if (token) {
      useGetAdminInvitation(token, (status) => {
        console.log(`got admin invitation response ${status}`);
        if (status === "INVITATION_NOT_FOUND") {
          navigate("/login?invitation_not_found=true");
        }
      });
    }
  }, []);

  const onSubmit = async (values: AcceptInvitationFormValues): Promise<void> => {
    try {
      if (token) {
        console.log(`onSubmit() - submitting values: ${JSON.stringify(values, null, 2)}`);
        usePostAcceptAdminInvitation({ token, ...values }, (success) => {
          if (success) {
            navigate("/login?invitation_accepted=true");
          } else {
            navigate("/login?invitation_not_found=true");
          }
        });
      }
    } catch (error) {
      console.log(`onSubmit() - error: ${JSON.stringify(error, null, 2)}`);
    }
  };

  return (
    <Formik
      initialValues={{ email: email || "", firstName: "", lastName: "", password: "", confirmPassword: "", consent: false }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize={true}
    >
      {(props: FormikProps<AcceptInvitationFormValues>) => {
        const { isValid, values, touched, errors, handleBlur, handleChange } = props;

        const isSubmitButtonDisabled = (): boolean => {
          if (isValid && values.email !== ""
            && values.firstName !== ""
            && values.lastName !== ""
            && values.password !== ""
            && values.confirmPassword !== "") {
            return false;
          }
          return true;
        };

        return (
          <Container className={classes.main}>
            <Form>
              <div className={classes.dialogMain}>
                <DialogFormTitle
                  Icon={VpnKey}
                  text="You have been invited to TypingTest Pro"
                />
                <Box style={{ margin: "0px 0px 0" }}>
                  <Typography variant="subtitle1" className={classes.infoText}>Please enter your information below to join.</Typography>
                  <Typography variant="subtitle1" className={classes.infoEmail}>
                    Your email address will be your login ID: <Box component="span" fontWeight='fontWeightMedium'>{email}</Box>
                  </Typography>
                </Box>
                <Box style={{ margin: "20px 0px 0" }}>
                  <TextField
                    name="firstName"
                    id="firstName"
                    label="First name"
                    value={values.firstName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    variant="outlined"
                    color="secondary"
                    className={classes.textField}
                    style={{ marginTop: "10px" }}
                    helperText={
                      errors.firstName && touched.firstName
                        ? errors.firstName
                        : ""
                    }
                    error={errors.firstName && touched.firstName ? true : false}
                  />
                  <TextField
                    name="lastName"
                    id="lastName"
                    label="Last name"
                    value={values.lastName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    variant="outlined"
                    color="secondary"
                    className={classes.textField}
                    helperText={
                      errors.lastName && touched.lastName
                        ? errors.lastName
                        : ""
                    }
                    error={errors.lastName && touched.lastName ? true : false}
                  />
                </Box>
                <Box style={{ margin: "20px 0px 0" }}>
                  <TextField
                    name="password"
                    id="password"
                    label="Password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="password"
                    variant="outlined"
                    color="secondary"
                    className={classes.textField}
                    helperText={
                      errors.password && touched.password
                        ? errors.password
                        : ""
                    }
                    error={errors.password && touched.password ? true : false}
                  />
                  <TextField
                    name="confirmPassword"
                    id="confirmPassword"
                    label="Confirm Password"
                    value={values.confirmPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="password"
                    variant="outlined"
                    color="secondary"
                    className={classes.textField}
                    helperText={
                      errors.confirmPassword && touched.confirmPassword
                        ? errors.confirmPassword
                        : ""
                    }
                    error={errors.confirmPassword && touched.confirmPassword ? true : false}
                  />
                </Box>
                <Box style={{ marginLeft: "10px", display: "flex" }}>
                  <FormControlLabel
                    control={
                      <Field
                        component={Switch}
                        name="consent"
                        id="consent"
                        type="checkbox"
                        color="secondary"
                        aria-label="state"
                        value={values.consent}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={
                          errors.consent && touched.consent
                            ? errors.consent
                            : ""
                        }
                        error={errors.consent && touched.consent ? true : false}
                      />
                    }
                    label=""
                    className={classes.darkColor}
                  />
                  <span style={{ marginLeft: "-10px", marginTop: "7px" }}>I agree to TypingTest Pro&nbsp;
                    <a href="https://www.iubenda.com/terms-and-conditions/85323953" target="_blank" tabIndex={-1}>Terms of Service</a>&nbsp;and&nbsp;
                    <a href="https://www.iubenda.com/privacy-policy/85323953" target="_blank" tabIndex={-1}>Privacy Policy</a></span>
                </Box>
                <Button id="submit_button"
                  className={classes.submitBtn}
                  type="submit"
                  disabled={isSubmitButtonDisabled()}
                  variant="contained"
                  color="secondary"
                >
                  Create my account
                </Button>
              </div>
            </Form>
          </Container >
        );
      }}
    </Formik >
  );
};

export default AcceptInvitationForm;

