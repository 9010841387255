import { useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { makeStyles } from 'tss-react/mui';
import { PlanProps, UpgradeSubscriptionResponse, useGetPlans } from "../../hooks/useApi";
import PlanCard from "../../components/PlanCard/PlanCard";
import PlanCardUpgradeDialog from "../../components/PlanCard/PlanCardUpgradeDialog";
import { getCurrentDateDifferenceInDays } from "../../utils/time";

const useStyles = makeStyles()((theme) => ({
    root: {
        margin: "0px 45px 0 45px"
    },
    title: {
        fontFamily: "'Lexend', sans-serif;",
        textAlign: "left",
        display: "block",
        fontSize: "34px",
        margin: "8px 0 20px"
    },
    planCardsSection: {
        display: "grid",
        gridTemplateColumns: "repeat(3,1fr)",
        textAlign: "center",
        paddingBottom: "60px"
    }
}));

export interface UpgradedStatus {
    status: string;
    completed: boolean;
    fetching: boolean;
    data?: Omit<UpgradeSubscriptionResponse, 'status'>;
}

const Plans = () => {
    const { classes } = useStyles();
    const [plans, setPlans] = useState<PlanProps[]>([]);
    const [currentPlanId, setCurrentPlanId] = useState<number>(-1);
    const [fsLegacyManageLink, setFsLegacyManageLink] = useState<string>();
    const [currentPlanIsActive, setCurrentPlanIsActive] = useState<boolean>();
    const [fastSpringLegacyId, setFastSpringLegacyId] = useState<string>();
    const [upgradedStatus, setUpgradedStatus] = useState<UpgradedStatus>({ status: '', completed: false, fetching: false });
    const [subscriptionCanNotBeChanged, setSubscriptionCanNotBeChanged] = useState(false);

    useEffect(() => {
        const setData = (plans: PlanProps[], currentPlanId: number, currentPlanActiveState: boolean, fastSpringLegacy: string, fsLegacyManageLink: string) => {
            setPlans(plans);
            setCurrentPlanId(currentPlanId);
            setCurrentPlanIsActive(currentPlanActiveState);
            setFastSpringLegacyId(fastSpringLegacy);
            setFsLegacyManageLink(fsLegacyManageLink);
        };
        useGetPlans(setData);

        const canNotBeChanged = localStorage.getItem('canNotBeChanged') as string;
        if (canNotBeChanged) {
            const canNotBeChangedDate = new Date(Number(canNotBeChanged));
            if (canNotBeChangedDate.toString() !== 'Invalid Date') {
                const difference = getCurrentDateDifferenceInDays(canNotBeChangedDate);
                if (difference > -2) {
                    setSubscriptionCanNotBeChanged(true);
                }
            }
        }
    }, []);

    const currentPlan = useMemo(() => {
        return plans?.find((p) => p.planId === currentPlanId);
    }, [plans]);

    const handleUpdateUpgradedStatus = (updates: Partial<UpgradedStatus>) => {
        const newUpgradedStatus = { ...upgradedStatus, ...updates };
        setUpgradedStatus(newUpgradedStatus);
    };

    return (
        <div>
            <Box className={classes.root}>
                <Typography variant="h1" color="secondary" className={classes.title}>Perfect Plan for you</Typography>
                <div className={classes.planCardsSection}>
                    {plans &&
                        plans.map(plan => (
                            <PlanCard
                                key={plan.planId}
                                plan={plan}
                                currentPlan={currentPlan}
                                fsLegacyManageLink={fsLegacyManageLink}
                                currentPlanIsActive={currentPlanIsActive}
                                fastSpringLegacyId={fastSpringLegacyId}
                                updateUpgradedStatus={handleUpdateUpgradedStatus}
                                subscriptionCanNotBeChanged={subscriptionCanNotBeChanged}
                            />
                        ))
                    }
                </div>
            </Box>
            <PlanCardUpgradeDialog
                open={upgradedStatus.completed || upgradedStatus.fetching}
                onClose={() => handleUpdateUpgradedStatus({ completed: false })}
                fetching={upgradedStatus.fetching}
                data={upgradedStatus.data}
                status={upgradedStatus.status}
            />
        </div>
    );
};

export default Plans;
