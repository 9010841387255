import { ReactNode, useEffect, useState } from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { makeStyles } from 'tss-react/mui';
import Container from "@mui/material/Container";
import { StepIconProps } from "@mui/material/StepIcon";
import DoneIcon from "@mui/icons-material/Done";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import * as wizardCss from "../../theme/wizardStyle";
import StepLabel from "@mui/material/StepLabel";
import defaultLogo from "../../ttpro_logo.png";
import { AccountTestList, AccountTestListReturnData, useAccountTestList, useAccountTestListForMigratedUsers } from "../../hooks/useApi";
import { getAccountDomainFromTestLink } from "../../utils/accountDomain";
import useRedirectToApp from "../../hooks/useRedirectToApp";
import getBaseUrl from "../../utils/getBaseUrl";


const TESTS_COUNT_FOR_COLUMN = 7;
const MAX_SHOW_COUNT = 12;

interface TestSelectWizardProps {
    migrated: boolean;
}
const useStyles = makeStyles()((theme) => ({
    back: {
        width: "100%",
        maxWidth: "100%"
    },
    root: {
        width: "100%",
        maxWidth: "90%",
        margin: "0 auto",
        position: "absolute",
        transform: "translate(-50%, -50%)",
        top: "45%",
        left: "50%",
        "@media (max-width: 1024px)": {
            position: "relative",
            transform: "translate(0,0)",
            top: "10px",
            left: "0px",
            minWidth: "1018px",
            minHeight: "712px"
        },
        "@media (max-height: 800px)": {
            position: "relative",
            transform: "translate(0,0)",
            top: "10px",
            left: "0px",
            minWidth: "1018px",
            minHeight: "712px"
        }
    },
    stepper: {
        width: "100%",
        paddingLeft: "0px",
        backgroundColor: theme.palette.primary.main
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    testCard: {
        padding: "8px",
        border: "1px solid #e3e3e3",
        borderRadius: "2px",
        display: "flex",
        justifyContent: "space-between",
        marginLeft: "10px",
        marginTop: "10px",
        maxHeight: "50px"
    },
    showAllSection: {
        width: "70%",
        textAlign: "center",
        padding: "20px",
        marginTop: "-80px"
    },
    logoCircleSmall: {
        width: "auto",
        height: "auto",
        maxWidth: "300px",
        maxHeight: "300px",
        marginLeft: "auto",
        marginBottom: "auto"
    },
    errorMessage: {
        textAlign: "center",
        fontSize: "28px",
        color: "red",
        paddingTop: "50px"
    },
    cardRoot: {
        display: "block",
        width: "100%",
        height: "600px",
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        border: "1px solid #0000001F",
        borderRadius: "4px",
        borderLeft: "8px solid #7684D0"
    },
    titleDisabled: {
        color: theme.palette.warning.main
    },
    warningBox: {
        position: "absolute",
        width: "1000px",
        height: "60px",
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
        fontSize: "27px",
        transform: "translate(-50%, -50%)",
        left: "50%",
        top: "50%",
        textAlign: "center",
        lineHeight: "60px",
        borderRadius: "6px"
    },
    keyCircleBig: {
        position: "absolute",
        width: "300px",
        height: "300px",
        top: "90px",
        right: "40px",
        display: "flex"
    },
    errorIcon: {
        position: "absolute",
        fontSize: "36px",
        left: "10px",
        top: "11px"
    },
    logo: {
        textAlign: "left",
        fontStyle: "italic",
        fontWeight: 700,
        fontFamily: "\"Roboto- BoldItalic\", \"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
        fontSize: "24px",
        lineHeight: "36px",
        textIndent: "40px"
    },
    testName: {
        color: "#3F51B5",
        fontSize: "22px",
        margin: 0,
        fontWeight: 600,
        width: "calc(80% - 32px)",
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    logo_img: {
        position: "absolute"
    },
    logo_span: {
        fontFamily: "\"Roboto- Italic\", \"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
        fontWeight: 400
    },
    navButton: {
        textTransform: "none"
    },

    textPrimary: {
        textTransform: "none",
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.primary.main
    },

    textSecondary: {
        textTransform: "none",
        color: theme.palette.text.secondary,
        backgroundColor: theme.palette.primary.main
    },

    stepCompleted: {
        color: theme.palette.primary.dark + " !important"
    },
    loginSection: {
        textAlign: "right",
        marginBottom: "-60px",
        marginRight: "20px",
        marginTop: "26px"
    },
    adminLoginButton: {
        marginRight: theme.spacing(1),
        position: "absolute",
        right: "20px",
        top: "20px",
        zIndex: 100
    },
}));

interface StyledTestsListProps {
    showAll: boolean;
    showOneRow: boolean;
}
const useTestsStyles = makeStyles<StyledTestsListProps>()((_, { showAll, showOneRow }) => ({
    testsAndImageContainer: {
        width: "100%",
        display: "flex",
        height: "80%",
        "@media (max-width: 1244px)": {
            width: "94%",
        }
    },
    testsContainer: {
        width: "70%",
        height: "80%",
        overflowY: showAll ? "scroll" : "unset",
        zIndex: 9,
    },
    tests: {
        display: "grid",
        gridTemplateColumns: `repeat(${showOneRow ? "1,1fr" : "2,50%"})`,
        alignContent: showAll ? "flex-start" : "center",
        height: "100%",
    },
}));


const TestSelectWizard = ({ migrated }: TestSelectWizardProps) => {
    useRedirectToApp();
    const { classes } = useStyles();
    const navigate = useNavigate();
    const { classes: wizardClasses } = wizardCss.wizardStyles();
    const [imageData, setImageData] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [urlForRedirect, setUrlForRedirect] = useState("");
    const [tests, setTests] = useState<AccountTestList>();
    const [showAll, setShowAll] = useState<boolean>(false);
    const { classes: testsClasses } = useTestsStyles({ showAll, showOneRow: (tests && tests.length ? tests.length <= TESTS_COUNT_FOR_COLUMN : true) });

    const getSteps = () => {
        return ["Select Test", "Test Info", "Personal Info", "Practice", "Typing Test", "Test Summary"];
    };
    const steps = getSteps();

    const setError = (status: string): void => {
        switch (status) {
            case "ACCOUNT_NOT_FOUND":
                setErrorMessage("Account not found")
                break;
            default:
                setErrorMessage("Unknown error")
        }
    }

    useEffect(() => {
        const accountDomain = getAccountDomainFromTestLink();
        const baseUrl = getBaseUrl(window.location.href, migrated);
        setUrlForRedirect(baseUrl);
        if (!migrated) {
            if (accountDomain.length === 0) {
                navigate("/login");
            } else {
                useAccountTestList(
                    accountDomain,
                    ({ companyName, testList, image }: AccountTestListReturnData) => {
                        setCompanyName(companyName);
                        setTests(testList);
                        if (image) {
                            setImageData(image);
                        }
                    },
                    () => {
                        navigate("/login");
                    },
                );
            }
        } else {
            useAccountTestListForMigratedUsers(accountDomain, setImageData, setTests, setCompanyName, (status) => {
                setError(status)
            });
        }
    }, []);


    const appLogo = () => {
        return (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                    <img alt="TypingMater logo" src="/assets/img/logo.svg" className={classes.logo_img} />
                    <Typography variant="h6" component="h1" className={classes.logo}>

                        TypingTest<span className={classes.logo_span}> Pro </span>
                    </Typography>
                </div>
            </div>
        );
    };

    const useStepIconStyles = makeStyles()((theme) => ({
        root: {
            backgroundColor: "#999999",
            zIndex: 1,
            color: theme.palette.primary.light,
            width: 32,
            height: 32,
            display: "flex",
            borderRadius: "50%",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "16px"
        },
        active: {
            backgroundColor: theme.palette.primary.contrastText,
            color: theme.palette.primary.light
        },
        completed: {
            backgroundColor: theme.palette.primary.contrastText,
            color: theme.palette.primary.light
        }
    }));

    function StepIcon(props: StepIconProps) {
        const { classes } = useStepIconStyles();
        const { active, completed } = props;

        let elem: ReactNode = <DoneIcon />;
        if (!props.completed) {
            elem = String(props.icon);
        }

        return (
            <div
                className={clsx(classes.root, {
                    [classes.active]: active,
                    [classes.completed]: completed
                })}
            >
                {elem}
            </div>
        );
    }

    const toggleShowAll = () => {
        setShowAll(!showAll);
    }

    return (
        <Container className={classes.back}>
            <div className={classes.root}>
                {appLogo()}
                <Stepper activeStep={0} className={classes.stepper}>
                    {steps.map((label) => {
                        const stepProps = {};
                        const labelProps = {};
                        return (
                            <Step key={label} {...stepProps}
                            >
                                <StepLabel {...labelProps} StepIconComponent={StepIcon}
                                    classes={{
                                        completed: classes.stepCompleted
                                    }}>
                                    {label}
                                </StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
                <div style={{ position: "relative" }}>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => window.location.href = `${urlForRedirect}/login`}
                        className={classes.adminLoginButton}
                    >
                        Admin Login
                    </Button>
                    <Typography component={"span"} className={classes.cardRoot}>
                        <Container className={wizardClasses.mainContainer}>
                            <Box display="block">
                                <Typography variant="h1" component="h1">Typing Test for {companyName}</Typography>
                            </Box>

                            {errorMessage.length > 0
                                ? (<p className={classes.errorMessage}>{errorMessage}</p>)
                                : (
                                    <div className={testsClasses.testsAndImageContainer}>
                                        <div className={testsClasses.testsContainer}>
                                            <div className={testsClasses.tests}>
                                                {tests?.map((test, index) => {
                                                    const limit = showAll ? tests?.length : MAX_SHOW_COUNT;
                                                    if (index < limit) {
                                                        return (
                                                            <div className={classes.testCard}>
                                                                <p
                                                                    title={test.name}
                                                                    className={classes.testName}
                                                                >
                                                                    {test.name}
                                                                </p>
                                                                <Button
                                                                    style={{ width: "20%" }}
                                                                    variant="contained"
                                                                    color="secondary"
                                                                    onClick={() => window.location.href = `${urlForRedirect}/test?id=${test.testId}`}
                                                                    className={wizardClasses.button}
                                                                >
                                                                    Start
                                                                </Button>
                                                            </div>
                                                        );
                                                    }
                                                })}
                                            </div>
                                        </div>

                                        <div className={classes.keyCircleBig}>
                                            <img className={classes.logoCircleSmall}
                                                alt="logo"
                                                id="logo"
                                                src={imageData === "" ? defaultLogo : imageData}
                                            />
                                        </div>
                                    </div>
                                )
                            }

                            {tests && tests.length > MAX_SHOW_COUNT && (
                                <div className={classes.showAllSection}>
                                    <Button color="secondary" onClick={toggleShowAll}>
                                        {showAll ? "Hide" : "Show all"}
                                    </Button>
                                </div>
                            )}

                        </Container>
                    </Typography>
                </div>
            </div>
        </Container>
    );
};

export default TestSelectWizard;
