import Typography from "@mui/material/Typography";
import { Accuracy } from "../../hooks/useApi";
import { useTableCellStyles } from "../../theme/tableCellStyles";

interface ResultTableCellAccuracyProps {
    accuracyObj: Accuracy;
}

const ResultTableCellAccuracy = ({
    accuracyObj,
}: ResultTableCellAccuracyProps) => {
    const { classes } = useTableCellStyles();

    let cls = accuracyObj.passed ? classes.passText : classes.errText;
    if (accuracyObj.accuracy === "n/a") {
        cls = classes.naText;
    }
    return (
        <Typography align="center" variant="h4" style={{ fontSize: "24px" }} className={cls}>
            {String(accuracyObj.accuracy) === "n/a" ? "-" : parseInt(String(accuracyObj.accuracy))}
            <span style={{ fontSize: "17px", marginLeft: "0px" }}>
                {String(accuracyObj.accuracy) === "n/a" ? "" : "%"}
            </span>
        </Typography>
    );
};

export default ResultTableCellAccuracy;
