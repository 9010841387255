import React, { useEffect, useRef, useState } from "react";
import { Formik, Form, FormikProps } from "formik";
import TextField from "@mui/material/TextField";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import { makeStyles } from 'tss-react/mui';
import Checkbox from "@mui/material/Checkbox";
import ErrorIcon from "@mui/icons-material/Error";
import * as wizardCss from "../../theme/wizardStyle";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { useFeature } from "flagged";
import { getLogger } from "../../utils/logger";
import { CandidatePersonalData, networkErrorMessage } from "../../hooks/useApi";
import validationSchema from "../../utils/personalDataValidation";
import useConnectionStatus from "../../hooks/useConnectionStatus";
import Loading from "../Loading/Loading";

const log = getLogger();

const EMAIL_MAX_LENGTH = 100;
const INPUT_MAX_LENGTH = 50;
const attemptsMessage = "All Attempts Have Been Used."

const getAttemptsMessage = (newAttemptsAllowed: number): string => {
    if (newAttemptsAllowed !== -1) {
        const allowedDaysOrHours = newAttemptsAllowed < 24 ? `${newAttemptsAllowed} hours` : `${newAttemptsAllowed / 24} days`;
        return `${attemptsMessage} New attempts allowed in ${allowedDaysOrHours}.`;
    } else {
        return attemptsMessage;
    }
};

const initialErrorMessage = { message: "", reason: null };

const useStyles = makeStyles<{ infoFieldsEnabled: boolean }>()((theme, { infoFieldsEnabled }) => ({
    root: {
        width: "100%"
    },
    title: {
        margin: infoFieldsEnabled ? "20px 0" : "30px 0",
    },
    rightArea: {
        position: "relative",
        width: "27%",
        height: "100%",
        color: theme.palette.text.secondary,
        fontSize: "16px",
        lineHeight: "58px",
        textAlign: "right",
        paddingLeft: "30px"
    },
    leftCircle: {
        position: "absolute",
        top: 20,
        right: 20,
        width: "89px",
        height: "89px",
        color: "#CBD2FA"
    },
    checkbox: {
        position: "absolute",
        top: -10,
        left: 0
    },
    navButton: {
        textTransform: "none",
        float: "left",
        marginTop: -18,
        marginLeft: -8
    },
    textPrimary: {
        textTransform: "none",
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.primary.light
    },
    bigField: {
        width: 475,
        height: 57,
        borderRadius: 4,
        marginBottom: infoFieldsEnabled ? 24 : 30,
        "& .MuiFormLabel-root": {
            color: theme.palette.warning.main + "!important"
        },
        "& .MuiInputBase-input": {
            color: theme.palette.primary.dark + "!important"
        },
        "& .Mui-disabled": {
            color: theme.palette.warning.main + "!important"
        }
    },
    smallField: {
        width: 227,
        height: 57,
        color: "#fff",
        borderRadius: 4,
        marginLeft: 10,
        marginRight: 10,
        marginBottom: infoFieldsEnabled ? 24 : 30,
        "& .MuiFormLabel-root": {
            color: theme.palette.warning.main + "!important"
        },
        "& .MuiInputBase-input": {
            color: theme.palette.primary.dark + "!important"
        },
        "& .Mui-disabled": {
            color: theme.palette.warning.main + "!important"
        }
    },
    consentContainer: {
        width: "475px",
        marginTop: infoFieldsEnabled ? 0 : "15px"
    },
    fieldBox: {
        width: 575,
        flexWrap: "wrap",
        justifyContent: "center",
        height: "fit-content"
    },
    notFoundText: {
        color: theme.palette.text.secondary,
        fontSize: "14px",
        margin: "-10px 0 10px 0"
    },
    warningBox: {
        position: "absolute",
        width: "1000px",
        height: "60px",
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
        fontSize: "27px",
        transform: "translate(-50%, -50%)",
        left: "50%",
        top: "50%",
        textAlign: "center",
        lineHeight: "60px",
        borderRadius: "6px"
    },
    viewButton: {
        fontSize: "14px",
        textTransform: "none"
    }
}));

export interface TestStepTwoFormValues {
    email: string;
    firstName: string;
    lastName: string;
    infoFieldOneValue: string;
    infoFieldTwoValue: string;
    consentAccepted: boolean;
}

export interface TestStepTwoProps {
    handleNext: (values: TestStepTwoFormValues, onError?: (reason: "network" | "unknown") => void) => void;
    handleBack: () => void;
    testId: string;
    initialEmail: string;
    initialFirstName: string;
    initialLastName: string;
    infoFieldOneEnabled: boolean;
    infoFieldOneTitle: string;
    initialInfoFieldOneValue: string;
    infoFieldTwoEnabled: boolean;
    infoFieldTwoTitle: string;
    initialInfoFieldTwoValue: string;
    initialConsentAccepted: boolean;
    personalData: {
        email: string;
        firstName: string;
        lastName: string;
        infoFieldOneValue: string;
        infoFieldTwoValue: string;
        consentAccepted: boolean;
        candidateId: string;
    };
    candidate?: CandidatePersonalData;
    checkEmail: (email: string) => Promise<{ found: boolean, isNetworkError?: boolean }>;
    disableOtherThanEmail: boolean;
    emailChecked: boolean;
    attemptsLeft: number;
    emailFound: boolean;
    newAttemptsAllowed: number;
}

const TestStepTwo = (props: TestStepTwoProps) => {
    const { classes } = useStyles({ infoFieldsEnabled: props.infoFieldOneEnabled && props.infoFieldTwoEnabled });
    const { classes: wizardClasses } = wizardCss.wizardStyles();
    const showElements = useFeature("d_ce_s");

    const firstNameRef = useRef<HTMLInputElement>(null);

    const [consentError, setConsentError] = useState(false);
    const { isOffline } = useConnectionStatus();
    const [errorMessage, setErrorMessage] = useState<{ message: string, reason: "network" | "attempts" | null }>(initialErrorMessage);
    const [checkingEmail, setCheckingEmail] = useState(false);

    useEffect(() => {
        if (isOffline) {
            setErrorMessage({ message: networkErrorMessage, reason: "network" });
        } else {
            if (errorMessage.reason === "network") {
                setErrorMessage(initialErrorMessage);
            }
        }
    }, [isOffline]);

    useEffect(() => {
        if (props.attemptsLeft === 0) {
            setErrorMessage({ message: getAttemptsMessage(props.newAttemptsAllowed), reason: "attempts" });
        }
    }, [props.attemptsLeft]);

    const handleSubmit = (values: TestStepTwoFormValues) => {
        props.handleNext(values, (reason) => {
            if (reason === "network") {
                setErrorMessage({ message: networkErrorMessage, reason: "network" });
            }
        });
    };

    return (
        <Formik
            initialValues={{
                email: props.candidate ? props.candidate.email : props.initialEmail,
                firstName: props.candidate ? props.candidate.firstName : props.initialFirstName,
                lastName: props.candidate ? props.candidate.lastName : props.initialLastName,
                infoFieldOneValue: props.candidate ? props.candidate.infoFieldOneValue : props.initialInfoFieldOneValue,
                infoFieldTwoValue: props.candidate ? props.candidate.infoFieldTwoValue : props.initialInfoFieldTwoValue,
                consentAccepted: props.initialConsentAccepted
            }}
            validationSchema={validationSchema}
            onSubmit={() => {
                // does nothing
            }}
            enableReinitialize={true}
        >
            {(formProps: FormikProps<TestStepTwoFormValues>) => {
                const { isValid, values, touched, dirty, errors, handleBlur, handleChange, setFieldTouched } = formProps;

                const isNextButtonDisabled = (): boolean => {
                    const initialEmailOk = props.initialEmail !== "";
                    if (!values.consentAccepted) {
                        return true;
                    }
                    if (initialEmailOk && isValid) {
                        return false;
                    }
                    if (isValid && dirty) {
                        return false;
                    }
                    return true;
                };

                const processEmail = async (e: React.FocusEvent<HTMLInputElement>): Promise<void> => {
                    handleBlur(e);
                    if (props.emailChecked) {
                        return;
                    }
                    if (!errors.email) {
                        if (values.email !== "") {
                            setCheckingEmail(true);
                            const { found, isNetworkError } = await props.checkEmail(values.email);
                            setCheckingEmail(false);
                            if (!found) {
                                if (isNetworkError) {
                                    setErrorMessage({ message: networkErrorMessage, reason: "network" });
                                } else if (firstNameRef && firstNameRef.current) {
                                    firstNameRef.current.focus();
                                }
                            }
                        }
                    }
                };

                return (
                    <Container className={wizardClasses.mainContainer}>
                        <Box
                            sx={{
                                position: "absolute"
                            }}
                        >
                            <Loading
                                show={checkingEmail}
                                text="Please wait ..."
                                delay={1000}
                            />
                        </Box>
                        <Typography
                            variant="h1"
                            component="h1"
                            className={classes.title}
                        >
                            Personal Information
                        </Typography>
                        <Form>
                            <Box display="flex">
                                <div className={wizardClasses.leftArea}>
                                    <AccountCircle className={classes.leftCircle} />
                                </div>
                                <Box display="flex" className={classes.fieldBox}>

                                    <Box position="relative">
                                        <TextField
                                            name="email"
                                            id="email"
                                            label="Email Address"
                                            value={values.email}
                                            helperText={
                                                errors.email && touched.email ? errors.email : ""
                                            }
                                            error={errors.email && touched.email ? true : false}
                                            onChange={handleChange}
                                            onBlur={processEmail}
                                            variant="outlined"
                                            color="secondary"
                                            className={classes.bigField}
                                            autoFocus={!props.emailChecked}
                                            inputProps={{ maxLength: EMAIL_MAX_LENGTH }}
                                            disabled={Boolean(props.candidate)}
                                        />
                                        <p className={classes.notFoundText}>{props.emailFound ? "Email found - returning candidate" : null}</p>
                                    </Box>
                                    <TextField
                                        name="firstName"
                                        id="firstName"
                                        label="First Name"
                                        value={values.firstName}
                                        type="text"
                                        helperText={
                                            errors.firstName && touched.firstName
                                                ? errors.firstName
                                                : ""
                                        }
                                        error={errors.firstName && touched.firstName ? true : false}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        variant="outlined"
                                        color="secondary"
                                        className={classes.smallField}
                                        disabled={props.disableOtherThanEmail}
                                        inputRef={firstNameRef}
                                        inputProps={{ maxLength: INPUT_MAX_LENGTH }}
                                    />
                                    <TextField
                                        name="lastName"
                                        id="lastName"
                                        label="Last Name"
                                        value={values.lastName}
                                        type="text"
                                        helperText={
                                            errors.lastName && touched.lastName
                                                ? errors.lastName
                                                : ""
                                        }
                                        error={errors.lastName && touched.lastName ? true : false}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        variant="outlined"
                                        color="secondary"
                                        className={classes.smallField}
                                        disabled={props.disableOtherThanEmail}
                                        inputProps={{ maxLength: INPUT_MAX_LENGTH }}
                                    />
                                    {props.infoFieldOneEnabled ? (
                                        <TextField
                                            name="infoFieldOneValue"
                                            id="infoFieldOneValue"
                                            label={props.infoFieldOneTitle}
                                            value={values.infoFieldOneValue}
                                            type="text"
                                            helperText={
                                                errors.infoFieldOneValue && touched.infoFieldOneValue
                                                    ? errors.infoFieldOneValue
                                                    : ""
                                            }
                                            error={errors.infoFieldOneValue && touched.infoFieldOneValue ? true : false}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            variant="outlined"
                                            color="secondary"
                                            className={classes.bigField}
                                            disabled={props.disableOtherThanEmail}
                                            inputProps={{ maxLength: INPUT_MAX_LENGTH }}
                                        />) : null}

                                    {props.infoFieldTwoEnabled && (
                                        <TextField
                                            name="infoFieldTwoValue"
                                            id="infoFieldTwoValue"
                                            label={props.infoFieldTwoTitle}
                                            value={values.infoFieldTwoValue}
                                            type="text"
                                            helperText={
                                                errors.infoFieldTwoValue && touched.infoFieldTwoValue
                                                    ? errors.infoFieldTwoValue
                                                    : ""
                                            }
                                            error={errors.infoFieldTwoValue && touched.infoFieldTwoValue ? true : false}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            variant="outlined"
                                            color="secondary"
                                            className={classes.bigField}
                                            disabled={props.disableOtherThanEmail}
                                            inputProps={{ maxLength: INPUT_MAX_LENGTH }}
                                        />)}
                                    {props.emailFound && showElements && (
                                        <Box width="475px" marginTop="-30px">
                                            <Button className={classes.viewButton} color="secondary" id="btn_view">
                                                View and modify your information
                                            </Button>
                                        </Box>)}
                                    <div className={classes.consentContainer}>
                                        <FormControl error={consentError}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={values.consentAccepted}
                                                        onChange={(e, checked) => {
                                                            if (checked) {
                                                                setConsentError(false);
                                                            } else {
                                                                setConsentError(true);
                                                            }
                                                            handleChange(e);
                                                        }}
                                                        name="consentAccepted" id="consent"
                                                        color="secondary"
                                                    />
                                                }
                                                label=""
                                                className={classes.checkbox}
                                                onBlur={() => {
                                                    setFieldTouched("consentAccepted");
                                                }}
                                                color="error"
                                            />
                                            {consentError ? (<FormHelperText style={{
                                                textIndent: "30px",
                                                position: "absolute",
                                                marginTop: "-20px"
                                            }}>Must Accept Terms and Conditions</FormHelperText>) : null}
                                            <Typography variant="subtitle1" style={{ textIndent: "30px" }}>I consent to
                                                the processing and storing of the personal data that I have provided for
                                                the purpose of conducting this test.</Typography>
                                            <div>
                                                <a href="https://typingtestpro.zendesk.com/hc/en-us/articles/4639986340882"
                                                    target="_blank" className={classes.navButton}>
                                                    <Button variant="text" color="primary" style={{ marginTop: "20px" }}
                                                        classes={{ textPrimary: classes.textPrimary }} disableElevation>
                                                        Read more
                                                    </Button>
                                                </a>
                                            </div>
                                        </FormControl>
                                    </div>
                                </Box>
                            </Box>

                            <div className={wizardClasses.buttonContainer} style={{ bottom: "30px" }}>
                                <Button className={wizardClasses.button} color="secondary" onClick={props.handleBack}
                                    id="btn_back">
                                    Back
                                </Button>
                                {errorMessage.message ? (
                                    <Box display="inline-block">
                                        <div className={classes.warningBox}>
                                            < ErrorIcon />
                                            {errorMessage.message}
                                        </div>
                                    </Box>
                                ) : (
                                    <div style={{ display: "inline-block" }} onClick={() => {
                                        log.debug(`Next clicked`);

                                        if (!values.consentAccepted) {
                                            setConsentError(true);
                                        }
                                    }}>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => handleSubmit(values)}
                                            className={wizardClasses.button}
                                            id="btn_next"
                                            disabled={isNextButtonDisabled()}
                                        >
                                            Next
                                        </Button>
                                    </div>
                                )}
                            </div>
                        </Form>
                    </Container>
                );
            }}
        </Formik>
    );
};

export default TestStepTwo;
