import Button from "@mui/material/Button";
import axios from "axios";
import { useAuth, useAuthTokens } from "../../context/auth";
import { useNavigate } from "react-router-dom";

const instance = axios.create({ baseURL: process.env.REACT_APP_BACKEND_ADDRESS, withCredentials: true });

const Logout = () => {
    const { setAuthTokens } = useAuth();
    const navigate = useNavigate();

    const tokens = useAuthTokens();
    const clearAuthTokens = () => {
        if (setAuthTokens) {
            setAuthTokens({ email: "", status: "" });
        }
        localStorage.removeItem("tokens");
    };
    const logOut = () => {
        clearAuthTokens();

        instance
            .post(`api/logout`)
            .then((result) => {
                if (result.status === 200) {
                    console.log(`logged out successfully`);
                } else {
                    console.log(`logged out but received status ${result.status}`);
                }
            })
            .catch((error) => {
                console.log(`exception while logging out ${JSON.stringify(error)}`);
            })
            .finally(() => {
                navigate("/login");
            });
    };
    let render = "";
    if (tokens) {
        render = tokens.email;
    }

    return render === "" ? null : (
        <Button
            id="btn_logout"
            onClick={logOut}
            variant="contained"
            color="secondary"
            size="small"
        >
            Log out
        </Button>
    );
};

export default Logout;
