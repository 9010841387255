import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { UpgradeSubscriptionResponse } from "../../hooks/useApi";
import { useMemo } from "react";

interface PlanCardUpgradeDialogProps {
    open: boolean;
    onClose: () => void;
    fetching: boolean;
    data?: Omit<UpgradeSubscriptionResponse, 'status'>
    status: string;
}

const enGbYyMmDdFormatter = new Intl.DateTimeFormat("en-GB", {
    year: "numeric",
    month: "short",
    day: "numeric"
});

const PlanCardUpgradeDialog = ({
    open,
    onClose,
    fetching,
    data,
    status,
}: PlanCardUpgradeDialogProps) => {
    const errorText = useMemo(() => {
        let text = 'Something wrong! Please try again later.';
        if (status === 'STATUS_IS_NOT_ACTIVE') {
            text = 'The plan is not active, please activate before upgrading.';
        } else if (status === 'CAN_NOT_BE_CHANGED') {
            text = 'For some reason plan cannot be changed.';
        }
        return text;
    }, [status]);

    const nextPeriodDateStr = useMemo(() => {
        if (data?.nextPeriodDate) {
            try {
                const formattedDate = enGbYyMmDdFormatter.format(new Date(data.nextPeriodDate));
                return formattedDate;
            } catch {
                // do nothing
            }
        }
        return null;
    }, [data?.nextPeriodDate]);

    const handleClose = () => {
        if (fetching) return null;

        window.location.reload();
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
        >
            <DialogTitle color="primary.dark">
                Upgrade Subscription Plan
            </DialogTitle>
            <DialogContent
                sx={{
                    minWidth: '600px'
                }}
            >
                {fetching ? (
                    <Typography>
                        Loading ...
                    </Typography>
                ) : data ? (
                    <>
                        <Typography color="primary.dark">
                            Your new plan is:
                            {' '}
                            <Typography component="span" fontWeight="bold">
                                {data.newPlanName}
                            </Typography>
                        </Typography>
                        {nextPeriodDateStr && (
                            <Typography color="primary.dark">
                                The next billing date is
                                {' '}
                                <Typography component="span" fontWeight="bold">
                                    {enGbYyMmDdFormatter.format(new Date(data.nextPeriodDate))}
                                </Typography>
                            </Typography>
                        )}
                    </>
                ) : (
                    <>
                        <Typography color="primary.dark" gutterBottom>
                            {errorText}
                        </Typography>
                        <Typography color="primary.dark" gutterBottom>
                            Upgrading is not possible, please buy a license instead.
                        </Typography>
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleClose}
                    disabled={fetching}
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default PlanCardUpgradeDialog;
