import { useEffect } from 'react';
import { ensureAccountDomainInUse, getAccountDomainFromTestLink } from '../utils/accountDomain';

const useRedirectToApp = () => {
    useEffect(() => {
        const currentAccountDomain = getAccountDomainFromTestLink();
        ensureAccountDomainInUse(currentAccountDomain);
    }, []);
};

export default useRedirectToApp;
