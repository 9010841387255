import { useEffect, useRef, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()({
    loadingSvg: {
        color: "#3F51B5"
    },
});

interface LoadingProps {
    show: boolean;
    text?: string;
    size?: number;
    delay?: number;
}

const Loading = ({
    show,
    text,
    size = 30,
    delay,
}: LoadingProps) => {
    const { classes } = useStyles();
    const [loading, setLoading] = useState(false);
    const timeout = useRef<ReturnType<typeof setTimeout>>();

    useEffect(() => {
        if (timeout.current) {
            clearTimeout(timeout.current);
        }
        if (show) {
            if (delay) {
                timeout.current = setTimeout(() => {
                    setLoading(true);
                }, delay);
            } else {
                if (!loading) {
                    setLoading(show);
                }
            }
        } else {
            if (loading !== show) {
                setLoading(show);
            }
        }
    }, [show]);

    if (!loading) return null;

    if (text) {
        return (
            <Typography variant="body2" color="gray">
                {text}
            </Typography>
        )
    }

    return (
        <CircularProgress size={`${size}px`} className={classes.loadingSvg} />
    );
};

export default Loading;
