import { makeStyles } from "tss-react/mui"

const useStyles = makeStyles()({
    statusPassed: {
        width: "114px",
        height: "27px",
        border: "2px solid #02BA7C",
        borderRadius: "40px",
        color: "#02BA7C"
    },
    statusFail: {
        width: "114px",
        height: "27px",
        border: "2px solid #E0789A",
        borderRadius: "40px",
        color: "#E0789A"
    },
    statusIncomplete: {
        width: "114px",
        height: "27px",
        border: "2px solid #ADADAD",
        borderRadius: "40px",
        color: "#ADADAD"
    },
    statusNotStarted: {
        width: "114px",
        height: "27px",
        color: "#ADADAD"
    },
    statusSpan: {
        lineHeight: "27px",
        display: "block",
        textAlign: "center",

        fontSize: "14px",
        fontWeight: 700,
        marginTop: "-1px"
    },
})

interface ResultTableCellStatusProps {
    status: string;
}

const ResultTableCellStatus = ({
    status,
}: ResultTableCellStatusProps) => {
    const { classes } = useStyles();

    let cls = classes.statusPassed;

    if (status === "NOT PASSED") {
        cls = classes.statusFail;
    } else if (status === "INCOMPLETE") {
        cls = classes.statusIncomplete;
    } else if (status === "NOT STARTED") {
        cls = classes.statusNotStarted;
    }

    return (
        <div className={cls}><span className={classes.statusSpan}>{status}</span></div>
    );
};

export default ResultTableCellStatus;
