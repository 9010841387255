import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { makeStyles } from 'tss-react/mui';
import React, { FC, useEffect, useRef, useState } from 'react';
import { MyAxiosError, arrayBufferToBase64, useDeleteAccountLogoData, useGetAccountLogoData, usePostAccountLogoData } from '../../hooks/useApi';
import Input from '@mui/material/Input';
import { getLogger } from "../../utils/logger";
const log = getLogger();

const useStyles = makeStyles()({
  root: {
    marginTop: "16px",
    display: "flex",
  },
  bigCircle: {
    width: "242px",
    height: "242px",
    display: "flex",
    margin: "0px 20px 0 50px",
    position: "relative",
  },
  logo: {
    maxWidth: "242px",
    maxHeight: "242px",
    width: "auto",
    height: "auto",
    margin: "auto",
  },
  buttonBox: {
    marginTop: "70px",
    marginRight: "30px"
  },
});

const AccountLogo: FC = () => {
  const { classes } = useStyles();

  const [imageData, setImageData] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const fileInput = useRef<HTMLInputElement>(null);

  const getErrorMessage = (error: MyAxiosError): string => {
    if (error && error.response && error.response.data && error.response.data.message) {
      if (error.response.data.message === "File too large") {
        return "Maximum size is 500kB"
      }
      return error.response.data.message;
    }
    return "Unknown error, please try again later"
  }

  const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      log.debug(`onFileChange() - returning early, no file selected`);
      return;
    }
    log.debug(`onFileChange() - new file selected`);
    log.debug(e.target.files[0]);
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    usePostAccountLogoData(formData, () => {
      getLogoImage();
    }, (error) => {
      log.error(`onFileChange() - error: ${JSON.stringify(error, null, 2)}`);
      setErrorMessage(getErrorMessage(error));
      getLogoImage();
    });
  }

  const onDelete = async () => {
    useDeleteAccountLogoData(() => {
      getLogoImage();
    }, (error) => {
      log.error(`onDelete() - error: ${JSON.stringify(error, null, 2)}`);
      getLogoImage();
    });
  }

  const clearFileInputValue = () => {
    if (fileInput && fileInput.current) {
      fileInput.current.value = "";
    }
  }

  const getLogoImage = async () => {
    log.debug(`getLogoImage()`);
    useGetAccountLogoData((data) => {
      if (data.logo) {
        const imageString = arrayBufferToBase64(data.logo.image.data.data);
        setImageData(`data:${data.logo.image.type};base64,${imageString}`);
      } else {
        setImageData("");
      }
      clearFileInputValue();
    }, (error) => {
      log.error(`getLogoImage() - ${error}`);
      clearFileInputValue();
    });
  }

  const closeErrorDialog = () => {
    setErrorMessage("");
  }

  useEffect(() => {
    getLogoImage();
  }, []);

  return (
    <div>
      <Box className={classes.root}>

        <Box className={classes.bigCircle}>
          {imageData !== "" ? (
            <img alt="logo" id="img" src={imageData} className={classes.logo} />
          ) : null}

        </Box>

        <Box className={classes.buttonBox}>
          <label htmlFor="btn_select_file" style={{ display: "block" }}>
            <Input ref={fileInput} id="btn_select_file" type="file" onChange={onFileChange} style={{ display: "none" }} />
            <Button color="secondary" variant="outlined" component="span">
              CHANGE LOGO
            </Button>
          </label>
          <Button color="secondary" id="btn_remove_logo" variant="outlined" component="span" onClick={onDelete}>
            REMOVE LOGO
          </Button>
        </Box>

        <Dialog
          open={errorMessage !== ""}
          onClose={closeErrorDialog}
          aria-labelledby="logo-upload-dialog-title"
          aria-describedby="logo-upload-dialog-description"
        >
          <DialogTitle
            id="logo-upload-dialog-title"
            style={{ textAlign: "center" }}>
            Logo Upload Error
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="logo-upload-dialog-description">
              {errorMessage}
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ margin: "0 auto 20px auto" }}>
            <Button onClick={closeErrorDialog} color="secondary" autoFocus id="btn_ok">
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </Box>

    </div>
  )
};

export default AccountLogo;
