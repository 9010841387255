import Container from "@mui/material/Container";
import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { makeStyles } from 'tss-react/mui';
import ErrorIcon from "@mui/icons-material/Error";
import defaultLogo from "../../ttpro_logo.png";
import * as wizardCss from "../../theme/wizardStyle";
import { accessCodeField, useGetTestAccessCode } from "../../hooks/useApi";
import TextField from "@mui/material/TextField";

const useStyles = makeStyles()((theme) => ({
    root: {
        width: "100%",
        height: "100%",
    },
    cardTestRoot: {
        minWidth: 400,
        maxWidth: 400,
        minHeight: 400,
        maxHeight: 400,
        alignContent: "center",
    },
    titleDisabled: {
        color: theme.palette.warning.main,
    },
    keyCircleBig: {
        position: "absolute",
        width: "300px",
        height: "300px",
        top: "90px",
        right: "40px",
        display: "flex",
    },
    logoCircleSmall: {
        width: "auto",
        height: "auto",
        maxWidth: "300px",
        maxHeight: "300px",
        marginLeft: "auto",
        marginBottom: "auto",
    },
    textDisabled: {
        color: theme.palette.text.primary + " !important",
    },
    warningBoxBase: {
        position: "absolute",
        minWidth: "900px",
        minHeight: "60px",
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
        transform: "translate(-50%, -50%)",
        left: "50%",
        top: "50%",
        textAlign: "center",
        borderRadius: "6px",
    },
    warningBox: {
        fontSize: "27px",
        lineHeight: "60px",
    },
    warningBoxSmall: {
        fontSize: "24px",
        lineHeight: "43px",
    },
    notFoundBox: {
        position: "absolute",
        width: "1000px",
        height: "60px",
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
        fontSize: "27px",
        transform: "translate(-50%, -50%)",
        left: "50%",
        bottom: "30px",
        textAlign: "center",
        lineHeight: "60px",
        borderRadius: "6px",
    },
    errorIcon: {
        position: "absolute",
        fontSize: "36px",
        left: "10px",
        top: "11px",
    },
    titleCompanySpan: {
        width: "100%",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
    accessField: {
        width: "400px",
        height: "30px",
        fontSize: "18px",
        color: "#000000"
    }
}));

export interface TestStepOneProps {
    handleNext: () => void;
    companyName: string;
    duration: number;
    maxAttempts: number;
    active: boolean;
    minNetWpm: number;
    minAccuracy: number;
    useLimits: boolean;
    showLimits: boolean;
    note: {
        enabled: boolean;
        text: string;
    };
    dataReceived: boolean;
    name: string;
    imageData: string;
    accessCode: accessCodeField;
    testId: string;
    testResponseErrorStatus?: string;
}

const TestStepOne = (props: TestStepOneProps) => {
    const { classes } = useStyles();
    const { classes: wizardClasses } = wizardCss.wizardStyles();
    const [accessCodeIsValid, setAccessCodeIsValid] = useState(false)
    const [accessCode, setAccessCode] = useState("")
    const [showErrorMessage, setShowErrorMessage] = useState(false)

    if (!props.dataReceived) {
        return null;
    }

    if (props.testResponseErrorStatus === 'ERROR_SUBSCRIPTION_PLAN_EXPIRED') {
        return (
            <Container className={wizardClasses.mainContainer}>
                <Box display="block">
                    <Typography variant="h1" component="h1" className={classes.titleDisabled}>Welcome to TypingTest Pro</Typography>
                    <Box className={`${classes.warningBoxBase} ${classes.warningBoxSmall}`} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '16px' }}>
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                            <ErrorIcon sx={{ marginRight: '8px' }} />
                            This typing test subscription is expired.
                        </span>
                        Please contact your test administrator to activate this test.
                    </Box>
                </Box>
            </Container>
        );
    }

    if (props.companyName == "") {
        return (
            <Container className={wizardClasses.mainContainer}>
                <Box display="block">
                    <Typography variant="h1" component="h1" className={classes.titleDisabled}>Welcome to TypingTest Pro</Typography>
                    <div className={`${classes.warningBoxBase} ${classes.warningBox}`}>
                        <ErrorIcon className={classes.errorIcon} /> Typing test not found - unknown Test ID
                    </div>
                </Box>
            </Container >
        );
    }

    if (!props.active) {
        return (
            <Container className={wizardClasses.mainContainer}>
                <Box display="block">
                    <Typography variant="h1" component="h1" className={classes.titleDisabled}>Welcome to TypingTest Pro</Typography>

                    <Box display="flex">
                        <div className={wizardClasses.leftArea}>
                            <p>Organization <br /> Test title <br /> Duration <br /> Pass limits <br /> Note</p>
                        </div>

                        <div className={wizardClasses.centerArea}>
                            <p className={classes.titleDisabled}>{props.companyName}<br />{props.name}<br />...<br />...<br />...</p>
                        </div>
                    </Box>
                </Box>

                <div className={classes.notFoundBox}> <ErrorIcon className={classes.errorIcon} /> This typing test is currently not active</div>

            </Container >
        );
    }

    const getPassLimitsDiv = () => {
        if (props.useLimits && props.showLimits && (props.minNetWpm > 0 || props.minAccuracy > 0)) {
            return (
                <div>
                    <div className={wizardClasses.labelSpan}>{getPassLimitsTitle()}</div>
                    <div className={wizardClasses.titleSpan}>{getPassLimits()}</div>
                </div>
            )
        } else {
            return (
                <></>
            )
        }
    }

    const getPassLimitsTitle = () => {
        if (props.useLimits && props.showLimits) {
            return (
                <>
                    Pass limits
                </>
            )
        } else {
            return (
                <></>
            )
        }
    }

    const getPassLimits = () => {
        if (props.useLimits && props.showLimits) {
            if (props.minNetWpm > 0 && props.minAccuracy > 0) {
                return (
                    <>
                        {props.minNetWpm} WPM net speed and {props.minAccuracy}% accuracy<br />
                    </>
                )
            }
            if (props.minNetWpm === 0 && props.minAccuracy > 0) {
                return (
                    <>
                        {props.minAccuracy}% accuracy<br />
                    </>
                )
            }
            if (props.minNetWpm > 0 && props.minAccuracy === 0) {
                return (
                    <>
                        {props.minNetWpm} WPM net speed<br />
                    </>
                )
            }
        } else {
            return (
                <></>
            )
        }
    }

    const handleNext = () => {
        if (props.accessCode.enabled) {
            useGetTestAccessCode(props.testId, accessCode, props.handleNext, setShowErrorMessage, setAccessCodeIsValid)
        } else {
            props.handleNext()
        }
    }

    return (
        <Container className={wizardClasses.mainContainer}>
            <Box display="block">
                <Typography variant="h1" component="h1">Welcome to TypingTest Pro</Typography>

                <Box display="flex">
                    <div className={wizardClasses.centerArea} style={{ width: "70%" }}>
                        <div><div className={wizardClasses.labelSpan}>Organizer</div>
                            <div className={wizardClasses.titleSpan} style={{ width: "80%" }}>
                                <div className={classes.titleCompanySpan}>{props.companyName}</div>
                            </div>
                        </div>
                        <div><div className={wizardClasses.labelSpan}>Test title</div><div className={wizardClasses.titleSpan}>{props.name}</div></div>
                        <div><div className={wizardClasses.labelSpan}>Duration</div><div className={wizardClasses.titleSpan}>You have {props.maxAttempts} attempts, {props.duration} min each</div></div>
                        {getPassLimitsDiv()}
                        {props.accessCode.enabled && (
                            <div style={{ lineHeight: "0" }}>
                                <div className={wizardClasses.labelSpanAccessCode}>Access Code</div>
                                <div className={wizardClasses.titleSpanAccessCode}>
                                    <TextField
                                        margin="dense"
                                        id="accessCode"
                                        name="accessCode"
                                        label="Access Code"
                                        style={{ width: "500px" }}
                                        onKeyDown={(event) => {
                                            if (event.key === "Enter") {
                                                handleNext();
                                            }
                                        }}
                                        onChange={(event) => {
                                            setAccessCode(event.target.value)
                                        }}
                                        variant="outlined"
                                        color="secondary"
                                        InputProps={{ classes: { input: classes.accessField } }}
                                        helperText={
                                            showErrorMessage && !accessCodeIsValid
                                                ? "Invalid Code. Please contact the organizer for providing you with the access code."
                                                : ""
                                        }
                                        error={!accessCodeIsValid ? true : false}
                                    />
                                </div>
                            </div>
                        )}
                        <div style={{ display: "flex", minHeight: "58px" }}>
                            <div className={wizardClasses.labelSpan} style={{ lineHeight: "24px", minWidth: "17%" }}>
                                <div style={{ marginTop: "18px" }}>{props.note.enabled ? "Note" : null}</div>
                            </div>
                            <div className={wizardClasses.titleSpan} style={{ lineHeight: "24px" }}>
                                <div style={{ marginTop: "18px" }}>{props.note.enabled ? props.note.text : ""}</div>
                            </div>
                        </div>
                    </div>
                </Box>
            </Box>
            <div className={classes.keyCircleBig}>
                <img className={classes.logoCircleSmall}
                    alt="logo"
                    id="logo"
                    src={props.imageData === "" ? defaultLogo : props.imageData}
                />
            </div>
            <div className={wizardClasses.buttonContainer}>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleNext}
                    className={wizardClasses.button}
                    id="btn_next"
                    autoFocus
                >
                    Next
                </Button>
            </div>
        </Container >
    );
};

export default TestStepOne;
