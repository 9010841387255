import Container from "@mui/material/Container";
import { makeStyles } from 'tss-react/mui';
import Typography from "@mui/material/Typography";
import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate, Navigate } from "react-router-dom";

const useStyles = makeStyles()((theme) => ({
    main: {
        backgroundImage: "url(./assets/img/login_back.png)",
        backgroundRepeat: "no-repeat",
        backgroundPositionX: "center",
        width: "100%",
        height: "calc(100vh - 272px)",
        backgroundSize: "contain",
        minHeight: "530px",
    },
    dialogMain: {
        width: "412px",
        margin: "60px auto",
        padding: "15px 25px",
        background: "white",
        WebkitBoxShadow: "5px 5px 15px 5px rgba(0,0,0,0.33)",
    },
    title: {
        display: "inline-block",
        fontSize: "34px",
        color: theme.palette.warning.main,
        marginBottom: "0 !important",
    },
}));

const EmailConfirmation = () => {
    const { classes } = useStyles();
    const useQuery = () => {
        const location = useLocation();
        return new URLSearchParams(location.search);
    };
    const query = useQuery();
    const token = query.get("token");
    const navigate = useNavigate();

    if (!token) {
        console.log(`no token is present`);
        navigate("/");
    }

    enum CONFIRMATION_STATE {
        notStarted = 0,
        started,
        success,
        failure_token_not_found,
    }
    const [state, setState] = useState<CONFIRMATION_STATE>(
        CONFIRMATION_STATE.notStarted,
    );

    const sendEmailConfirmation = async () => {
        try {
            setState(() => {
                return CONFIRMATION_STATE.started;
            });
            await axios.post(`${process.env.REACT_APP_BACKEND_ADDRESS}/api/email-confirmation`, {
                token,
            });
            setState(() => {
                return CONFIRMATION_STATE.success;
            });
        } catch (error) {
            console.log(`submitForm - ${JSON.stringify(error)}`);
            setState(() => {
                return CONFIRMATION_STATE.failure_token_not_found;
            });
        }
    };

    const renderDialog = () => {
        return (
            <Container className={classes.main}>
                {state === CONFIRMATION_STATE.failure_token_not_found ? (
                    <div className={classes.dialogMain}>
                        <Typography className={classes.title}>
                            Account confirmation has expired, please register again.
                        </Typography>
                    </div>
                ) : (
                    <></>
                )}
            </Container >
        );
    }

    useEffect(() => {
        if (state === CONFIRMATION_STATE.notStarted) {
            sendEmailConfirmation();
        }
    }, []);

    if (state === CONFIRMATION_STATE.success) {
        return <Navigate to="/login?email_confirmed=true" />;
    }

    return renderDialog();
};

export default EmailConfirmation;
