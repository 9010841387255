import React from "react";
import Button from "@mui/material/Button";
import { makeStyles } from 'tss-react/mui';
import { NavLink as RouterNavLink, useMatch } from "react-router-dom";

const useStyles = makeStyles()((theme) => ({
    root: {
        height: "var(--footer-height)",
        display: "block",
        margin: "auto 32px 0",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        borderTop: "2px solid #D5D5D5",
        fontSize: "16px",
        color: theme.palette.text.secondary,
    },
    text: {
        textTransform: "none",
        color: "#ABABAB",
        backgroundColor: theme.palette.primary.main,
        marginTop: "10px",
    },
    link: {
        color: "#ABABAB",
        '&:hover': {
            color: "#ABABAB",
        },
    },
    noLink: {
        color: "#ABABAB",
        '&:hover': {
            color: "#ABABAB",
            textDecoration: "none",
        },
    },
}));

const Footer = () => {

    const { classes } = useStyles();
    const testUrl = useMatch("/test");

    return (
        <div className={classes.root} style={{ display: testUrl ? "none" : "block" }}>
            <a href="https://typingtestpro.zendesk.com/hc/en-us" target="_blank" className={classes.link}>
                <Button variant="text" color="secondary" className={classes.text} disableElevation>
                    Support
                </Button>
            </a>
            <a href="https://www.iubenda.com/terms-and-conditions/85323953" target="_blank" className={classes.link}>
                <Button variant="text" color="secondary" className={classes.text} disableElevation>
                    Terms and Conditions
                </Button>
            </a>
            <a href="https://www.iubenda.com/privacy-policy/85323953" target="_blank" className={classes.link}>
                <Button variant="text" color="secondary" className={classes.text} disableElevation>
                    Privacy Policy
                </Button>
            </a>
            <RouterNavLink to={"/heartbeat"} className={classes.noLink}>
                <Button variant="text" color="secondary" className={classes.text} style={{ float: "right" }} disableElevation>
                    © Typing Master, Inc. 2024
                </Button>
            </RouterNavLink>
        </div >
    )
};

export default Footer;
