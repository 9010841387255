import React from "react";
import TestWizard from "../../components/TestWizard/TestWizard";

const Test = () => (
    <div>
        <TestWizard />
    </div>
);

export default Test;
