import { getLogger } from "../utils/logger"
const log = getLogger();

type FOOBAR_ENV = "DEV" | "STAGING" | "NEW_STAGING" | "PROD" | "NEW_PROD";

const FIRST = 0;
const SECOND = 1;
const THIRD = 2;

const detectEnv = (hostParts: string[]): FOOBAR_ENV => {
    if (hostParts[FIRST] === "com" &&
        hostParts[SECOND] === "mydevbox") {
        return "DEV";
    } else if (hostParts[FIRST] === "com" &&
        hostParts[SECOND] === "typingmaster10" &&
        hostParts[THIRD] === "typingtestpro-stage") {
        return "STAGING";
    }
    else if (hostParts[FIRST] === "com" &&
        hostParts[SECOND] === "typingtestpro" &&
        hostParts[THIRD] === "sta") {
        return "NEW_STAGING";
    }
    else if (hostParts[FIRST] === "com" &&
        hostParts[SECOND] === "typingtest") {
        return "PROD";
    }
    else if (hostParts[FIRST] === "com" &&
        hostParts[SECOND] === "typingtestpro") {
        return "NEW_PROD";
    }
    return "DEV";
}

const devEnvStrategy = (parts: string[], accountDomain: string | undefined): string => {
    const [tld, sld, thirdLevelDomain] = parts;

    let strategyDomain = "";

    if (thirdLevelDomain === "ttpro" && accountDomain !== "" && accountDomain) {
        log.debug(`devEnvStrategy() - using provided accountDomain: ${accountDomain}`);
        strategyDomain = accountDomain + "." + thirdLevelDomain + "." + sld + "." + tld;
    } else if ((thirdLevelDomain && thirdLevelDomain === accountDomain) || thirdLevelDomain === "ttpro") {
        log.debug(`devEnvStrategy() - thirdLevelDomain is ok: ${thirdLevelDomain}`);
        strategyDomain = thirdLevelDomain + "." + sld + "." + tld;
    } else if (!accountDomain || accountDomain === "") {
        log.debug(`devEnvStrategy() - no account domain`);
        strategyDomain = "ttpro" + "." + sld + "." + tld;
    } else if (thirdLevelDomain !== "ttpro") {
        log.debug(`devEnvStrategy() - mismatch -> using provided accountDomain: ${accountDomain} and ttpro`);
        strategyDomain = accountDomain + "." + "ttpro" + "." + sld + "." + tld;
    }
    log.debug(`devEnvStrategy() - returning ${strategyDomain}`);
    return strategyDomain;
}

const stagingEnvStrategy = (parts: string[], accountDomain: string | undefined): string => {
    const [tld, sld, thirdLevelDomain, fourthLevelDomain] = parts;

    if (fourthLevelDomain === "ttpro" && accountDomain !== "" && accountDomain) {
        log.debug(`stagingEnvStrategy() - using provided accountDomain: ${accountDomain}`);
        return accountDomain + "." + thirdLevelDomain + "." + sld + "." + tld;
    } else if ((fourthLevelDomain && fourthLevelDomain === accountDomain) || fourthLevelDomain === "ttpro") {
        log.debug(`stagingEnvStrategy() - fourthLevelDomain is ok: ${fourthLevelDomain}`);
        return fourthLevelDomain + "." + thirdLevelDomain + "." + sld + "." + tld;
    } else if (!accountDomain || accountDomain === "") {
        log.debug(`stagingEnvStrategy() - no account domain`);
        return "ttpro" + "." + thirdLevelDomain + "." + sld + "." + tld;
    } else if (fourthLevelDomain !== accountDomain) {
        log.debug(`stagingEnvStrategy() - mismatch -> using provided accountDomain: ${accountDomain}`);
        return accountDomain + "." + thirdLevelDomain + "." + sld + "." + tld;
    }
    return "";
}

const newStagingEnvStrategy = (parts: string[], accountDomain: string | undefined): string => {
    const [tld, sld, thirdLevelDomain, fourthLevelDomain] = parts;

    if (fourthLevelDomain && accountDomain && accountDomain !== "" && fourthLevelDomain !== accountDomain) {
        log.debug(`newStagingEnvStrategy() - domain doesn't match, switching to accountDomain: ${accountDomain}`);
        return accountDomain + "." + thirdLevelDomain + "." + sld + "." + tld;
    } else if (!fourthLevelDomain && !accountDomain) {
        log.debug(`newStagingEnvStrategy() - accountDomain and fourthLevelDomain are undefined`);
        return thirdLevelDomain + "." + sld + "." + tld;
    } else if (fourthLevelDomain === accountDomain) {
        log.debug(`newStagingEnvStrategy() - current domain matches to accountDomain: ${accountDomain}`);
        return fourthLevelDomain + "." + thirdLevelDomain + "." + sld + "." + tld;
    } else if (!accountDomain || accountDomain === "") {
        log.debug(`newStagingEnvStrategy() - no account domain, staying in current domain`);
        return thirdLevelDomain + "." + sld + "." + tld;
    } else if (fourthLevelDomain !== accountDomain) {
        log.debug(`newStagingEnvStrategy() - mismatch -> using provided accountDomain: ${accountDomain}`);
        return accountDomain + "." + thirdLevelDomain + "." + sld + "." + tld;
    }
    return "";
}

const prodEnvStrategy = (parts: string[], accountDomain: string | undefined): string => {
    const [tld, sld, thirdLevelDomain] = parts;

    if (thirdLevelDomain === "app" && accountDomain !== "" && accountDomain) {
        log.debug(`prodEnvStrategy() - using provided accountDomain: ${accountDomain}`);
        return accountDomain + "." + sld + "." + tld;
    } else if ((thirdLevelDomain && thirdLevelDomain === accountDomain) || thirdLevelDomain === "app") {
        log.debug(`prodEnvStrategy() - thirdLevelDomain is ok: ${thirdLevelDomain}`);
        return thirdLevelDomain + "." + sld + "." + tld;
    } else if (!accountDomain || accountDomain === "") {
        log.debug(`prodEnvStrategy() - no account domain`);
        return "app" + "." + sld + "." + tld;
    } else if (thirdLevelDomain !== accountDomain) {
        log.debug(`prodEnvStrategy() - mismatch -> using provided accountDomain: ${accountDomain}`);
        return accountDomain + "." + sld + "." + tld;
    }
    return "";
}

const newProdEnvStrategy = (parts: string[], accountDomain: string | undefined): string => {
    const [tld, sld, thirdLevelDomain] = parts;

    if (thirdLevelDomain === "app" && accountDomain !== "" && accountDomain) {
        log.debug(`newProdEnvStrategy() - using provided accountDomain: ${accountDomain}`);
        return accountDomain + "." + sld + "." + tld;
    } else if ((thirdLevelDomain && thirdLevelDomain === accountDomain) || thirdLevelDomain === "app") {
        log.debug(`newProdEnvStrategy() - thirdLevelDomain is ok: ${thirdLevelDomain}`);
        return thirdLevelDomain + "." + sld + "." + tld;
    } else if (!accountDomain || accountDomain === "") {
        log.debug(`newProdEnvStrategy() - no account domain`);
        return "app" + "." + sld + "." + tld;
    } else if (thirdLevelDomain !== accountDomain) {
        log.debug(`newProdEnvStrategy() - mismatch -> using provided accountDomain: ${accountDomain}`);
        return accountDomain + "." + sld + "." + tld;
    }
    return "";
}

const handleAccountDomain = (accountDomain: string | undefined): string => {
    try {
        log.debug(`handleAccountDomain() - accountDomain: ${JSON.stringify(accountDomain)}`);
        const host = window.location.host;
        log.debug(`handleAccountDomain() - host: ${JSON.stringify(host)}`);
        const parts = host.split(".").reverse();

        const protocol = window.location.protocol;
        log.debug(`handleAccountDomain() - protocol: ${JSON.stringify(protocol)}`);

        let newHost = protocol + "//";

        switch (detectEnv(parts)) {
            case "DEV": {
                newHost += devEnvStrategy(parts, accountDomain);
                break;
            }
            case "STAGING": {
                newHost += stagingEnvStrategy(parts, accountDomain);
                break;
            }
            case "NEW_STAGING": {
                newHost += newStagingEnvStrategy(parts, accountDomain);
                break;
            }
            case "PROD": {
                newHost += prodEnvStrategy(parts, accountDomain);
                break;
            }
            case "NEW_PROD": {
                newHost += newProdEnvStrategy(parts, accountDomain);
                break;
            }
        }

        return newHost;

    } catch (error) {
        log.error(`handleAccountDomain() - error: ${JSON.stringify(error)}`);
        return window.location.protocol + "//" + window.location.host;
    }
}

export const ensureAccountDomainInUse = (accountDomain: string | undefined): void => {
    try {
        log.debug(`ensureAccountDomainInUse() - accountDomain: ${JSON.stringify(accountDomain)}`);
        const host = window.location.host;
        const protocol = window.location.protocol;
        const newHost = handleAccountDomain(accountDomain);

        if (protocol + "//" + host !== newHost) {
            log.debug(`ensureAccountDomainInUse() - ${host} !== ${newHost} navigating to subdomain: ${newHost}`);
            window.location.assign(newHost);
        }

    } catch (error) {
        log.error(`ensureAccountDomainInUse() - error: ${JSON.stringify(error)}`);
    }
}

export const getTestLinkWithAccountDomain = (accountDomain: string, testId: string): string => {
    try {
        log.debug(`getTestLinkWithAccountDomain() - accountDomain: ${JSON.stringify(accountDomain)}`);

        const newHost = handleAccountDomain(accountDomain);
        log.debug(`getTestLinkWithAccountDomain() - returning: ${JSON.stringify(newHost)}`);
        return newHost + "/test?id=" + testId;

    } catch (error) {
        log.error(`getTestLinkWithAccountDomain() - error: ${JSON.stringify(error)}`);
        return window.location.protocol + "//" + window.location.host + "/test?id=" + testId;
    }
}

const accountDomainDevEnvStrategy = (parts: string[]): string => {
    const [, , , fourthLevelDomain] = parts;

    if (fourthLevelDomain) {
        log.debug(`accountDomainDevEnvStrategy() - fourthLevelDomain is ok: ${fourthLevelDomain}`);
        return fourthLevelDomain;
    }
    log.debug(`accountDomainDevEnvStrategy() - no accountDomain in use`);
    return "";
}

const accountDomainStagingEnvStrategy = (parts: string[]): string => {
    const [, , , fourthLevelDomain] = parts;

    if (fourthLevelDomain === "ttpro") {
        log.debug(`accountDomainStagingEnvStrategy() - no accountDomain in use`);
        return "";
    } else if (fourthLevelDomain) {
        log.debug(`accountDomainStagingEnvStrategy() - fourthLevelDomain is ok: ${fourthLevelDomain}`);
        return fourthLevelDomain;
    }
    log.debug(`accountDomainStagingEnvStrategy() - no accountDomain in use`);
    return "";
}

const accountDomainNewStagingEnvStrategy = (parts: string[]): string => {
    const [, , , fourthLevelDomain] = parts;

    if (fourthLevelDomain) {
        log.debug(`accountDomainNewStagingEnvStrategy() - fourthLevelDomain is ok: ${fourthLevelDomain}`);
        return fourthLevelDomain;
    }
    log.debug(`accountDomainNewStagingEnvStrategy() - no accountDomain in use`);
    return "";
}

const accountDomainProdEnvStrategy = (parts: string[]): string => {
    const [, , thirdLevelDomain] = parts;

    if (thirdLevelDomain === "app") {
        log.debug(`accountDomainProdEnvStrategy() - no accountDomain in use`);
        return "";
    } else if (thirdLevelDomain) {
        log.debug(`accountDomainProdEnvStrategy() - thirdLevelDomain is ok: ${thirdLevelDomain}`);
        return thirdLevelDomain;
    }
    log.debug(`accountDomainProdEnvStrategy() - no accountDomain in use`);
    return "";
}

const accountDomainNewProdEnvStrategy = (parts: string[]): string => {
    const [, , thirdLevelDomain] = parts;

    if (thirdLevelDomain === "app") {
        log.debug(`accountDomainNewProdEnvStrategy() - no accountDomain in use`);
        return "";
    } else if (thirdLevelDomain) {
        log.debug(`accountDomainNewProdEnvStrategy() - thirdLevelDomain is ok: ${thirdLevelDomain}`);
        return thirdLevelDomain;
    }
    log.debug(`accountDomainNewProdEnvStrategy() - no accountDomain in use`);
    return "";
}

export const getAccountDomainFromTestLink = (): string => {
    try {
        log.debug(`getAccountDomainFromTestLink()`);
        const host = window.location.host;
        log.debug(`getAccountDomainFromTestLink() - host: ${JSON.stringify(host)}`);
        const parts = host.split(".").reverse();

        let accountDomain = "";
        switch (detectEnv(parts)) {
            case "DEV": {
                accountDomain = accountDomainDevEnvStrategy(parts);
                break;
            }
            case "STAGING": {
                accountDomain = accountDomainStagingEnvStrategy(parts);
                break;
            }
            case "NEW_STAGING": {
                accountDomain = accountDomainNewStagingEnvStrategy(parts);
                break;
            }
            case "PROD": {
                accountDomain = accountDomainProdEnvStrategy(parts);
                break;
            }
            case "NEW_PROD": {
                accountDomain = accountDomainNewProdEnvStrategy(parts);
                break;
            }
        }

        return accountDomain;

    } catch (error) {
        log.error(`getAccountDomainFromTestLink() - error: ${JSON.stringify(error)}`);
        return "";
    }
}