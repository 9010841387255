import { useEffect, useState } from "react";
import { Theme } from "@mui/material/styles";
import { makeStyles } from 'tss-react/mui';
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import KeyboardIcon from "@mui/icons-material/Keyboard";
import DateRangeIcon from "@mui/icons-material/DateRange";
import Switch from "@mui/material/Switch";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import CheckIcon from "@mui/icons-material/Check";
import Divider from "@mui/material/Divider";
import SettingsIcon from "@mui/icons-material/Settings";
import DescriptionIcon from "@mui/icons-material/Description";
import VisibilityIcon from "@mui/icons-material/Visibility";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import LinkIcon from "@mui/icons-material/Link";
import ViewHeadlineIcon from "@mui/icons-material/ViewHeadline";
import Hidden from "@mui/material/Hidden";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Tooltip from "@mui/material/Tooltip";
import useMediaQuery from "@mui/material/useMediaQuery";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from '@mui/icons-material/Close';
import TestSettings, { CUSTOM_TEXT_NAME, TestSettingsFormValues } from "../TestSettings/TestSettings";
import { PostNewTestParams, useUpdateTest, useGetTestData, useGetTestStatistics } from "../../hooks/useApi";
import { FIRST_TEXT } from "../TestsHeader/TestsHeader";
import { getLogger } from "../../utils/logger";
import CandidateInvitationDialog from "../CandidateInvitationDialog/CandidateInvitationDialog";
import CopyTest from "../CopyTest/CopyTest";
import Loading from "../Loading/Loading";

const log = getLogger();

const useStyles = makeStyles()((theme: Theme) => ({
    root: {
        padding: theme.spacing(1)
    },
    candidatesDivider: {
        backgroundColor: "#3F51B5",
        height: "5px"
    },
    passDivider: {
        backgroundColor: "#00CF8A",
        height: "5px"
    },
    notPassedDivider: {
        backgroundColor: "#E0789A",
        height: "5px"
    },
    sevenDaysDivider: {
        backgroundColor: "#8090E8",
        height: "5px"
    },
    title: {
        fontSize: "20px",
        color: theme.palette.primary.dark,
        fontFamily: "'Lexend', sans-serif;",
        marginTop: "4px",
        marginLeft: "10px",
        marginRight: "12px"
    },
    titleSwitch: {
        marginLeft: "auto"
    },
    disableColor: {
        color: theme.palette.text.secondary
    },
    summaryContainer: {
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "20px"
    },
    summaryBox: {
        width: "25%",
        padding: "0 8px 0"
    },
    summaryLabel: {
        fontSize: "16px",
        textAlign: "center",
        display: "block",
        color: theme.palette.primary.dark,
        marginTop: "10px"
    },
    summaryVal: {
        fontSize: "34px",
        marginTop: "10px",
        color: theme.palette.primary.dark
    },
    textItemContainer: {
        position: "relative"
    },
    textIcon: {
        color: theme.palette.primary.dark,
        position: "absolute",
        fontSize: "27px",
        left: "0px"
    },
    textIconDisabled: {
        color: theme.palette.text.secondary,
        position: "absolute",
        fontSize: "27px",
        left: "0px"
    },
    textItem: {
        color: theme.palette.primary.dark,
        fontSize: "16px",
        marginLeft: "10px",
        textIndent: "25px"
    },
    button: {
        width: "121px",
        height: "36px",
        marginLeft: "10px"
    },
    iconButton: {
        padding: "8px"
    }
}));

export enum TestCardAction {
    edit = "EDIT",
    results = "RESULTS",
    preview = "PREVIEW",
    copy = "COPY",
    delete = "DELETE",
    updateActive = "UPDATE_ACTIVE",
}

export interface TestCardProps {
    testId: string;
    testUrl: string;
    name: string;
    active: boolean;
    attempts: number;
    duration: number;
    limits: {
        inUse: boolean;
        netWpm: number;
        accuracy: number;
    };
    text: string;
    customText: string;
    useCustomText: boolean;
    statistics: {
        candidates: number;
        passed: number;
        notPassed: number;
        sevenDays: number;
    };
    onAction: (testId: string, action: TestCardAction) => void;
    onActiveChange: (testId: string, checked: boolean) => void;
    cardIndex: number;
    createdAt: Date;
    onTestAdded: () => void;
    setError: (status: string) => void;
}

export const areExistingResultsAffected = (statistics: TestCardProps["statistics"], values: TestSettingsFormValues, original: TestSettingsFormValues): boolean => {
    if (statistics.candidates !== 0 && (original.netWpm != values.netWpm || original.duration != values.duration ||
        original.accuracy != values.accuracy || original.selectedText != values.selectedText)) {
        return true;
    } else {
        return false;
    }
};

export const convertFormDataToApi = (formData: TestSettingsFormValues, active: boolean): PostNewTestParams => {

    const textId = formData.textSelectionOptions[formData.selectedText].textId;

    const newResultRecipients = formData.resultRecipients.map((recipient) => {
        const index = formData.selectedResultRecipients.indexOf(recipient.email);
        if (index !== -1) {
            return { email: recipient.email, selected: true };
        } else {
            return { email: recipient.email, selected: false };
        }
    });

    const apiData: PostNewTestParams = {
        name: formData.name,
        active,
        duration: "" + formData.duration,
        attempts: "" + formData.attempts,
        sendResultsByEmail: formData.sendResultsByEmail,
        resultRecipients: newResultRecipients,
        limits: {
            inUse: formData.limitsInUse,
            shownToCandidate: formData.shownToCandidate,
            netWpm: "" + formData.netWpm,
            accuracy: "" + formData.accuracy
        },
        note: {
            enabled: formData.noteEnabled,
            text: formData.note
        },
        infoFieldOne: {
            enabled: formData.infoFieldOneEnabled,
            title: formData.infoFieldOneTitle
        },
        infoFieldTwo: {
            enabled: formData.infoFieldTwoEnabled,
            title: formData.infoFieldTwoTitle
        },
        textSelection: {
            options: formData.textSelectionOptions,
            selected: formData.selectedText
        },
        customText: formData.customText,
        useCustomText: formData.useCustomText,
        migratedResultEmails: formData.migratedResultEmails,
        textId,
        exitPassUrl: {
            enabled: formData.exitPassUrlState,
            title: formData.exitPassUrl
        },
        exitFailUrl: {
            enabled: formData.exitFailUrlState,
            title: formData.exitFailUrl
        },
        newAttemptsAllowed: formData.newAttemptsAllowed,
        accessCode: {
            enabled: formData.accessCodeEnabled,
            title: formData.accessCode
        },
    };
    log.debug(`convertFormDataToApi() - returning: ${JSON.stringify(apiData, null, 2)}`);
    return apiData;
};

export const checkForInvalidCharacters = (customText: string): string => {
    const allowedList =
        "abcdefghijklmnopqrstuvwxyzåäöABCDEFGHIJKLMNOPQRSTUVWXYZÅÄÖ0123456789,;.:-_'*!\"#%&/(){[]}<>^\\=+?@£$€“”‘’—– \n";
    let errors = "";
    let found = false;
    for (let i = 0; i < customText.length; i++) {
        const candidate = customText.charAt(i);
        for (let j = 0; j < allowedList.length; j++) {
            const valid = allowedList.charAt(j);
            if (candidate === valid) {
                found = true;
                break;
            }
        }
        if (!found) {
            log.debug(`validate() - ${candidate} not ok`);
            errors = errors + candidate;
        }
        found = false;
    }
    return errors;
};

export const updateToFormData = (apiData: PostNewTestParams, setFormData: (data: TestSettingsFormValues) => void, isCopy = false): void => {
    const recipients: string[] = [];
    apiData.resultRecipients.map((recipient) => {
        if (recipient.selected === true) {
            recipients.push(recipient.email);
        }
    });
    let selectedTestText: string = apiData.textSelection.options[apiData.textSelection.selected].textName;
    let selectedTextContent = apiData.textSelection.options[apiData.textSelection.selected].content;

    if (apiData.useCustomText) {
        selectedTestText = CUSTOM_TEXT_NAME;
        selectedTextContent = apiData.customText;
    }

    const formData: TestSettingsFormValues = {
        name: isCopy ? "" : apiData.name,
        duration: +apiData.duration,
        attempts: +apiData.attempts,
        sendResultsByEmail: apiData.sendResultsByEmail,
        resultRecipients: apiData.resultRecipients,
        selectedResultRecipients: recipients,
        limitsInUse: apiData.limits.inUse,
        shownToCandidate: apiData.limits.shownToCandidate,
        netWpm: +apiData.limits.netWpm,
        accuracy: +apiData.limits.accuracy,
        noteEnabled: apiData.note.enabled,
        note: apiData.note.text,
        infoFieldOneEnabled: apiData.infoFieldOne.enabled,
        infoFieldOneTitle: apiData.infoFieldOne.title,
        infoFieldTwoEnabled: apiData.infoFieldTwo.enabled,
        infoFieldTwoTitle: apiData.infoFieldTwo.title,
        testText: selectedTestText,
        textSelectionOptions: apiData.textSelection.options,
        selectedText: apiData.textSelection.selected,
        customText: apiData.customText,
        useCustomText: apiData.useCustomText,
        selectedTextContent: selectedTextContent,
        migratedResultEmails: apiData.migratedResultEmails,
        exitPassUrlState: apiData.exitPassUrl.enabled,
        exitFailUrlState: apiData.exitFailUrl.enabled,
        exitPassUrl: apiData.exitPassUrl.title,
        exitFailUrl: apiData.exitFailUrl.title,
        newAttemptsAllowed: apiData.newAttemptsAllowed,
        accessCodeEnabled: apiData.accessCode.enabled,
        accessCode: apiData.accessCode.title
    };
    log.debug(`updateToFormData() - setting: ${JSON.stringify(formData, null, 2)}`);
    setFormData(formData);
};

interface SummaryItem {
    key: string;
    stl: any;
    val: any;
    descr: string;
    id: string;
}
export const ATTEMPTS_MAX_VALUE = 300;
const TestCard = (
    {
        testId,
        testUrl,
        name,
        active,
        attempts,
        duration,
        limits,
        text,
        customText,
        useCustomText,
        statistics,
        onAction,
        onActiveChange,
        cardIndex,
        createdAt,
        onTestAdded,
        setError
    }: TestCardProps) => {
    const { classes } = useStyles();
    const [openDelete, setOpenDelete] = useState(false);
    const [openSettings, setOpenSettings] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>()
    const [candidateInvitationDialog, setCandidateInvitationDialog] = useState(false);
    const [openInvalidCharacters, setOpenInvalidCharacters] = useState(false);
    const [invalidCharacters, setInvalidCharacters] = useState("");
    const [openResultsAlreadyExists, setOpenResultsAlreadyExists] = useState(false);
    const [openNotEnabledDialog, setOpenNotEnabledDialog] = useState(false);
    const [statisticsUpdated, setStatisticsUpdated] = useState(statistics);
    const [fetchingStatistics, setFetchingStatistics] = useState(false);

    const matches = useMediaQuery('(min-width:600px)');

    useEffect(() => {
        if (testId) {
            setTimeout(() => {
                setFetchingStatistics(true);
                useGetTestStatistics(testId, (newStatisticsUpdated) => {
                    setStatisticsUpdated(newStatisticsUpdated);
                })
                    .finally(() => {
                        setFetchingStatistics(false);
                    });
            }, 300);
        }
    }, [testId]);

    const handleClickOpenDelete = () => {
        setOpenDelete(true);
    };
    const handleCloseDelete = (): void => {
        setOpenDelete(false);
    };

    const handleError = (status: string) => {
        switch (status) {
            case "ERROR_ATTEMPTS_MAX_LIMIT":
                setErrorMessage(`Maximum attempts is ${ATTEMPTS_MAX_VALUE}`)
                break;
            case "ERROR_TEST_NAME_ALREADY_EXIST":
                setErrorMessage(`Test name must be unique. Please choose a different name.`)
                break;
            default:
                return setErrorMessage("Unknown error");

        }
    }

    const handleClose = (): void => {
        setOpenSettings(false);
        setErrorMessage(undefined)
    };

    const handleCloseLinkDialog = (): void => {
        setCandidateInvitationDialog(false);
    };

    const handleOpenCandidateInvitationDialog = (): void => {
        if (active) {
            setCandidateInvitationDialog(true);
        } else {
            setOpenNotEnabledDialog(true);
        }
    };

    const handleCloseNotEnabledDialog = () => {
        setOpenNotEnabledDialog(false);
    };

    const handleCloseInvalidCharacters = () => {
        setOpenInvalidCharacters(false);
    };

    const handleCloseResultsAlreadyExist = () => {
        setOpenResultsAlreadyExists(false);
    };

    const initialFormData: PostNewTestParams = {
        name: "",
        active: false,
        duration: "5",
        attempts: "5",
        limits: {
            inUse: false,
            shownToCandidate: false,
            netWpm: "0",
            accuracy: "0"
        },
        textSelection: {
            options: [],
            selected: FIRST_TEXT
        },
        customText: "",
        useCustomText: false,
        note: {
            text: "",
            enabled: false
        },
        infoFieldOne: {
            enabled: false,
            title: ""
        },
        infoFieldTwo: {
            enabled: false,
            title: ""
        },
        sendResultsByEmail: false,
        resultRecipients: [],
        textId: "",
        migratedResultEmails: "",
        exitPassUrl: {
            enabled: false,
            title: ""
        },
        exitFailUrl: {
            enabled: false,
            title: ""
        },
        newAttemptsAllowed: -1,
        accessCode: {
            enabled: false,
            title: ""
        },
    };

    const initialDefaultData: TestSettingsFormValues = {
        name: "",
        duration: 5,
        attempts: 5,
        limitsInUse: false,
        shownToCandidate: false,
        netWpm: 0,
        accuracy: 0,
        note: "",
        noteEnabled: false,
        infoFieldOneTitle: "",
        infoFieldOneEnabled: false,
        infoFieldTwoTitle: "",
        infoFieldTwoEnabled: false,
        sendResultsByEmail: false,
        resultRecipients: [],
        selectedResultRecipients: [],
        testText: "",
        textSelectionOptions: [],
        selectedText: FIRST_TEXT,
        customText: "",
        useCustomText: false,
        selectedTextContent: "",
        migratedResultEmails: "",
        exitPassUrlState: false,
        exitFailUrlState: false,
        exitPassUrl: "",
        exitFailUrl: "",
        newAttemptsAllowed: -1,
        accessCodeEnabled: false,
        accessCode: ""
    };
    const [initialData, setInitialData] = useState<TestSettingsFormValues>(initialDefaultData);

    const [tempData, setTempData] = useState<PostNewTestParams>(initialFormData);

    const handleCloseAfterEdit = (values: TestSettingsFormValues) => {
        try {
            const existingResultsAffected = areExistingResultsAffected(statisticsUpdated, values, initialData);
            let invalidCharacters = "";
            const formData = convertFormDataToApi(values, active);
            if (existingResultsAffected) {
                setTempData(formData);
                setOpenResultsAlreadyExists(true);
            } else if (values.useCustomText) {
                invalidCharacters = checkForInvalidCharacters(values.customText);
                if (invalidCharacters !== "") {
                    setInvalidCharacters(invalidCharacters);
                    setTempData(formData);
                    setOpenInvalidCharacters(true);
                }
            }

            if (!existingResultsAffected && invalidCharacters === "") {
                useUpdateTest(
                    {
                        testId: testId,
                        testUpdate: formData
                    },
                    (response) => {
                        log.debug(`handleCloseAfterEdit() - response: ${JSON.stringify(response, null, 2)}`);
                        handleClose();
                        onAction(testId, TestCardAction.edit);
                    },
                    (status) => {
                        handleError(status);
                    }
                );
            }
        } catch (error) {
            log.error(`handleCloseAfterEdit() - error: ${JSON.stringify(error, null, 2)}`);
        }
    };

    const getFormattedActiveDate = (dateString: Date): string => {
        try {
            const date = new Date(dateString);
            const enUSFormatter = new Intl.DateTimeFormat("en-US");
            return enUSFormatter.format(date);
        } catch (error) {
            log.error(error);
        }
        return dateString.toString();
    };

    const actionItems = () => {
        return (
            <CardActions>
                <Box display="flex" width="100%">
                    <Button
                        color="secondary"
                        variant="contained"
                        aria-label="edit"
                        id={`edit_btn_test_${cardIndex}`}
                        onClick={() => {
                            log.debug(`EDIT clicked`);
                            useGetTestData({ testId }, (data) => {
                                updateToFormData(data, setInitialData);
                                setOpenSettings(true);
                            });
                        }}
                        startIcon={<SettingsIcon />}
                        className={classes.button}
                    >
                        <Hidden smDown>EDIT</Hidden>
                    </Button>
                    <Button
                        color="secondary"
                        variant="contained"
                        aria-label="results"
                        id={`results_btn_test_${cardIndex}`}
                        onClick={() => onAction(testId, TestCardAction.results)}
                        startIcon={<DescriptionIcon />}
                        className={classes.button}
                    >
                        <Hidden smDown>Results</Hidden>
                    </Button>
                    <Button
                        color="secondary"
                        variant="contained"
                        aria-label="edit"
                        id={`copy_link_btn_test_${cardIndex}`}
                        onClick={handleOpenCandidateInvitationDialog}
                        startIcon={<LinkIcon />}
                        className={classes.button}
                    >
                        <Hidden smDown>INVITE</Hidden>
                    </Button>
                    <Box marginLeft="auto" marginTop="-5px">
                        <Tooltip title="Preview test">
                            <IconButton
                                color="secondary"
                                aria-label="preview"
                                component="span"
                                id={`preview_btn_test_${cardIndex}`}
                                onClick={() => onAction(testId, TestCardAction.preview)}
                                className={classes.iconButton}
                            >
                                <VisibilityIcon />
                            </IconButton>
                        </Tooltip>
                        <CopyTest
                            cardIndex={cardIndex}
                            testId={testId}
                            onTestAdded={onTestAdded}
                            setError={setError}
                        />
                        <Tooltip title="Delete test">
                            <IconButton
                                color="secondary"
                                aria-label="delete test"
                                component="span"
                                id={`del_btn_test_${cardIndex}`}
                                onClick={handleClickOpenDelete}
                                className={classes.iconButton}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>

                    </Box>
                </Box>
                <Dialog
                    open={openDelete}
                    onClose={handleCloseDelete}
                    aria-labelledby="delete-dialog-title"
                    aria-describedby="delete-dialog-description"
                >
                    <DialogTitle id="delete-dialog-title" style={{ textAlign: "center" }}>{"Delete Test"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="delete-dialog-description">
                            <b>Important:</b> Deleting the test also deletes all results for this test from the
                            database. If you still need to view the candidates and their results, do not delete the test
                            but set it inactive instead or go to report and download results as a CSV file first.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions style={{ margin: "0 auto 20px auto" }}>
                        <Button onClick={() => {
                            onAction(testId, TestCardAction.delete);
                            handleCloseDelete();
                        }} id="delete_test" color="secondary" variant="contained">
                            Yes, delete test
                        </Button>
                        <Button onClick={handleCloseDelete} color="secondary" autoFocus>
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={openResultsAlreadyExists}
                    onClose={handleCloseResultsAlreadyExist}
                    aria-labelledby="result-exists-dialog-title"
                    aria-describedby="result-exists-dialog-description"
                >
                    <DialogTitle id="result_exists-dialog-title"
                        style={{ textAlign: "center" }}>{"Change test settings"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="result-exists-dialog-description">
                            <p>There are already results for this test in the database. Do you really want to change the
                                settings? </p>
                            <p><b>Note:</b> Changing the speed or accuracy requirement will update all Passed/Not passed
                                result status' to match the new pass limits.</p>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions style={{ margin: "0 auto 20px auto" }}>
                        <Button onClick={() => {
                            useUpdateTest(
                                {
                                    testId: testId,
                                    testUpdate: tempData
                                },
                                (response) => {
                                    log.debug(`onClick() - response: ${JSON.stringify(response, null, 2)}`);
                                    handleCloseResultsAlreadyExist();
                                    handleClose();
                                    onAction(testId, TestCardAction.edit);
                                },
                                (status) => {
                                    handleError(status);
                                }
                            );
                        }}
                            id="result-exists_test" color="secondary" variant="contained">
                            Yes
                        </Button>
                        <Button onClick={handleCloseResultsAlreadyExist} color="secondary" autoFocus>
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={openInvalidCharacters}
                    onClose={handleCloseInvalidCharacters}
                    aria-labelledby="invalid-characters-dialog-title"
                    aria-describedby="invalid-characters-dialog-description"
                >
                    <DialogTitle id="invalid-characters-dialog-title" style={{ textAlign: "center" }}>Non-supported
                        Characters Found</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="invalid-characters-dialog-description">
                            <p style={{ textAlign: "center" }}>Following characters are not supported in typing test
                                texts:</p>
                            <p style={{ textAlign: "center" }}>{invalidCharacters}</p>
                            <p style={{ textAlign: "center" }}>Remove the not supported characters before
                                continuing.</p>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions style={{ margin: "0 auto 20px auto" }}>
                        <Button onClick={() => {
                            useUpdateTest(
                                {
                                    testId: testId,
                                    testUpdate: tempData
                                },
                                (response) => {
                                    log.debug(`onSubmit() - response: ${JSON.stringify(response, null, 2)}`);
                                    handleCloseInvalidCharacters();
                                    handleClose();
                                    onAction(testId, TestCardAction.edit);
                                },
                                (status) => {
                                    handleError(status);
                                }
                            );
                        }} id="close_invalid_characters" color="secondary" variant="contained">
                            No, continue anyway
                        </Button>
                        <Button onClick={handleCloseInvalidCharacters} color="secondary" autoFocus
                            id="cancel_invalid_characters">
                            Wait, I'll remove those
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={openNotEnabledDialog}
                    onClose={handleCloseNotEnabledDialog}
                >
                    <DialogTitle color="GrayText">
                        The test is disabled
                        <IconButton
                            aria-label="close"
                            onClick={handleCloseNotEnabledDialog}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            This typing test is currently disabled, do you want to activate it?
                        </DialogContentText>
                        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={active}
                                        color="secondary"
                                        type="checkbox"
                                        onChange={(e, checked) => {
                                            onActiveChange(testId, checked);
                                        }}
                                    />
                                }
                                label={
                                    <Typography>
                                        Enable test
                                    </Typography>
                                }
                                labelPlacement="start"
                            />
                        </Box>
                    </DialogContent>
                </Dialog>
                <TestSettings
                    errorMessage={errorMessage}
                    open={openSettings}
                    handleClose={handleClose}
                    initialValues={initialData}
                    handleCloseAfterEdit={handleCloseAfterEdit}
                />
                {candidateInvitationDialog && (
                    <CandidateInvitationDialog
                        open={candidateInvitationDialog}
                        handleOwnClose={handleCloseLinkDialog}
                        testUrl={testUrl}
                        onAction={onAction}
                        testId={testId}
                    />
                )}
            </CardActions>
        );
    };

    const passLimits = () => {
        if (!limits.inUse) {
            return (
                <>Disabled</>
            )
        }
        return (
            <>{limits.netWpm} net WPM, {limits.accuracy}% accuracy</>
        )
    }

    const testParameters = () => {
        return (
            <Box sx={{ marginTop: "20px" }}>
                <Box className={classes.textItemContainer}>
                    <DateRangeIcon
                        className={active ? classes.textIcon : classes.textIconDisabled} />
                    <p className={classes.textItem}>
                        <span
                            className={active ? "" : classes.disableColor}>Created {getFormattedActiveDate(createdAt)} </span>
                    </p>
                </Box>
                <Box className={classes.textItemContainer}>
                    <WatchLaterIcon
                        className={active ? classes.textIcon : classes.textIconDisabled} />
                    <p className={classes.textItem}>
                        <span id={`duration_attempts_${cardIndex}`}
                            className={active ? "" : classes.disableColor}>{duration} min. (max {attempts} attempts) </span>
                    </p>
                </Box>
                <Box className={classes.textItemContainer}>
                    <CheckIcon
                        className={active ? classes.textIcon : classes.textIconDisabled} />
                    <p className={classes.textItem}>
                        <span id={`pass_limits_${cardIndex}`} className={active ? "" : classes.disableColor}>Pass limits: {passLimits()}</span>
                    </p>
                </Box>
                <Box className={classes.textItemContainer}>
                    <ViewHeadlineIcon
                        className={active ? classes.textIcon : classes.textIconDisabled} />
                    <p className={classes.textItem} style={{
                        display: "-webkit-box",
                        height: "48px",
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden"
                    }}>
                        <span id={`test_text_${cardIndex}`}
                            className={active ? "" : classes.disableColor}>Text: "{useCustomText ? customText : text}" </span>
                    </p>
                </Box>
            </Box>
        );
    };

    const testStatus = () => {

        const items: SummaryItem[] = [
            {
                key: "1",
                stl: classes.candidatesDivider,
                val: statisticsUpdated.candidates,
                descr: "Candidates",
                id: "candidates"
            },
            { key: "2", stl: classes.passDivider, val: statisticsUpdated.passed, descr: "Passed", id: "passed" },
            {
                key: "3",
                stl: classes.notPassedDivider,
                val: statisticsUpdated.notPassed,
                descr: "Not Passed",
                id: "not_passed"
            },
            {
                key: "4",
                stl: classes.sevenDaysDivider,
                val: statisticsUpdated.sevenDays,
                descr: "Last 7 days",
                id: "last_7_days"
            }
        ];

        const getSummaryItems = () => {
            return items.map(({ key, stl, val, descr, id }, index) => {
                if (key === "4") {
                    return (
                        <div key={index} className={classes.summaryBox}>
                            <Divider className={stl} style={active ? { opacity: 1 } : { opacity: 0.5 }} />
                            <Typography align="center" variant="caption" className={classes.summaryLabel}>
                                <span className={active ? "" : classes.disableColor}>{descr}</span>
                            </Typography>
                            <Typography align="center" variant="h4" className={classes.summaryVal}>
                                <span className={active ? "" : classes.disableColor} id={id}>{val}</span>
                            </Typography>
                        </div>
                    );
                }

                return (
                    <div key={index} className={classes.summaryBox}>
                        <Divider className={stl} />
                        <Typography align="center" variant="caption"
                            className={classes.summaryLabel}>{descr}</Typography>
                        <Typography align="center" variant="h4" className={classes.summaryVal}
                            id={id}>{val} </Typography>
                    </div>
                );
            });
        };

        return (

            <Box className={classes.summaryContainer}>
                {getSummaryItems()}
            </Box>
        );
    };

    const testTitle = () => {
        return (
            <Box display="flex" style={{ marginBottom: "15px" }}>
                <KeyboardIcon className={active ? "" : classes.disableColor} color="secondary"
                    style={{ fontSize: "41px" }} />

                <Typography gutterBottom variant="h6" component="h2" className={classes.title} noWrap
                    id={`test_name_${cardIndex}`}>
                    <span className={active ? "" : classes.disableColor}>
                        {name}
                    </span>
                </Typography>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center"
                    }}
                >
                    <Loading
                        show={fetchingStatistics}
                        size={20}
                    />
                </Box>
                <Tooltip title={"Enable/disable candidates to take this test"} >
                    <Switch
                        className={classes.titleSwitch}
                        checked={active}
                        color="secondary"
                        type="checkbox"
                        aria-label="state"
                        id={`active_${cardIndex}`}
                        onChange={(e, checked) => {
                            onActiveChange(testId, checked);
                        }}
                    />
                </Tooltip>
            </Box>
        );
    };

    return (
        <Card className={classes.root} sx={{ width: matches ? 'auto' : '100%' }}>
            <CardContent>
                {testTitle()}
                {testStatus()}
                <Divider />
                {testParameters()}
            </CardContent>
            {actionItems()}
        </Card>
    );
};

export default TestCard;
