export const getBaseUrl = (url: string, migrated: boolean): string => {
    const productionDomain = process.env.REACT_APP_PRODUCTION_DOMAIN ?? "typingtest.com";
    const appSubdomain = "app";

    const urlParts = url.split("/");
    const protocol = `${urlParts[0]}//`;
    const origin = `${urlParts[2]}`;
    const originParts = origin.split(".");
    const currentDomain = `${originParts[originParts.length - 2]}.${originParts[originParts.length - 1]}`;
    const lastSubdomain = originParts[originParts.length - 3];
    if (currentDomain.includes(productionDomain) && lastSubdomain !== appSubdomain) {
        originParts.splice(originParts.length - 2, 0, appSubdomain);
    }
    let baseUrl = "";
    if (!migrated) {
        baseUrl = `${protocol}${originParts.join(".")}`;
    } else {
        const urlWithoutAccountDomain = originParts.slice(1, originParts.length).join(".");
        baseUrl = `${protocol}${urlWithoutAccountDomain}`;
    }
    return baseUrl;
};

export default getBaseUrl;
