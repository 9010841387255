import { makeStyles } from 'tss-react/mui';

export const useTableCellStyles = makeStyles()((theme) => ({
    passText: {
        color: theme.palette.primary.dark,
    },
    naText: {
        color: theme.palette.text.secondary,
        marginLeft: "30px",
        fontSize: "24px",
    },
    errText: {
        color: theme.palette.error.main,
    },
    smallCell: {
        fontSize: "16px",
    },
}));
