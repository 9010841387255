import { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import WarningIcon from "@mui/icons-material/Warning";
import ErrorIcon from "@mui/icons-material/Error";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from "@mui/material/DialogContentText";
import IconButton from "@mui/material/IconButton";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { ActivePlanProps, useGetAccountSubscriptionInformation } from '../../hooks/useApi';
import { CandidatesExceeded } from '../../context/auth';
import { Typography } from '@mui/material';

interface CandidatesExceededDialogProps {
    open: boolean;
    onClose: VoidFunction;
    candidatesExceeded: CandidatesExceeded;
}

const CandidatesExceededDialog = ({
    open,
    onClose,
    candidatesExceeded,
}: CandidatesExceededDialogProps) => {
    const [data, setData] = useState<{ planInformation?: ActivePlanProps, fetching: boolean }>({ fetching: true });

    useEffect(() => {
        useGetAccountSubscriptionInformation(
            (data) => {
                setData({ fetching: false, planInformation: data });
            },
            () => {
                setData({ fetching: false });
            }
        )
    }, []);

    if (data.fetching) {
        return null;
    }

    let currentCandidatesCount = '';
    let allowedCandidatesCount = '';

    if (data.planInformation?.candidates?.includes('/')) {
        [currentCandidatesCount, allowedCandidatesCount] = data.planInformation.candidates.split('/');
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
        >
            <DialogTitle sx={{ display: "flex", alignItems: "center" }}>
                {candidatesExceeded.error ? (
                    <ErrorIcon color='error' />
                ) : (
                    <WarningIcon color='warning' />
                )}
                <Typography component="span" variant="inherit" sx={{ marginLeft: "18px" }}>
                    Payment Required
                </Typography>
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{ marginTop: '16px' }}>
                {candidatesExceeded.error ? (
                    <DialogContentText>
                        Your plan exceeded the monthly candidate limit.
                        <br />
                        To view your test results, you need to upgrade your plan.
                    </DialogContentText>
                ) : (
                    <DialogContentText>
                        Uh-oh! You've reached the limit of your plan for monthly candidates.
                        <br />
                        An upgrade is recommended!
                    </DialogContentText>
                )}
                {currentCandidatesCount && allowedCandidatesCount && (
                    <DialogContentText sx={{ marginTop: '24px' }}>
                        {`The current count is ${currentCandidatesCount} out of ${allowedCandidatesCount} candidates.`}
                    </DialogContentText>
                )}
            </DialogContent>
            <DialogActions sx={{ padding: '16px' }}>
                <Button
                    color="secondary"
                    variant="outlined"
                    sx={{ marginRight: '16px' }}
                    onClick={onClose}
                >
                    Close
                </Button>
                <Link to="/plans" onClick={onClose}>
                    <Button
                        color="secondary"
                        variant="contained"
                    >
                        Change Plan
                    </Button>
                </Link>
            </DialogActions>
        </Dialog>
    );
};

export default CandidatesExceededDialog;
