import { useEffect } from "react";
import * as wizardCss from "../../theme/wizardStyle";
import { theme } from "../../theme/Theme";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { makeStyles } from 'tss-react/mui';
import defaultLogo from "../../ttpro_logo.png";
import { PracticeData } from "../TestStepThree/TestStepThree";
import { GetTestDataResponse, PersonalData } from "../TestWizard/TestWizard";
import { CandidatePersonalData, CandidateTestData, useGetCandidateTestData } from "../../hooks/useApi";
import EllipsisWithTooltip from "../EllipsisWithTooltip/EllipsisWithTooltip";
import { useFeature } from "flagged";
import clsx from "clsx";

const useStyles = makeStyles()((theme) => ({
    root: {
        width: "100%",
    },
    button: {
        marginRight: theme.spacing(1),
    },
    cardTestRoot: {
        minWidth: 400,
        maxWidth: 400,
        minHeight: 400,
        maxHeight: 400,
        alignContent: "center",
    },
    bottomBox: {
        width: "36%",
        height: "130px",
        border: "none",
        opacity: 1,
        margin: "27px 30px 0 140px",
    },
    circleBig: {
        width: "300px",
        height: "150px",
        marginLeft: "auto",
        display: "flex",
        position: "relative"
    },
    logoCircleSmall: {
        width: "auto",
        height: "auto",
        maxWidth: "300px",
        maxHeight: "150px",
        marginLeft: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    },
    titleSpan: {
        display: "inline-block",
        marginBottom: "-4px",
        paddingLeft: "15px",
        verticalAlign: "top",
        height: "54px",
        lineHeight: "54px",
        maxWidth: "70%",
        "@media (min-width: 1401px) and (max-width: 1700px)": {
            maxWidth: "380px",
        },
        "@media (min-width: 1201px) and (max-width: 1400px)": {
            maxWidth: "330px",
        },
        "@media (max-width: 1200px)": {
            maxWidth: "280px",
        }
    },
    labelSpan: {
        width: "50%",
        "@media (min-width: 1200px)": {
            width: "40%",
        },
        "@media (min-width: 1700px)": {
            width: "30%",
        },
    },
    labelAndTitle: {
        whiteSpace: "nowrap",
    },
}));

export interface TestStepCompletedProps {
    handleBack: () => void;
    handleNextAttempt: () => void;
    typingTestData: PracticeData;
    personalData: PersonalData;
    testInfo: GetTestDataResponse;
    updateCandidateTestData: (candidateTestData: CandidateTestData) => void;
    candidateTestData: CandidateTestData;
    showLimits: boolean;
    useLimits: boolean;
    imageData: string;
    candidate?: CandidatePersonalData;
}

const TestStepCompleted = (props: TestStepCompletedProps) => {
    const { classes } = useStyles();
    const { classes: wizardClasses } = wizardCss.wizardStyles();
    const showElements = useFeature("d_ce_s");
    const hideFractional = useFeature('s_hide_fractional');


    useEffect(() => {
        let candidateId = '';
        if (props.candidate) {
            candidateId = props.candidate.candidateId;
        } else {
            candidateId = props.personalData.candidateId;
        }
        useGetCandidateTestData(props.testInfo.testId, candidateId, props.updateCandidateTestData);
    }, []);

    const getFormattedBestResultDate = (dateString: string): string => {
        try {
            const date = new Date(dateString);
            const enUSFormatter = new Intl.DateTimeFormat('en-US', {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
            });
            return enUSFormatter.format(date);
        } catch (error) {
            console.log(error);
        }
        return dateString;
    }

    const getBestResultStatus = (status: string): string => {
        if (props.useLimits && props.showLimits) {
            if (status === "PASS") {
                return "Passed"
            } else if (status === "FAIL") {
                return "Not passed"
            } else if (status === "NOT_FINISHED") {
                return "Incomplete"
            } else if (status === "INCOMPLETE") {
                return "Incomplete"
            } else {
                return "Incomplete"
            }
        } else {
            return "";
        }
    }

    const getStatus = () => {
        let status = "Completed";
        if (props.useLimits && props.showLimits) {
            status = getBestResultStatus(props.candidateTestData.bestResult.status);
        }

        return (
            <>{status}</>
        )
    }

    const getInfoFieldOneTitle = () => {
        return getInfoFieldTitle(props.testInfo.infoFieldOne);
    }

    const getInfoFieldTwoTitle = () => {
        return getInfoFieldTitle(props.testInfo.infoFieldTwo);
    }

    const getInfoFieldTitle = (infoField: { enabled: boolean; title: string; }) => {
        if (infoField.enabled) {
            return (
                <>{infoField.title}</>
            )
        } else {
            return (
                <></>
            )
        }
    }

    const netWpm = hideFractional ? Math.floor(props.candidateTestData.bestResult.netWpm) : props.candidateTestData.bestResult.netWpm;

    const email = props.candidate ? props.candidate.email : props.personalData.email;
    const firstName = props.candidate ? props.candidate.firstName : props.personalData.firstName;
    const lastName = props.candidate ? props.candidate.lastName : props.personalData.lastName;
    const infoFieldOneValue = props.candidate ? props.candidate.infoFieldOneValue : props.personalData.infoFieldOneValue;
    const infoFieldTwoValue = props.candidate ? props.candidate.infoFieldTwoValue : props.personalData.infoFieldTwoValue;

    return (
        <Container className={wizardClasses.mainContainer}>
            <Typography variant="h1" component="h1">Test Summary</Typography>
            <Box display="flex">
                <div className={wizardClasses.centerArea} style={{ width: "30%", height: "239px", position: "relative", marginTop: "20px" }}>
                    <Typography variant="subtitle2"
                        style={{ color: theme.palette.text.secondary, marginTop: "-30px", marginLeft: "40%", position: "absolute" }}>
                        BEST OF ALL ATTEMPTS</Typography>
                    <div className={classes.labelAndTitle}>
                        <div className={wizardClasses.labelSpan} style={{ width: "40%" }}>Net Speed</div>
                        <div className={classes.titleSpan}><span id="netWpm">{netWpm}</span> WPM</div>
                    </div>
                    <div className={classes.labelAndTitle}>
                        <div className={wizardClasses.labelSpan} style={{ width: "40%" }}>Accuracy</div>
                        <div className={classes.titleSpan}>{props.candidateTestData.bestResult.accuracy}%</div>
                    </div>
                    <div className={classes.labelAndTitle}>
                        <div className={wizardClasses.labelSpan} style={{ width: "40%", height: "70px" }}>
                            Status
                        </div>
                        <div className={classes.titleSpan}>
                            {getStatus()}
                            <Typography variant="subtitle2" style={{ color: theme.palette.text.secondary, margin: "-15px 0 8px" }}>
                                {getFormattedBestResultDate(props.candidateTestData.bestResult.createdAt as string)}
                            </Typography>
                        </div>
                    </div>
                    <div className={classes.labelAndTitle}>
                        <div
                            className={wizardClasses.labelSpan}
                            style={{ width: "40%", height: "73px" }}
                        >
                            Total Attempts
                        </div>
                        <div className={classes.titleSpan}>{props.testInfo.maxAttempts - props.candidateTestData.attemptsLeft} of {props.testInfo.maxAttempts}</div>
                    </div>

                    {props.candidateTestData.attemptsLeft === 0 ? null : (
                        <Button className={wizardClasses.button} style={{ marginTop: "-80px", marginLeft: "calc(40% + 10px)" }} color="secondary" onClick={props.handleNextAttempt} id="btn_new_attempt" >
                            New Attempt
                        </Button>
                    )}
                </div>

                <div className={wizardClasses.centerArea} style={{ width: "70%", height: "239px", position: "relative", marginTop: "20px" }} >
                    <Box display="flex" style={{ marginLeft: "40%", position: "absolute" }}>
                        <Typography variant="subtitle2" style={{ color: theme.palette.text.secondary, margin: "-30px 0 5px 80px" }}>PERSONAL INFORMATION</Typography>
                        {showElements ? (<Button className={wizardClasses.button} color="secondary" id="btn_edit" style={{ marginTop: "-37px" }} >
                            EDIT
                        </Button>) : null}
                    </Box>
                    <div>
                        <div className={clsx(wizardClasses.labelSpan, classes.labelSpan)}>Email</div>
                        <div className={classes.titleSpan}>
                            <EllipsisWithTooltip tooltip={email} label="email">
                                {email}
                            </EllipsisWithTooltip>
                        </div>
                    </div>
                    <div>
                        <div className={clsx(wizardClasses.labelSpan, classes.labelSpan)}>First Name</div>
                        <div className={classes.titleSpan}>
                            <EllipsisWithTooltip tooltip={firstName} label="first-name">
                                {firstName}
                            </EllipsisWithTooltip>
                        </div>
                    </div>
                    <div>
                        <div className={clsx(wizardClasses.labelSpan, classes.labelSpan)}>Last Name</div>
                        <div className={classes.titleSpan}>
                            <EllipsisWithTooltip tooltip={lastName} label="last-name">
                                {lastName}
                            </EllipsisWithTooltip>
                        </div>
                    </div>
                    <div>
                        <div className={clsx(wizardClasses.labelSpan, classes.labelSpan)}>{getInfoFieldOneTitle()}</div>
                        <div className={classes.titleSpan}>
                            <EllipsisWithTooltip tooltip={infoFieldOneValue} label="info1">
                                {infoFieldOneValue}
                            </EllipsisWithTooltip>
                        </div>
                    </div>
                    <div>
                        <div className={clsx(wizardClasses.labelSpan, classes.labelSpan)}>{getInfoFieldTwoTitle()}</div>
                        <div className={classes.titleSpan}>
                            <EllipsisWithTooltip tooltip={infoFieldTwoValue} label="info2">
                                {infoFieldTwoValue}
                            </EllipsisWithTooltip>
                        </div>
                    </div>
                </div>
            </Box>
            <div style={{ display: "flex", marginTop: "24px" }}>
                <div className={classes.bottomBox}>
                    <div className={classes.circleBig}>
                        <img className={classes.logoCircleSmall}
                            alt="logo"
                            id="logo"
                            src={props.imageData === "" ? defaultLogo : props.imageData}
                        />
                    </div>

                </div>
                <div>
                    <p style={{ fontSize: "23px", marginTop: "65px" }}>Thank you for participating!</p>
                </div>
            </div>
        </Container >
    );
};
export default TestStepCompleted;
