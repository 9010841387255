import { useEffect, useRef, useState, useMemo } from "react";
import { makeStyles } from 'tss-react/mui';
import TestResultHeader from "../../components/TestResultHeader/TestResultHeader";
import {
    CandidateBestResultData,
    GetBestTestResultsData,
    GetBestTestResultsResponse,
    GetCandidateTestResultsData,
    GetCsvTestResultsResponse,
    getStatus,
    GetTestResultsData,
    OrderBy,
    SortBy,
    useDeleteResult,
    useGetBestTestResults,
    useGetCandidateTestResults,
    useGetCsvTestResults
} from "../../hooks/useApi";
import TestResultSummary, { tabKeys } from "../TestResultSummary/TestResultSummary";
import { useSessionStorage, clearSessionStorageKey } from "../../hooks/useSessionStorage";
import TestResultTable, { ResultTableRowData, ResultTableData } from "../TestResultTable/TestResultTable";
import CandidateDetails from "../CandidateDetails/CandidateDetails";
import useDebounce from "../../hooks/useDebounce";
import CsvDownload from "../CsvDownload/CsvDownload";
import { CSVLink } from "react-csv";
import { getLogger } from "../../utils/logger";
import TrialPlanExceededDialog from "../TrialPlanExceededDialog/TrialPlanExceededDialog";
import useSubscriptionInfo from "../../hooks/useSubscriptionInfo";
import WarningMessage from "../WarningMessage/WarningMessage";

const log = getLogger();

const DEFAULT_SORTBY = "date";
const DEFAULT_ORDERBY = "desc";
const DEBOUNCE_INTERVAL_IN_MS = 400;
const PAGE_0 = 0;

const useStyles = makeStyles()({
    root: {
        width: "100%",
        height: "auto"
    }
});

type HeaderData = {
    testId: string; testName: string; active: boolean;
}

const getFieldValueBasedOnStatus = (status: string, fieldValue: string | number): string => {
    if (status === "INCOMPLETE" || status === "NOT STARTED" || (!fieldValue && fieldValue !== 0)) {
        return "n/a";
    }
    return "" + fieldValue;
};

const createBestTestResultTable = (candidates: CandidateBestResultData[]): ResultTableData => {
    if (candidates === null) {
        log.debug(`createBestTestResultTable() - candidates is null`);
        return [];
    }
    const table: (ResultTableRowData | null)[] = candidates.map((candidate, index) => {
        if (candidate === null) {
            log.debug(`createBestTestResultTable() - candidate at index ${index} is null`);
            const row: ResultTableRowData = {
                emailForCsv: "",
                fullNameForCsv: "",
                netWpmForCsv: "",
                accuracyForCsv: "",
                info1ForCsv: "",
                info2ForCsv: "",
                candidate: { fullName: "", email: "" },
                status: "NOT PASSED",
                errorCount: 0,
                hits: 0,
                netWpm: { netWpm: "", passed: false, resultId: "" },
                netWpmToAvgPercentage: "",
                accuracy: { accuracy: "0%", passed: false },
                grossWpm: "",
                createdAt: new Date(Date.now()).toString(),
                lastResultDate: new Date(Date.now()).toString(),
                attempts: 0,
                info: { info1: "", info2: "" }
            };
            return row;
        }
        const status = getStatus(candidate.status);
        try {
            const row: ResultTableRowData = {
                emailForCsv: candidate.email,
                fullNameForCsv: candidate.lastName + ", " + candidate.firstName,
                netWpmForCsv: "" + candidate.netWpm,
                accuracyForCsv: "" + candidate.accuracy,
                info1ForCsv: candidate.infoFieldOneValue,
                info2ForCsv: candidate.infoFieldTwoValue,
                candidate: { fullName: candidate.lastName + ", " + candidate.firstName, email: candidate.email },
                status: getStatus(candidate.status),
                netWpm: {
                    netWpm: getFieldValueBasedOnStatus(status, candidate.netWpm),
                    passed: candidate.netWpmPassed,
                    resultId: ""
                },
                netWpmToAvgPercentage: getFieldValueBasedOnStatus(status, candidate.netWpmToAvgPercentage + "%"),
                accuracy: {
                    accuracy: getFieldValueBasedOnStatus(status, candidate.accuracy),
                    passed: candidate.accuracyPassed
                },
                grossWpm: getFieldValueBasedOnStatus(status, candidate.grossWpm),
                createdAt: candidate.createdAt,
                lastResultDate: candidate.lastResultDate ? candidate.lastResultDate.toString() : "",
                attempts: candidate.attempts,
                errorCount: candidate.errorCount,
                hits: candidate.hits,
                info: { info1: candidate.infoFieldOneValue, info2: candidate.infoFieldTwoValue }
            };
            return row;
        } catch (error) {
            log.debug(`createBestTestResultTable() - error: ${error}`);
            return null;
        }
    });
    const tableFiltered = table.filter(t => Boolean(t)) as ResultTableRowData[];
    return tableFiltered;
};

const getStoredTestId = (storedTestId: string, data: GetBestTestResultsResponse): string => {
    if (storedTestId === "") {
        return data.test.testId;
    }
    const activeTestIndex = data.test.allTests.findIndex((item) => item.testId === storedTestId);
    if (activeTestIndex === -1) {
        log.debug(`getStoredTestId() - session storage is outdated, clearing and returning from api`);
        clearSessionStorageKey("testId");
        return data.test.testId;
    } else {
        return storedTestId;
    }
};

const createCandidateDetails = (candidate: GetCandidateTestResultsData): GetCandidateTestResultsData => {
    const testResults: GetCandidateTestResultsData["testResults"] = [];

    for (let index = 0; index < candidate.testResults.length; index++) {
        const status = getStatus(candidate.testResults[index].status);
        const testResult = {
            resultId: candidate.testResults[index].resultId,
            createdAt: candidate.testResults[index].createdAt,
            status: status,
            netWpm: {
                netWpm: getFieldValueBasedOnStatus(status, candidate.testResults[index].netWpm.netWpm),
                passed: candidate.testResults[index].netWpm.passed,
                resultId: candidate.testResults[index].resultId
            },
            netWpmToAvgPercentage: getFieldValueBasedOnStatus(status, candidate.testResults[index].netWpmToAvgPercentage),
            accuracy: {
                accuracy: getFieldValueBasedOnStatus(status, candidate.testResults[index].accuracy.accuracy),
                passed: candidate.testResults[index].accuracy.passed
            },
            grossWpm: getFieldValueBasedOnStatus(status, candidate.testResults[index].grossWpm),
            errorCount: candidate.testResults[index].errorCount,
            hits: candidate.testResults[index].hits
        };
        testResults.push(testResult);
    }
    const details = {
        testName: candidate.testName,
        testId: candidate.testId,
        candidateId: candidate.candidateId,
        bestResultId: candidate.bestResultId,
        firstName: candidate.firstName,
        lastName: candidate.lastName,
        useLimits: candidate.useLimits,
        status: getStatus(candidate.status),
        email: candidate.email,
        infoFieldOneTitle: candidate.infoFieldOneTitle,
        infoFieldOneValue: candidate.infoFieldOneValue,
        infoFieldOneEnabled: candidate.infoFieldOneEnabled,
        infoFieldTwoTitle: candidate.infoFieldTwoTitle,
        infoFieldTwoValue: candidate.infoFieldTwoValue,
        infoFieldTwoEnabled: candidate.infoFieldTwoEnabled,
        attempts: candidate.attempts,
        testResults: testResults,
        resultsInOtherTests: candidate.resultsInOtherTests
    };
    return details;
};

const TestResultList = () => {
    const { classes } = useStyles();
    const [bestData, setBestData] = useState<GetBestTestResultsData>();
    const [headerData, setHeaderData] = useState<HeaderData[]>([
        { testName: "", testId: "", active: false }
    ]);
    const [activeTestId, setActiveTestId] = useState("");
    const [storedActiveTest, setStoredActiveTest] = useSessionStorage("testId", "");
    const [totalCandidates, setTotalCandidates] = useState(0);

    const [showCandidateDetails, setShowCandidateDetails] = useState(false);
    const [trialPlanExceeded, setTrialPlanExceeded] = useState(true);
    const [trialPlanExceededDialogState, setTrialPlanExceededDialogState] = useState(false);
    const [candidateDetails, setCandidateDetails] = useState<GetCandidateTestResultsData>(
        {
            testName: "",
            testId: "",
            useLimits: false,
            candidateId: "",
            firstName: "",
            lastName: "",
            status: "",
            email: "",
            infoFieldOneTitle: "",
            infoFieldOneValue: "",
            infoFieldOneEnabled: false,
            infoFieldTwoTitle: "",
            infoFieldTwoValue: "",
            infoFieldTwoEnabled: false,
            attempts: 0,
            testResults: [],
            resultsInOtherTests: [],
            bestResultId: ""
        }
    );
    const [testResultTable, setTestResultTable] = useState<ResultTableData>([]);

    const [page, setPage] = useState(0);
    const [size, setSize] = useState(10);
    const [fetching, setFetching] = useState(false);
    const [sorting, setSorting] = useState<{ sortBy: SortBy; orderBy: OrderBy }>({
        sortBy: DEFAULT_SORTBY,
        orderBy: DEFAULT_ORDERBY
    });
    const { candidatesExceeded, subscriptionIsAvailable } = useSubscriptionInfo();

    const sortUpdate = (newSortBy: SortBy, newOrderBy: OrderBy) => {
        if (fetching) {
            return;
        }
        log.debug(`sortUpdate() - newSortBy ${newSortBy},  newOrderBy ${newOrderBy}`);
        const finalSort = { sortBy: newSortBy, orderBy: newOrderBy };

        if (newOrderBy === "none") {
            finalSort.sortBy = DEFAULT_SORTBY;
            finalSort.orderBy = DEFAULT_ORDERBY;

        }
        setSorting(finalSort);
        let testId = undefined;
        if (activeTestId !== "") {
            testId = activeTestId;
        } else if (storedActiveTest !== "") {
            testId = storedActiveTest;
        }
        log.debug(`sortUpdate() - testId ${JSON.stringify(testId)}`);
        log.debug(`sortUpdate() - sorting ${JSON.stringify(finalSort)}`);

        let search = undefined;
        if (debouncedSearchTerm !== null) {
            search = debouncedSearchTerm;
        }
        setFetching(true);
        useGetBestTestResults({ testId, page, activeTab, size, ...finalSort, search }, refreshFullBestTestResultsData);
    };

    const [searchTerm, setSearchTerm] = useState<string | null>(null);
    const [activeTab, setActiveTab] = useState<string>("1");
    const debouncedSearchTerm = useDebounce<string | null>(searchTerm, DEBOUNCE_INTERVAL_IN_MS);
    const searchChange = (searchText: string | null): void => {
        log.debug(`searchChange() - searching for: ${JSON.stringify(searchText)}`);
        setSearchTerm(searchText);
    };

    const handleChangeActiveTab = (value: string) => {
        if (fetching) {
            return;
        }
        setActiveTab(value);
    };

    const [initialRenderSkipped, setInitialRenderSkipped] = useState(false);

    useEffect(() => {
        if (!initialRenderSkipped) {
            setInitialRenderSkipped(true);
            return;
        }
        let testId = undefined;
        if (activeTestId !== "") {
            testId = activeTestId;
        } else if (storedActiveTest !== "") {
            testId = storedActiveTest;
        }
        let search = undefined;
        if (debouncedSearchTerm !== null) {
            search = debouncedSearchTerm;
        }
        setFetching(true);
        setPage(PAGE_0);
        useGetBestTestResults({
            testId,
            page: PAGE_0,
            activeTab,
            size, ...sorting,
            search
        }, refreshFullBestTestResultsData);
    }, [debouncedSearchTerm]);

    const initFullBestTestResultsData = (data: GetBestTestResultsResponse): void => {
        setHeaderData(data.test.allTests);
        const toBeActiveTestId = getStoredTestId(storedActiveTest, data);
        setActiveTestId(toBeActiveTestId);
        refreshFullBestTestResultsData(data);
    };
    const refreshFullBestTestResultsData = (data: GetBestTestResultsResponse): void => {
        setBestData(data.test);
        const table = createBestTestResultTable(data.test.results);
        setTestResultTable(table);
        setTotalCandidates(data.test.totalCandidates);
        setTrialPlanExceeded(data.trialPlanExceeded);
        setFetching(false);
    };

    const displayCandidateDetails = (email: string, index: number) => {
        if (trialPlanExceeded) {
            setTrialPlanExceededDialogState(true);
        } else {
            log.debug(`displayCandidateDetails() - ${email}`);
            const candidate = bestData?.results[index];
            const result = testResultTable[index];
            if (candidate && result) {
                const status = getStatus(candidate.status, bestData.useLimits);
                const details = createCandidateDetails({
                    email: candidate.email,
                    firstName: candidate.firstName,
                    lastName: candidate.lastName,
                    candidateId: candidate.candidateId,
                    testName: candidate.testName,
                    useLimits: bestData.useLimits,
                    attempts: candidate.attempts,
                    status: status,
                    infoFieldOneEnabled: bestData.infoFieldOne.enabled,
                    infoFieldOneTitle: bestData.infoFieldOne.title,
                    infoFieldOneValue: candidate.infoFieldOneValue,
                    infoFieldTwoEnabled: bestData.infoFieldTwo.enabled,
                    infoFieldTwoTitle: bestData.infoFieldOne.title,
                    infoFieldTwoValue: candidate.infoFieldTwoValue,
                    resultsInOtherTests: [],
                    testId: candidate.testId,
                    testResults: [{
                        accuracy: result.accuracy,
                        createdAt: candidate.createdAt,
                        errorCount: candidate.errorCount,
                        grossWpm: result.grossWpm,
                        hits: candidate.hits,
                        netWpm: result.netWpm,
                        netWpmToAvgPercentage: String(candidate.netWpmToAvgPercentage),
                        resultId: "",
                        status: status,
                    }]
                });

                setCandidateDetails(details)
            }
            setShowCandidateDetails(true);
        }
    };
    const getTestDataStats = (data: GetBestTestResultsData | undefined): GetTestResultsData["statistics"] => {
        if (data) {
            return data.statistics;
        } else {
            return {
                candidates: 0,
                passed: 0,
                notPassed: 0,
                sevenDays: 0,
                thirtyDays: 0,
                netSpeedAverage: 0,
                accuracyAverage: 0
            };
        }
    };
    const deleteTestResult = (resultId: string) => {
        log.debug(`deleteResult() - deleting ${resultId}`);
        useDeleteResult({ resultId }, () => {
            useGetCandidateTestResults({
                testId: bestData ? bestData.testId : "",
                email: candidateDetails.email
            }, (details) => {
                log.debug(`deleteTestResult() - ${JSON.stringify(details, null, 2)}`);
                const newDetails: GetCandidateTestResultsData = createCandidateDetails(details);
                setCandidateDetails(newDetails);
                setShowCandidateDetails(true);
            });
        });
    };
    const showCandidateInAnotherTest = (email: string, testId: string) => {
        log.debug(`showCandidateInAnotherTest() - email: ${email}`);
        log.debug(`showCandidateInAnotherTest() - testId: ${testId}`);
        useGetCandidateTestResults({ testId: testId, email: email }, (details) => {
            log.debug(`deleteTestResult() - ${JSON.stringify(details, null, 2)}`);
            const newDetails: GetCandidateTestResultsData = createCandidateDetails(details);
            setCandidateDetails(newDetails);
            setShowCandidateDetails(true);
        });
    };
    const csvLinkRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);
    const [csvData, setCsvData] = useState<GetCsvTestResultsResponse["results"]>([]);
    const downloadCSV = () => {
        useGetCsvTestResults({ testId: activeTestId }, (data) => {
            setCsvData(data.results);
            setTimeout(() => {
                csvLinkRef?.current?.link.click();
            });
        });

    };

    useEffect(() => {
        let testId = undefined;
        if (activeTestId !== "") {
            testId = activeTestId;
        } else if (storedActiveTest !== "") {
            testId = storedActiveTest;
        }
        setFetching(true);
        let search = undefined;
        if (debouncedSearchTerm !== null) {
            search = debouncedSearchTerm;
        }
        useGetBestTestResults({ testId, page, activeTab, size, ...sorting, search }, initFullBestTestResultsData);
    }, [activeTab]);

    const filteredCandidatesCount = useMemo(() => {
        const statistics = getTestDataStats(bestData);
        if (activeTab === tabKeys.activeTabTwo) {
            return statistics.passed;
        } else if (activeTab === tabKeys.activeTabThree) {
            return statistics.notPassed;
        } else if (activeTab === tabKeys.activeTabFour) {
            return statistics.sevenDays;
        } else if (activeTab === tabKeys.activeTabFive) {
            return statistics.thirtyDays;
        }

        return statistics.candidates;
    }, [bestData, activeTab]);

    return (
        <div className={classes.root}>
            <WarningMessage
                candidatesExceeded={candidatesExceeded}
                subscriptionIsAvailable={subscriptionIsAvailable}
            />
            <TestResultHeader
                headerData={headerData}
                selectedTestId={activeTestId}
                loading={fetching}
                onTestSelected={(testId: string) => {
                    log.debug(`onTestSelected() - currently active test: ${activeTestId}`);
                    log.debug(`onTestSelected() - setting new active test: ${testId}`);
                    setActiveTestId(testId);
                    log.debug(`onTestSelected() - storing new active test: ${testId}`);
                    setStoredActiveTest(testId);
                    setPage(PAGE_0);

                    setFetching(true);
                    let search = undefined;
                    if (debouncedSearchTerm !== null) {
                        search = debouncedSearchTerm;
                    }
                    useGetBestTestResults({
                        testId,
                        page: PAGE_0,
                        activeTab,
                        size, ...sorting,
                        search
                    }, refreshFullBestTestResultsData);
                }}
                onRefreshTests={() => {
                    log.debug(`onRefreshTests()`);
                    log.debug(`onRefreshTests() - currently active test: ${activeTestId}`);
                    log.debug(`onRefreshTests() - storedActiveTest: ${storedActiveTest}`);
                    setFetching(true);
                    let search = undefined;
                    if (debouncedSearchTerm !== null) {
                        search = debouncedSearchTerm;
                    }
                    useGetBestTestResults({
                        testId: activeTestId,
                        page,
                        activeTab,
                        size, ...sorting,
                        search
                    }, refreshFullBestTestResultsData);
                }}
            />
            <TestResultSummary
                {...getTestDataStats(bestData)}
                setActiveTab={handleChangeActiveTab}
                activeTab={activeTab}
                setPage={setPage}
            />
            <TestResultTable
                candidateClicked={displayCandidateDetails}
                tableData={testResultTable}
                setPage={(page: number) => {
                    if (fetching) {
                        return;
                    }
                    setFetching(true);
                    setPage(page);
                    let search = undefined;
                    if (debouncedSearchTerm !== null) {
                        search = debouncedSearchTerm;
                    }
                    useGetBestTestResults({
                        testId: activeTestId,
                        activeTab,
                        page,
                        size, ...sorting,
                        search
                    }, refreshFullBestTestResultsData);
                }}
                setSize={(size: number) => {
                    if (fetching) {
                        return;
                    }
                    setFetching(true);
                    setSize(size);
                    let search = undefined;
                    if (debouncedSearchTerm !== null) {
                        search = debouncedSearchTerm;
                    }
                    useGetBestTestResults({
                        testId: activeTestId,
                        activeTab,
                        page,
                        size, ...sorting,
                        search
                    }, refreshFullBestTestResultsData);
                }}
                count={filteredCandidatesCount}
                isLoading={fetching}
                page={page}
                sortUpdate={sortUpdate}
                searchChange={searchChange}
                downloadCSV={downloadCSV}
            />
            <CandidateDetails
                open={showCandidateDetails}
                handleClose={(updated?: boolean) => {
                    if (updated) {
                        setShowCandidateDetails(false);
                        setFetching(true);
                        let search = undefined;
                        if (debouncedSearchTerm !== null) {
                            search = debouncedSearchTerm;
                        }
                        useGetBestTestResults({
                            testId: activeTestId,
                            activeTab,
                            page,
                            size, ...sorting,
                            search
                        }, refreshFullBestTestResultsData);
                    } else {
                        setShowCandidateDetails(false);
                    }
                }}
                candidateDetails={candidateDetails}
                setCandidateDetails={setCandidateDetails}
                candidateUpdated={() => {
                    setShowCandidateDetails(false);
                    setFetching(true);
                    let search = undefined;
                    if (debouncedSearchTerm !== null) {
                        search = debouncedSearchTerm;
                    }
                    useGetBestTestResults({
                        testId: activeTestId,
                        activeTab,
                        page,
                        size, ...sorting,
                        search
                    }, refreshFullBestTestResultsData);
                }}
                deleteResult={deleteTestResult}
                testId={bestData?.testId}
                showCandidateInAnotherTest={showCandidateInAnotherTest}
                createCandidateDetails={createCandidateDetails}
            />
            <CsvDownload csvLink={csvLinkRef} csvData={csvData} />
            <TrialPlanExceededDialog trialPlanExceededDialogState={trialPlanExceededDialogState} setTrialPlanExceededDialogState={setTrialPlanExceededDialogState} />
        </div>
    );
};

export default TestResultList;
