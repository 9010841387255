import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/auth";
import { getAccountDomainFromTestLink } from "../utils/accountDomain";
import { getLogger } from "../utils/logger";
const log = getLogger();

const useLogout = (): [() => void] => {
    const navigate = useNavigate();
    const { setAuthTokens } = useAuth();

    const useForceLogout = (): void => {
        const accountDomain = getAccountDomainFromTestLink();
        log.debug(`useForceLogout() - logging out user`);
        if (setAuthTokens) {
            log.debug(`useForceLogout() - clearing auth tokens from context`);
            setAuthTokens({ email: "", status: "" });
        }
        log.debug(`useForceLogout() - clearing auth tokens from local storage`);
        localStorage.removeItem("tokens");
        if(accountDomain.length > 0){
            log.debug(`useForceLogout() - going to /select`);
            navigate("/select");
        } else {
            log.debug(`useForceLogout() - going to /login`);
            navigate("/login");
        }
    };
    return [useForceLogout];
};

export { useLogout };
