import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { makeStyles } from 'tss-react/mui';
import {
    ActivePlanProps,
    useGetAccountSubscriptionInformation,
} from "../../hooks/useApi";
import ErrorOutline from "@mui/icons-material/ErrorOutline";
import { Link } from "react-router-dom";
import useSubscriptionIsAvailable from "../../hooks/useSubscriptionInfo";
import { getCurrentDateDifferenceInDays } from "../../utils/time";

const LEGACY_PLANS = [0];
const MIGRATED_PLANS = [4, 5, 6, 19, 20, 21];
const useStyles = makeStyles()(() => ({
    root: {
        margin: "0px 45px 0 45px"
    },
    cardContainer: {
        boxShadow: "none",
        borderBottom: "1px solid #0000001F",
        padding: "5px 20px",
        marginBottom: "20px"
    },
    informationContainer: {
        boxShadow: "none",
        padding: "5px 20px",
        display: "flex",
        gap: 300
    },
    informationTitle: {
        color: "#000000",
        fontWeight: 600,
        marginBottom: "4px"
    },
    information: {
        color: "#000000"
    },
    errorMessage: {
        color: "red"
    }
}));

const enGbYyMmDdFormatter = new Intl.DateTimeFormat("en-GB", {
    year: "numeric",
    month: "short",
    day: "numeric"
});

const errorStyle = {
    error: {
        border: "1px solid #e81a2f",
        padding: "10px",
        color: "#000000",
        fontWeight: 400,
        background: "#ffcfcf",
        marginBottom: "10px",
        display: "flex",
        alignItems: "flex-end"
    },
    warning: {
        border: "1px solid #fae290",
        padding: "10px",
        color: "#b77d57",
        fontWeight: 600,
        background: "#fffbeb",
        marginBottom: "10px",
        display: "flex",
        alignItems: "flex-end"
    }
};

const EmptyInformation: ActivePlanProps = {
    name: "--",
    candidates: "--",
    validUntil: "--",
    totalAmount: "--",
    paid: false,
    fastSpringOrderUrl: "",
    fastSpringLegacyId: "",
    legacy: false,
    fsLegacyManageLink: "",
    planId: -1,
};

const Subscription = () => {
    const { classes } = useStyles();

    const { subscriptionIsAvailable } = useSubscriptionIsAvailable();
    const [planInformation, setPLanInformation] = useState<ActivePlanProps>(EmptyInformation);
    const [message, setMessage] = useState<string>();

    const error = (status: string) => {
        switch (status) {
            case "ERROR_LOGIN_FIRST":
                setMessage("Please Log in.");
                break;
            case "ERROR_ACCOUNT_NOT_FOUND":
                setMessage("User not found.");
                break;
            case "ERROR_PLAN_NOT_FOUND":
                setMessage("Subscription plan not found.");
                break;
        }
        setPLanInformation(EmptyInformation);
    };

    const getSubscriptionText = (validUntil: Date, planId: number) => {
        const subscriptionValidUntilDate = new Date(validUntil);
        const difference = getCurrentDateDifferenceInDays(subscriptionValidUntilDate);
        const validUntilDateToString = enGbYyMmDdFormatter.format(subscriptionValidUntilDate);

        if (LEGACY_PLANS.indexOf(planId) > -1 && difference >= -1) {
            return (
                <span>Your plan will expire on {validUntilDateToString}. To ensure uninterrupted service, kindly <a href={"/plans"}>purchase a new plan</a> before the expiration date.</span>
            )
        }

        if (MIGRATED_PLANS.indexOf(planId) > -1 && difference >= -1) {
            return (<span>Your subscription is valid until {validUntilDateToString}</span>);
        }

        if (LEGACY_PLANS.indexOf(planId) === -1 && MIGRATED_PLANS.indexOf(planId) === -1 && difference >= -1) {
            return (<span>You subscription automatically renews on {validUntilDateToString}</span>);
        }

        return (<span>Your subscription was expired on {validUntilDateToString}</span>);
    };

    useEffect(() => {
        useGetAccountSubscriptionInformation(
            setPLanInformation,
            error
        );
    }, []);

    return (
        <>
            {
                !subscriptionIsAvailable?.available &&
                <div style={subscriptionIsAvailable?.messageType === "warning" ? errorStyle.warning : errorStyle.error}>
                    <ErrorOutline style={{ marginRight: "10px" }} />
                    <span>Your plan is expired. To continue using all your favorite features without any interruption, simply{" "}<Link to={"/plans"} style={{ cursor: "pointer" }}>choose a plan.</Link></span>
                </div>
            }
            <div className={classes.cardContainer}>
                <p style={{ fontWeight: 600, color: "#000000", marginTop: "30px" }}>My plan</p>
                {message ? (
                    <p className={classes.errorMessage}>{message}</p>
                ) : (
                    planInformation.validUntil !== "--" && (
                        <p style={{ color: "#797979" }}>{getSubscriptionText(new Date(planInformation.validUntil), planInformation.planId)}</p>
                    )
                )}
            </div>
            <div className={classes.informationContainer}>
                <div>
                    <p className={classes.informationTitle}>PLAN</p>
                    <p className={classes.information}>{planInformation?.name}</p>

                    <div style={{ marginTop: "40px" }}>
                        <p className={classes.informationTitle}>CANDIDATES PER MONTH</p>
                        <p className={classes.information}>{planInformation?.candidates}</p>
                    </div>
                </div>
                <div>
                    <p className={classes.informationTitle}>
                        {
                            LEGACY_PLANS.indexOf(planInformation.planId) === -1 ?
                                ("PRE-PAID UNTIL") :
                                ("VALID UNTIL")
                        }
                    </p>
                    <p className={classes.information}>
                        {planInformation.validUntil !== "--" ? enGbYyMmDdFormatter.format(new Date(planInformation.validUntil)) : planInformation.validUntil}
                    </p>

                    <div style={{ marginTop: "40px" }}>
                        <p className={classes.informationTitle}>TOTAL CHARGE</p>
                        <p className={classes.information}>${planInformation?.totalAmount} USD</p>
                    </div>
                </div>
                {
                    LEGACY_PLANS.indexOf(planInformation.planId) === -1 && planInformation.fastSpringOrderUrl && (
                        <div>
                            {planInformation.fsLegacyManageLink && (
                                <div style={{ height: "50%" }}>
                                    <a href={planInformation.fsLegacyManageLink} target={"_blank"}>
                                        <Button id="submit_button"
                                            style={{ width: "200px" }}
                                            type="submit"
                                            variant="contained"
                                            color="secondary"
                                        >
                                            Cancel Subscription
                                        </Button>
                                    </a>
                                </div>
                            )}
                            {planInformation.legacy && planInformation.fsLegacyManageLink &&
                                <div style={{ height: "50%" }}>
                                    <a href={planInformation.fsLegacyManageLink} target={"_blank"}>
                                        <Button id="submit_button"
                                            style={{ width: "200px" }}
                                            type="submit"
                                            variant="contained"
                                            color="secondary"
                                        >
                                            Update Credit Card
                                        </Button>
                                    </a>
                                </div>
                            }
                        </div>
                    )
                }
            </div>
        </ >
    );
};

export default Subscription;
