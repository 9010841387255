import { makeStyles } from "tss-react/mui";

export const wizardStyles = makeStyles()((theme) => ({
    mainContainer: {
        position: 'relative',
        width: '100%',
        height: '100%',
    },
    button: {
        marginRight: theme.spacing(1),
    },
    titleDisabled: {
        color: theme.palette.warning.main,
    },
    textDisabled: {
        color: theme.palette.text.primary + ' !important',
    },
    buttonContainer: {
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
        left: '50%',
        bottom: '50px',
    },
    leftArea: {
        position: "relative",
        width: "17%",
        height: "310px",
        color: theme.palette.text.secondary,
        borderRight: "2px solid #ADBAFD",
        fontSize: "16px",
        lineHeight: "58px",
        textAlign: "right",
        paddingRight: "30px",
    },
    centerArea: {
        width: "50%",
        color: theme.palette.primary.dark,
        fontSize: "24px",
        lineHeight: "58px",
        paddingLeft: "40px",
        marginTop: "0px",
    },
    labelSpan: {
        display: "inline-block",
        width: "17%",
        color: theme.palette.text.secondary,
        borderRight: "2px solid #ADBAFD",
        fontSize: "16px",
        textAlign: "right",
        paddingRight: "15px",
        position: "relative",
        verticalAlign: "top",
        height: "58px",
        lineHeight: "58px",
        whiteSpace: "nowrap",
    },
    titleSpan: {
        display: "inline-block",
        marginBottom: "-4px",
        paddingLeft: "15px",
        verticalAlign: "top",
        height: "58px",
        lineHeight: "58px",
    },
    labelSpanAccessCode: {
        display: "inline-block",
        width: "17%",
        color: theme.palette.text.secondary,
        borderRight: "2px solid #ADBAFD",
        fontSize: "16px",
        textAlign: "right",
        paddingRight: "15px",
        paddingTop: "15px",
        position: "relative",
        verticalAlign: "text-top",
        height: "80px",
        lineHeight: "58px",
    },
    titleSpanAccessCode: {
        display: "inline-block",
        marginBottom: "-4px",
        paddingLeft: "15px",
        paddingTop: "10px",
        verticalAlign: "top",
        height: "80px",
        lineHeight: "58px",
    }
}));
