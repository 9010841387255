import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import Dialog from "@mui/material/Dialog";
import { makeStyles } from 'tss-react/mui';
import { useAuth } from "../../context/auth";
import { TestCardAction } from "../TestCard/TestCard";
import { useFormik } from "formik";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import Close from "@mui/icons-material/Close";
import Remove from "@mui/icons-material/Close";
import Edit from "@mui/icons-material/Edit";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CopyToClipboard from "react-copy-to-clipboard";
import * as Yup from "yup";
import { InviteCandidatesProps, useGetTestInvitationText, useInviteCandidatesForTest } from "../../hooks/useApi";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";
import { useFeature } from "flagged";

const defaultInvitationText = (companyName: string) => `Dear test taker,\n` +
    `I am writing to invite you to take the Typing Test assessment provided by ${companyName}.\n` +
    `Please log into Typing Test Pro and complete the test as soon as possible. If you have any questions or need assistance, feel free to reach out.\n` +
    `Thank you for your participation.`;

export interface CandidateInvitationDialogProps {
    open: boolean;
    handleOwnClose: () => void;
    testUrl: string;
    onAction: (testId: string, action: TestCardAction) => void;
    testId: string;
}

const EMAIL_MAX_LENGTH = 100;
const GREETING_TEXT_MAX_LENGTH = 500;
const DIALOG_CLOSING_TIMER = 3000;

const validationSchema = Yup.object({
    email: Yup.string()
        .email()
        .max(EMAIL_MAX_LENGTH, `Email should be no longer than ${EMAIL_MAX_LENGTH} characters`),
    greetingText: Yup.string()
        .max(GREETING_TEXT_MAX_LENGTH, `Greeting text should be no longer than ${GREETING_TEXT_MAX_LENGTH} characters`)
});

const schema = Yup.string().email();

interface StylesProps {
    hasEmails: boolean;
}

const useStyles = makeStyles<StylesProps>()((theme, { hasEmails }) => ({
    root: {
        width: "100%",
        color: "#000000",
        "& .MuiTypography-h6": {
            fontSize: "34px",
            minHeight: "55px"
        },
        "& .MuiDialog-paperScrollPaper": {
            width: "65%"
        }
    },
    errorText: {
        color: "red",
        fontSize: "20px",
        fontWeight: 500
    },
    successText: {
        color: "green",
        fontSize: "20px",
        fontWeight: 500
    },
    header: {
        backgroundColor: "#eae7eb",
        paddingLeft: "48px",
        paddingRight: "48px",
        paddingTop: "40px",
        paddingBottom: "40px"
    },
    link: {
        color: "#000000",
        fontWeight: 700,
        fontSize: "18px"
    },
    shareDescription: {
        color: "#000000",
        fontSize: "18px",
        marginBottom: "1rem",
    },
    closeIcon: {
        cursor: "pointer",
        color: "#c0c0c0"
    },
    previewSection: {
        border: "1px solid #ffffff",
        width: "25%",
        padding: "8px 12px",
        color: "#000000",
        fontWeight: 600,
        cursor: "pointer",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "6px"
    },
    copyLinkSection: {
        border: "1px solid #ffffff",
        width: "70%",
        padding: "8px 12px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    testUrl: {
        color: "#4276d7",
        background: "transparent",
        width: "100%",
        border: "none",
        outline: "none"
    },
    copyButton: {
        border: "1px solid #fc7566",
        fontSize: "12px",
        height: "24px",
        color: "#fc7566",
        fontWeight: 900,
        outline: "none!important"
    },
    editButton: {
        border: "1px solid #bfbfbf",
        fontSize: "12px",
        height: "24px",
        color: "#b8b8b8",
        fontWeight: 900,
        outline: "none!important",
        position: "absolute",
        right: "13px",
        top: "30px"
    },
    form: {
        backgroundColor: "#ffffff",
        paddingLeft: "48px",
        paddingRight: "48px",
        paddingTop: "20px",
        paddingBottom: "40px",
        fontSize: "18px"
    },
    customTextEmail: {
        color: theme.palette.primary.dark,
        fontSize: "16px",
        letterSpacing: "0.00938em"
    },
    customText: {
        color: theme.palette.primary.dark,
        fontSize: "16px",
        lineHeight: "1.5"
    },
    invitedEmail: {
        background: "#f5f5f5",
        display: "flex",
        height: "29px",
        color: "#97908c",
        alignItems: "center",
        padding: "0px 16px",
        borderRadius: "10px"
    },
    inviteEmailSection: {
        display: "inline-block",
        paddingRight: "10px"
    },
    button: {
        width: "121px",
        height: "36px",
        marginLeft: "10px",
        backgroundColor: "#3F51B5"
    },
    loading: {
        backgroundColor: "#3F51B5",
        width: "121px",
        height: "36px",
        marginTop: "16px",
        float: "right",
        position: "relative"
    },
    loadingSvg: {
        position: "absolute",
        top: "8px",
        left: "0",
        right: "0",
        margin: "0 auto",
        display: "block"
    },
    addedEmailsSection: {
        width: "600px",
        lineHeight: 1.75,
        maxHeight: "150px",
        overflowY: hasEmails ? "scroll" : "auto",
        marginBottom: "10px"
    }
}));

const CandidateInvitationDialog = (
    {
        open,
        handleOwnClose,
        testUrl,
        testId,
        onAction
    }: CandidateInvitationDialogProps) => {
    const { authTokens } = useAuth();
    const navigate = useNavigate();
    const enableInvite = useFeature("f_ie_a");
    const companyName = authTokens && authTokens.companyName ? authTokens.companyName : "";

    const [errorMessage, setErrorMessage] = useState<string>("");
    const [successMessage, setSuccessMessage] = useState<string>("");
    const [initialEmails, setInitialEmails] = useState<string[]>([]);
    const [isDisabledGreetingText, setIsDisabledGreetingText] = useState<boolean>(true);
    const [isFocusGreetingText, setIsFocusGreetingText] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showError, setShowError] = useState<boolean>(false);
    const [visibleSend, setVisibleSend] = useState<boolean>(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const [isValidEmail, setIsValidEmail] = useState<boolean>(true);
    const [showInvitationEditingDisabledMessage, setShowInvitationEditingDisabledMessage] = useState(false);
    const [invitationText, setInvitationText] = useState('');
    const { classes } = useStyles({ hasEmails: initialEmails.length > 0 });

    const closeDialog = (): void => {
        handleOwnClose();
        setErrorMessage("");
        setSuccessMessage("");
        setInitialEmails([]);
        setIsDisabledGreetingText(true);
        setIsLoading(false);
    };

    const removeFromMails = (index: number): void => {
        setInitialEmails((current) =>
            current.filter((email, currentIndex) => currentIndex !== index)
        );
    };


    const handleOpenInvitationEditingDisabledDialog = (): void => {
        setShowInvitationEditingDisabledMessage(true);
    };

    const setError = (message: string): void => {
        setIsLoading(false);
        setSuccessMessage("");
        switch (message) {
            case "ERROR_LOGIN_FIRST":
                setErrorMessage("Please trying after login");
                break;
            case "ERROR_INVALID_PARAMETERS":
                setErrorMessage("Invalid parameters");
                break;
            case "UNABLE_TO_SEND_CONFIRMATION":
                setErrorMessage("Could not send mails");
                break;
            case "ERROR_ACCOUNT_NOT_FOUND":
                setErrorMessage("Account not found");
                break;
            case "ERROR_TRY_AGAIN_LATER":
                setErrorMessage("Please try again later");
                break;
            case "SUBMIT_EMAIL":
                setErrorMessage("Please confirm last email");
                break;
            case "ERROR_SUBSCRIPTION_PLAN_EXPIRED":
                setErrorMessage("Your plan has expired");
                break;
            default:
                break;
        }
        clearMessages();
    };

    const setSuccess = (message: string): void => {
        setSuccessMessage(message);
        setErrorMessage("");
        setIsLoading(false);
        clearMessages();
        setTimeout(function () {
            closeDialog();
        }, DIALOG_CLOSING_TIMER);
    };

    const checkIsEmail = async (email: string): Promise<boolean> => {
        return new Promise((resolve) => resolve(schema.isValid(email)));
    };

    const clearMessages = (): void => {
        setTimeout(function () {
            setErrorMessage("");
            setSuccessMessage("");
        }, 3000);
    };

    const handleEdit = (): void => {
        setIsFocusGreetingText(true);
        setIsDisabledGreetingText(false);
    };

    const { handleSubmit, errors, handleChange, initialValues, values, setFieldValue, isValid } = useFormik({
        enableReinitialize: true,
        initialValues: {
            email: "",
            greetingText: invitationText
        },
        onSubmit: (values) => {
            if (values.email.length > 0) {
                setError("SUBMIT_EMAIL");
                return;
            }
            setIsLoading(true);
            if (initialEmails.length > 0) {
                const invitedParams: InviteCandidatesProps = {
                    emails: initialEmails,
                    testId: testId,
                    greetingText: values.greetingText ? values.greetingText : invitationText,
                    testUrl: testUrl
                };
                useInviteCandidatesForTest(
                    setError,
                    invitedParams,
                    setSuccess
                );
            } else {
                setError("NO_EMAIL_DATA");
            }
        },
        validationSchema,
    });

    const changeEmail = async (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const value = event.target.value;
        const emailsSplit = value.split(",");
        if (emailsSplit.length > 1) {
            const invalidEmails: string[] = [];
            for (const email of emailsSplit) {
                const emailValidation = await checkIsEmail(email);
                if (emailValidation) {
                    initialEmails.indexOf(email) === -1 && setInitialEmails((prev) => {
                        return [...prev, email];
                    });
                } else {
                    invalidEmails.push(email);
                }
            }
            if (invalidEmails.length > 0) {
                setFieldValue("email", invalidEmails.join(" "));
            }
            setVisibleSend(true);
        } else {
            checkIsEmail(event.target.value).then((res) => setIsValidEmail(res));
            handleChange(event);
            setShowError(false);
            initialEmails.length === 0 ? event.target.value.length > 1 && isValidEmail && !(errors && errors.email) ? setVisibleSend(true) : setVisibleSend(false) : setVisibleSend(true);
        }
    };

    useEffect(() => {
        if (initialEmails.length === 0 && ((errors && errors.email) || values.email.length === 0)) {
            setVisibleSend(false);
        }
    }, [initialEmails]);

    const updateInvitationText = () => {
        useGetTestInvitationText(
            testId,
            (text) => {
                if (text) {
                    setInvitationText(text);
                    setFieldValue('greetingText', text);
                } else {
                    const defaultText = defaultInvitationText(companyName);
                    setInvitationText(defaultText);
                    setFieldValue('greetingText', defaultText);
                }
            },
        );
    };

    useEffect(() => {
        if (open) {
            updateInvitationText();
        }
    }, [open]);

    return (
        <>

            <Dialog
                classes={{ root: classes.root }} maxWidth="xl" style={{ width: "100%" }}
                open={open}
                onClose={closeDialog}
            >
                <Box className={classes.header}>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography className={classes.link}>Get Link</Typography>
                        <IconButton onClick={closeDialog} className={classes.closeIcon} data-testid="CloseIconButton">
                            <Close />
                        </IconButton>
                    </div>
                    <Typography
                        className={classes.shareDescription}
                    >
                        Share the Test
                        ID <b>{testUrl.substring(testUrl.length - 8)}</b> or link to invite candidates to take the typing
                        speed test.
                    </Typography>
                    <Box style={{ display: "flex", fontSize: "18px", justifyContent: "space-between" }}>
                        <div className={classes.previewSection}
                            onClick={() => onAction(testId, TestCardAction.preview)}>
                            <VisibilityIcon style={{ marginRight: "5px" }} />
                            PREVIEW TEST
                        </div>
                        <div className={classes.copyLinkSection}>
                            <input className={classes.testUrl} ref={inputRef} value={testUrl} />
                            <CopyToClipboard text={testUrl}>
                                <Button
                                    id={`copy_url`}
                                    onClick={() => {
                                        inputRef?.current?.focus();
                                        inputRef?.current?.select();
                                    }}
                                    className={classes.copyButton}>
                                    COPY
                                </Button>
                            </CopyToClipboard>
                        </div>
                    </Box>
                </Box>
                <form className={classes.form} onSubmit={handleSubmit}>
                    <Box>
                        <Typography sx={{ fontSize: "20px", color: "#000000", fontWeight: 700, marginBottom: "16px" }}>Invite candidates</Typography>
                        <div className={classes.addedEmailsSection}>
                            <div style={{ marginBottom: "10px" }}>
                                {initialEmails.map((element, index) => {
                                    return (
                                        <div className={classes.inviteEmailSection} key={element + index}>
                                            <div className={classes.invitedEmail}>
                                                <Typography id={"email_" + index}>{element}</Typography>
                                                <Remove
                                                    style={{ marginLeft: "10px", cursor: "pointer", fontSize: "12px" }}
                                                    onClick={() => removeFromMails(index)}
                                                />
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <TextField
                            id="email"
                            name="email"
                            placeholder={"example@email.com"}
                            multiline
                            value={values.email.trim()}
                            onChange={(event) => {
                                changeEmail(event);
                            }}
                            variant="outlined"
                            error={showError && values.email.length > 0 ? !!(errors && errors.email) : false}
                            helperText={showError && values.email.length > 0 && errors.email}
                            onKeyDown={(event) => {
                                if (event.key === "Enter" || event.key === " " || event.key === ",") {
                                    event.preventDefault();
                                    if (!errors.email && values.email.length > 0) {
                                        setShowError(false);
                                        initialEmails.indexOf(values.email) === -1 && setInitialEmails((prev) => {
                                            return [...prev, values.email];
                                        });
                                        setFieldValue("email", "");
                                    } else {
                                        setShowError(true);
                                    }
                                }
                            }}
                            InputProps={{ classes: { input: classes.customTextEmail } }}
                            style={{ width: "600px", color: "#000000" }}
                            inputProps={{ maxLength: EMAIL_MAX_LENGTH }}
                        />
                        <div style={{ paddingTop: "20px", position: "relative" }}>
                            <TextField
                                label="Greeting text"
                                id="greetingText"
                                name="greetingText"
                                onChange={enableInvite ? handleChange : () => { return "" }}
                                defaultValue={initialValues.greetingText}
                                autoFocus={isFocusGreetingText}
                                disabled={isDisabledGreetingText}
                                multiline
                                minRows="5"
                                variant="outlined"
                                color="secondary"
                                InputProps={{ classes: { input: classes.customText } }}
                                style={{ width: "100%" }}
                                InputLabelProps={{ shrink: true }}
                                error={Boolean(errors.greetingText)}
                                helperText={errors.greetingText ? errors.greetingText : ''}
                            />
                            <Button
                                id="edit_greeting_text"
                                startIcon={<Edit style={{ color: "#64b4df", fontSize: "14px" }} />}
                                onClick={enableInvite ? handleEdit : handleOpenInvitationEditingDisabledDialog}
                                className={classes.editButton}>
                                EDIT
                            </Button>
                        </div>
                    </Box>
                    <Box style={{ display: "flex", justifyContent: "right", alignItems: "baseline" }}>
                        {errorMessage && (
                            <Box style={{ height: "28px", marginBottom: "20px" }}>
                                <span className={classes.errorText}>{errorMessage}</span>
                            </Box>
                        )}
                        {successMessage && (
                            <Box style={{ height: "28px", marginBottom: "20px" }}>
                                <span className={classes.successText}>{successMessage}</span>
                            </Box>
                        )}
                        {!isLoading ? (
                            <Button
                                style={{ marginTop: "16px" }}
                                color="secondary"
                                type={"submit"}
                                onClick={() => {
                                    if (errors && errors.email) {
                                        setShowError(true);
                                    } else if (values.email.length > 0 && !(errors && errors.email)) {
                                        initialEmails.indexOf(values.email) === -1 && setInitialEmails((prev) => {
                                            return [...prev, values.email];
                                        });
                                        setFieldValue("email", "");
                                    }
                                }}
                                id="submit"
                                variant="contained"
                                disabled={!visibleSend || !isValid}
                                aria-label="edit"
                                className={classes.button}
                            >
                                SEND
                            </Button>
                        ) : (
                            <div className={classes.loading}>
                                <CircularProgress size="20px" className={classes.loadingSvg} />
                            </div>
                        )
                        }
                    </Box>
                </form>
            </Dialog>

            <Dialog
                open={showInvitationEditingDisabledMessage}
                onClose={() => {
                    setShowInvitationEditingDisabledMessage(false);
                }}
                aria-labelledby="error-message"
                aria-describedby="errorMessage-description"
            >
                <DialogTitle id="error-message" style={{ textAlign: "center" }}>
                    Oops!
                </DialogTitle>
                <DialogContent style={{ width: "600px", textAlign: "center" }}>
                    <DialogContentText id="errorMessage-description">
                        You need to have a paid plan to use this feature.
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ margin: "0 auto 20px auto" }}>
                    <Button
                        color="secondary"
                        variant="outlined"
                        onClick={() => {
                            navigate("/plans");
                        }}
                    >
                        View plans
                    </Button>
                    <Button id="close_invitation_editing_disabled_dlg" color="secondary" variant="contained" onClick={() => {
                        setShowInvitationEditingDisabledMessage(false);
                    }}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default CandidateInvitationDialog;