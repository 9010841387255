import { getLogger } from "./logger"
const log = getLogger();

export const convertToMinutes = (hhMm: string): number => {
    try {
        const [hours, minutes] = hhMm.split(":");
        let totalMinutes = 0;
        if (hours && minutes) {
            const multiplier = +hours < 0 ? -1 : 1;
            totalMinutes = +hours * 60 + +minutes * multiplier;
        }
        log.debug(`convertToMinutes() - returning: ${totalMinutes}`);
        return totalMinutes;

    } catch (error) {
        log.error(`convertToMinutes() - error: ${JSON.stringify(error)}`);
        return 0;
    }
}

export const getCurrentDateDifferenceInDays = (date: Date): number => {
    const dateNow = new Date();
    const difference = (date.getTime() - dateNow.getTime()) / (1000 * 60 * 60 * 24);
    return difference;
};