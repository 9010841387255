import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import { makeStyles } from 'tss-react/mui';
import Divider from "@mui/material/Divider";
import { useState } from "react";

const useStyles = makeStyles()(() => ({
    mainBox: {
        marginLeft: "40px",
        marginRight: "40px",
        display: "flex",
        justifyContent: "space-between"
    },
    itemBox: {
        width: "12%",
        borderRadius: "7px",
        padding: "14px",
        cursor: "pointer"
    },
    itemLabel: {
        fontSize: "16px",
        textAlign: "center",
        display: "block"
    },
    itemVal: {
        fontSize: "34px",
        marginTop: "10px"
    },
    candidatesDivider: {
        backgroundColor: "#3F51B5",
        height: "5px"
    },
    passDivider: {
        backgroundColor: "#00CF8A",
        height: "5px"
    },
    notPassedDivider: {
        backgroundColor: "#E0789A",
        height: "5px"
    },
    sevenDaysDivider: {
        backgroundColor: "#8090E8",
        height: "5px"
    },
    thirtyDaysDivider: {
        backgroundColor: "#B6C0F5",
        height: "5px"
    },
    averageNetSpeedDivider: {
        backgroundColor: "grey",
        height: "1px"
    },
    averageAccuracyDivider: {
        backgroundColor: "grey",
        height: "1px"
    }
})
);

export const tabKeys = {
    activeTabOne: "1",
    activeTabTwo: "2",
    activeTabThree: "3",
    activeTabFour: "4",
    activeTabFive: "5",
    activeTabSix: "6",
    activeTabSeven: "7",
};

interface summaryItem {
    key: string;
    stl: any;
    val: any;
    descr: string;
    shadow?: string;
    border?: string;
    title?: string;
    id?: string;
}

export interface TestResultSummaryProps {
    candidates: number;
    passed: number;
    notPassed: number;
    sevenDays: number;
    thirtyDays: number;
    netSpeedAverage: number;
    accuracyAverage: number;
    setActiveTab?: (key: string) => void,
    activeTab?: string,
    setPage?: (page: number) => void
}

const TestResultSummary = ({
    candidates,
    passed,
    notPassed,
    sevenDays,
    thirtyDays,
    netSpeedAverage,
    accuracyAverage,
    setActiveTab,
    activeTab,
    setPage,
}: TestResultSummaryProps) => {
    const { classes } = useStyles();

    const [tabsClicked, setTabsClicked] = useState<Record<string, boolean>>({});

    const items: summaryItem[] = [
        {
            key: tabKeys.activeTabOne,
            stl: classes.candidatesDivider,
            val: candidates,
            descr: "All Results",
            shadow: "rgb(63, 81, 181,0.3) 0px 5px 22px 3px, rgba(0, 0, 0, 0.1) 0px 4px 6px -4px",
            border: "1px solid #d3d2d2",
            title: "Display all results"
        },
        {
            key: tabKeys.activeTabTwo,
            stl: classes.passDivider,
            val: passed,
            descr: "Passed",
            shadow: "rgb(0, 207, 138,0.3) 0px 5px 22px 3px, rgba(0, 0, 0, 0.1) 0px 4px 6px -4px",
            border: "1px solid #d3d2d2",
            title: "Display passed results"
        },
        {
            key: tabKeys.activeTabThree,
            stl: classes.notPassedDivider,
            val: notPassed,
            descr: "Not Passed",
            shadow: "rgb(224, 120, 154,0.3) 0px 5px 22px 3px, rgba(0, 0, 0, 0.1) 0px 4px 6px -4px",
            border: "1px solid #d3d2d2",
            title: "Display not passed or incomplete results"
        },
        {
            key: tabKeys.activeTabFour,
            stl: classes.sevenDaysDivider,
            val: sevenDays,
            descr: "Last 7 days",
            id: "lastWeekResult",
            shadow: "rgb(128, 144, 232,0.3) 0px 5px 22px 3px, rgba(0, 0, 0, 0.1) 0px 4px 6px -4px",
            border: "1px solid #d3d2d2",
            title: "Limit results to 7 days"
        },
        {
            key: tabKeys.activeTabFive,
            stl: classes.thirtyDaysDivider,
            val: thirtyDays,
            descr: "Last 30 days",
            shadow: "rgb(182, 192, 245,0.3) 0px 5px 22px 3px, rgba(0, 0, 0, 0.1) 0px 4px 6px -4px",
            border: "1px solid #d3d2d2",
            title: "Limit results to 30 days"
        },
        { key: tabKeys.activeTabSix, stl: classes.averageNetSpeedDivider, val: netSpeedAverage, descr: "Net Speed avg." },
        { key: tabKeys.activeTabSeven, stl: classes.averageAccuracyDivider, val: accuracyAverage, descr: "Accuracy avg." }
    ];

    const filterCandidates = (key: string): void => {
        setPage && setPage(0)
        setActiveTab && key !== tabKeys.activeTabSix && key !== tabKeys.activeTabSeven && setActiveTab(key);
    };

    const getItemVal = (key: string, val: any) => {

        if (key === tabKeys.activeTabOne) {
            return (

                <div style={{ position: "relative" }}>
                    <Typography align="center" variant="h4" className={classes.itemVal}>{val} </Typography>

                </div>

            );
        }

        if (key === tabKeys.activeTabSix) {
            const num = parseInt(val) + ".";
            const add = (parseFloat(val) % 1 * 10).toFixed(0);
            return (
                <Typography align="center" variant="h4" className={classes.itemVal}>
                    {num}{add}<span style={{ fontSize: "27px" }}> WPM</span>
                </Typography>
            );
        }

        if (key === tabKeys.activeTabSeven) {
            return (
                <Typography align="center" variant="h4" className={classes.itemVal}>
                    {parseInt(val)} <span style={{ fontSize: "27px", marginLeft: "-7px" }}>%</span>
                </Typography>
            );
        }

        return (
            <Typography align="center" variant="h4" className={classes.itemVal}>{val} </Typography>
        );
    };

    const handleClick = (key: string) => {
        setTabsClicked({ ...tabsClicked, [key]: true });
        filterCandidates(key);
    };

    return (
        <Box className={classes.mainBox}>
            {items.map(({ key, stl, val, descr, shadow, border, title, id }) => (
                <Tooltip title={title && !tabsClicked[key] ? title : ""} key={key}>
                    <div
                        id={id ? id : ""}
                        key={"" + Math.random()}
                        className={classes.itemBox}
                        onClick={() => handleClick(key)}
                        style={{ boxShadow: activeTab === key ? shadow : "", border: border ? border : "" }}
                    >
                        <Divider className={stl} />
                        <Typography align="center" variant="caption"
                            className={classes.itemLabel}>{descr}</Typography>
                        {getItemVal(key, val)}
                    </div>
                </Tooltip>
            ))}
        </Box>
    );
};

export default TestResultSummary;
