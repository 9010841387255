import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles, withStyles } from 'tss-react/mui';

export interface EllipsisWithTooltipProps {
  tooltip: string;
  label: string;
  children: React.ReactNode
}

const useStyles = makeStyles()(() => ({
  ellipsisDefaultStyle: {
    overflow: 'hidden',
    overflowWrap: 'break-word',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    wordBreak: 'break-all',
  },
  customWidth: {
    maxWidth: 500,
  },
}));

const LightTooltip = withStyles(Tooltip, (theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 16,
  },
  tooltipPlacementBottom: {
    margin: "0px",
  },
}));


const EllipsisWithTooltip = ({ tooltip, label, children }: EllipsisWithTooltipProps) => {
  const { classes } = useStyles();
  return (
    <div>
      <LightTooltip title={tooltip} aria-label={label} classes={{ tooltip: classes.customWidth }} placement="bottom">
        <div className={classes.ellipsisDefaultStyle}>{children}</div>
      </LightTooltip>
    </div>
  );
};

export default EllipsisWithTooltip;
