import * as Yup from "yup";
import emailRegex from "./emailRegex";

export const personalDataValidationSchemaParts = {
    email: Yup.string()
        .required("Must Provide Email")
        .test({
            test: (value, ctx) => {
                const hasError = !emailRegex.test(value);
                if (hasError) {
                    return ctx.createError({ message: "Email is incorrect" });
                }

                return true;
            },
        }),
    firstName: Yup.string().max(50).min(2, "First name is too short").required("Must Provide First Name"),
    lastName: Yup.string().max(50).min(2, "Last name is too short").required("Must Provide Last Name"),
    consentAccepted: Yup.boolean().oneOf([true], "Must Accept Terms and Conditions"),
};

const validationSchema = Yup.object(personalDataValidationSchemaParts);

export default validationSchema;
