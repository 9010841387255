import React from "react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { useNavigate } from "react-router-dom";

interface DialogForCandidateDetailsProps {
    trialPlanExceededDialogState: boolean;
    setTrialPlanExceededDialogState: (state: boolean) => void
}

const TrialPlanExceededDialog = ({ trialPlanExceededDialogState, setTrialPlanExceededDialogState }: DialogForCandidateDetailsProps) => {
    const navigate = useNavigate();

    return (
        <Dialog
            open={trialPlanExceededDialogState}
            onClose={() => setTrialPlanExceededDialogState(false)}
        >
            <DialogTitle style={{ textAlign: "center" }}>
                Oops!
            </DialogTitle>
            <DialogContent style={{ width: "600px", textAlign: "center" }}>
                <DialogContentText>
                    Sorry, you don't have a plan. To show details, please purchase a plan.
                </DialogContentText>
            </DialogContent>
            <DialogActions style={{ margin: "0 auto 20px auto" }}>
                <Button
                    color="secondary"
                    variant="outlined"
                    onClick={() => {
                        navigate("/plans");
                    }}
                >
                    View plans
                </Button>
                <Button
                    color="secondary"
                    variant="contained"
                    onClick={() => setTrialPlanExceededDialogState(false)}
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default TrialPlanExceededDialog;