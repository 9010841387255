import { useEffect, useState } from "react";
import { withFormik, FormikProps, Form, FormikHelpers } from "formik";
import * as Yup from "yup";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import { useFeature } from "flagged";
import { AccountInfoParams, useGetAccountInfo, usePatchAccountInfo } from "../../hooks/useApi";
import { useLogout } from "../../hooks/useLogout";
import { makeStyles } from 'tss-react/mui';
import { getLogger } from "../../utils/logger";
import { useSetTokenAndFeatures } from "../../context/auth";

const log = getLogger();

const COMPANY_NAME_MAX_LENGTH = 100;
const ACCOUNT_DOMAIN_MIN_LENGTH = process.env.REACT_APP_MIN_SUBDOMAIN_LEN ? Number(process.env.REACT_APP_MIN_SUBDOMAIN_LEN) : 4;
const ACCOUNT_DOMAIN_MAX_LENGTH = 20;
const productionDomain = process.env.REACT_APP_PRODUCTION_DOMAIN ?? "typingtest.com";

const useStyles = makeStyles()((theme) => ({

    root: {
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        paddingTop: "20px"
    },

    boxField: {
        marginTop: 0,
        marginBottom: "25px"
    },

    inputField: {
        height: "35px",
        lineHeight: "35px",
        color: theme.palette.primary.dark
    },
    inputFieldDisabled: {
        height: "35px",
        lineHeight: "35px",
        color: theme.palette.text.secondary
    },
    buttonBox: {
        display: "flex",
        justifyContent: "center",
        marginTop: "30px"
    },
    typingtest: {
        color: "#828282",
        marginLeft: "5px"
    }
}));


const timezones = [
    {
        value: "-12:00",
        label: "(GMT -12:00) Eniwetok, Kwajalein"
    },
    {
        value: "-11:00",
        label: "(GMT -11:00) Midway Island, Samoa"
    },
    {
        value: "-10:00",
        label: "(GMT -10:00) Hawaii"
    },
    {
        value: "-09:50",
        label: "(GMT -9:30) Taiohae"
    },
    {
        value: "-09:00",
        label: "(GMT -9:00) Alaska"
    },
    {
        value: "-08:00",
        label: "(GMT -8:00) Pacific Time (US & Canada)"
    },
    {
        value: "-07:00",
        label: "(GMT -7:00) Mountain Time (US & Canada)"
    },
    {
        value: "-06:00",
        label: "(GMT -6:00) Central Time (US & Canada), Mexico City"
    },
    {
        value: "-05:00",
        label: "(GMT -5:00) Eastern Time (US & Canada), Bogota, Lima"
    },
    {
        value: "-04:50",
        label: "(GMT -4:30) Caracas"
    },
    {
        value: "-04:00",
        label: "(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz"
    },
    {
        value: "-03:50",
        label: "(GMT -3:30) Newfoundland"
    },
    {
        value: "-03:00",
        label: "(GMT -3:00) Brazil, Buenos Aires, Georgetown"
    },
    {
        value: "-02:00",
        label: "(GMT -2:00) Mid-Atlantic"
    },
    {
        value: "-01:00",
        label: "(GMT -1:00) Azores, Cape Verde Islands"
    },
    {
        value: "+00:00",
        label: "(GMT) Western Europe Time, London, Lisbon, Casablanca"
    },
    {
        value: "+01:00",
        label: "(GMT +1:00) Brussels, Copenhagen, Madrid, Paris"
    },
    {
        value: "+02:00",
        label: "(GMT +2:00) Kaliningrad, South Africa"
    },
    {
        value: "+03:00",
        label: "(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg"
    },
    {
        value: "+03:50",
        label: "(GMT +3:30) Tehran"
    },
    {
        value: "+04:00",
        label: "(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi"
    },
    {
        value: "+04:50",
        label: "(GMT +4:30) Kabul"
    },
    {
        value: "+05:00",
        label: "(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent"
    },
    {
        value: "+05:50",
        label: "(GMT +5:30) Bombay, Calcutta, Madras, New Delhi"
    },
    {
        value: "+05:75",
        label: "(GMT +5:45) Kathmandu, Pokhara"
    },
    {
        value: "+06:00",
        label: "(GMT +6:00) Almaty, Dhaka, Colombo"
    },
    {
        value: "+06:50",
        label: "(GMT +6:30) Yangon, Mandalay"
    },
    {
        value: "+07:00",
        label: "(GMT +7:00) Bangkok, Hanoi, Jakarta"
    },
    {
        value: "+08:00",
        label: "(GMT +8:00) Beijing, Perth, Singapore, Hong Kong"
    },
    {
        value: "+08:75",
        label: "(GMT +8:45) Eucla"
    },
    {
        value: "+09:00",
        label: "(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk"
    },
    {
        value: "+09:50",
        label: "(GMT +9:30) Adelaide, Darwin"
    },
    {
        value: "+10:00",
        label: "(GMT +10:00) Eastern Australia, Guam, Vladivostok"
    },
    {
        value: "+10:50",
        label: "(GMT +10:30) Lord Howe Island"
    },
    {
        value: "+11:00",
        label: "(GMT +11:00) Magadan, Solomon Islands, New Caledonia"
    },
    {
        value: "+11:50",
        label: "(GMT +11:30) Norfolk Island"
    },
    {
        value: "+12:00",
        label: "(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka"
    },
    {
        value: "+12:75",
        label: "(GMT +12:45) Chatham Islands"
    },
    {
        value: "+13:00",
        label: "(GMT +13:00) Apia, Nukualofa"
    },
    {
        value: "+14:00",
        label: "(GMT +14:00) Line Islands, Tokelau"
    }
];

type AccountInfoProps = AccountInfoParams & {
    onSubmit: (values: AccountInfoParams, helpers: FormikHelpers<AccountInfoParams>) => void;
};

const validationSchema = Yup.object({
    companyName: Yup.string().trim().min(2, "Name is too short").max(100, "Name should be no longer than 100 characters"),
    accountDomain: Yup.string().trim().min(ACCOUNT_DOMAIN_MIN_LENGTH, `ID should be no shorter than ${ACCOUNT_DOMAIN_MIN_LENGTH} characters`)
        .max(ACCOUNT_DOMAIN_MAX_LENGTH, "ID should be no longer than 20 characters")
        .matches(/^[a-z0-9]+(-[a-z0-9]+)*$/, "ID can contain a-z, 0-9 and single '-' characters in between")
        .notOneOf(["ttpro", "pro", "app", "beta", "typingmaster", "www", "www2", "course", "courses", "train", "training", "trainings", "free", "test", "typingtest", "typingtests", "typingtestpro", "typingtest-pro", "lesson", "lessons", "sta"], "This ID is already reserved")
});

const AccountInfoForm = (props: FormikProps<AccountInfoParams>) => {
    const { values, errors, touched, dirty, isValid, resetForm, handleChange, handleBlur, handleSubmit, setFieldValue } = props;
    const { classes } = useStyles();
    const enableSubDomain = useFeature("f_custom_url");

    const endAdornment = <span className={classes.typingtest}>.{productionDomain}</span>

    const enableDisableSubdomain = () => {
        return (
            <Box display="flex" style={{ alignItems: "center" }}>
                <TextField
                    margin="dense"
                    name="accountDomain"
                    id="accountDomain"
                    label={enableSubDomain ? "Custom URL" : "Custom URL (Locked in this plan)"}
                    value={enableSubDomain ? values.accountDomain : (
                        values.accountDomain.length ? values.accountDomain + "." + productionDomain : "app." + productionDomain
                    )}
                    type="text"
                    fullWidth
                    variant="outlined"
                    color="secondary"
                    className={classes.boxField}
                    helperText={
                        errors.accountDomain
                            ? errors.accountDomain
                            : ""
                    }
                    error={errors.accountDomain ? true : false}
                    onChange={(e) => {
                        const value = e.target.value || "";
                        setFieldValue("accountDomain", value.toLowerCase());
                    }}
                    onBlur={handleBlur}
                    InputProps={enableSubDomain ? {
                        endAdornment,
                        classes: { input: classes.inputField }
                    } : {
                        classes: { input: classes.inputFieldDisabled }
                    }}
                    sx={{
                        '& .MuiFormLabel-root:not(.MuiInputLabel-shrink)': {
                            transform: 'translate(14px, 23px) scale(1)'
                        }
                    }}
                    inputProps={{ maxLength: ACCOUNT_DOMAIN_MAX_LENGTH }}
                    disabled={!enableSubDomain}
                />
            </Box>
        );
    };

    return (

        <Form onSubmit={handleSubmit} className={classes.root}>
            <TextField
                margin="dense"
                name="companyName"
                id="companyName"
                label="Organization Name"
                value={values.companyName}
                type="text"
                fullWidth
                onBlur={handleBlur}
                error={errors.companyName && touched.companyName ? true : false}
                onChange={handleChange}
                variant="outlined"
                color="secondary"
                className={classes.boxField}
                InputProps={{ classes: { input: classes.inputField } }}
                inputProps={{ maxLength: COMPANY_NAME_MAX_LENGTH }}
                sx={{
                    '& .MuiFormLabel-root:not(.MuiInputLabel-shrink)': {
                        transform: 'translate(14px, 23px) scale(1)'
                    }
                }}
                helperText={
                    errors.companyName && touched.companyName
                        ? errors.companyName
                        : ""
                } />


            <TextField
                margin="dense"
                name="timeZone"
                id="select_timeZone"
                label="Time Zone"
                value={values.timeZone}
                select
                fullWidth
                onBlur={handleBlur}
                onChange={handleChange}
                variant="outlined"
                color="secondary"
                className={classes.boxField}
                InputProps={{ classes: { input: classes.inputField } }}
                inputProps={{ inputMode: "text" }}
                sx={{
                    '& .MuiFormLabel-root:not(.MuiInputLabel-shrink)': {
                        transform: 'translate(14px, 23px) scale(1)'
                    }
                }}
            >
                {timezones.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </TextField>

            {enableDisableSubdomain()}


            <Box className={classes.buttonBox}>
                <Button disabled={!dirty} onClick={() => {
                    log.debug(`Cancel clicked`);
                    resetForm();
                }} color="secondary" style={{ marginRight: "20px" }}>
                    Cancel
                </Button>

                <Button type="submit" disabled={!(isValid && dirty)} id="btn_save" color="secondary"
                    variant="contained">
                    Save
                </Button>

            </Box>

        </Form>
    );
};

const EnhancedAccountInfo = (props: AccountInfoProps) => {
    const EnhancedAccountInfo2 = withFormik<AccountInfoProps, AccountInfoParams>({
        mapPropsToValues: ({ companyName, timeZone, accountDomain }) => {
            return {
                companyName: companyName || "",
                timeZone: timeZone || "",
                accountDomain: accountDomain || ""
            };
        },
        handleSubmit: props.onSubmit,
        validationSchema: validationSchema,
        enableReinitialize: true
    })(AccountInfoForm);
    return <EnhancedAccountInfo2 {...props} />;
};

export type TopAccountInfoProps = {
    setTimeZone: (timeZone: string) => void;
}

const AccountInfo = (props: TopAccountInfoProps) => {
    const [formValues, setFormValues] = useState<AccountInfoParams>({
        companyName: "",
        timeZone: "",
        accountDomain: ""
    });

    const [useForceLogout] = useLogout();
    const { setTokenAndFeatures } = useSetTokenAndFeatures();

    const onSubmit = async (values: AccountInfoParams, helpers: FormikHelpers<AccountInfoParams>): Promise<void> => {
        try {
            log.debug(`onSubmit()`);
            usePatchAccountInfo(values,
                (data) => {
                    setFormValues((prev) => {
                        return { ...prev, ...data };
                    });
                    setTokenAndFeatures();
                },
                (error) => {
                    log.error(`onSubmit() - error ${error} updating account`);
                    if (error === "ERROR_ACCOUNT_DOMAIN_RESERVED") {
                        helpers.setFieldError("accountDomain", "This Account ID is reserved");
                    } else if (error === "ERROR_ACCOUNT_NAME_RESERVED") {
                        helpers.setFieldError("companyName", "This Company Name is reserved");
                    } else {
                        useForceLogout();
                    }

                });
        } catch (error) {
            log.error(`onSubmit() - error: ${JSON.stringify(error, null, 2)}`);
        }
    };

    useEffect(() => {
        useGetAccountInfo(
            (data) => {
                setFormValues((prev) => {
                    return {
                        ...prev,
                        companyName: data.companyName,
                        timeZone: data.timeZone,
                        accountDomain: data.accountDomain
                    };
                });
                props.setTimeZone(data.timeZone);
            },
            (error) => {
                log.error(`onError() - error ${error}`);
                useForceLogout();
            });
    }, []);

    return <EnhancedAccountInfo {...formValues} onSubmit={onSubmit} />;
};

export default AccountInfo;
