import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import DialogTitle from "@mui/material/DialogTitle";
import Settings from "@mui/icons-material/Settings";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { makeStyles } from 'tss-react/mui';
import { theme } from "../../theme/Theme";
import { PatchAccountAdminParams, useGetUserInformation, usePatchAccountAdmin } from "../../hooks/useApi";
import { useAuthTokens, useAuth, useSetTokenAndFeatures } from "../../context/auth";
import { getLogger } from "../../utils/logger";
import * as Yup from "yup";
import DialogFormTitle from "../DialogFormTitle/DialogFormTitle";

export interface AccountSettingsComponentProps {
    modalShowState: boolean,
    isOpen: (item: boolean) => void,
    adminId: string,
    isSuperAdmin?: boolean,
}

const PASSWORD_MAX_LENGTH = 6;
const MESSAGE_FOR_PASSWORDS_DONT_MATCH = "Passwords don't match";

const getValidationSchema: (superAdmin?: boolean) => { [key: string]: Yup.AnySchema } = (superAdmin) => ({
    email: Yup.string()
        .email("Email is not in correct format")
        .required("Email is required"),
    firstName: Yup.string().required("Please fill First Name"),
    lastName: Yup.string().required("Please fill Last Name"),
    password: superAdmin ?
        Yup.string() :
        Yup.string()
            .required(PASSWORD_MAX_LENGTH + " symbols min")
            .min(PASSWORD_MAX_LENGTH, PASSWORD_MAX_LENGTH + " symbols min"),
    newPassword: Yup.string()
        .required(PASSWORD_MAX_LENGTH + " symbols min")
        .min(PASSWORD_MAX_LENGTH, PASSWORD_MAX_LENGTH + " symbols min")
});

type handleChangesKeys = "email" | "firstName" | "lastName" | "password" | "newPassword"
const log = getLogger();
const useStyles = makeStyles()({
    inputField: {
        height: "35px",
        lineHeight: "35px",
        color: theme.palette.primary.dark
    },
    textField: {
        height: "57px",
        borderRadius: "4px",
        "& .MuiFormLabel-root": {
            color: theme.palette.warning.main + "!important"
        },
        "& .MuiInputBase-input": {
            color: theme.palette.primary.dark + "!important"
        },
        "& .Mui-disabled": {
            color: theme.palette.warning.main + "!important"
        },
        margin: "15px 0"
    },
    errorText: {
        color: "red",
        fontSize: "20px",
        fontWeight: 500
    },
    successText: {
        color: "green",
        fontSize: "20px",
        fontWeight: 500
    },
});

const validateInitialObject = {
    email: { isValid: true, message: "" },
    firstName: { isValid: true, message: "" },
    lastName: { isValid: true, message: "" },
    password: { isValid: true, message: "" },
    newPassword: { isValid: true, message: "" },
    passNewAgain: { isValid: true, message: "" }
};

const AccountSettings = (
    {
        modalShowState,
        isOpen,
        adminId,
        isSuperAdmin,
    }: AccountSettingsComponentProps) => {
    const { classes } = useStyles();
    const [isPassChange, setPassChange] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [changePasswordIsVisible, setChangePasswordIsVisible] = useState<boolean>(true);
    const [enableChangingPasswordSaveState, setEnableChangingPasswordSaveState] = useState<boolean>(false);
    const [passwordAgain, setPasswordAgain] = useState<string>("");
    const { setAuthTokens } = useAuth();
    const authTokens = useAuthTokens();
    const [isPassChangeSuccess, setPassChangeSuccess] = useState<boolean>(false);
    const [enableValidate, setEnableValidate] = useState<boolean>(false);
    const [initialState, setInitialState] = useState<PatchAccountAdminParams>({
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        newPassword: "",
        adminId: ""
    });
    const { setTokenAndFeatures } = useSetTokenAndFeatures();

    const validationSchema = useMemo(() => {
        return getValidationSchema(isSuperAdmin);
    }, [isSuperAdmin]);
    const [validateInitialState, setValidateInitialState] = useState(validateInitialObject);

    const handleChange = (
        event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
        key: string
    ): void => {
        setInitialState((prev) => {
            return {
                ...prev,
                [key as handleChangesKeys]: event.target.value
            };
        });
        validationSchema[key].validate(event.target.value)
            .then(() => {
                changeValidateState(key, "", true);
            }).catch((validateError) => {
                changeValidateState(key, validateError.errors, false);
            });
        key === "newPassword" && passwordAgain.length && passwordAgain !== event.target.value ?
            changeValidateState("passNewAgain", MESSAGE_FOR_PASSWORDS_DONT_MATCH, false) :
            changeValidateState("passNewAgain", "", true);
        setChangePasswordIsVisible(false);
        checkAllPasswordsValue();
        setErrorMessage("");
    };

    const changeValidateState = (key: string, message: string, isValid: boolean): void => {
        setValidateInitialState((prev) => {
            return {
                ...prev,
                [key]: {
                    isValid: isValid,
                    message: message
                }
            };
        });
    };

    const setError = (error: string): void => {
        let msg = "Something went wrong!";

        switch (error) {
            case "ERROR_EMAIL_ALREADY_EXISTS": {
                msg = "This email is already taken";
                break;
            }
            case "ERROR_INCORRECT_PASSWORD": {
                msg = "Incorrect current password";
                break;
            }
        }

        setErrorMessage(msg);
    };

    const checkPasswordsMath = (
        event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ): void => {
        setPasswordAgain(event.target.value);
        event.target.value !== initialState.newPassword ?
            changeValidateState("passNewAgain", MESSAGE_FOR_PASSWORDS_DONT_MATCH, false) :
            changeValidateState("passNewAgain", "", true);
        checkAllPasswordsValue();
    };

    const checkAllPasswordsValue = (): void => {
        (isSuperAdmin ? true : initialState.password?.length) && initialState.newPassword?.length && passwordAgain.length ?
            setEnableChangingPasswordSaveState(true) :
            setEnableChangingPasswordSaveState(false);
    };

    const onClosePersonalDialog = (): void => {
        isOpen(false);
        setChangePasswordIsVisible(true);
        setValidateInitialState(validateInitialObject);
        setErrorMessage("");
    };

    const onClosePasswordsDialog = (): void => {
        setPassChangeSuccess(false);
        setPassChange(false);
        setValidateInitialState(validateInitialObject);
        setErrorMessage("");
        setPasswordAgain("");
        setChangePasswordIsVisible(true);
    };

    useEffect(() => {
        Object.values(validateInitialState).find(item => item.isValid === false) ?
            setEnableValidate(false) :
            setEnableValidate(true);
    }, [validateInitialState]);

    useEffect(() => {
        useGetUserInformation(adminId, setInitialState);
    }, [adminId]);

    return (
        <>
            <Dialog
                open={modalShowState}
                onClose={onClosePersonalDialog}
                aria-labelledby="setup-dialog-title"
                aria-describedby="setuo-dialog-description">
                <Box style={{ height: "fit-content", overflow: "hidden" }}>
                    <DialogTitle id="setup-dialog-title">
                        <DialogFormTitle
                            Icon={Settings}
                            text="Admin Settings"
                            marginBottom={4}
                        />
                    </DialogTitle>
                    <DialogContent id="adminContent">
                        <Box style={{ minWidth: "500px" }}>
                            <TextField
                                margin="dense"
                                name="adminEmail"
                                id="adminEmail"
                                label="Email"
                                value={initialState.email}
                                onChange={(event) => handleChange(event, "email")}
                                error={!validateInitialState.email.isValid}
                                helperText={validateInitialState.email.message}
                                type="text"
                                fullWidth
                                variant="outlined"
                                color="secondary"
                                className={classes.textField}
                                InputProps={{ classes: { input: classes.inputField } }}
                            />
                            <Box style={{ display: "flex", gap: "10px" }}>
                                <TextField
                                    margin="dense"
                                    name="firstName"
                                    id="firstName"
                                    label="First Name"
                                    value={initialState.firstName}
                                    onChange={(event) => handleChange(event, "firstName")}
                                    error={!validateInitialState.firstName.isValid}
                                    helperText={validateInitialState.firstName.message}
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    color="secondary"
                                    className={classes.textField}
                                    InputProps={{ classes: { input: classes.inputField } }}
                                />
                                <TextField
                                    margin="dense"
                                    name="lastName"
                                    id="lastName"
                                    label="Last Name"
                                    value={initialState.lastName}
                                    onChange={(event) => handleChange(event, "lastName")}
                                    error={!validateInitialState.lastName.isValid}
                                    helperText={validateInitialState.lastName.message}
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    color="secondary"
                                    className={classes.textField}
                                    InputProps={{ classes: { input: classes.inputField } }}
                                />
                            </Box>
                            <Box style={{ height: "28px" }}>
                                <span className={classes.errorText}
                                    style={{ display: errorMessage != "" ? "block" : "none" }}>{errorMessage}</span>
                            </Box>
                        </Box>
                    </DialogContent>
                    <DialogActions style={{ margin: "0 auto 10px auto", justifyContent: "center" }}>
                        <Button
                            onClick={onClosePersonalDialog}
                            color="secondary" autoFocus>
                            Cancel
                        </Button>
                        <Button
                            onClick={() => {
                                setErrorMessage("");
                                usePatchAccountAdmin({
                                    firstName: initialState.firstName,
                                    lastName: initialState.lastName,
                                    email: initialState.email,
                                    adminId: initialState.adminId
                                }, (data) => {
                                    setInitialState((prev) => {
                                        return {
                                            ...prev,
                                            lastName: data.admin.lastName,
                                            firstName: data.admin.firstName,
                                            email: data.admin.email
                                        };
                                    });
                                    if (authTokens && !authTokens?.superAdmin) {
                                        if (setAuthTokens) {
                                            setAuthTokens({
                                                ...authTokens,
                                                email: data.admin.email
                                            });
                                        }
                                    }
                                    setTokenAndFeatures();
                                    isOpen(false);
                                }, (error) => {
                                    setError(error);
                                    log.error(`error: ${error}`);
                                });
                            }}
                            id="setup_admin" color="secondary" variant="contained"
                            disabled={changePasswordIsVisible || !enableValidate}>
                            Save
                        </Button>
                    </DialogActions>

                    <Box style={{ padding: "0px 24px" }}>
                        <TextField
                            label="Password"
                            value={"*******"}
                            type="password"
                            fullWidth
                            variant="outlined"
                            color="secondary"
                            disabled
                            className={classes.textField}
                            InputProps={{
                                endAdornment: <Button onClick={() => {
                                    isOpen(false);
                                    setPassChange(true);
                                }} id="change_pass" color="secondary" variant="contained" style={{ width: "110px" }}
                                    disabled={!changePasswordIsVisible}>
                                    CHANGE
                                </Button>
                            }}
                        />
                    </Box>
                </Box>
            </Dialog>

            <Dialog
                open={isPassChange}
                onClose={onClosePasswordsDialog}
                aria-labelledby="pass-change-dialog-title"
                aria-describedby="pass-change-dialog-description"
            >
                <Box style={{ height: "fit-content", overflow: "hidden" }}>
                    <DialogTitle id="pass-change-dialog-title">
                        <DialogFormTitle
                            Icon={Settings}
                            text="Change Password"
                            marginBottom={4}
                        />
                    </DialogTitle>
                    <DialogContent>
                        <Box style={{ minWidth: "500px" }}>
                            {!isSuperAdmin && (
                                <TextField
                                    name="currectPass"
                                    id="password"
                                    label="Current Password"
                                    onChange={(event) => handleChange(event, "password")}
                                    type="password"
                                    fullWidth
                                    variant="outlined"
                                    color="secondary"
                                    className={classes.textField}
                                    error={!validateInitialState.password.isValid}
                                    helperText={validateInitialState.password.message}
                                />
                            )}
                            <TextField
                                name="newPassword"
                                id="passwordNew"
                                label="New Password"
                                onChange={(event) => handleChange(event, "newPassword")}
                                type="password"
                                fullWidth
                                variant="outlined"
                                color="secondary"
                                className={classes.textField}
                                error={!validateInitialState.newPassword.isValid}
                                helperText={validateInitialState.newPassword.message}
                            />
                            <TextField
                                name="passNewAgain"
                                id="passwordNewAgain"
                                label="Repeat New Password"
                                fullWidth
                                onChange={(event) => checkPasswordsMath(event)}
                                type="password"
                                variant="outlined"
                                color="secondary"
                                className={classes.textField}
                                error={!validateInitialState.passNewAgain.isValid}
                                helperText={validateInitialState.passNewAgain.message}
                            />
                            <Box style={{ height: "28px" }}>
                                <span className={classes.successText}
                                    style={{ display: isPassChangeSuccess ? "block" : "none" }}>Password changed successfully</span>
                                <span className={classes.errorText}
                                    style={{ display: errorMessage != "" ? "block" : "none" }}>{errorMessage}</span>
                            </Box>
                        </Box>
                    </DialogContent>
                    <DialogActions style={{ margin: "0 auto 10px auto", justifyContent: "center" }}>
                        <Button
                            onClick={onClosePasswordsDialog}
                            color="secondary"
                            autoFocus
                            style={{ display: !isPassChangeSuccess ? "block" : "none" }}>
                            Cancel
                        </Button>
                        <Button
                            onClick={() => {
                                setErrorMessage("");
                                usePatchAccountAdmin({
                                    firstName: initialState.firstName,
                                    lastName: initialState.lastName,
                                    email: initialState.email,
                                    adminId: initialState.adminId,
                                    password: !isSuperAdmin ? initialState.password : "1",
                                    newPassword: initialState.newPassword
                                }, () => {
                                    setPassChangeSuccess(true);
                                    setChangePasswordIsVisible(true);
                                }, (error) => {
                                    setError(error);
                                    log.error(`error message: ${error}`);
                                });
                            }}
                            color="secondary" variant="contained"
                            disabled={!enableValidate || !enableChangingPasswordSaveState}
                            style={{ display: !isPassChangeSuccess ? "block" : "none" }}
                        >
                            Save
                        </Button>
                        <Button
                            onClick={onClosePasswordsDialog}
                            color="secondary" variant="contained"
                            style={{ display: isPassChangeSuccess ? "block" : "none" }}
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    );
};

export default AccountSettings;