import { useEffect, useState } from 'react';
import { Navigate } from "react-router-dom";
import { useSetTokenAndFeatures } from "../../context/auth";

const InitialPage = () => {
    const [fetching, setFetching] = useState(true);
    const { setTokenAndFeatures } = useSetTokenAndFeatures();

    useEffect(() => {
        setTokenAndFeatures({
            finallyCallback: () => { setFetching(false); }
        });
    }, []);

    if (fetching) {
        return null;
    }

    return (
        <Navigate
            to="/manage-tests"
        />
    )
};

export default InitialPage;
