
import Typography from '@mui/material/Typography';
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from "@mui/material/Tooltip";
import makeStyles from '@mui/styles/makeStyles';
import { useEffect, useState } from "react";
import { theme } from '../../theme/Theme';
import Box from '@mui/material/Box';
import TablePagination from '@mui/material/TablePagination';
import EmailIcon from '@mui/icons-material/Email';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import MUIDataTable, { MUIDataTableOptions, MUIDataTableState, Responsive, SelectableRows } from 'mui-datatables';
import { Accuracy, NetWpm, OrderBy, SortBy } from '../../hooks/useApi';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import JumpToPage from '../JumpToPage/JumpToPage';
import { Link } from "react-router-dom";
import { useFeature } from 'flagged';
import { getLogger } from "../../utils/logger";
import ResultTableCellNetSpeed from '../ResultTableCellNetSpeed/ResultTableCellNetSpeed';
import ResultTableCellGrossSpeed from '../ResultTableCellGrossSpeed/ResultTableCellGrossSpeed';
import ResultTableCellAccuracy from '../ResultTableCellAccuracy/ResultTableCellAccuracy';
import ResultTableCellVsAvg from '../ResultTableCellVsAvg/ResultTableCellVsAvg';
import ResultTableCellStatus from '../ResultTableCellStatus/ResultTableCellStatus';

const log = getLogger();

const HIDDEN_EMAIL_COLUMN = 1;

type Candidate = {
  fullName: string;
  email: string;

}

type Info = {
  info1: string;
  info2: string;
}

export type ResultTableRowData = {
  fullNameForCsv: string;
  emailForCsv: string;
  netWpmForCsv: string;
  accuracyForCsv: string;
  info1ForCsv: string;
  info2ForCsv: string;
  candidate: Candidate;
  status: string;
  netWpm: NetWpm;
  netWpmToAvgPercentage: string;
  accuracy: Accuracy;
  grossWpm: string;
  createdAt: string;
  lastResultDate: string;
  attempts: number;
  info: Info;
  testName?: string;
  testId?: string;
  errorCount?: number;
  hits?: number;
};

export type ResultTableData =
  ResultTableRowData[];



export interface TestResultTableProps {
  candidateClicked: (email: string, index: number) => void;
  tableData: ResultTableData;
  setPage: (page: number) => void;
  setSize: (size: number) => void;
  count: number;
  isLoading: boolean;
  page: number;
  sortUpdate: (sortBy: SortBy, orderBy: OrderBy) => void;
  searchChange: (search: string | null) => void;
  downloadCSV: () => void;
}

const useStyles = makeStyles({
  mainBox: {
    margin: "40px 40px 40px 40px",
    background: theme.palette.primary.light,
    minWidth: "1500px",
    position: "relative",

    '& button[data-testid="Search-iconButton"]': {
      position: "absolute",
      left: "10px",
      top: "6px",
    },

    '& .MUIDataTableSearch-searchIcon-2429': {
      visibility: "false",
      opacity: 0,
    },

    '& thead tr th': {
      background: "#ECECEC !important",
    },

    '& tbody tr': {
      cursor: "pointer",
    },
  },
  header: {
    color: theme.palette.primary.dark,
    fontSize: "14px",
  },
  gridRoot: {
    minWidth: "1500px",
    overflow: "auto",
    '& .MuiDataGrid-columnSeparator': {
      display: "none",
    },
    "& :hover": {

    },

    '& .MuiDataGrid-row:hover .MuiSvgIcon-root': {
      color: theme.palette.text.primary,
    },

  },
  accountIcon: {
    fontSize: "32px",
    color: theme.palette.text.secondary,
    '&:hover': {
      color: theme.palette.text.primary,
    }
  },
  accountName: {
    fontSize: "16px",
    color: theme.palette.primary.dark,
  },
  accountEmail: {
    fontSize: "16px",
    color: theme.palette.text.secondary,
  },
  fieldBig: {
    fontSize: "24px",
    color: theme.palette.primary.dark,
  },
  fieldSmall: {
    fontSize: "16px",
    color: theme.palette.primary.dark,
  },
  fieldSmallNorm: {
    fontSize: "16px",
  },
  arrowIcon: {
    position: "absolute",
    color: theme.palette.text.secondary,
    fontSize: "30px",
    top: "50%",
    right: "0px",
    transform: "translateY(-50%)",
  },
  count: {
    position: "absolute",
    left: "25px",
    bottom: "20px",
    fontSize: "14px",
    color: theme.palette.text.secondary,
  },
  downloadIcon: {
    cursor: "pointer",
    fill: theme.palette.text.secondary,
    transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    '&:hover': {
      fill: theme.palette.text.primary,
    }
  },
  customPagination: {
    float: "right",
  },
  searchInput: {
    borderBottom: '1px solid #3F51B5',
    marginLeft: "30px",
    width: "320px"
  }
});

const TestResultTable = (
  {
    candidateClicked,
    tableData,
    setSize,
    setPage,
    count,
    isLoading,
    page,
    sortUpdate,
    searchChange,
    downloadCSV,
  }: TestResultTableProps) => {
  const classes = useStyles();
  const [disableRowClick, setDisableRowClick] = useState<boolean>(false)
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [data, setData] = useState<ResultTableData>();
  const hideFractional = useFeature('s_hide_fractional') as boolean;

  const getCandidateField = (value: Candidate | undefined) => {
    return (
      <div style={{ minWidth: "250px", display: "flex" }} >
        <div>
          <Link
            title="Send Email"
            to='#'
            onClick={(e) => {
              if (value && value.email) {
                window.location.href = `mailto:${value.email}`;
              }
              e.preventDefault();
            }}
          >
            <EmailIcon className={classes.accountIcon}
              onMouseOver={() => {
                setDisableRowClick(true)
              }} onMouseOut={() => {
                setDisableRowClick(false)
              }} />
          </Link>
        </div>
        <div style={{ wordBreak: "break-all", paddingLeft: "15px" }}>
          <Typography className={classes.accountName}>{value?.fullName}</Typography>
          <Typography className={classes.accountEmail}>{value?.email}</Typography>
        </div>
      </div >
    );
  }

  const enUsYyMmDdFormatter = new Intl.DateTimeFormat('en-GB', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });

  const enUsHhMmFormatter = new Intl.DateTimeFormat('en-US', {
    hour: 'numeric',
    minute: '2-digit',
  });

  const getDateTimeField = (value: string) => {
    const date = new Date(value);
    let formattedYyMmDd = "";
    let formattedHhMm = "";

    try {
      formattedYyMmDd = enUsYyMmDdFormatter.format(date);
      formattedHhMm = enUsHhMmFormatter.format(date).toLowerCase();
    } catch (error) {
      log.debug(error);
    }

    return (
      <div>
        <Typography className={classes.fieldSmall}>
          {formattedYyMmDd}
        </Typography>
        <Typography style={{ fontSize: "16px", color: theme.palette.text.secondary }}>
          {formattedHhMm}
        </Typography>
      </div>
    );
  }

  const getAttemptsField = (value: number) => {
    return (
      <Typography className={classes.fieldSmall}>{value}</Typography>
    );
  }

  const getCustomField = (info: Info) => {
    return (
      <div style={{ position: "relative", width: "100%" }}>
        <div style={{ width: "80%", wordBreak: "break-all" }}>
          <Typography className={classes.fieldSmall}>{info.info1}</Typography>
          <Typography className={classes.fieldSmall}>{info.info2}</Typography>
        </div>
        <div style={{ width: "20%" }}>
          <ArrowForwardIcon className={classes.arrowIcon} />
        </div>
      </div>
    );
  }

  const columns = [
    {
      name: "fullNameForCsv",
      label: "Full Name",
      options: { display: false, sort: false, download: true, print: false, searchable: false, viewColumns: false, filter: false }
    },
    {
      name: "emailForCsv",
      label: "Email",
      options: { display: false, sort: false, download: true, print: false, searchable: false, viewColumns: false, filter: false }
    },
    {
      name: "candidate",
      label: "Candidate",
      options: {
        download: false,
        customBodyRender: (candidate: Candidate) => {
          return getCandidateField(candidate);
        },
        sortThirdClickReset: true,
        sortCompare: (order: string) => {
          return (obj1: { data: { fullName: string } }, obj2: { data: { fullName: string } }) => {
            const val1 = obj1.data.fullName.toUpperCase();
            const val2 = obj2.data.fullName.toUpperCase();
            if (val1 < val2) {
              const ret = (order === 'asc' ? -1 : 1);
              return ret;
            }
            if (val1 > val2) {
              const ret = (order === 'asc' ? 1 : -1);
              return ret;
            }
            return 0;
          };
        }
      },
    },
    {
      name: "status",
      label: "Status",
      options: { display: false, sort: false, download: true, print: false, searchable: false, viewColumns: false, filter: false }
    },
    {
      name: "status",
      label: "Status",
      options: {
        download: false,
        customBodyRender: (status: string) => {
          return <ResultTableCellStatus status={status} />;
        },
        sortThirdClickReset: true,
      },
    },
    {
      name: "netWpmForCsv",
      label: "netWpm",
      options: { display: false, sort: false, download: true, print: false, searchable: false, viewColumns: false, filter: false }
    },
    {
      name: "netWpm",
      label: "Net Speed",
      options: {
        download: false,
        customBodyRender: (netWpm: NetWpm) => {
          return <ResultTableCellNetSpeed netWpmObj={netWpm} hideFractional={hideFractional} />;
        },
        sortThirdClickReset: true,
        sortCompare: (order: string) => {
          return (obj1: { data: NetWpm }, obj2: { data: NetWpm }) => {
            const val1 = parseInt(obj1.data.netWpm, 10);
            const val2 = parseInt(obj2.data.netWpm, 10);
            return (val1 - val2) * (order === 'asc' ? 1 : -1);
          };
        }
      },
    },
    {
      name: "netWpmToAvgPercentage",
      label: "vs Avg.",
      options: {
        searchable: false,
        customBodyRender: (value: string) => {
          return <ResultTableCellVsAvg value={value} />;
        },
        sortThirdClickReset: true,
        sortCompare: (order: string) => {
          return (obj1: { data: string }, obj2: { data: string }) => {
            const val1 = parseInt(obj1.data, 10);
            const val2 = parseInt(obj2.data, 10);
            return (val1 - val2) * (order === 'asc' ? 1 : -1);
          };
        }
      },
    },
    {
      name: "accuracyForCsv",
      label: "Accuracy",
      options: { display: false, sort: false, download: true, print: false, searchable: false, viewColumns: false, filter: false }
    },
    {
      name: "accuracy",
      label: "Accuracy",
      options: {
        searchable: false,
        download: false,
        customBodyRender: (value: Accuracy) => {
          return <ResultTableCellAccuracy accuracyObj={value} />;
        },
        sortThirdClickReset: true,
        sortCompare: (order: string) => {
          return (obj1: { data: { accuracy: string } }, obj2: { data: { accuracy: string } }) => {
            const val1 = parseInt(obj1.data.accuracy, 10);
            const val2 = parseInt(obj2.data.accuracy, 10);
            return (val1 - val2) * (order === 'asc' ? 1 : -1);
          };
        }
      },
    },
    {
      name: "grossWpm",
      label: "grossWpm",
      options: { display: false, sort: false, download: true, print: false, searchable: false, viewColumns: false, filter: false }
    },
    {
      name: "grossWpm",
      label: "Gross Speed", options: {
        download: false,
        searchable: false,
        customBodyRender: (value: string) => {
          return <ResultTableCellGrossSpeed grossWpm={value} />;
        },
        sortThirdClickReset: true,
        sortCompare: (order: string) => {
          return (obj1: { data: string }, obj2: { data: string }) => {
            const val1 = parseInt(obj1.data, 10);
            const val2 = parseInt(obj2.data, 10);
            return (val1 - val2) * (order === 'asc' ? 1 : -1);
          };
        }
      },
    },
    {
      name: "hits",
      label: "Hits",
      sortThirdClickReset: true,
    },
    {
      name: "errorCount",
      label: "Errors",
      sortThirdClickReset: true,
    },
    {
      name: "createdAt",
      label: "Date & Time (UTC)",
      options: { display: false, sort: false, download: true, print: false, searchable: false, viewColumns: false, filter: false }
    },
    {
      name: "createdAt",
      label: "Best Result", options: {
        download: false,
        searchable: false,
        customBodyRender: (value: string) => {
          return getDateTimeField(value);
        },
        sortThirdClickReset: true,
      },
    },
    {
      name: "lastResultDate",
      label: "Last Use", options: {
        download: false,
        searchable: false,
        customBodyRender: (value: string) => {
          return getDateTimeField(value);
        },
        sortThirdClickReset: true,
      },
    },
    {
      name: "attempts",
      label: "Attempts", options: {
        searchable: false,
        customBodyRender: (value: number) => {
          return getAttemptsField(value);
        },
        sortThirdClickReset: true,
      },
    },
    {
      name: "info",
      label: "Additional Fields",
      options: {
        download: false,
        searchable: false,
        customBodyRender: (value: Info) => {
          return getCustomField(value);
        },
        sort: false,
      },
    },
    {
      name: "info1ForCsv",
      label: "Info 1",
      options: { display: false, sort: false, download: true, print: false, searchable: false, viewColumns: false, filter: false }
    },
    {
      name: "info2ForCsv",
      label: "Info 2",
      options: { display: false, sort: false, download: true, print: false, searchable: false, viewColumns: false, filter: false }
    },
  ];

  const customToolbar = () => {

    const handlePageChange = (event: any, newPage: number) => {
      log.debug("Change Page!");
      log.debug(newPage);
      setPage(newPage);
    }

    const handleRowsChange = (event: any) => {
      setSize(parseInt(event.target.value, 10));
      setRowsPerPage(parseInt(event.target.value, 10));
    }

    return (
      <div style={{ display: "flex", float: "right" }}>
        <Tooltip title=" Download All Results (csv)">
          <Box display={"flex"} style={{ alignItems: "center" }}>
            <CloudDownloadIcon className={classes.downloadIcon}
              onClick={() => {
                downloadCSV();
              }} />
            <a style={{ marginLeft: "10px" }} href={"#"} onClick={downloadCSV}>Export Data</a>
          </Box>
        </Tooltip>
        <JumpToPage

          count={count}
          page={page}
          rowsPerPage={rowsPerPage}
          changePage={handlePageChange}
        />
        <TablePagination
          component="div"
          count={count}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={handlePageChange}
          rowsPerPageOptions={[10, 25, 50, 75, 100]}
          onRowsPerPageChange={handleRowsChange}
          className={classes.customPagination}
        />
      </div>
    );
  }

  const options: MUIDataTableOptions = {
    selectableRows: 'none' as SelectableRows,
    enableNestedDataAccess: '.',
    onRowClick: (rowData: string[], { rowIndex }) => {
      if (!disableRowClick) {
        candidateClicked(rowData[HIDDEN_EMAIL_COLUMN], rowIndex);
      }
    },
    download: false,
    /*
    onDownload: () => {
      downloadCSV();
      return false;
    },
    */
    onSearchChange: (searchText: string | null) => {
      log.debug(`onSearchChange() - searchText: ${searchText}`);
      searchChange(searchText);
    },
    onTableChange: (action: string, tableState: MUIDataTableState) => {
      switch (action) {
        case 'changePage':
          log.debug(`onTableChange() - action: ${action}, page: ${tableState.page}, sortOrder: ${JSON.stringify(tableState.sortOrder)}`);
          setPage(tableState.page);
          break;
        case 'sort':
          log.debug(`onTableChange() - action: ${action}, page: ${tableState.page}, sortOrder: ${JSON.stringify(tableState.sortOrder)}`);
          break;
        case 'changeRowsPerPage':
          log.debug(`onTableChange() - action: ${action}, page: ${tableState.page}, sortOrder: ${JSON.stringify(tableState.sortOrder)}`);
          setSize(tableState.rowsPerPage);
          break;
        default:
          log.debug(`onTableChange() - action not handled: ${action}`);
          break;
      }
    },
    responsive: 'simple' as Responsive,
    filter: false,
    viewColumns: false,
    print: false,
    search: true,
    searchOpen: true,
    searchPlaceholder: 'Search Candidates',
    textLabels: {
      body: {
        noMatch: "No rows",
      }
    },
    serverSide: true,
    count: count,
    page: page,
    rowsPerPage: rowsPerPage,
    rowsPerPageOptions: [10, 25, 50, 75, 100],
    onChangeRowsPerPage: (newRowsPerPage) => {
      setRowsPerPage(newRowsPerPage);
    },
    jumpToPage: true,
    onColumnSortChange: (changedColumn: string, direction: "asc" | "desc") => {
      const convertColumn = (changedColumn: string): SortBy => {
        if (changedColumn === "createdAt") {
          return "date" as SortBy;
        }
        if (changedColumn === "candidate") {
          return "name" as SortBy;
        }
        if (changedColumn === "netWpmToAvgPercentage") {
          return "vsAvg" as SortBy;
        }
        return changedColumn as SortBy;
      }
      const order: OrderBy = direction;

      const sort: SortBy = convertColumn(changedColumn);
      log.debug(`onColumnSortChange() - sort: ${sort}, order: ${order}`);
      sortUpdate(sort, order);
    },

    customToolbar: () => { return customToolbar(); },
    elevation: 0,
  };

  useEffect(() => {
    setData(tableData)
  }, [tableData])

  return (
    <Box className={classes.mainBox}>
      <>
        {isLoading && (
          <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
            <CircularProgress disableShrink />
          </div>
        )}
        {data && (
          <MUIDataTable
            title={""}
            data={data}
            columns={columns}
            options={options}
          />
        )}
      </>
      <Tooltip title=" Download All Results (csv)">
        <Box display={"flex"} style={{
          position: "absolute",
          bottom: "14px",
          left: "30px",
        }}>
          <CloudDownloadIcon className={classes.downloadIcon}
            onClick={() => {
              downloadCSV();
            }} />
          <a style={{ marginLeft: "10px" }} href={"#"} onClick={downloadCSV}>Export Data</a>
        </Box>
      </Tooltip>
    </Box>
  );
};

export default TestResultTable;
