import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthTokens } from "../../context/auth";
import Container from "@mui/material/Container";
import TestsHeader from "../TestsHeader/TestsHeader";
import {
    useGetTests,
    GetTestsData,
    useDeleteTest,
    useActivateTest
} from "../../hooks/useApi";
import TestCardContainer from "../TestCardContainer/TestCardContainer";
import { TestCardAction } from "../TestCard/TestCard";
import { usePreview } from "../../hooks/usePreview";
import { useLogout } from "../../hooks/useLogout";
import { getTestLinkWithAccountDomain } from "../../utils/accountDomain";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { getLogger } from "../../utils/logger";
import useSubscriptionInfo from "../../hooks/useSubscriptionInfo";
import WarningMessage from "../WarningMessage/WarningMessage";

const log = getLogger();

export interface PatchResponseData {
    status: string;
    test: {
        name: string;
        active: boolean;
        testUrl: string;
        testId: string;
    };
}

type TestData = GetTestsData & {
    onAction: (testId: string, action: TestCardAction) => void;
    onActiveChange: (testId: string, active: boolean) => void;
    cardIndex: number;
    onTestAdded: () => void;
    setError: (status: string) => void;
}

const errorStyle = {
    error: {
        border: "1px solid #e81a2f",
        padding: "10px",
        color: "#000000",
        fontWeight: 400,
        background: "#ffcfcf",
        marginBottom: "10px",
        display: "flex",
        alignItems: "flex-end"
    },
    warning: {
        border: "1px solid #fae290",
        padding: "10px",
        color: "#b77d57",
        fontWeight: 600,
        background: "#fffbeb",
        marginBottom: "10px",
        display: "flex",
        alignItems: "flex-end"
    }
};

const TestInfo = () => {
    const [tests, setTests] = useState<TestData[] | []>([]);
    const { subscriptionIsAvailable, candidatesExceeded } = useSubscriptionInfo();
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [showPlansButton, setShowPlanButton] = useState<boolean>(false);
    const navigate = useNavigate();
    const authTokens = useAuthTokens();

    const setDecoratedTests = (tests: GetTestsData[]): void => {
        const newTests: TestData[] = [];
        const accountDomain = authTokens?.accountDomain ? authTokens.accountDomain : "";
        for (let index = 0; index < tests.length; index++) {
            const newTest: TestData = {
                name: tests[index].name,
                testUrl: getTestLinkWithAccountDomain(accountDomain, tests[index].testId),
                testId: tests[index].testId,
                active: tests[index].active,
                attempts: tests[index].attempts,
                duration: tests[index].duration,
                limits: {
                    inUse: tests[index].limits.inUse,
                    shownToCandidate: tests[index].limits.shownToCandidate,
                    netWpm: tests[index].limits.netWpm,
                    accuracy: tests[index].limits.accuracy
                },
                textSelection: tests[index].textSelection,
                customText: tests[index].customText,
                useCustomText: tests[index].useCustomText,
                note: tests[index].note,
                infoFieldOne: tests[index].infoFieldOne,
                infoFieldTwo: tests[index].infoFieldTwo,
                sendResultsByEmail: tests[index].sendResultsByEmail,
                resultRecipients: tests[index].resultRecipients,
                text: tests[index].text,
                textId: tests[index].textId,
                statistics: {
                    candidates: tests[index].statistics.candidates,
                    passed: tests[index].statistics.passed,
                    notPassed: tests[index].statistics.notPassed,
                    sevenDays: tests[index].statistics.sevenDays
                },
                createdAt: tests[index].createdAt,
                onAction: (testId: string, action: TestCardAction): void => {
                    log.info(`onAction() - testId: ${testId}, action: ${action}`);
                    switch (action) {
                        case TestCardAction.delete: {
                            useDeleteTest({ testId }, (remainingTests) => {
                                setDecoratedTests(remainingTests);
                            });
                            break;
                        }
                        case TestCardAction.edit: {
                            useGetTests(
                                setDecoratedTests,
                                (error) => {
                                    log.error(`useEffect() - error: ${JSON.stringify(error, null, 2)}`);
                                    useForceLogout();
                                });
                            break;
                        }
                        case TestCardAction.results: {
                            sessionStorage.setItem("testId", testId);
                            navigate("/testresults");
                            break;
                        }
                        case TestCardAction.preview: {
                            usePreview(testId);
                            break;
                        }
                    }
                },
                onActiveChange: async (testId: string, active: boolean): Promise<void> => {
                    log.info(`onActiveChange() - testId: ${testId}, active: ${active}`);
                    const testIndex = tests.findIndex((test) => {
                        return test.testId === testId;
                    });
                    if (testIndex === -1) {
                        log.error(`onActiveChange() - no test found from index ${testIndex}`);
                        return;
                    }
                    log.info(`onActiveChange() - updating test from index ${testIndex}`);
                    const newTests = tests;
                    newTests[testIndex].active = active;
                    setDecoratedTests(newTests);
                    await useActivateTest(testId, active, () => {
                        log.info('onActiveChange() - updating succeeded');
                    }, (status) => {
                        newTests[testIndex].active = !active;
                        setDecoratedTests(newTests);
                        setError(status);
                    });
                },
                cardIndex: index,
                onTestAdded: testAdded,
                setError: setError
            };
            newTests.push(newTest);
        }
        setTests(newTests);
    };

    const [useForceLogout] = useLogout();

    const setError = (status: string) => {
        setShowPlanButton(false);
        switch (status) {
            case "LIMIT_REACHED_FOR_ADDING":
                setShowPlanButton(true);
                setErrorMessage("Your current plan does not permit the creation of additional tests. To create a new test, you will need to disable or remove an existing one. Alternatively, you may consider upgrading to a higher plan that offers more test creation capabilities. Please visit our plans page to explore your options for upgrading.");
                break;
            case "LIMIT_REACHED_FOR_UPDATING":
                setShowPlanButton(true);
                setErrorMessage("Your current plan does not permit the activation of additional tests. To activate this test, you will need to disable or remove an existing one. Alternatively, you may consider upgrading to a higher plan that offers more test creation capabilities. Please visit our plans page to explore your options for upgrading.");
                break;
            case "ERROR_LOGIN_FIRST":
                setErrorMessage("Please Log in.");
                break;
            case "ERROR_ACCOUNT_NOT_FOUND":
                setErrorMessage("User not found.");
                break;
            case "ACCOUNT_DONT_HAVE_SUBSCRIPTION":
                setErrorMessage("Account does not have subscription.");
                break;
            default:
                setErrorMessage("Unknown error, please try again later");
        }
    };

    const closeDialog = (): void => {
        setErrorMessage("");
    };

    const testAdded = (): void => {
        useGetTests(
            setDecoratedTests,
            (error) => {
                log.debug(`useEffect() - error: ${JSON.stringify(error, null, 2)}`);
                useForceLogout();
            });
    };

    useEffect(() => {
        try {
            useGetTests(
                setDecoratedTests,
                (error) => {
                    log.debug(`useEffect() - error: ${JSON.stringify(error, null, 2)}`);
                    useForceLogout();
                });

        } catch (error) {
            log.debug(`useEffect() - error: ${JSON.stringify(error, null, 2)}`);
        }
    }, []);

    return (
        <Container maxWidth="lg">
            <WarningMessage
                subscriptionIsAvailable={subscriptionIsAvailable}
                candidatesExceeded={candidatesExceeded}
            />
            <TestsHeader
                testCount={tests.length}
                handleAdd={() => {
                    return;
                }}
                onTestAdded={testAdded}
                setError={setError}
            />
            <TestCardContainer cards={tests} />

            <Dialog
                open={errorMessage !== ""}
                onClose={closeDialog}
                aria-labelledby="error-message"
                aria-describedby="errorMessage-description"
            >
                <DialogTitle id="error-message" style={{ textAlign: "center" }}>
                    Oops! Your plan limit has been reached.
                </DialogTitle>
                <DialogContent style={{ width: "600px", textAlign: "center" }}>
                    <DialogContentText id="errorMessage-description">
                        {errorMessage}
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ margin: "0 auto 20px auto" }}>
                    {showPlansButton && (
                        <Button
                            color="secondary"
                            variant="outlined"
                            onClick={() => {
                                navigate("/plans")
                            }}
                        >
                            View plans
                        </Button>
                    )}
                    <Button id="close_add_dialog" color="secondary" variant="contained" onClick={closeDialog}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

        </Container>
    );
};

export default TestInfo;
