import React from "react";
import TestSelectWizard from "../../components/TestSelect/TestSelectWizard";

const TestSelect = () => (
    <div>
        <TestSelectWizard migrated={false} />
    </div>
);

export default TestSelect;
