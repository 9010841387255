import type { SvgIconComponent } from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles<{ fontSize: number, marginBottom: number }>()((theme, { fontSize, marginBottom }) => ({
    root: {
        display: "flex",
        alignItems: "center",
        marginBottom: `${marginBottom}px`,
    },
    title: {
        display: "inline-block",
        fontSize: `${fontSize}px`,
        color: theme.palette.text.primary,
    },
    icon: {
        width: "49px",
        height: "49px",
        color: theme.palette.text.primary,
        marginRight: "10px"
    },
}));

interface DialogFormTitleProps {
    Icon: SvgIconComponent;
    text: string;
    fontSize?: number;
    marginBottom?: number;
}

const DialogFormTitle = ({ Icon, text, fontSize = 34, marginBottom = 20 }: DialogFormTitleProps) => {
    const { classes } = useStyles({ fontSize, marginBottom });
    return (
        <div className={classes.root}>
            <Icon className={classes.icon} />
            <Typography className={classes.title}>{text}</Typography>
        </div>
    );
};

export default DialogFormTitle;
