import { useState, useEffect } from "react";

const useConnectionStatus = () => {
    const [status, setStatus] = useState<"online" | "offline">("online");

    useEffect(() => {
        const setOffline = () => {
            setStatus("offline");
        };
        const setOnline = () => {
            setStatus("online");
        };

        window.addEventListener("offline", setOffline);
        window.addEventListener("online", setOnline);

        return () => {
            window.removeEventListener("offline", setOffline);
            window.removeEventListener("online", setOnline);
        };
    }, []);

    return { connectionStatus: status, isOffline: status === "offline" };
};

export default useConnectionStatus;
