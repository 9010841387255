import Box from '@mui/material/Box';
import React, { RefObject, useEffect } from "react";
import { CSVLink } from "react-csv";
import { GetCsvTestResultsResponse } from '../../hooks/useApi';

let headers = [
  { label: "First Name", key: "firstName" },
  { label: "Last Name", key: "lastName" },
  { label: "Email", key: "email" },
  { label: "Status", key: "status" },
  { label: "netWpm", key: "netWpm" },
  { label: "Accuracy", key: "accuracy" },
  { label: "grossWpm", key: "grossWpm" },
  { label: "Hits", key: "hits" },
  { label: "Errors", key: "errorCount" },
  { label: "Date & Time (UTC)", key: "createdAt" },
  { label: "Attempts", key: "attempts" },
  { label: "Info 1", key: "info1" },
  { label: "Info 2", key: "info2" },
];

export interface CsvDownloadProps {
  csvLink: RefObject<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>;
  csvData: GetCsvTestResultsResponse["results"];
  testName?: boolean
}

const CsvDownload = ({ csvLink, csvData, testName = false }: CsvDownloadProps) => {

  useEffect(() => {
    if (testName) {
      headers.push({ label: "Test", key: "testName" })
    } else {
      headers = headers.filter(header => header.label !== "Test")
    }
  }, [csvData])

  return (
    <Box display="none">
      <CSVLink
        filename={"testresults.csv"}
        data={csvData}
        headers={headers}
        ref={csvLink}>
        Download me
      </CSVLink>;
    </Box>
  )
};

export default CsvDownload;
