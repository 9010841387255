import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import { makeStyles } from 'tss-react/mui';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import { Form, withFormik } from "formik";
import { FormikProps } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import TableHead from "@mui/material/TableHead";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
    GetAccountAdminsData,
    useDeleteAdmin,
    useGetAccountAdmins,
    usePostAdminInvitation
} from "../../hooks/useApi";
import { theme } from "../../theme/Theme";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Settings from "@mui/icons-material/Settings";
import { Email } from "@mui/icons-material";
import { convertToMinutes } from "../../utils/time";
import { getLogger } from "../../utils/logger";
import AccountSettings from "../AccountSettings/AccountSettings";
import { useNavigate } from "react-router-dom";
import { useAuthTokens, useAuth } from "../../context/auth";
import DialogFormTitle from "../DialogFormTitle/DialogFormTitle";

const log = getLogger();

const useStyles = makeStyles()({
    root: {
        width: "100%",
        boxShadow: "none",
        border: "1px solid #0000001F"
    },
    table: {
        width: "100%"
    },
    tableHeader: {
        fontSize: "14px",
        color: theme.palette.primary.dark,
        fontWeight: 600

    },
    accountIcon: {
        fontSize: "32px",
        color: theme.palette.text.secondary,
        "&:hover": {
            color: theme.palette.text.primary
        }
    },
    accountName: {
        fontSize: "16px",
        color: theme.palette.primary.dark,
        textIndent: "10px",
        lineHeight: "32px"
    },
    email: {
        fontSize: "16px",
        color: theme.palette.primary.dark,
        lineHeight: "32px"
    },
    date: {
        fontSize: "16px",
        color: theme.palette.primary.dark,
        lineHeight: "16px"
    },
    hours: {
        fontSize: "16px",
        color: theme.palette.text.secondary
    },
    statusYou: {
        width: "60px",
        height: "27px",
        border: "2px solid #02BA7C",
        borderRadius: "40px",
        color: "#02BA7C",
        marginTop: "4px",
        marginLeft: "10px"
    },
    statusInvited: {
        width: "114px",
        height: "27px",
        border: "2px solid #ADADAD",
        borderRadius: "40px",
        color: "#ADADAD",
        marginTop: "2px"
    },
    statusPrimary: {
        width: "114px",
        height: "27px",
        border: "2px solid #3F51B5",
        borderRadius: "40px",
        color: "#3F51B5",
        marginTop: "4px",
        marginLeft: "10px"
    },
    statusSpan: {
        lineHeight: "27px",
        display: "block",
        textAlign: "center",

        fontSize: "14px",
        fontWeight: 700,
        marginTop: "-1px"
    },
    lastCell: {
        width: "60px"
    },
    plusIcon: {
        width: "20px",
        height: "20px"
    },
    boxField: {
        marginTop: "15px",
        marginBottom: "15px"
    },
    inputField: {
        height: "35px",
        lineHeight: "35px",
        color: theme.palette.primary.dark
    },
    textField: {
        height: "57px",
        borderRadius: "4px",
        "& .MuiFormLabel-root": {
            color: theme.palette.warning.main + "!important"
        },
        "& .MuiInputBase-input": {
            color: theme.palette.primary.dark + "!important"
        },
        "& .Mui-disabled": {
            color: theme.palette.warning.main + "!important"
        },
        margin: "15px 0"
    },
    errorText: {
        color: "red",
        fontSize: "20px",
        fontWeight: 500
    },
    successText: {
        color: "green",
        fontSize: "20px",
        fontWeight: 500
    }

});

interface InviteAdminFormValues {
    email: string;
    inviteFailed: boolean;
    closeError: () => void;
    handleCloseInvite: () => void;
}

interface InviteAdminFormProps {
    email?: string;
    inviteFailed?: boolean;
    closeError: () => void;
    handleCloseInvite: () => void;
}

const validationSchema = Yup.object({
    email: Yup.string()
        .email()
        .required("Email is required")
        .min(2, "Email is too short")
        .max(100, "Email should be no longer than 100 characters")
});

const InviteAdminForm = (props: FormikProps<InviteAdminFormValues>) => {
    const { values, errors, touched, dirty, handleChange, handleBlur, handleSubmit } = props;
    const { classes } = useStyles();

    return (
        <Box>
            <Form onSubmit={handleSubmit}>
                <TextField
                    margin="dense"
                    name="email"
                    id="inviteEmail"
                    label="Email"
                    value={values.email}
                    type="text"
                    helperText={
                        errors.email && touched.email
                            ? errors.email
                            : " "
                    }
                    fullWidth
                    variant="outlined"
                    color="secondary"
                    error={errors.email && touched.email ? true : false}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={classes.boxField}
                    InputProps={{ classes: { input: classes.inputField } }}
                />
                <Box style={{ display: "flex", justifyContent: "center", gap: "20px" }}>
                    <Button onClick={() => {
                        props.values.handleCloseInvite();
                    }} color="secondary" autoFocus>
                        Cancel
                    </Button>
                    <Button type="submit" disabled={!dirty} id="btn_send_invitation" color="secondary"
                        variant="contained">
                        SEND INVITATION
                    </Button>
                </Box>
            </Form>
            <Dialog
                open={props.values.inviteFailed}
                onClose={props.values.closeError}
                aria-labelledby="invite-error-dialog-title"
                aria-describedby="invite-error-dialog-description"
            >
                <DialogTitle id="invite-error-dialog-title" style={{ textAlign: "center" }}>Email is already in
                    use</DialogTitle>
                <DialogContent>
                    <DialogContentText id="invite-error-dialog-description">
                        User with this email address is already registered as an administrator on another TypingTest Pro
                        account. Please use another email address.
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ margin: "0 auto 20px auto" }}>
                    <Button id="delete_admin" color="secondary" variant="contained" onClick={props.values.closeError}>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

interface InviteAdminProps {
    onAdminInvited: (status: string) => void;
    inviteFailed: boolean;
    closeError: () => void;
    handleCloseInvite: () => void;
}

const InviteAdmin = (props: InviteAdminProps) => {
    const InviteAdminWithFormik = withFormik<InviteAdminFormProps, InviteAdminFormValues>({
        mapPropsToValues: ({ email, inviteFailed, closeError, handleCloseInvite }) => {
            return {
                email: email || "",
                inviteFailed: inviteFailed || false,
                closeError: closeError,
                handleCloseInvite: handleCloseInvite
            };
        },
        handleSubmit: async (values: InviteAdminFormValues): Promise<void> => {
            try {
                log.debug(`handleSubmit() - submitting values: ${JSON.stringify(values, null, 2)}`);
                usePostAdminInvitation(values.email, (status) => {
                    log.debug(`handleSubmit() - response: ${JSON.stringify(status, null, 2)}`);
                    props.onAdminInvited(status);
                    if (status === "OK") {
                        props.handleCloseInvite();
                    }
                });
            } catch (error) {
                log.error(`handleSubmit() - error: ${JSON.stringify(error, null, 2)}`);
            }
        },
        validationSchema: validationSchema,
        enableReinitialize: true
    })(InviteAdminForm);
    return <InviteAdminWithFormik inviteFailed={props.inviteFailed} closeError={props.closeError}
        handleCloseInvite={props.handleCloseInvite} />;
};

const enUsYyMmDdFormatter = new Intl.DateTimeFormat("en-GB", {
    year: "numeric",
    month: "short",
    day: "numeric",
    timeZone: "UTC"
});

const enUsHhMmFormatter = new Intl.DateTimeFormat("en-US", {
    hour: "numeric",
    minute: "2-digit",
    timeZone: "UTC"
});

interface AdminListProps {
    isShowSettings: boolean;
    setIsShowSettings: (show: boolean) => void
    admins: GetAccountAdminsData[];
    setAdmins: (value: React.SetStateAction<GetAccountAdminsData[]>) => void;
    deleteFailed: boolean;
    onAdminDeleted: (status: string, admins: GetAccountAdminsData[]) => void;
    timeZone: string;
    allowInvite: boolean;
    setAllowInvite: (allowInvite: boolean) => void;
    isSuperAdmin: boolean;
}

const AdminList = ({ admins, setAdmins, deleteFailed, onAdminDeleted, timeZone, allowInvite, setAllowInvite, isShowSettings, setIsShowSettings, isSuperAdmin }: AdminListProps) => {
    const authTokens = useAuthTokens();
    const { classes } = useStyles();
    const [deleteEmail, setDeleteEmail] = useState("");
    const navigate = useNavigate();
    const [currentUser, setCurrentUser] = useState<GetAccountAdminsData>();
    const handleClickDeleteEmail = (email: string) => {
        setDeleteEmail(email);
    };
    const handleCloseDelete = () => {
        setDeleteEmail("");
    };

    const [isInvite, setIsInvite] = useState(false);
    const handleCloseInvite = () => {
        setIsInvite(false);
    };

    const [inviteError, setInviteError] = useState(false);
    const [inviteDialog, setInviteDialog] = useState(false);

    const getAdminName = ({ firstName, lastName, current, joinByInvitation }: { firstName: string; lastName: string, current: boolean, joinByInvitation: boolean }) => {
        let displayName = "";
        if (firstName && lastName) {
            displayName = lastName + ", " + firstName;
        }

        return (
            <div style={{ position: "relative", minWidth: "250px", display: "flex" }}>
                <AccountCircleIcon className={classes.accountIcon} />
                <Typography className={classes.accountName}>{displayName}</Typography>
                {current && (<div className={classes.statusYou}><span className={classes.statusSpan}>YOU</span></div>)}
                {!joinByInvitation && (<div className={classes.statusPrimary}><span className={classes.statusSpan}>PRIMARY</span></div>)}
            </div>
        );
    };

    const getLoginDate = ({ registered, lastLogin }: { registered: boolean; lastLogin: string; }) => {

        let formattedYyMmDd = "";
        let formattedHhMm = "";
        log.debug(`timeZone: ${timeZone}`);

        if (lastLogin) {
            const addMinutes = convertToMinutes(timeZone);
            const date = new Date(lastLogin);
            date.setMinutes(date.getMinutes() + addMinutes);
            try {
                formattedYyMmDd = enUsYyMmDdFormatter.format(date);
                formattedHhMm = enUsHhMmFormatter.format(date).toLowerCase();
            } catch (error) {
                console.error(error);
            }
        }

        return (
            <div>
                {!registered ? (
                    <div className={classes.statusInvited}><span className={classes.statusSpan}>INVITED</span>
                    </div>) : (
                    <div>
                        <Typography className={classes.date}>{formattedYyMmDd}</Typography>
                        <Typography className={classes.hours}>{formattedHhMm}</Typography>
                    </div>
                )
                }
            </div>
        );
    };

    const deleteButton = (index: number, email: string, adminId: string) => {
        return (
            <IconButton
                style={{ color: "#7D8CDF" }}
                aria-label={`delete ${email}`}
                component="span"
                id={`del_btn_admin_${index}`}
                onClick={() => {
                    handleClickDeleteEmail(adminId);
                }}
            >
                <DeleteIcon />
            </IconButton>
        );
    };

    const settingButton = (adminId: string) => {
        return (
            <IconButton
                component="span"
                style={{ color: "#3F51B5" }}
                onClick={() => {
                    setIsShowSettings(true);
                    setCurrentUser(admins.find(admin => admin.adminId === adminId));
                }}
            >
                <Settings />
            </IconButton>
        );
    };

    const getIcon = (index: number, email: string, adminId: string, current: boolean) => {
        if (current) {
            return settingButton(adminId);
        } else {
            if (authTokens?.superAdmin) {
                return (
                    <>
                        {settingButton(adminId)}
                        {deleteButton(index, email, adminId)}
                    </>
                );
            } else {
                return deleteButton(index, email, adminId);
            }
        }
    };

    return (
        <Box>
            <TableContainer component={Paper} className={classes.table}>
                <Table className={classes.table} aria-label="admins table">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Typography className={classes.tableHeader}>Name</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography className={classes.tableHeader}>Email Address</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography className={classes.tableHeader}>Last Login</Typography>
                            </TableCell>
                            <TableCell className={classes.lastCell}>

                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {admins.map(({ firstName, lastName, email, registered, adminId, current, lastLogin, joinByInvitation }, index) => (
                            <TableRow key={index}>
                                <TableCell component="th" scope="row">
                                    {getAdminName({ firstName, lastName, current, joinByInvitation })}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    <Typography className={classes.email}>{email}</Typography>
                                </TableCell>
                                <TableCell>
                                    {getLoginDate({ registered, lastLogin })}
                                </TableCell>

                                <TableCell className={classes.lastCell}>
                                    <Box display={"flex"}>
                                        {getIcon(index, email, adminId, current)}
                                    </Box>
                                </TableCell>
                            </TableRow>
                        ))}
                        <TableRow>
                            <TableCell>
                                <Button id="btn_invite" color="secondary" variant="contained"
                                    startIcon={<img className={classes.plusIcon} alt=""
                                        src="./assets/img/plus.svg" />}
                                    onClick={() => {
                                        allowInvite ? setIsInvite(true) : setInviteDialog(true);
                                    }}>
                                    Invite
                                </Button>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                {deleteFailed ? "Error deleting admin" : null}
            </TableContainer>
            <Dialog
                open={deleteEmail !== ""}
                onClose={handleCloseDelete}
                aria-labelledby="delete-dialog-title"
                aria-describedby="delete-dialog-description"
            >
                <DialogTitle id="delete-dialog-title">
                    <DialogFormTitle
                        Icon={DeleteIcon}
                        text="Delete Administrator"
                        marginBottom={4}
                    />
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="delete-dialog-description">
                        Deletion is permanent and can't be reversed. Do you want to proceed?
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ margin: "0 auto 20px auto" }}>

                    <Button onClick={async () => {
                        await useDeleteAdmin({ adminId: deleteEmail }, onAdminDeleted);
                        handleCloseDelete();
                        useGetAccountAdmins(setAdmins, setAllowInvite);
                    }} id="delete_admin" color="secondary" variant="contained">
                        Yes, delete
                    </Button>
                    <Button onClick={handleCloseDelete} color="secondary" autoFocus>
                        Cancel
                    </Button>

                </DialogActions>
            </Dialog>

            <Dialog
                open={inviteDialog}
                onClose={() => setInviteDialog(false)}
                aria-labelledby="disable-invite"
                aria-describedby="disable-invite-description"
            >
                <DialogTitle id="error-message" style={{ textAlign: "center" }}>
                    Oops! Your plan limit has been reached.
                </DialogTitle>
                <DialogContent style={{ width: "600px", textAlign: "center" }}>
                    <DialogContentText id="errorMessage-description">
                        Sorry, your current plan doesn't allow more admin accounts. To add more admins, please upgrade
                        your plan.
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ margin: "0 auto 20px auto" }}>
                    <Button
                        color="secondary"
                        variant="outlined"
                        onClick={() => {
                            navigate("/plans");
                        }}
                    >
                        View plans
                    </Button>
                    <Button color="secondary" variant="contained" onClick={() => setInviteDialog(false)}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={isInvite}
                onClose={handleCloseInvite}
                aria-labelledby="invite-dialog-title"
                aria-describedby="invite-dialog-description"

            >
                <Box style={{ padding: "5px 10px 25px" }}>
                    <DialogTitle id="invite-dialog-title">
                        <DialogFormTitle
                            Icon={Email}
                            text="Invite a New Admin"
                            marginBottom={4}
                        />
                    </DialogTitle>
                    <DialogContent>
                        <InviteAdmin
                            onAdminInvited={(status: string) => {
                                if (status === "OK") {
                                    setInviteError(false);
                                    useGetAccountAdmins(setAdmins, setAllowInvite);
                                } else {
                                    log.error(`invite error!`);
                                    setInviteError(true);
                                }
                            }}
                            inviteFailed={inviteError}
                            closeError={() => setInviteError(false)}
                            handleCloseInvite={handleCloseInvite}
                        />
                    </DialogContent>
                </Box>
            </Dialog>
            {
                currentUser && (
                    <AccountSettings
                        modalShowState={isShowSettings}
                        isOpen={setIsShowSettings}
                        adminId={currentUser.adminId}
                        isSuperAdmin={isSuperAdmin}
                    />
                )
            }
        </Box>
    );
};

export type AdminManagementProps = {
    timeZone: string;
};

const AdminManagement = (props: AdminManagementProps) => {
    const [admins, setAdmins] = useState<GetAccountAdminsData[]>([]);
    const { authTokens } = useAuth();
    const [deleteError, setDeleteError] = useState(false);
    const [isShowSettings, setIsShowSettings] = useState<boolean>(false);
    const [allowInvite, setAllowInvite] = useState<boolean>(false);
    const { classes } = useStyles();

    useEffect(() => {
        useGetAccountAdmins(setAdmins, setAllowInvite);
    }, [isShowSettings, authTokens]);

    return (
        <Card className={classes.root}>
            <AdminList
                admins={admins}
                isShowSettings={isShowSettings}
                setIsShowSettings={setIsShowSettings}
                setAdmins={setAdmins}
                allowInvite={allowInvite}
                setAllowInvite={setAllowInvite}
                onAdminDeleted={(deleteStatus: string, admins: GetAccountAdminsData[]) => {
                    if (deleteStatus === "OK") {
                        setDeleteError(false);
                        setAdmins(admins);
                    } else {
                        setDeleteError(true);
                    }
                }}
                deleteFailed={deleteError}
                timeZone={props.timeZone}
                isSuperAdmin={Boolean(authTokens?.superAdmin)}
            />

        </Card>
    );
};
export default AdminManagement;
