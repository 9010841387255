import { useSearchParams } from 'react-router-dom';

interface UrlParams {
    testId: string | null;
    email: string | null;
    firstName: string | null;
    lastName: string | null;
    customField1: string | null;
    customField2: string | null;
}

const useTestUrlParams = (): UrlParams => {
    const [searchParams] = useSearchParams();

    const testId = searchParams.get("id") || searchParams.get("testid") || searchParams.get("testId");
    const email = searchParams.get('email');
    const firstName = searchParams.get('firstname');
    const lastName = searchParams.get('lastname');
    const customField1 = searchParams.get('customfield1');
    const customField2 = searchParams.get('customfield2');

    return {
        testId,
        email,
        firstName,
        lastName,
        customField1,
        customField2,
    };
};

export default useTestUrlParams;
