import ErrorOutline from "@mui/icons-material/ErrorOutline";
import { SubscriptionIsAvailable } from "../../hooks/useApi";
import { CandidatesExceeded } from "../../context/auth";
import { Link } from "react-router-dom";

interface WarningMessageProps {
    subscriptionIsAvailable?: SubscriptionIsAvailable;
    candidatesExceeded?: CandidatesExceeded;
};

const errorStyle = {
    base: {
        padding: "10px",
        margin: "0 40px 12px 40px",
        display: "flex",
        alignItems: "flex-end",
    },
    error: {
        border: "1px solid #e81a2f",
        color: "#000000",
        fontWeight: 400,
        background: "#ffcfcf",
    },
    warning: {
        border: "1px solid #fae290",
        color: "#b77d57",
        fontWeight: 600,
        background: "#fffbeb",
    }
};

const WarningMessage = ({ candidatesExceeded, subscriptionIsAvailable }: WarningMessageProps) => {
    return (
        <>
            {!subscriptionIsAvailable?.available &&
                <div style={{ ...errorStyle.base, ...(subscriptionIsAvailable?.messageType === "warning" ? errorStyle.warning : errorStyle.error) }}>
                    <ErrorOutline style={{ marginRight: "10px" }} />
                    <span>Your plan is expired. To continue using all your favorite features without any interruption, simply{" "}<Link to={"/plans"} style={{ cursor: "pointer" }}>choose a plan.</Link></span>
                </div>
            }
            {candidatesExceeded && candidatesExceeded.exceeded && candidatesExceeded.error && (
                <div style={{ ...errorStyle.base, ...errorStyle.error }}>
                    <ErrorOutline style={{ marginRight: "10px" }} />
                    <span>Your Plan Exceeded Limit. To view your test results, you need to <Link to={"/plans"} style={{ cursor: "pointer" }}>upgrade your plan.</Link></span>
                </div>
            )}
        </>
    )
};

export default WarningMessage;
