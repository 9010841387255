import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { makeStyles } from 'tss-react/mui';
import Container from "@mui/material/Container";
import axios from "axios";
import { Link } from "react-router-dom";
import Switch from "@mui/material/Switch";
import FormControlLabel from '@mui/material/FormControlLabel';
import { Mail, VpnKey } from "@mui/icons-material";
import { getLogger } from "../../utils/logger";
import { MyAxiosError } from "../../hooks/useApi";
import DialogFormTitle from "../DialogFormTitle/DialogFormTitle";
import emailRegex from "../../utils/emailRegex";

const log = getLogger();

const useStyles = makeStyles()((theme) => ({
    main: {
        backgroundImage: "url(./assets/img/login_back.png)",
        backgroundRepeat: "no-repeat",
        backgroundPositionX: "center",
        width: "100%",
        //height: "calc(100vh - 272px)",
        backgroundSize: "contain",
        minHeight: "530px",


    },
    dialogMain: {
        width: "786px",
        margin: "60px auto",
        padding: "15px",
        WebkitBoxShadow: "5px 5px 15px 5px rgba(0,0,0,0.33)",
        background: "white",
    },
    textField: {
        width: "calc(50% - 20px)",
        height: "57px",
        borderRadius: "4px",
        "& .MuiFormLabel-root": {
            color: theme.palette.warning.main + "!important"
        },
        "& .MuiInputBase-input": {
            color: theme.palette.primary.dark + "!important"
        },
        "& .Mui-disabled": {
            color: theme.palette.warning.main + "!important"
        },
        margin: "10px 10px 10px",
    },
    submitBtn: {
        display: "block",
        margin: "20px auto",

    },
    link: {
        textAlign: "center",
    },
    darkColor: {
        color: theme.palette.primary.dark,
    },
}));

const RegistrationForm = () => {
    const [submitDisable, setSubmitDisable] = useState<boolean>(false)
    const { classes } = useStyles();
    const [state, setState] = useState({
        email: "",
        password: "",
        passwordAgain: "",
        companyName: "",
        firstName: "",
        lastName: "",
        consent: false,
        statusMessage: "",
        isError: false,
        isSuccess: false,
        isPassError: false,
        passHelperText: " ",
        isPassAError: false,
        passAHelperText: " ",
        isEmailError: false,
        emailHelperText: " ",
        ready: false,
    });

    const registrationSuccessful = () => {
        setState((prevState) => {
            return {
                ...prevState,
                isError: false,
                isSuccess: true,
            };
        });
    };

    const getErrorMessage = (error: MyAxiosError): string => {
        let message = "";
        if (error.response && error.response.data && error.response.data.status) {
            log.error(
                `getErrorMessage() - error response data status: ${JSON.stringify(error.response.data.status)}`
            );
            const { status } = error.response.data;
            if (status === "ERROR_ADDRESS_IN_USE_NOT_CONFIRMED") {
                message = "You have already registered, please check your email for account confirmation message.";
            } else if (status === "ERROR_ADDRESS_IN_USE_CONFIRMED") {
                message = "You have already registered and confirmed your email, please login.";
            } else if (status === "COMPANY_ALREADY_EXISTS") {
                message = "Company already has account, please ask to be invited.";
            }
        } else if (error.request) {
            log.error(`getErrorMessage() - error request: ${JSON.stringify(error.request)}`);
        } else {
            log.error(`getErrorMessage() - other error: ${JSON.stringify(error)}`);
        }
        return message;
    };

    const registrationFailed = (error: MyAxiosError) => {
        setState((prevState) => {
            return {
                ...prevState,
                statusMessage: getErrorMessage(error),
                isError: true,
                isSuccess: false,
            };
        });
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { id, value, checked } = event.target;

        const obj = {
            ...state,
            [id]: value,
        };
        if (id === "consent") {
            obj[id] = checked;
        }

        //check password
        if (id === "password") {
            if (obj.password.length < 6) {
                obj.isPassError = true;
                obj.passHelperText = "6 symbols min";
            }
            else {
                obj.isPassError = false;
                obj.passHelperText = " ";
            }
        }

        if (id === "passwordAgain") {
            if (obj.password != obj.passwordAgain) {
                obj.isPassAError = true;
                obj.passAHelperText = "Passwords don't match";
            } else {
                obj.isPassAError = false;
                obj.passAHelperText = " ";
            }
        }

        //email validation
        if (id === "email") {
            if (emailRegex.test(String(value).toLowerCase())) {
                obj.isEmailError = false;
                obj.emailHelperText = " ";
            } else {
                obj.isEmailError = true;
                obj.emailHelperText = "Email is not in correct format";
            }
        }


        obj.ready = false;
        if (!obj.isPassError && obj.password != "" && !obj.isPassAError && obj.passwordAgain != "" && !obj.isEmailError && obj.firstName != "" && obj.lastName != "" && obj.companyName != "" && obj.consent) {
            obj.ready = true;
        }

        setState((prevState) => {
            const newState = {
                ...obj,
            };
            if (id === "consent") {
                log.info(`handleChange() consent being changed`);
                newState[id] = checked;
            }
            log.info(`prevState: ${JSON.stringify(prevState)}`);

            log.info((`newState: ${JSON.stringify(newState)}`));
            return newState;
        });
    };

    const submitForm = async () => {
        setSubmitDisable(true);
        const message = {
            firstName: state.firstName,
            lastName: state.lastName,
            email: state.email,
            password: state.password,
            company: state.companyName,
        };
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_ADDRESS}/api/subscription/free-trial`,
                message
            );
            log.info(`submitForm - success: ${JSON.stringify(response)}`);
            registrationSuccessful();
        } catch (error) {
            log.error(`submitForm - error: ${JSON.stringify(error)}`);
            registrationFailed(error as MyAxiosError);
        } finally {
            setSubmitDisable(false);
        }
    };

    const refreshPage = () => {
        window.location.reload();
    }
    return (
        <div className={classes.main}>
            <Container style={{ display: state.isSuccess ? "none" : "block" }}>
                <Box className={classes.dialogMain}>
                    <DialogFormTitle
                        Icon={VpnKey}
                        text="Start your free trial"
                    />
                    <Box style={{ margin: "20px 0px 0" }}>
                        <TextField
                            name="companyName"
                            id="companyName"
                            label="Company name"
                            value={state.companyName}
                            onChange={handleChange}
                            variant="outlined"
                            color="secondary"
                            className={classes.textField}
                        />
                        <TextField
                            name="email"
                            id="email"
                            label="Email address"
                            value={state.email}
                            onChange={handleChange}
                            variant="outlined"
                            color="secondary"
                            className={classes.textField}
                            error={state.isEmailError}
                            helperText={state.emailHelperText}
                        />
                    </Box>
                    <Box>
                        <TextField
                            name="firstName"
                            id="firstName"
                            label="First name"
                            value={state.firstName}
                            onChange={handleChange}
                            variant="outlined"
                            color="secondary"
                            className={classes.textField}
                        />
                        <TextField
                            name="lastName"
                            id="lastName"
                            label="Last name"
                            value={state.lastName}
                            onChange={handleChange}
                            variant="outlined"
                            color="secondary"
                            className={classes.textField}
                        />
                    </Box>

                    <Box>
                        <TextField
                            name="pass"
                            id="password"
                            label="Password"
                            value={state.password}
                            onChange={handleChange}
                            type="password"
                            variant="outlined"
                            color="secondary"
                            className={classes.textField}
                            error={state.isPassError}
                            helperText={state.passHelperText}
                        />
                        <TextField
                            name="passAgain"
                            id="passwordAgain"
                            label="Password again"
                            value={state.passwordAgain}
                            onChange={handleChange}
                            type="password"
                            variant="outlined"
                            color="secondary"
                            className={classes.textField}
                            error={state.isPassAError}
                            helperText={state.passAHelperText}
                        />
                    </Box>
                    <Box style={{ marginLeft: "10px", display: "flex" }}>
                        <FormControlLabel
                            control={
                                <Switch
                                    color="secondary"
                                    type="checkbox"
                                    aria-label="state"
                                    onChange={handleChange}
                                    name="limitsInUse"
                                    id="consent"
                                />
                            }
                            label=""
                            className={classes.darkColor}
                        />
                        <span style={{ marginLeft: "-10px", marginTop: "7px" }}>I agree to TypingTest Pro&nbsp;
                            <a href="https://www.iubenda.com/terms-and-conditions/85323953" target="_blank" tabIndex={-1}>Terms of Service</a>&nbsp;and&nbsp;
                            <a href="https://www.iubenda.com/privacy-policy/85323953" target="_blank" tabIndex={-1}>Privacy Policy</a></span>
                    </Box>
                    <Button disabled={!state.ready || submitDisable}
                        onClick={submitForm}
                        id="submit_button"
                        className={classes.submitBtn}
                        variant="contained"
                        color="secondary">
                        Submit
                    </Button>
                    <RegistrationMessage message={state.statusMessage} isError={state.isError} />
                    <Link to="/login" className={classes.link}>Already have an account?</Link>
                </Box>
            </Container>

            <Container style={{ display: state.isSuccess ? "block" : "none" }}>

                <Box className={classes.dialogMain} style={{ width: "645px" }}>

                    <DialogFormTitle
                        Icon={Mail}
                        text="Please confirm your email address"
                    />
                    <div style={{ marginLeft: "60px" }}>
                        <Typography style={{ fontWeight: "normal", lineHeight: "20px" }}>Confirmation email has been sent to you. Once you’ve confirmed that {state.email}{" "} is your email address, we’ll create the account and you can get started on Typing Test Pro.<br /><br />
                            <b>Didn't get the email?</b><br />
                            Check your spam folder and also that you entered your email correctly {state.email}{"  "}
                            <Link to="" onClick={refreshPage} className={classes.link}>Change email</Link>
                        </Typography>

                    </div>
                </Box>
            </Container>
        </div >
    );
};

interface RegistrationStatusMessage {
    message: string;
    isError: boolean;
}

const RegistrationMessage = (props: RegistrationStatusMessage) => {
    log.info(`RegistrationMessage: ${props.message}, error: ${props.isError}`);
    if (props.message) {
        return (
            <>
                {props.isError ? (
                    <div>
                        <p style={{ color: "red" }}>{props.message}</p>
                    </div>
                ) : (
                    <div>{props.message}</div>
                )}
            </>
        );
    } else {
        return null;
    }
};

export default RegistrationForm;
