import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { Form, Formik, FormikProps } from "formik";
import * as Yup from "yup";
import React from "react";
import { ResultRecipientType, TextSelectionType } from "../../hooks/useApi";
import { makeStyles } from 'tss-react/mui';
import Typography from "@mui/material/Typography";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import KeyboardIcon from "@mui/icons-material/Keyboard";
import { getLogger } from "../../utils/logger";
import { useFeature } from "flagged";
import { useNavigate } from "react-router-dom";
import emailRegex from "../../utils/emailRegex";

const log = getLogger();

const useStyles = makeStyles()((theme) => ({
    root: {
        width: "100%",
        color: theme.palette.primary.dark,

        "& .MuiTypography-h6": {
            fontSize: "34px",
            minHeight: "55px"
        },
        "& .MuiDialog-paperScrollPaper": {
            width: "100%"
        }
    },

    boxField: {
        marginTop: 0,
        marginBottom: "24px"
    },

    inputField: {
        height: "35px",
        color: theme.palette.primary.dark
    },

    inputFieldDisabled: {
        height: "35px",
        color: theme.palette.text.secondary
    },

    minText: {
        position: "absolute",
        top: "15px",
        right: "45px",
        color: theme.palette.primary.dark
    },

    minTextDisabled: {
        position: "absolute",
        top: "15px",
        right: "45px",
        color: theme.palette.text.secondary
    },

    darkColor: {
        color: theme.palette.primary.dark
    },
    rightSwitch: {
        marginLeft: "10px",
        marginTop: "5px"

    },
    showAllButton: {
        position: "absolute",
        top: "0px",
        left: "740px"
    },
    errorMessageSection: {
        color: "red",
        fontSize: "24px",
        marginTop: "13px"
    }
}));

const useBottomTextStyles = makeStyles()((theme) => ({
    customText: {
        color: theme.palette.primary.dark,
        fontSize: "16px",
        lineHeight: "1.75",
        letterSpacing: "0.00938em"
    },
}));

export const CUSTOM_TEXT_NAME = "Custom Text";

const getTextForView = (text: string, showAll: boolean) => {
    if (text === "") {
        return null;
    }
    let str = text;
    if (!showAll) {
        str = str.substring(0, 300);
        if (text.length > str.length) {
            str += "...";
        }
    }
    let paragraphs = [<p key={0}>text error...</p>];
    if (str.includes("\\r\\n")) {
        paragraphs = str.split("\\r\\n").map((str, index) => <p key={index}>{str}</p>);
    } else if (str.includes("\\n")) {
        paragraphs = str.split("\\n").map((str, index) => <p key={index}>{str}</p>);
    } else if (str.includes("\\\\r\\\\n")) {
        paragraphs = str.split("\\\\r\\\\n").map((str, index) => <p key={index}>{str}</p>);
    } else {
        log.debug(`text without crlf's`);
        paragraphs = [<p key={0}>{str}</p>];
    }
    if (!showAll && paragraphs.length > 3) {
        log.debug(`text contains too many paragraphs: ${paragraphs.length}`);
        paragraphs = paragraphs.slice(0, 3);
    }
    return paragraphs;
};

interface BottomTextProps {
    useCustomText: boolean;
    enableCustomUrl: boolean;
    customText: string;
    handleChange: (e: React.ChangeEvent<any>) => void;
    handleBlur: (e: React.FocusEvent<any, Element>) => void;
    error: string | undefined;
    selectedTextContent: string;
    showAll: boolean;
    toggleShowAll: () => void;
}

const BottomText = ({
    useCustomText,
    enableCustomUrl,
    customText,
    handleChange,
    handleBlur,
    error,
    selectedTextContent,
    showAll,
    toggleShowAll,
}: BottomTextProps) => {
    const { classes } = useBottomTextStyles();
    if (useCustomText) {
        log.debug(`getBottomText() - using custom text`);
        return (
            <Box width="100%" marginBottom="30px" marginTop={enableCustomUrl ? "0px" : "20px"}
                position="relative">
                <TextField
                    label="Custom text"
                    id="customText"
                    name="customText"
                    multiline
                    value={customText}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    InputProps={{ classes: { input: classes.customText } }}
                    style={{ width: "100%" }}
                    inputProps={{ maxLength: 5000 }}
                    error={error ? true : false}
                />
            </Box>
        );
    }

    log.debug(`getBottomText() - using predefined text`);
    return (
        <Box width="100%" marginBottom="30px" marginTop="30px" position="relative">
            <Typography variant="subtitle1" style={{ width: "100%", paddingRight: "20px" }}>
                {getTextForView(selectedTextContent, showAll)}
                <Button color="secondary" onClick={toggleShowAll}>
                    {showAll ? "Hide" : "Show all"}
                </Button>
            </Typography>
        </Box>
    );
};

export interface TestSettingsProps {
    handleClose: () => void;
    handleCloseAfterEdit: (values: TestSettingsFormValues) => void;
    open: boolean;
    initialValues: TestSettingsFormValues;
    errorMessage?: string | undefined;
}

const validationSchema = Yup.object().shape({
    name: Yup.string()
        .trim()
        .required("Name is required")
        .min(1, "Name is too short")
        .max(50, "Name should be no longer than 50 characters"),
    duration: Yup.number()
        .required("Duration is required")
        .integer('Duration must be an integer')
        .min(1, "Minimum is 1")
        .max(600, "Maximum is 600"),
    attempts: Yup.number()
        .required("Attempts is required")
        .integer('Attempts must be an integer')
        .max(300, "Maximum is 300")
        .min(1, "Minimum is 1"),
    netWpm: Yup.number()
        .required("Minimum Net Speed is required")
        .min(0, "Number between 0 - 100 is required")
        .max(100, "Number between 0 - 100 is required")
        .integer("Minimum Net Speed must be an integer"),
    accuracy: Yup.number()
        .required("Minimum Accuracy is required")
        .min(0, "Number between 0 - 100 is required")
        .max(100, "Number between 0 - 100 is required")
        .integer("Minimum Accuracy must be an integer"),
    noteEnabled: Yup.boolean(),
    note: Yup.string()
        .when("noteEnabled", {
            is: true,
            then: (schema) => schema.max(100, "Text is too long, maximum 100 characters can be entered in the field").required("If enabled, this information is required")
        }),
    infoFieldOneEnabled: Yup.boolean(),
    infoFieldOneTitle: Yup.string()
        .when("infoFieldOneEnabled", {
            is: true,
            then: (schema) => schema.max(50, "Text is too long, maximum 50 characters can be entered in the field").required("If enabled, this information is required")
        }),
    customText: Yup.string()
        .when("useCustomText", {
            is: true,
            then: (schema) => schema
                .required('Custom Text is a required field if enabled')
                .when("duration", ([duration], schema) => (
                    duration <= 3 ?
                        schema.min(1000, 'Custom text is too short, it must be 1000 characters or longer for a 3-minute test') :
                        schema.min(2000, 'Custom text is too short, it must be 2000 characters or longer for a 5-minute test')
                ))
                .max(5000, "Text is too long, maximum 5000 characters can be entered in the field"),
        }),
    infoFieldTwoEnabled: Yup.boolean(),
    infoFieldTwoTitle: Yup.string()
        .when("infoFieldTwoEnabled", {
            is: true,
            then: (schema) => schema.max(50, "Text is too long, maximum 50 characters can be entered in the field").required("If enabled, this information is required")
        }),
    sendResultsByEmail: Yup.boolean(),
    migratedResultEmails: Yup.string()
        .when("sendResultsByEmail", {
            is: true,
            then: (schema) => schema
                .test({
                    test: (value, ctx) => {
                        if (!value) {
                            return ctx.createError({ message: "If enabled, this information is required" });
                        }

                        const emails = value.split(",");
                        const moreThanOneEmail = emails.length > 1;
                        let invalidEmailIndex = -1;
                        const hasInvalidEmail = emails.some((email, i) => {
                            const hasError = !emailRegex.test(email.trim());
                            if (hasError) {
                                invalidEmailIndex = i;
                            }
                            return hasError;
                        });
                        if (hasInvalidEmail) {
                            let message = "Email is incorrect";
                            if (moreThanOneEmail) {
                                message = `Incorrect email at position ${invalidEmailIndex + 1}`;
                            }
                            return ctx.createError({ message });
                        }

                        return true;
                    }
                })
        }),
    exitPassUrlState: Yup.boolean(),
    exitFailUrlState: Yup.boolean(),
    exitPassUrl: Yup.string()
        .when("exitPassUrlState", {
            is: true,
            then: (schema) => schema.url("Please enter correct url").required("If enabled, this information is required")
        }),
    exitFailUrl: Yup.string()
        .when("exitFailUrlState", {
            is: true,
            then: (schema) => schema.url("Please enter correct url").required("If enabled, this information is required")
        }),
    accessCodeEnabled: Yup.boolean(),
    accessCode: Yup.string()
        .when("accessCodeEnabled", {
            is: true,
            then: (schema) => schema.required("If enabled, this information is required")
        })
});

export interface TestSettingsFormValues {
    name: string;
    duration: number;
    attempts: number;
    sendResultsByEmail: boolean;
    resultRecipients: ResultRecipientType[];
    selectedResultRecipients: string[];
    limitsInUse: boolean;
    shownToCandidate: boolean;
    netWpm: number;
    accuracy: number;
    noteEnabled: boolean;
    note: string;
    infoFieldOneEnabled: boolean;
    infoFieldOneTitle: string;
    infoFieldTwoEnabled: boolean;
    migratedResultEmails: string;
    infoFieldTwoTitle: string;
    testText: string;
    textSelectionOptions: TextSelectionType["options"];
    selectedText: number;
    customText: string;
    useCustomText: boolean;
    selectedTextContent: string;
    exitPassUrlState: boolean;
    exitFailUrlState: boolean;
    exitPassUrl: string;
    exitFailUrl: string;
    newAttemptsAllowed: number;
    accessCodeEnabled: boolean,
    accessCode: string
}

const newAttemptsAllowed = [
    { textName: "never", value: -1 },
    { textName: "after 1 hour", value: 1 },
    { textName: "after 2 hours", value: 2 },
    { textName: "after 4 hours", value: 4 },
    { textName: "after 24 hours", value: 24 },
    { textName: "after 48 hours", value: 48 },
    { textName: "after 72 hours", value: 72 },
    { textName: "after a week", value: 168 },
    { textName: "after 2 weeks", value: 336 },
    { textName: "after a month", value: 720 },
    { textName: "after 3 months", value: 2160 },
    { textName: "after 6 months", value: 4320 },
    { textName: "after 1 year", value: 8760 }
];

const MyTextField = (props: TextFieldProps) => {
    return (
        <TextField
            size="small"
            variant="outlined"
            color="secondary"
            {...props}
        />
    );
};

const TestSettings = ({
    open,
    handleClose,
    initialValues,
    handleCloseAfterEdit,
    errorMessage
}: TestSettingsProps) => {
    const { classes } = useStyles();
    const navigate = useNavigate();

    const [showAll, setShowAll] = React.useState(false);
    const enableCustomText = useFeature("f_custom_text") as boolean;
    const enableCustomUrl = useFeature("f_exit_link") as boolean;
    const exitLinkWhenPassedLabel = !enableCustomUrl ? "Advanced: Exit Link when Passed (locked in this plan)" : "Advanced: Exit Link when Passed";
    const exitLinkWhenFailedLabel = !enableCustomUrl ? "Advanced: Exit Link when Failed (locked in this plan)" : "Advanced: Exit Link when Failed";
    const toggleShowAll = () => {
        setShowAll(!showAll);
    };

    return (
        <Formik
            initialValues={{
                name: initialValues.name,
                duration: initialValues.duration,
                attempts: initialValues.attempts,
                sendResultsByEmail: initialValues.sendResultsByEmail,
                resultRecipients: initialValues.resultRecipients,
                selectedResultRecipients: initialValues.selectedResultRecipients,
                limitsInUse: initialValues.limitsInUse,
                shownToCandidate: initialValues.shownToCandidate,
                netWpm: initialValues.netWpm,
                accuracy: initialValues.accuracy,
                noteEnabled: initialValues.noteEnabled,
                note: initialValues.note,
                infoFieldOneEnabled: initialValues.infoFieldOneEnabled,
                infoFieldOneTitle: initialValues.infoFieldOneTitle,
                infoFieldTwoEnabled: initialValues.infoFieldTwoEnabled,
                infoFieldTwoTitle: initialValues.infoFieldTwoTitle,
                testText: initialValues.testText,
                textSelectionOptions: initialValues.textSelectionOptions,
                selectedText: initialValues.selectedText,
                customText: initialValues.customText,
                useCustomText: initialValues.useCustomText,
                selectedTextContent: initialValues.selectedTextContent,
                migratedResultEmails: initialValues.migratedResultEmails,
                exitPassUrlState: initialValues.exitPassUrlState,
                exitFailUrlState: initialValues.exitFailUrlState,
                exitPassUrl: initialValues.exitPassUrl,
                exitFailUrl: initialValues.exitFailUrl,
                newAttemptsAllowed: initialValues.newAttemptsAllowed,
                accessCodeEnabled: initialValues.accessCodeEnabled,
                accessCode: initialValues.accessCode
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
                log.debug(`onSubmit() - values: ${JSON.stringify(values)}`);
                const valuesNameTrimmed = {
                    ...values,
                    name: values.name.trim(),
                }
                handleCloseAfterEdit(valuesNameTrimmed);
            }}
            enableReinitialize={true}
        >
            {(formProps: FormikProps<TestSettingsFormValues>) => {
                const { isValid, values, touched, errors, handleBlur, handleChange, handleSubmit, setFieldValue, resetForm } = formProps;
                const handleTextChange = (event: SelectChangeEvent<string>) => {
                    const { value } = event.target;
                    log.debug(`handleTextChange() - value: ${JSON.stringify(value, null, 2)}`);
                    testTextSelected(value as string);
                    if (value === CUSTOM_TEXT_NAME) {
                        setFieldValue('useCustomText', true);
                    } else {
                        setFieldValue('useCustomText', false);
                    }
                };

                const handleAllowingTimeChange = (event: SelectChangeEvent<number>) => {
                    const { value } = event.target;
                    log.debug(`handleAllowingTimeChange() - value: ${JSON.stringify(value, null, 2)}`);
                    setFieldValue("newAttemptsAllowed", value);
                };

                const testTextSelected = (value: string) => {
                    setFieldValue("testText", value);
                    if (value === CUSTOM_TEXT_NAME) {
                        log.debug(`testTextSelected() - custom text selected`);
                        setFieldValue("useCustomText", true);
                    } else {
                        log.debug(`testTextSelected() - text ${value} selected`);
                        setFieldValue("useCustomText", false);
                        for (let index = 0; index < values.textSelectionOptions.length; index++) {
                            if (value === values.textSelectionOptions[index].textName) {
                                log.debug(`testTextSelected() - setting text at index ${index} for view`);
                                setFieldValue("selectedTextContent", values.textSelectionOptions[index].content);
                                setFieldValue("selectedText", index);
                                break;
                            }
                        }
                    }
                };

                const handleOwnClose = (event: Record<string, unknown>, reason: string) => {
                    log.debug(`handleOwnClose() - reason: ${reason}`);
                    if (reason === "backdropClick" || reason === "escapeKeyDown") {
                        resetForm();
                    }
                    handleClose();
                };

                return (
                    <Dialog open={open} onClose={handleOwnClose} aria-labelledby="form-dialog-title"
                        classes={{ root: classes.root }} maxWidth="lg" style={{ width: "100%" }}>
                        <Form onSubmit={handleSubmit} autoComplete={"off"}>
                            <Box display="flex">
                                <KeyboardIcon color="secondary"
                                    style={{ marginLeft: "20px", marginTop: "16px", fontSize: "52px" }} />
                                <DialogTitle id="form-dialog-title" style={{
                                    marginLeft: "-20px",
                                    maxWidth: "calc(100% - 200px)"
                                }}>
                                    {values.name}
                                    {errorMessage && (
                                        <div className={classes.errorMessageSection}>
                                            <p>{errorMessage}</p>
                                        </div>
                                    )}
                                </DialogTitle>
                                <DialogActions style={{ marginLeft: "auto", marginRight: "40px" }}>
                                    <Button onClick={() => {
                                        resetForm();
                                        handleClose();
                                    }} color="secondary">
                                        Cancel
                                    </Button>
                                    <Button type="submit"
                                        color="secondary"
                                        id="btn_save"
                                        variant="contained"
                                        disabled={!isValid}
                                    >
                                        Save
                                    </Button>
                                </DialogActions>
                            </Box>
                            <DialogContent>

                                <Box display="flex" width="100%">
                                    <Box width="50%" style={{ paddingRight: "20px" }}>
                                        <MyTextField
                                            value={values.name}
                                            spellCheck="false"
                                            autoComplete="none"
                                            aria-autocomplete="none"
                                            margin="dense"
                                            name="name"
                                            id="name"
                                            label="Test Name"
                                            type="text"
                                            fullWidth
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            helperText={
                                                errors.name && touched.name
                                                    ? errors.name
                                                    : ""
                                            }
                                            error={errors.name && touched.name ? true : false}
                                            className={classes.boxField}
                                            InputProps={{ classes: { input: classes.inputField } }}
                                        />

                                        <Box position="relative" width="100%">
                                            <MyTextField
                                                value={values.duration}
                                                margin="dense"
                                                id="duration"
                                                label="Duration"
                                                inputProps={{ inputMode: "numeric", pattern: "[0-9]{2}" }}
                                                type="number"
                                                fullWidth
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                helperText={
                                                    errors.duration && touched.duration
                                                        ? errors.duration
                                                        : ""
                                                }
                                                error={errors.duration && touched.duration ? true : false}
                                                className={classes.boxField}
                                                InputProps={{ classes: { input: classes.inputField } }}
                                            />
                                            <span className={classes.minText}>min.</span>
                                        </Box>

                                        <Box position="relative" width="100%" display={"flex"}>
                                            <Box position="relative" width="50%" paddingRight="20px">
                                                <MyTextField
                                                    value={values.attempts}
                                                    margin="dense"
                                                    id="attempts"
                                                    label="Max. Attempts"
                                                    inputProps={{ inputMode: "numeric", pattern: "[0-9]{2}" }}
                                                    type="number"
                                                    fullWidth
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={
                                                        errors.attempts && touched.attempts
                                                            ? errors.attempts
                                                            : ""
                                                    }
                                                    error={errors.attempts && touched.attempts ? true : false}
                                                    className={classes.boxField}
                                                    InputProps={{
                                                        classes: { input: classes.inputField },
                                                        inputProps: { min: 0, max: 300 }
                                                    }}
                                                />
                                            </Box>

                                            <Box display="flex" width="50%">
                                                <FormControl
                                                    variant="outlined"
                                                    color="secondary"
                                                    size="medium"
                                                    sx={{ width: "100%" }}
                                                >
                                                    <InputLabel id="allowingTimes">New attempts allowed</InputLabel>
                                                    <Select
                                                        labelId="allowingTimes"
                                                        id="newAttemptsAllowed"
                                                        name="newAttemptsAllowed"
                                                        value={values.newAttemptsAllowed ? values.newAttemptsAllowed : 2160}
                                                        onChange={handleAllowingTimeChange}
                                                        label="New attempts allowed"
                                                        className={classes.darkColor}
                                                        sx={{
                                                            '& .MuiSelect-select': {
                                                                padding: '14.5px 14px'
                                                            }
                                                        }}
                                                    >
                                                        {newAttemptsAllowed.map((option, index) => {
                                                            return (
                                                                <MenuItem
                                                                    id={`option_${index}`}
                                                                    key={index}
                                                                    value={option.value}>
                                                                    {option.textName}
                                                                </MenuItem>
                                                            );
                                                        })}
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                        </Box>

                                        <Box display="flex">
                                            <Box position="relative" width="50%">
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            color="secondary"
                                                            type="checkbox"
                                                            aria-label="state"
                                                            checked={values.limitsInUse}
                                                            onChange={handleChange}
                                                            name="limitsInUse"
                                                            id="limitsInUse"
                                                        />
                                                    }
                                                    label="Enable pass limits"
                                                    className={classes.darkColor}
                                                />
                                            </Box>
                                            <Box position="relative" width="50%">
                                                <MyTextField
                                                    value={values.netWpm}
                                                    margin="dense"
                                                    id="netWpm"
                                                    name="netWpm"
                                                    label="Minimum Net Speed"
                                                    inputProps={{ inputMode: "numeric", pattern: "[0-9]{3}" }}
                                                    type="number"
                                                    fullWidth
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={
                                                        errors.netWpm && touched.netWpm
                                                            ? errors.netWpm
                                                            : ""
                                                    }
                                                    error={errors.netWpm && touched.netWpm ? true : false}
                                                    className={classes.boxField}
                                                    InputProps={{ classes: { input: values.limitsInUse ? classes.inputField : classes.inputFieldDisabled } }}
                                                    disabled={!values.limitsInUse}
                                                />
                                                <span
                                                    className={values.limitsInUse ? classes.minText : classes.minTextDisabled}>WPM</span>
                                            </Box>
                                        </Box>

                                        <Box display="flex">
                                            <Box position="relative" width="50%">
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            color="secondary"
                                                            type="checkbox"
                                                            aria-label="state"
                                                            checked={values.shownToCandidate}
                                                            onChange={handleChange}
                                                            name="shownToCandidate"
                                                            id="shownToCandidate"
                                                        />
                                                    }
                                                    label="Show requirements to candidates"
                                                    className={classes.darkColor}
                                                />
                                            </Box>
                                            <Box position="relative" width="50%">
                                                <MyTextField
                                                    value={values.accuracy}
                                                    margin="dense"
                                                    id="accuracy"
                                                    name="accuracy"
                                                    label="Minimum Accuracy"
                                                    inputProps={{ inputMode: "numeric", pattern: "[0-9]{3}" }}
                                                    type="number"
                                                    fullWidth
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={
                                                        errors.accuracy && touched.accuracy
                                                            ? errors.accuracy
                                                            : ""
                                                    }
                                                    error={errors.accuracy && touched.accuracy ? true : false}
                                                    className={classes.boxField}
                                                    InputProps={{ classes: { input: values.limitsInUse ? classes.inputField : classes.inputFieldDisabled } }}
                                                    disabled={!values.limitsInUse}
                                                />
                                                <span
                                                    className={values.limitsInUse ? classes.minText : classes.minTextDisabled}
                                                    style={{ right: "45px" }}>%</span>
                                            </Box>
                                        </Box>

                                        <Box display="flex">
                                            <Box position="relative" width="50%">
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            color="secondary"
                                                            type="checkbox"
                                                            aria-label="state"
                                                            checked={values.accessCodeEnabled}
                                                            onChange={(event) => {
                                                                handleChange(event);
                                                                setTimeout(() => {
                                                                    (document.querySelector("#accessCode") as HTMLElement)?.focus();
                                                                }, 150);
                                                            }}
                                                            onBlur={handleBlur}
                                                            name="accessCodeEnabled"
                                                            id="accessCodeState"
                                                        />
                                                    }
                                                    label="Enable Access Code"
                                                    className={classes.darkColor}
                                                />
                                            </Box>
                                            <Box position="relative" width="50%">
                                                <MyTextField
                                                    value={values.accessCode}
                                                    margin="dense"
                                                    id="accessCode"
                                                    label="Access Code"
                                                    type="text"
                                                    fullWidth
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={classes.boxField}
                                                    inputProps={{ maxLength: 500 }}
                                                    InputProps={{ classes: { input: values.accessCode ? classes.inputField : classes.inputFieldDisabled } }}
                                                    disabled={!values.accessCodeEnabled}
                                                    helperText={
                                                        errors.accessCode && touched.accessCode
                                                            ? errors.accessCode
                                                            : ""
                                                    }
                                                    error={errors.accessCode && touched.accessCode ? true : false}
                                                />
                                            </Box>
                                        </Box>


                                        <Box display="flex" width="100%" marginBottom="10px" marginTop="12px">
                                            <FormControl variant="outlined" color="secondary"
                                                style={{ width: "415px" }}>
                                                <InputLabel id="testTextLabel">Test Text</InputLabel>
                                                <Select
                                                    labelId="testTextLabel"
                                                    id="testText"
                                                    name="testText"
                                                    value={values.testText}
                                                    onChange={handleTextChange}
                                                    label="Test Text"
                                                    className={classes.darkColor}
                                                    sx={{
                                                        '& .MuiSelect-select': {
                                                            padding: '14.5px 14px'
                                                        }
                                                    }}
                                                >
                                                    {values.textSelectionOptions.map((option, index) => {
                                                        let disabled = false;
                                                        let style = { textIndent: 17 };
                                                        if (option.isTheme) {
                                                            disabled = true;
                                                            style = { textIndent: 0 };
                                                        } else if (option.textName === CUSTOM_TEXT_NAME) {
                                                            style = { textIndent: 0 };
                                                        }
                                                        if (option.textName === CUSTOM_TEXT_NAME && !enableCustomText) {
                                                            return null;
                                                        }
                                                        return (
                                                            <MenuItem
                                                                key={index}
                                                                value={option.textName}
                                                                disabled={disabled}
                                                                style={style}>
                                                                {option.textName}
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </Select>
                                            </FormControl>
                                            {enableCustomText ? (
                                                <Button
                                                    id="selectCustomText"
                                                    color="secondary"
                                                    style={{ marginLeft: "20px" }}
                                                    onClick={() => testTextSelected(CUSTOM_TEXT_NAME)}>
                                                    <Box sx={{ minWidth: "165px", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        <span>Custom Text</span>
                                                    </Box>
                                                </Button>
                                            ) : (
                                                <Button
                                                    id="selectCustomText"
                                                    color="secondary"
                                                    style={{ marginLeft: "20px" }}
                                                    onClick={() => navigate("/plans")}>
                                                    <div style={{ minWidth: "165px" }}>
                                                        <p>Custom Text</p>
                                                        <p>(Locked in this plan)</p>
                                                    </div>
                                                </Button>
                                            )}
                                            {values.useCustomText && (
                                                <Box sx={{ position: 'relative', marginLeft: '16px' }}>
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems: 'flex-end',
                                                            width: '400px',
                                                            position: 'absolute',
                                                            top: 0,
                                                            bottom: 0,
                                                        }}
                                                    >
                                                        <Typography color="error" sx={{ width: '100%' }}>
                                                            {errors.customText}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            )}
                                        </Box>
                                    </Box>
                                    <Box width="50%">
                                        <Box position="relative" width="100%" display="flex">
                                            <MyTextField
                                                value={values.note}
                                                margin="dense"
                                                id="note"
                                                name="note"
                                                label="Welcome Page Note"
                                                fullWidth
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={classes.boxField}
                                                InputProps={{ classes: { input: values.noteEnabled ? classes.inputField : classes.inputFieldDisabled } }}
                                                disabled={!values.noteEnabled}
                                                helperText={
                                                    errors.note
                                                        ? errors.note
                                                        : ""
                                                }
                                                error={errors.note ? true : false}
                                            />
                                            <Switch
                                                color="secondary"
                                                type="checkbox"
                                                aria-label="state"
                                                className={classes.rightSwitch}
                                                checked={values.noteEnabled}
                                                onChange={(event) => {
                                                    handleChange(event);
                                                    setTimeout(() => {
                                                        (document.querySelector("#note") as HTMLElement)?.focus();
                                                    }, 150);
                                                }}
                                                onBlur={handleBlur}
                                                name="noteEnabled"
                                                id="noteEnabled"
                                            />
                                        </Box>

                                        <Box position="relative" width="100%" display="flex">
                                            <MyTextField
                                                value={values.infoFieldOneTitle}
                                                margin="dense"
                                                id="infoFieldOneTitle"
                                                name="infoFieldOneTitle"
                                                label="Label for Additional Information 1"
                                                fullWidth
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={classes.boxField}
                                                InputProps={{ classes: { input: values.infoFieldOneEnabled ? classes.inputField : classes.inputFieldDisabled } }}
                                                disabled={!values.infoFieldOneEnabled}
                                                helperText={
                                                    errors.infoFieldOneTitle
                                                        ? errors.infoFieldOneTitle
                                                        : ""
                                                }
                                                error={errors.infoFieldOneTitle ? true : false}
                                            />
                                            <Switch
                                                color="secondary"
                                                type="checkbox"
                                                aria-label="state"
                                                className={classes.rightSwitch}
                                                checked={values.infoFieldOneEnabled}
                                                onChange={(event) => {
                                                    handleChange(event);
                                                    setTimeout(() => {
                                                        (document.querySelector("#infoFieldOneTitle") as HTMLElement)?.focus();
                                                    }, 150);
                                                }}
                                                onBlur={handleBlur}
                                                name="infoFieldOneEnabled"
                                                id="infoFieldOneEnabled"
                                            />
                                        </Box>

                                        <Box position="relative" width="100%" display="flex">
                                            <MyTextField
                                                value={values.infoFieldTwoTitle}
                                                margin="dense"
                                                id="infoFieldTwoTitle"
                                                name="infoFieldTwoTitle"
                                                label="Label for Additional Information 2"
                                                fullWidth
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={classes.boxField}
                                                InputProps={{ classes: { input: values.infoFieldTwoEnabled ? classes.inputField : classes.inputFieldDisabled } }}
                                                disabled={!values.infoFieldTwoEnabled}
                                                helperText={
                                                    errors.infoFieldTwoTitle
                                                        ? errors.infoFieldTwoTitle
                                                        : ""
                                                }
                                                error={errors.infoFieldTwoTitle ? true : false}
                                            />
                                            <Switch
                                                color="secondary"
                                                type="checkbox"
                                                aria-label="state"
                                                className={classes.rightSwitch}
                                                checked={values.infoFieldTwoEnabled}
                                                onChange={(event) => {
                                                    handleChange(event);
                                                    setTimeout(() => {
                                                        (document.querySelector("#infoFieldTwoTitle") as HTMLElement)?.focus();
                                                    }, 150);
                                                }}
                                                onBlur={handleBlur}
                                                name="infoFieldTwoEnabled"
                                                id="infoFieldTwoEnabled"
                                            />
                                        </Box>

                                        <Box position="relative" width="100%" display="flex">
                                            <MyTextField
                                                value={values.migratedResultEmails}
                                                margin="dense"
                                                id="migratedResultEmails"
                                                name="migratedResultEmails"
                                                label="Email the results to one or multiple addresses, separated by commas"
                                                fullWidth
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={classes.boxField}
                                                inputProps={{ maxLength: 500 }}
                                                InputProps={{ classes: { input: values.migratedResultEmails ? classes.inputField : classes.inputFieldDisabled } }}
                                                disabled={!values.sendResultsByEmail}
                                                helperText={
                                                    errors.migratedResultEmails && touched.migratedResultEmails
                                                        ? errors.migratedResultEmails
                                                        : ""
                                                }
                                                error={errors.migratedResultEmails && touched.migratedResultEmails ? true : false}
                                            />
                                            <Switch
                                                color="secondary"
                                                type="checkbox"
                                                aria-label="state"
                                                className={classes.rightSwitch}
                                                checked={values.sendResultsByEmail}
                                                onChange={(event) => {
                                                    handleChange(event);
                                                    setTimeout(() => {
                                                        (document.querySelector("#infoFieldTwoTitle") as HTMLElement)?.focus();
                                                    }, 150);
                                                }}
                                                onBlur={handleBlur}
                                                name="sendResultsByEmail"
                                                id="sendResultsByEmail"
                                            />
                                        </Box>

                                        <Box>
                                            <Box position="relative" width="100%" display="flex">
                                                <MyTextField
                                                    value={values.exitPassUrl}
                                                    autoFocus
                                                    margin="dense"
                                                    id="exitPassUrl"
                                                    label={exitLinkWhenPassedLabel}
                                                    type="text"
                                                    fullWidth
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={classes.boxField}
                                                    InputProps={{ classes: { input: values.exitPassUrl ? classes.inputField : classes.inputFieldDisabled } }}
                                                    disabled={!values.exitPassUrlState}
                                                    helperText={
                                                        errors.exitPassUrl && touched.exitPassUrl
                                                            ? errors.exitPassUrl
                                                            : ""
                                                    }
                                                    error={errors.exitPassUrl && touched.exitPassUrl ? true : false}
                                                />
                                                <Switch
                                                    color="secondary"
                                                    type="checkbox"
                                                    disabled={!enableCustomUrl}
                                                    aria-label="state"
                                                    className={classes.rightSwitch}
                                                    checked={values.exitPassUrlState}
                                                    onChange={(event) => {
                                                        handleChange(event);
                                                        setTimeout(() => {
                                                            (document.querySelector("#exitPassUrl") as HTMLElement)?.focus();
                                                        }, 150);
                                                    }}
                                                    onBlur={handleBlur}
                                                    name="exitPassUrlState"
                                                    id="exitPassUrlState"
                                                />
                                            </Box>

                                            <Box position="relative" width="100%" display="flex">
                                                <MyTextField
                                                    value={values.exitFailUrl}
                                                    autoFocus
                                                    margin="dense"
                                                    id="exitFailUrl"
                                                    label={exitLinkWhenFailedLabel}
                                                    type="text"
                                                    fullWidth
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={classes.boxField}
                                                    InputProps={{ classes: { input: values.exitFailUrl ? classes.inputField : classes.inputFieldDisabled } }}
                                                    disabled={!values.exitFailUrlState}
                                                    helperText={
                                                        errors.exitFailUrl && touched.exitFailUrl
                                                            ? errors.exitFailUrl
                                                            : ""
                                                    }
                                                    error={errors.exitFailUrl && touched.exitFailUrl ? true : false}
                                                />
                                                <Switch
                                                    color="secondary"
                                                    type="checkbox"
                                                    aria-label="state"
                                                    disabled={!enableCustomUrl}
                                                    className={classes.rightSwitch}
                                                    checked={values.exitFailUrlState}
                                                    onChange={(event) => {
                                                        handleChange(event);
                                                        setTimeout(() => {
                                                            (document.querySelector("#exitFailUrl") as HTMLElement)?.focus();
                                                        }, 150);
                                                    }}
                                                    onBlur={handleBlur}
                                                    name="exitFailUrlState"
                                                    id="exitFailUrlState"
                                                />
                                            </Box>
                                        </Box>

                                    </Box>
                                </Box>
                                <BottomText
                                    customText={values.customText}
                                    useCustomText={values.useCustomText}
                                    selectedTextContent={values.selectedTextContent}
                                    enableCustomUrl={enableCustomUrl}
                                    error={errors.customText}
                                    showAll={showAll}
                                    toggleShowAll={toggleShowAll}
                                    handleBlur={handleBlur}
                                    handleChange={handleChange}
                                />
                            </DialogContent>
                        </Form>
                    </Dialog>
                );
            }}

        </Formik>
    );
};

export default TestSettings;
