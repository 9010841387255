import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { makeStyles } from 'tss-react/mui';
import { Link, Navigate } from "react-router-dom";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { usePostResetPassword } from "../../hooks/useApi";
import { Form, Formik, FormikProps } from "formik";
import * as Yup from "yup";
import DialogFormTitle from "../DialogFormTitle/DialogFormTitle";


export interface DoPasswordResetProps {
  email: string;
  token: string;
}

interface DoPasswordResetFormValues {
  password: string;
  confirmPassword: string;
}

const useStyles = makeStyles()((theme) => ({
  dialogMain: {
    width: "412px",
    margin: "60px auto",
    padding: "15px 25px",
    background: "white",
    WebkitBoxShadow: "5px 5px 15px 5px rgba(0,0,0,0.33)",
  },
  textField: {
    width: "100%",
    height: "57px",
    borderRadius: "4px",
    marginBottom: "20px",
    "& .MuiFormLabel-root": {
      color: theme.palette.warning.main + "!important"
    },
    "& .MuiInputBase-input": {
      color: theme.palette.primary.dark + "!important"
    },
    "& .Mui-disabled": {
      color: theme.palette.warning.main + "!important"
    },
  },
  submitBtn: {
    display: "block",
    margin: "20px auto",

  },
  link: {
    textAlign: "left",
  },
}));

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required("Password is required")
    .min(6, "Password is too short"),
  confirmPassword: Yup.string()
    .required("Confirm password is required")
    .min(6, "Confirm password is too short")
    .oneOf([Yup.ref("password")], "Passwords don't match!"),
});

const DoPasswordReset = ({ token, email }: DoPasswordResetProps) => {
  const { classes } = useStyles();
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [resetDone, setResetDone] = useState(false);

  const getMessage = () => {
    if (isError) {
      return (
        <Typography component={'span'}><p>{errorMessage}</p></Typography>
      );

    } else {
      return (
        <Typography component={'span'}>
          {resetDone ? (
            <Navigate to="/login?reset_success=true" />
          ) : (<p>
            Enter your new password.
          </p>)}
        </Typography>
      );
    }
  }

  return (
    <Formik
      initialValues={{ password: "", confirmPassword: "" }}
      onSubmit={({ password }) => {
        console.log(`onSubmit()`);
        usePostResetPassword({ token, email, newPassword: password }, (data) => {
          console.log(`onData() - ${JSON.stringify(data)}`);
          setIsError(false);
          setResetDone(true);
        },
          (error) => {
            console.log(`onError() - ${error}`);
            if (error === "TOKEN_NOT_FOUND") {
              setErrorMessage("Password reset link has expired. Request a new reset link to be sent to your email address.");
            } else {
              setErrorMessage("Please try again later");
            }
            setIsError(true);
          });
      }}
      validationSchema={validationSchema}
      enableReinitialize={true}
    >
      {(props: FormikProps<DoPasswordResetFormValues>) => {
        const { isValid, values, touched, errors, handleBlur, handleChange } = props;

        const isResetButtonDisabled = (): boolean => {
          if (isValid && values.password !== "" && values.confirmPassword !== "") {
            return false;
          }
          return true;
        };

        return (
          <div>
            <Form>
              <div className={classes.dialogMain}>
                <DialogFormTitle
                  Icon={AccountCircle}
                  text="Reset Your Password"
                  fontSize={30}
                />
                {getMessage()}
                <TextField
                  name="password"
                  id="password"
                  label="Password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="password"
                  variant="outlined"
                  color="secondary"
                  className={classes.textField}
                  helperText={
                    errors.password && touched.password
                      ? errors.password
                      : ""
                  }
                  error={errors.password && touched.password ? true : false}
                />
                <TextField
                  name="confirmPassword"
                  id="confirmPassword"
                  label="Confirm Password"
                  value={values.confirmPassword}
                  onChange={handleChange}
                  type="password"
                  variant="outlined"
                  color="secondary"
                  className={classes.textField}
                  helperText={
                    errors.confirmPassword && touched.confirmPassword
                      ? errors.confirmPassword
                      : ""
                  }
                  error={errors.confirmPassword && touched.confirmPassword ? true : false}
                />

                <Button id="submit_button"
                  className={classes.submitBtn}
                  type="submit"
                  variant="contained"
                  color="secondary"
                  disabled={isResetButtonDisabled()}
                >
                  Reset Password
                </Button>
                <div>
                  <Link to="/login" className={classes.link}>
                    <Typography variant="body1">Back to Login page</Typography>
                  </Link>
                </div>
              </div></Form>
          </div >
        );
      }}
    </Formik>
  );
};

export default DoPasswordReset;
