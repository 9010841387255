import axios, { AxiosError } from "axios";
import { AuthTokens, CandidatesExceeded } from "../context/auth";
import { getAccountDomainFromTestLink } from "../utils/accountDomain";
import { getLogger } from "../utils/logger";
import { PatchResponseData } from "../components/TestInfo/TestInfo";

export type MyAxiosError = AxiosError<{ message?: string, status?: string }>;

interface ResponseBase {
    status: string;
}

const log = getLogger();

const instance = axios.create({ baseURL: process.env.REACT_APP_BACKEND_ADDRESS, withCredentials: true });

export const getStatus = (status: string, useLimits?: boolean): string => {
    let useLimitsDisabled = false;
    if (typeof useLimits !== "undefined") {
        useLimitsDisabled = !useLimits;
    }

    if (status === "NOT STARTED") {
        return status;
    }

    if (status === "NOT PASSED") {
        return status;
    }

    if (status === "NOT_STARTED") {
        if (useLimitsDisabled) {
            return "INCOMPLETE";
        }

        return "NOT STARTED";
    }

    if (status === "COMPLETED" || status === "INCOMPLETE") {
        return status;
    }

    if (status === "NOT_FINISHED") {
        return "INCOMPLETE";
    }

    if (useLimitsDisabled) {
        return "COMPLETED";
    }

    if (status === "FAIL") {
        return "NOT PASSED";
    }

    return "PASSED";
};

export const arrayBufferToBase64 = (buffer: Iterable<number>): string => {
    let binary = "";
    const bytes = [].slice.call(new Uint8Array(buffer));
    bytes.forEach((byte) => binary += String.fromCharCode(byte));
    return window.btoa(binary);
};

const getIsNetworkError = (error: unknown) => {
    if (axios.isAxiosError(error)) {
        if (error.message === "Network Error") {
            return true;
        }
    }

    return false;
};

export const networkErrorMessage = "Please check your internet connection.";

type ImageData = {
    data: {
        data: Iterable<number>;
    },
    type: string;
};

export interface ResultRecipientType {
    email: string;
    selected: boolean;
};

export interface TextSelectionType {
    options: {
        textName: string;
        textId: string;
        content: string;
        copyright: string;
        isTheme: boolean;
    }[];
    selected: number;
}

export interface GetNewTemplateData {
    name: string;
    active: boolean;
    duration: number;
    attempts: number;
    limits: {
        inUse: boolean;
        shownToCandidate: boolean;
        netWpm: number;
        accuracy: number;
    };
    textSelection: TextSelectionType;
    customText: string;
    useCustomText: boolean;
    note: {
        text: string;
        enabled: boolean;
    };
    infoFieldOne: {
        enabled: boolean;
        title: string;
    };
    infoFieldTwo: {
        enabled: boolean;
        title: string;
    };
    sendResultsByEmail: boolean;
    resultRecipients: ResultRecipientType[];
    textId: string;
    migratedResultEmails: string;
    exitPassUrl: {
        enabled: boolean;
        title: string;
    };
    exitFailUrl: {
        enabled: boolean;
        title: string;
    },
    newAttemptsAllowed: number,
    accessCode: {
        enabled: boolean,
        title: string
    }
}

interface GetNewTemplateResponse {
    status: string;
    template: GetNewTemplateData;
}

export const useGetNewTemplate = async (onData: (data: PostNewTestParams) => void, onError?: (status: string) => void): Promise<void> => {
    try {
        const { data } = await instance.get<GetNewTemplateResponse>(`api/manage-tests/new/template`);
        const message = convertFromApi(data.template);
        onData(message);
    } catch (error) {
        log.error(`useGetNewTemplate() - ${error}`);
        if (axios.isAxiosError(error) && error.response && error.response.data && error.response.data.status) {
            log.error(
                `useGetNewTemplate() - error response data status: ${JSON.stringify(error.response.data.status)}`
            );
            onError && onError(error.response.data.status)
        }
    }
};

export const useActivateTest = async (
    testId: string,
    active: boolean,
    success?: () => void,
    onError?: (status: string) => void,
): Promise<void> => {
    try {
        log.info(`useActivateTest()`);
        const response = await instance.patch<PatchResponseData>(`api/manage-tests/${testId}/status`, {
            active
        });
        log.info(`useActivateTest() - response: ${JSON.stringify(response.data)}`);
        success && success()
    } catch (error) {
        log.error(`useActivateTest() - ${error}`);
        if (axios.isAxiosError(error) && error.response && error.response.data && error.response.data.status) {
            log.error(
                `useActivateTest() - error response data status: ${JSON.stringify(error.response.data.status)}`
            );
            onError && onError(error.response.data.status)
        }
    }
};


interface PostLoginParams {
    email: string;
    password: string;
    accountDomain: string;
};

export const usePostLogin = async (
    params: PostLoginParams,
    onData: (data: AuthTokens) => void,
    onError: (status: string) => void
): Promise<void> => {
    try {
        log.debug(`usePostLogin() - sending values: ${JSON.stringify(params, null, 2)}`);
        const { data } = await instance.post<AuthTokens>(`api/login`, params);

        log.debug(`usePostLogin() - response: ${JSON.stringify(data, null, 2)}`);
        onData(data);
    } catch (error) {
        log.error(`usePostLogin() - error: ${JSON.stringify(error, null, 2)}`);
        if (axios.isAxiosError(error) && error.response && error.response.data && error.response.data.status) {
            log.error(
                `usePostLogin() - error response data status: ${JSON.stringify(error.response.data.status)}`
            );
            onError(error.response.data.status);
        }
    }
};


interface PostForgotPasswordParams {
    email: string;
};

interface PostForgotPasswordResponse {
    status: string;
}

export const usePostForgotPassword = async (
    params: PostForgotPasswordParams,
    onData: (data: PostForgotPasswordResponse) => void,
    onError: (status: string) => void
): Promise<void> => {
    try {
        log.debug(`usePostForgotPassword() - sending values: ${JSON.stringify(params, null, 2)}`);
        const { data } = await instance.post<PostForgotPasswordResponse>(`api/password/forgot`, params);

        log.debug(`usePostForgotPassword() - response: ${JSON.stringify(data, null, 2)}`);
        onData(data);
    } catch (error) {
        log.error(`usePostForgotPassword() - error: ${JSON.stringify(error, null, 2)}`);
        if (axios.isAxiosError(error) && error.response && error.response.data && error.response.data.status) {
            log.error(
                `usePostForgotPassword() - error response data status: ${JSON.stringify(error.response.data.status)}`
            );
            onError(error.response.data.status);
        }
    }
};


interface PostResetPasswordParams {
    token: string;
    email: string;
    newPassword: string;
};

interface PostResetPasswordResponse {
    status: string;
}

export const usePostResetPassword = async (
    params: PostResetPasswordParams,
    onData: (data: PostResetPasswordResponse) => void,
    onError: (status: string) => void
): Promise<void> => {
    try {
        log.debug(`usePostResetPassword() - sending values: ${JSON.stringify(params, null, 2)}`);
        const { data } = await instance.post<PostForgotPasswordResponse>(`api/password/reset`, params);

        log.debug(`usePostResetPassword() - response: ${JSON.stringify(data, null, 2)}`);
        onData(data);
    } catch (error) {
        log.error(`usePostResetPassword() - error: ${JSON.stringify(error, null, 2)}`);
        if (axios.isAxiosError(error) && error.response && error.response.data && error.response.data.status) {
            log.error(
                `usePostResetPassword() - error response data status: ${JSON.stringify(error.response.data.status)}`
            );
            onError(error.response.data.status);
        }
    }
};


interface GetTestDataParams {
    testId: string;
}

interface GetTestDataResponse {
    status: string;
    test: GetNewTemplateData;
}

export const useGetTestData = async (
    params: GetTestDataParams,
    onData: (data: PostNewTestParams) => void,
): Promise<void> => {
    try {
        log.debug(`useGetTestData() - getting test test data for testId: ${JSON.stringify(params.testId, null, 2)}`);
        const { data } = await instance.get<GetTestDataResponse>(`api/manage-tests/${params.testId}`);
        log.debug(`useGetTestData() - response: ${JSON.stringify(data, null, 2)}`);
        const message = convertFromApi(data.test);
        onData(message);
    } catch (error) {
        log.error(`useGetTestData() - ${error}`);
        if (axios.isAxiosError(error) && error.response && error.response.data && error.response.data.status) {
            log.error(
                `useGetTestData() - error response data status: ${JSON.stringify(error.response.data.status)}`
            );
        }
    }
};

export const useGetTestStatistics = async (testId: string, onData: (statistics: any) => void) => {
    try {
        log.debug(`useGetTestStatistics()`);
        const { data } = await instance.get(`api/test/${testId}/statistics`);
        if (data && data.updated) {
            onData(data.statistics);
        }
    } catch (error) {
        log.error(`useGetTestStatistics() - error ${error}`);
    }
};

export const useGetTestDataForCopy = async (
    params: GetTestDataParams,
    onData: (data: PostNewTestParams) => void,
    onError?: (status: string) => void
): Promise<void> => {
    try {
        log.debug(`useGetTestDataForCopy() - getting test test data for testId: ${JSON.stringify(params.testId, null, 2)}`);
        const { data } = await instance.get<GetTestDataResponse>(`api/manage-tests/copy/${params.testId}`);
        log.debug(`useGetTestDataForCopy() - response: ${JSON.stringify(data, null, 2)}`);
        const message = convertFromApi(data.test);
        onData(message);
    } catch (error) {
        log.error(`useGetTestDataForCopy() - ${error}`);
        if (axios.isAxiosError(error) && error.response && error.response.data && error.response.data.status) {
            log.error(
                `useGetTestDataForCopy() - error response data status: ${JSON.stringify(error.response.data.status)}`
            );
            onError && onError(error.response.data.status)
        }
    }
};

interface TextSelectionOption {
    textName: string;
    textId: string;
    copyright: string;
    content: string;
};

export interface GetTestsData {
    name: string;
    active: boolean;
    testUrl: string;
    testId: string;
    attempts: number;
    duration: number;
    limits: {
        inUse: boolean;
        shownToCandidate: boolean;
        netWpm: number;
        accuracy: number;
    };
    textSelection: {
        options: TextSelectionOption[];
        selected: number;
    };
    customText: string;
    useCustomText: boolean;
    note: {
        enabled: boolean;
        text: string;
    };
    infoFieldOne: InfoField;
    infoFieldTwo: InfoField;
    sendResultsByEmail: boolean;
    resultRecipients: {
        email: string;
        selected: boolean;
    }[];
    textId: string;
    text: string;
    statistics: { candidates: number; passed: number; notPassed: number; sevenDays: number };
    createdAt: Date;
}

interface GetTestsResponse {
    status: string;
    tests: GetTestsData[];
}

interface DeletedAccountsResponse {
    status: string;
}

export const useGetTests = async (
    onData: (data: GetTestsData[]) => void,
    onError: (status: string) => void,
): Promise<void> => {
    try {
        const { data } = await instance.get<GetTestsResponse>(`api/manage-tests`);
        onData(data.tests);
    } catch (error) {
        log.error(`useGetTests() - ${error}`);
        if (axios.isAxiosError(error) && error.response && error.response.data && error.response.data.status) {
            log.error(`useGetTests() - error response data status: ${JSON.stringify(error.response.data.status)}`);
            onError(error.response.data.status);
        }
    }
};
type SubscriptionDueDateMessageType = "warning" | "error" | "";
export interface DueDateInformationProps {
    subscriptionIsAvailable: SubscriptionIsAvailable;
    candidatesExceeded: CandidatesExceeded;
}
interface GetSubscriptionDueDateProps {
    status: string;
    dueDateInformation: SubscriptionIsAvailable;
    candidatesExceeded: CandidatesExceeded;
}

export interface SubscriptionIsAvailable {
    available: boolean;
    messageType: SubscriptionDueDateMessageType;
}

export const useGetSubscriptionDueDate = async (
    onData: (dueDate: DueDateInformationProps) => void
): Promise<void> => {
    try {
        const { data } = await instance.get<GetSubscriptionDueDateProps>(`api/subscription/due-date`);
        onData({ subscriptionIsAvailable: data.dueDateInformation, candidatesExceeded: data.candidatesExceeded });
    } catch (error) {
        log.error(`useGetSubscriptionDueDate() - ${error}`);
        if (axios.isAxiosError(error) && error.response && error.response.data && error.response.data.status) {
            log.error(`useGetSubscriptionDueDate() - error response data status: ${JSON.stringify(error.response.data.status)}`);
        }
    }
};

interface InfoField {
    enabled: boolean;
    title: string;
};

interface exitPassField {
    enabled: boolean;
    title: string;
};

export interface accessCodeField {
    enabled: boolean;
    title: string;
};

export interface PostNewTestParams {
    name: string;
    active: boolean;
    duration: string;
    attempts: string;
    limits: {
        inUse: boolean;
        shownToCandidate: boolean;
        netWpm: string;
        accuracy: string;
    };
    textSelection: TextSelectionType;
    customText: string;
    useCustomText: boolean;
    note: {
        text: string;
        enabled: boolean;
    };
    infoFieldOne: InfoField;
    infoFieldTwo: InfoField;
    sendResultsByEmail: boolean;
    resultRecipients: ResultRecipientType[];
    textId: string;
    migratedResultEmails: string,
    exitPassUrl: exitPassField,
    exitFailUrl: exitPassField,
    newAttemptsAllowed: number,
    accessCode: accessCodeField
};

interface PostNewTestResponse {
    status: string;
    test: {
        invalidCharacters: string;
    }
};

interface PostInviteCandidatesResponse {
    status: string;
    message: string
};

interface PostNewTestData {
    status: string;
    test: {
        invalidCharacters: string;
    }
};

const convertToApi = (params: PostNewTestParams): GetNewTemplateData => {
    const message: GetNewTemplateData = {
        name: params.name,
        active: params.active,
        duration: Number(params.duration),
        attempts: Number(params.attempts),
        limits: {
            inUse: params.limits.inUse,
            shownToCandidate: params.limits.shownToCandidate,
            netWpm: Number(params.limits.netWpm),
            accuracy: Number(params.limits.accuracy)
        },
        textSelection: params.textSelection,
        customText: params.customText,
        useCustomText: params.useCustomText,
        note: params.note,
        infoFieldOne: {
            enabled: params.infoFieldOne.enabled,
            title: params.infoFieldOne.title
        },
        infoFieldTwo: {
            enabled: params.infoFieldTwo.enabled,
            title: params.infoFieldTwo.title
        },
        sendResultsByEmail: params.sendResultsByEmail,
        resultRecipients: params.resultRecipients,
        textId: params.textId,
        migratedResultEmails: params.migratedResultEmails,
        exitPassUrl: {
            enabled: params.exitPassUrl.enabled,
            title: params.exitPassUrl.title
        },
        exitFailUrl: {
            enabled: params.exitFailUrl.enabled,
            title: params.exitFailUrl.title
        },
        newAttemptsAllowed: params.newAttemptsAllowed,
        accessCode: {
            enabled: params.accessCode.enabled,
            title: params.accessCode.title
        },
    };
    return message;
};

const convertFromApi = (params: GetNewTemplateData): PostNewTestParams => {
    const message: PostNewTestParams = {
        name: params.name,
        active: params.active,
        duration: String(params.duration),
        attempts: String(params.attempts),
        limits: {
            inUse: params.limits.inUse,
            shownToCandidate: params.limits.shownToCandidate,
            netWpm: String(params.limits.netWpm),
            accuracy: String(params.limits.accuracy)
        },
        textSelection: {
            options: params.textSelection.options,
            selected: params.textSelection.selected
        },
        customText: params.customText,
        useCustomText: params.useCustomText,
        note: params.note,
        infoFieldOne: {
            enabled: params.infoFieldOne.enabled,
            title: params.infoFieldOne.title
        },
        infoFieldTwo: {
            enabled: params.infoFieldTwo.enabled,
            title: params.infoFieldTwo.title
        },
        sendResultsByEmail: params.sendResultsByEmail,
        resultRecipients: params.resultRecipients,
        textId: params.textId,
        migratedResultEmails: params.migratedResultEmails,
        exitPassUrl: params.exitPassUrl,
        exitFailUrl: params.exitFailUrl,
        newAttemptsAllowed: params.newAttemptsAllowed,
        accessCode: params.accessCode,
    };
    return message;
};

export const usePostNewTest = async (
    params: PostNewTestParams,
    onData: (data: PostNewTestData) => void,
    onError?: (status: string) => void,
): Promise<void> => {
    try {
        const message: GetNewTemplateData = convertToApi(params);
        log.debug(`usePostNewTest() - submitting values: ${JSON.stringify(message, null, 2)}`);
        const { data } = await instance.post<PostNewTestResponse>(`api/manage-tests`, message);

        log.debug(`usePostNewTest() - response: ${JSON.stringify(data, null, 2)}`);
        onData(data);
    } catch (error) {
        log.error(`usePostNewTest() - error: ${JSON.stringify(error, null, 2)}`);
        if (axios.isAxiosError(error) && error.response && error.response.data && error.response.data.status) {
            onError && onError(error.response.data.status);
            log.error(
                `usePostNewTest() - error response data status: ${JSON.stringify(error.response.data.status)}`
            );
        }
    }
};


interface UpdateTestParams {
    testId: string;
    testUpdate: PostNewTestParams;
}

export const useUpdateTest = async (
    params: UpdateTestParams,
    onData: (data: PostNewTestData) => void,
    onError: (status: string) => void
): Promise<void> => {
    try {
        const message: GetNewTemplateData = convertToApi(params.testUpdate);
        log.debug(`useUpdateTest() - submitting values: ${JSON.stringify(message, null, 2)}`);
        const { data } = await instance.patch<PostNewTestResponse>(`api/manage-tests/${params.testId}`, message);
        log.debug(`useUpdateTest() - response: ${JSON.stringify(data, null, 2)}`);
        onData(data);
    } catch (error) {
        log.error(`useUpdateTest() - error: ${JSON.stringify(error, null, 2)}`);
        if (axios.isAxiosError(error) && error.response && error.response.data && error.response.data.status) {
            onError(error.response.data.status);
            log.error(
                `useInviteCandidatesForTest() - error response data status: ${JSON.stringify(error.response.data.status)}`
            );
        }
    }
};

export interface InviteCandidatesProps {
    testId: string,
    emails: string[],
    greetingText: string,
    testUrl: string,
}

export const useInviteCandidatesForTest = async (
    onError: (error: string) => void,
    params: InviteCandidatesProps,
    onSuccess: (message: string) => void
): Promise<void> => {
    try {
        const { data } = await instance.post<PostInviteCandidatesResponse>(`api/test/${params.testId}/invite/candidates`, params);
        log.debug(`useInviteCandidatesForTest() - response: ${JSON.stringify(data, null, 2)}`);
        data.status === "OK" && onSuccess(data.message);
    } catch (error) {
        log.error(`useInviteCandidatesForTest() - error: ${JSON.stringify(error, null, 2)}`);
        if (axios.isAxiosError(error) && error.response && error.response.data && error.response.data.status) {
            onError(error.response.data.status);
            log.error(
                `useInviteCandidatesForTest() - error response data status: ${JSON.stringify(error.response.data.status)}`
            );
        }
    }
};

interface DeleteTestParams {
    testId: string;
}

export const useDeleteTest = async (
    params: DeleteTestParams,
    onData: (data: GetTestsData[]) => void
): Promise<void> => {
    try {
        log.debug(`useDeleteTest() - deleting test: ${JSON.stringify(params.testId, null, 2)}`);
        const { data } = await instance.delete<GetTestsResponse>(`api/manage-tests/${params.testId}`);

        log.debug(`useDeleteTest() - response: ${JSON.stringify(data, null, 2)}`);
        onData(data.tests);
    } catch (error) {
        log.error(`useDeleteTest() - error, refreshing tests: ${JSON.stringify(error, null, 2)}`);
        useGetTests(
            onData,
            (newError) => {
                log.error(`useDeleteTest() - new error error: ${JSON.stringify(newError, null, 2)}`);
            }
        );
    }
};

export interface GetAccountAdminsData {
    firstName: string;
    lastName: string;
    email: string;
    registered: boolean;
    adminId: string;
    current: boolean;
    lastLogin: string;
    joinByInvitation: boolean;
};

interface GetAccountAdminsResponse {
    status: string;
    admins: GetAccountAdminsData[];
};

interface GetAccountAdminsListResponse extends GetAccountAdminsResponse {
    allowInvite: boolean;
};

export const useGetAccountAdmins = async (onData: (data: GetAccountAdminsData[]) => void, allowInvite?: (allow: boolean) => void): Promise<void> => {
    try {
        const { data } = await instance.get<GetAccountAdminsListResponse>(`api/account/admins`);
        log.debug(`useGetAccountAdmins() - response: ${JSON.stringify(data, null, 2)}`);
        onData(data.admins);
        allowInvite && allowInvite(data.allowInvite);
    } catch (error) {
        log.error(`useGetAccountAdmins() - ${error}`);
        if (axios.isAxiosError(error) && error.response && error.response.data && error.response.data.status) {
            log.error(
                `useGetAccountAdmins() - error response data status: ${JSON.stringify(error.response.data.status)}`
            );
        }
    }
};


export interface PatchAccountAdminParams {
    firstName: string;
    lastName: string;
    email: string;
    password?: string;
    newPassword?: string;
    adminId?: string;
};

interface PatchAccountAdminResponse {
    status: string;
    admin: {
        firstName: string;
        lastName: string;
        email: string;
    }
};

export const usePatchAccountAdmin = async (
    params: PatchAccountAdminParams,
    onData: (data: PatchAccountAdminResponse) => void,
    onError: (error: string) => void
): Promise<void> => {
    try {
        log.debug(`usePatchAccountAdmin() - sending values: ${JSON.stringify(params, null, 2)}`);
        const { data } = await instance.patch<PatchAccountAdminResponse>(`api/account/admins`, params);

        log.debug(`usePatchAccountAdmin() - response: ${JSON.stringify(data, null, 2)}`);
        onData(data);
    } catch (error) {
        log.error(`usePatchAccountAdmin() - error: ${JSON.stringify(error, null, 2)}`);
        if (axios.isAxiosError(error) && error.response && error.response.data && error.response.data.status) {
            log.error(`usePatchAccountAdmin() - error response data status: ${JSON.stringify(error.response.data.status)}`);
            onError(error.response.data.status);
        }
    }
};

export const useUpdateAutoLoginSettings = async (
    autoLogin: boolean,
    onSuccess: () => void,
    onError: () => void,
) => {
    try {
        log.debug('useUpdateAutoLoginSettings()');
        await instance.post('api/account/integrations/autoLogin', { autoLogin });
        onSuccess();
    } catch (error) {
        log.error(`useUpdateAutoLoginSettings() - error: ${error}`);
        onError();
    }
}

export interface GetAllAccountsData {
    companyName: string;
    accountId: string;
    firstName: string;
    lastName: string;
    email: string;
    lastUsed: Date;
    createdAt: Date;
    billing: string;
    validUntil: Date;
    planInformation: AllPlansWithUserPlanProps[];
    candidatesThisMonth: number;
    fastSpringLegacyId: string;
    expired: boolean;
    exceeded: boolean;
}

export interface GetAllAccountsWithoutAdminData {
    companyName: string;
    accountId: string;
    firstName: string;
    lastName: string;
    lastUsed: Date;
    createdAt: Date;
    candidatesThisMonth: number;
}


export interface AllPlansWithUserPlanProps {
    name: string;
    planId: number;
    selected: boolean;
}

export interface PlansInformationProps {
    name: string;
    id: number;
}

interface GetAllAccountsResponse {
    status: string;
    accounts: GetAllAccountsData[];
    count: number;
}

interface GetAllAccountsWithoutAdminResponse {
    status: string;
    accounts: GetAllAccountsWithoutAdminData[];
    count: number;
}

export const useGetAllAccountsData = async (
    params: getAccountsParams,
    onData: (data: GetAllAccountsData[]) => void,
    setCount: (count: number) => void,
): Promise<void> => {
    try {
        const urlSearchParams = convertUrlParamsToSearchParams<getAccountsParams>(params);
        const { data } = await instance.get<GetAllAccountsResponse>(`api/account/all?${urlSearchParams.toString()}`);
        onData(data.accounts);
        setCount(data.count)
    } catch (error) {
        log.error(`useGetAllAccountsData() - ${error}`);
        if (axios.isAxiosError(error) && error.response && error.response.data && error.response.data.status) {
            log.error(
                `useGetAllAccountsData() - error response data status: ${JSON.stringify(error.response.data.status)}`
            );
        }
    }
};

export const useGetAllAccountsDataWithoutAdmin = async (
    params: getAccountsParams,
    onData: (data: GetAllAccountsWithoutAdminData[]) => void,
    setCount: (count: number) => void,
): Promise<void> => {
    try {
        const urlParams = convertUrlParamsToString<getAccountsParams>(params);
        const { data } = await instance.get<GetAllAccountsWithoutAdminResponse>(`api/account/all/without-admin${urlParams}`);
        onData(data.accounts);
        setCount(data.count)
    } catch (error) {
        log.error(`useGetAllAccountsData() - ${error}`);
        if (axios.isAxiosError(error) && error.response && error.response.data && error.response.data.status) {
            log.error(
                `useGetAllAccountsData() - error response data status: ${JSON.stringify(error.response.data.status)}`
            );
        }
    }
};

interface PostSelectAccountParams {
    accountId: string;
}

interface PostSelectAccountResponse {
    status: string;
    companyName: string;
    email?: string;
    superAdmin?: boolean;
    adminId?: string;
}

export const usePostSelectAccount = async (
    params: PostSelectAccountParams,
    onData: (data: PostSelectAccountResponse) => void
): Promise<void> => {
    try {
        const { data } = await instance.post<PostSelectAccountResponse>(`api/account/${params.accountId}/select`);
        log.debug(`usePostSelectAccount() - response: ${JSON.stringify(data, null, 2)}`);
        onData(data);
        log.debug(`usePostSelectAccount() - finished`);
    } catch (error) {
        log.error(`usePostSelectAccount() - error: ${JSON.stringify(error, null, 2)}`);
    }
};


interface PostAdminInvitationResponse {
    status: string;
}

export const usePostAdminInvitation = async (email: string, onData: (status: string) => void): Promise<void> => {
    try {
        const { data } = await instance.post<PostAdminInvitationResponse>(`api/invitations`, {
            email: email
        });
        log.debug(`usePostAdminInvitation() - response: ${JSON.stringify(data, null, 2)}`);
        onData(data.status);
    } catch (error) {
        log.error(`usePostAdminInvitation() - ${error}`);
        if (axios.isAxiosError(error) && error.response && error.response.data && error.response.data.status) {
            log.error(
                `usePostAdminInvitation() - error response data status: ${JSON.stringify(error.response.data.status)}`
            );
            onData(error.response.data.status);
        }
    }
};

export const usePostAdminInvitationForAccountWithoutAdmin = async (email: string, accountId: string, onData: () => void): Promise<void> => {
    try {
        const { data } = await instance.post<PostAdminInvitationResponse>(`api/invitations/account-without-admin`, {
            email: email,
            accountId: accountId
        });
        log.debug(`usePostAdminInvitationForAccountWithoutAdmin() - response: ${JSON.stringify(data, null, 2)}`);
        onData();
    } catch (error) {
        log.error(`usePostAdminInvitationForAccountWithoutAdmin() - ${error}`);
    }
};

interface GetAdminInvitationResponse {
    status: string;
}

export const useGetAdminInvitation = async (token: string, onData: (status: string) => void): Promise<void> => {
    try {
        const { data } = await instance.get<GetAdminInvitationResponse>(`api/invitations?token=${token}`);
        log.debug(`useGetAdminInvitation() - response: ${JSON.stringify(data, null, 2)}`);
        onData(data.status);
    } catch (error) {
        log.error(`useGetAdminInvitation() - ${error}`);
        if (axios.isAxiosError(error) && error.response && error.response.data && error.response.data.status) {
            log.error(
                `useGetAdminInvitation() - error response data status: ${JSON.stringify(error.response.data.status)}`
            );
            onData(error.response.data.status);
        }

    }
};

interface PostAcceptAdminInvitationParams {
    token: string;
    firstName: string;
    lastName: string;
    password: string;
}

interface PostAcceptAdminInvitationResponse {
    status: string;
}

export const usePostAcceptAdminInvitation = async (
    params: PostAcceptAdminInvitationParams,
    onData: (success: boolean) => void
): Promise<void> => {
    try {
        const { data } = await instance.post<PostAcceptAdminInvitationResponse>(`api/invitations/accept`, {
            ...params
        });
        log.debug(`usePostAcceptAdminInvitation() - response: ${JSON.stringify(data, null, 2)}`);
        onData(data.status === "OK" ? true : false);
    } catch (error) {
        log.error(`usePostAcceptAdminInvitation() - ${error}`);
        if (axios.isAxiosError(error) && error.response && error.response.data && error.response.data.status) {
            log.error(
                `usePostAcceptAdminInvitation() - error response data status: ${JSON.stringify(
                    error.response.data.status
                )}`
            );
        }
        onData(false);
    }
};

interface DeleteAdminParams {
    adminId: string;
}

export const useDeleteAdmin = async (
    params: DeleteAdminParams,
    onData: (status: string, admins: GetAccountAdminsData[]) => void
): Promise<void> => {
    try {
        log.debug(`useDeleteAdmin() - deleting admin: ${JSON.stringify(params.adminId, null, 2)}`);
        const { data } = await instance.delete<GetAccountAdminsResponse>(`api/account/admins/${params.adminId}`);

        log.debug(`useDeleteAdmin() - response: ${JSON.stringify(data, null, 2)}`);
        onData(data.status, data.admins);
    } catch (error) {
        log.error(`usePostAcceptAdminInvitation() - ${error}`);
        if (axios.isAxiosError(error) && error.response && error.response.data && error.response.data.status) {
            log.error(`useDeleteAdmin() - error: ${JSON.stringify(error, null, 2)}`);
            onData(error.response.data.status, []);
        }
    };
};

interface UserInformation {
    status: string;
    firstName: string;
    lastName: string;
    email: string;
    adminId: string;
}
export const useGetUserInformation = async (
    adminId: string,
    onData: (data: PatchAccountAdminParams) => void
): Promise<void> => {
    try {
        log.debug(`useGetUserInformation() - get user: ${adminId}`);
        const { data } = await instance.get<UserInformation>(`api/account/admins/${adminId}`);

        log.debug(`useGetUserInformation() - response: ${JSON.stringify(data, null, 2)}`);
        onData({
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            adminId: data.adminId
        });
    } catch (error) {
        log.error(`useGetUserInformation() - ${error}`);
        if (axios.isAxiosError(error) && error.response && error.response.data && error.response.data.status) {
            log.error(`useGetUserInformation() - error: ${JSON.stringify(error, null, 2)}`);
        }
    };
};


interface TestResult {
    status: string;
    testId: string;
    time: number;
    timePassed: boolean;
    hits: number;
    errorCount: number;
    duration: number;
    email: string;
    firstName: string;
    lastName: string;
    attemptsLeft: number;
    testName: string;
    createdAt: string;
    netWpm: number;
    netWpmPassed: boolean;
    grossWpm: number;
    accuracy: number;
    accuracyPassed: boolean;
    maxAttempts: number;
    netWpmToAvgPercentage: number;
    resultId: string;
}

export interface CandidateData {
    candidateId: string;
    email: string;
    firstName: string;
    lastName: string;
    status: string;
    netWpm: number;
    netWpmPassed: boolean;
    netWpmToAvgPercentage: number;
    attempts: number;
    accuracy: number;
    accuracyPassed: boolean;
    grossWpm: number;
    createdAt: string;
    infoField1: string;
    infoField2: string;
    results: [TestResult];
}

export interface GetTestResultsData {
    testName: string;
    testId: string;
    active: boolean;
    statistics: {
        candidates: number;
        passed: number;
        notPassed: number;
        sevenDays: number;
        thirtyDays: number;
        netSpeedAverage: number;
        accuracyAverage: number;
    };
    infoFieldOne: {
        enabled: boolean;
        title: string;
    };
    infoFieldTwo: {
        enabled: boolean;
        title: string;
    };
    attempts: number;
    candidates: [CandidateData];
}

export interface CandidateBestResultData {
    candidateId: string;
    email: string;
    firstName: string;
    lastName: string;
    infoFieldOneValue: string;
    infoFieldTwoValue: string;
    lastResultDate: string;
    status: string;
    netWpm: number;
    netWpmPassed: boolean;
    netWpmToAvgPercentage: number;
    accuracy: number;
    accuracyPassed: boolean;
    grossWpm: number;
    createdAt: string;
    attempts: number;
    testName: string;
    errorCount: number;
    hits: number;
    testId: string;
}

export interface GetBestTestResultsData {
    testName: string;
    testId: string;
    useLimits: boolean;
    statistics: {
        candidates: number;
        passed: number;
        notPassed: number;
        sevenDays: number;
        thirtyDays: number;
        netSpeedAverage: number;
        accuracyAverage: number;
    };
    infoFieldOne: {
        enabled: boolean;
        title: string;
    };
    infoFieldTwo: {
        enabled: boolean;
        title: string;
    };
    attempts: number;
    results: CandidateBestResultData[];
    totalCandidates: number;
    hits: number;
    errorCount: number;
    allTests: {
        testName: string;
        testId: string;
        active: boolean;
    }[];
}

export interface GetBestTestResultsResponse {
    status: string;
    test: GetBestTestResultsData;
    trialPlanExceeded: boolean;
}

export type SortBy = "name" | "date" | "attempts" | "accuracy" | "status" | "netWpm" | "hits" | "errorCount";
export type SortByForCustomers = "company" | "email" | "createdAt" | "lastUsed" | "candidateMonth" | "plan" | "validUntil";
export type OrderBy = "asc" | "desc" | "none";

interface GetBestTestResultsParams {
    testId: string | undefined;
    page: number | undefined;
    size: number | undefined;
    activeTab?: string;
    sortBy: SortBy;
    orderBy: OrderBy;
    search?: string;
}

interface GetBestTestResultsForAllTestParams {
    page: number | undefined;
    size: number | undefined;
    sortBy: SortBy;
    orderBy: OrderBy;
    search?: string;
}

export interface getAccountsParams {
    page: number | undefined;
    size: number | undefined;
    sortBy: SortByForCustomers;
    orderBy: OrderBy;
    search?: string;
    searchByEmail?: boolean;
    hideTP?: 1;
}


// eslint-disable-next-line @typescript-eslint/no-explicit-any
const convertUrlParamsToString = <T extends { [key: string]: any }>(params: T): string => {
    let p = "";
    for (const [key, value] of Object.entries(params)) {
        if (value >= 0 || (value !== "" && value !== undefined)) {
            if (p === "") {
                p = `?${key}=${encodeURIComponent(value)}`;
            } else {
                p = p + `&${key}=${encodeURIComponent(value)}`;
            }
        }
    }
    return p;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const convertUrlParamsToSearchParams = <T extends { [key: string]: any }>(params: T): URLSearchParams => {
    const searchParams = new URLSearchParams();
    for (const [key, value] of Object.entries(params)) {
        if (value >= 0 || (value !== "" && value !== undefined)) {
            searchParams.set(key, value);
        }
    }
    return searchParams;
};

export const useGetBestTestResults = async (params: GetBestTestResultsParams, onData: (data: GetBestTestResultsResponse) => void): Promise<void> => {
    try {
        const urlParams = convertUrlParamsToString<GetBestTestResultsParams>(params);

        const { data } = await instance.get<GetBestTestResultsResponse>(`api/testresults/best${urlParams}`);
        onData(data);
    } catch (error) {
        log.error(`useGetBestTestResults() - ${error}`);
        if (axios.isAxiosError(error) && error.response && error.response.data && error.response.data.status) {
            log.error(
                `useGetBestTestResults() - error response data status: ${JSON.stringify(error.response.data.status)}`
            );
        }
    }
};

export interface GetCsvTestResultsData {
    fullName?: string;
    email: string;
    status: string;
    netWpm: number;
    netWpmToAvgPercentage: number;
    accuracy: number;
    grossWpm: number;
    hits: number;
    errorCount: number;
    createdAt: string;
    attempts: number;
    info1: string;
    info2: string;
    testName?: string;
}

export interface GetCsvTestResultsResponse {
    status: string;
    results: GetCsvTestResultsData[];
}

interface GetCsvTestResultsParams {
    testId: string;
}

export const useGetCsvTestResults = async (params: GetCsvTestResultsParams, onData: (data: GetCsvTestResultsResponse) => void): Promise<void> => {
    try {
        const { data } = await instance.get<GetCsvTestResultsResponse>(`api/testresults/csv?testId=${params.testId}`);
        onData(data);
    } catch (error) {
        log.error(`useGetCsvTestResults() - ${error}`);
        if (axios.isAxiosError(error) && error.response && error.response.data && error.response.data.status) {
            log.error(
                `useGetCsvTestResults() - error response data status: ${JSON.stringify(error.response.data.status)}`
            );
        }
    }
};


export type NetWpm = {
    netWpm: string;
    passed: boolean;
    resultId: string; //for CandidateDetails so it can delete INCOMPLETE result based on resultId
};

export type Accuracy = {
    accuracy: string;
    passed: boolean;
};

export interface GetCandidateTestResultsData {
    testName: string;
    testId: string;
    useLimits: boolean;
    candidateId: string;
    firstName: string;
    bestResultId?: string;
    lastName: string;
    status: string;
    email: string;
    infoFieldOneTitle: string;
    infoFieldOneValue: string;
    infoFieldOneEnabled: boolean;
    infoFieldTwoTitle: string;
    infoFieldTwoValue: string;
    infoFieldTwoEnabled: boolean;
    attempts: number;
    testResults: {
        resultId: string;
        createdAt: string;
        status: string;
        netWpm: NetWpm;
        netWpmToAvgPercentage: string;
        accuracy: Accuracy;
        grossWpm: string;
        errorCount: number;
        hits: number;
    }[];
    resultsInOtherTests: {
        testId: string;
        count: number;
        name: string;
    }[];
};

export interface GetCandidateTestResultsResponse {
    status: string;
    candidate: GetCandidateTestResultsData;
}

interface GetCandidateTestResultsParams {
    testId: string;
    email: string;
    sortBy?: SortBy;
    orderBy?: OrderBy;
}

export const useGetCandidateTestResults = async (params: GetCandidateTestResultsParams, onData: (data: GetCandidateTestResultsData) => void): Promise<void> => {
    try {
        const { data } = await instance.get<GetCandidateTestResultsResponse>(`api/testresults/candidate?testId=${params.testId}&email=${encodeURIComponent(params.email)}&sortBy=${params.sortBy ? params.sortBy : "date"}&orderBy=${params.orderBy ? params.orderBy : "desc"}`);
        onData(data.candidate);
    } catch (error) {
        log.error(`useGetCandidateTestResults() - ${error}`);
        if (axios.isAxiosError(error) && error.response && error.response.data && error.response.data.status) {
            log.error(
                `useGetCandidateTestResults() - error response data status: ${JSON.stringify(error.response.data.status)}`
            );
        }
    }
};


interface PostTestResultsParams {
    testId: string;
    candidateId: string;
}

interface PostTestResultResponseData {
    status: string;
    resultId: string;
}

export const usePostTestResults = async (params: PostTestResultsParams, onData: (data: PostTestResultResponseData) => void): Promise<void> => {
    try {
        const { data } = await instance.post<PostTestResultResponseData>(`api/testresults`, {
            testId: params.testId,
            candidateId: params.candidateId
        });
        onData(data);
    } catch (error) {
        log.error(`usePostTestResults() - ${error}`);
        if (axios.isAxiosError(error) && error.response && error.response.data && error.response.data.status) {
            log.error(
                `usePostTestResults() - error response data status: ${JSON.stringify(error.response.data.status)}`
            );
        }
    }
};


interface GetCandidateByEmailParams {
    testId: string;
    email: string;
}

interface GetCandidateByEmailResponse {
    status: string;
    data: GetCandidateByEmailData;
}

interface GetCandidateByEmailData {
    attemptsLeft: number;
    firstName: string;
    lastName: string;
    candidateId: string;
    infoFieldOneValue: string;
    infoFieldTwoValue: string;
    emailResults: boolean;
}

export const useGetCandidateByEmail = async (
    params: GetCandidateByEmailParams,
    onData: (data: GetCandidateByEmailData) => void,
    onNotFound: (isNetworkError?: boolean) => void
): Promise<void> => {
    try {
        log.debug(`useGetCandidateByEmail() - sending values: ${JSON.stringify(params, null, 2)}`);
        const { data } = await instance.get<GetCandidateByEmailResponse>(`api/test/${params.testId}/candidates?email=${encodeURIComponent(params.email)}`);

        log.debug(`useGetCandidateByEmail() - response: ${JSON.stringify(data, null, 2)}`);
        onData(data.data);
    } catch (error) {
        log.error(`useGetCandidateByEmail() - error: ${JSON.stringify(error, null, 2)}`);
        if (getIsNetworkError(error)) {
            onNotFound(true);
        } else {
            onNotFound();
        }
    }
};


interface PostCandidateParams {
    testId: string;
    email: string;
    firstName: string;
    lastName: string;
    infoFieldOneValue: string;
    infoFieldTwoValue: string;
    emailResults: boolean;
};

interface PostCandidateResponse {
    status: string;
    candidateId: string;
};

export const usePostCandidate = async (
    params: PostCandidateParams,
    onData: (data: string) => void,
    onError: (isNetworkError?: boolean) => void
): Promise<void> => {
    try {
        log.debug(`usePostCandidate() - sending values: ${JSON.stringify(params, null, 2)}`);
        const { data } = await instance.post<PostCandidateResponse>(`api/test/${params.testId}/candidates`, params);

        log.debug(`usePostCandidate() - response: ${JSON.stringify(data, null, 2)}`);
        onData(data.candidateId);
    } catch (error) {
        log.error(`usePostCandidate() - error: ${JSON.stringify(error, null, 2)}`);
        if (getIsNetworkError(error)) {
            onError(true);
        } else {
            onError();
        }
    }
};


interface PatchCandidateParams {
    testId: string;
    candidateId: string;
    email?: string;
    firstName?: string;
    lastName?: string;
    infoFieldOneValue?: string;
    infoFieldTwoValue?: string;
    emailResults?: boolean;
};

interface PatchCandidateResponse {
    status: string;
    candidateId: string;
};

export const usePatchCandidate = async (
    params: PatchCandidateParams,
    onData: (data: string) => void,
    onError: (errorData: { reason?: "network" | "unknown", error?: MyAxiosError }) => void
): Promise<void> => {
    try {
        log.debug(`usePatchCandidate() - sending values: ${JSON.stringify(params, null, 2)}`);
        const { data } = await instance.patch<PatchCandidateResponse>(`api/test/${params.testId}/candidates/${params.candidateId}`, params);

        log.debug(`usePatchCandidate() - response: ${JSON.stringify(data, null, 2)}`);
        onData(data.candidateId);
    } catch (error) {
        log.error(`usePatchCandidate() - error: ${JSON.stringify(error, null, 2)}`);
        if (getIsNetworkError(error)) {
            onError({ reason: "network" });
        } else {
            if (axios.isAxiosError(error) && error.response && error.response.data) {
                log.error(`usePatchCandidate() - error response data: ${JSON.stringify(error.response.data)}`);
                onError({ reason: "unknown", error });
            }
        }
    }
};


export type CandidateTestData = {
    attemptsLeft: number;
    bestResult: {
        netWpm: number;
        accuracy: number;
        status: string;
        createdAt: Date | string;
    };
};

interface GetCandidateDataResponse {
    data: CandidateTestData;
}

export const useGetCandidateTestData = async (
    testId: string,
    candidateId: string,
    updateCandidateTestData: (candidateTestData: CandidateTestData) => void
): Promise<void> => {
    try {
        const { data } = await instance.get<GetCandidateDataResponse>(`api/test/${testId}/candidates/${candidateId}`);
        log.debug(`useGetCandidateTestData() - response: ${JSON.stringify(data, null, 2)}`);
        updateCandidateTestData(data.data);
    } catch (error) {
        log.error(`useGetCandidateTestData() - ${error}`);
        if (axios.isAxiosError(error) && error.response && error.response.data && error.response.data.status) {
            log.error(`useGetCandidateTestData() - error response data status: ${JSON.stringify(error.response.data.status)}`);
        }
    }
};

export interface GetDataForTestResponse {
    companyName: string;
    active: boolean;
    textId: string;
    testId: string;
    duration: number;
    minNetWpm: number;
    minAccuracy: number;
    useLimits: boolean;
    showLimits: boolean;
    maxAttempts: number;
    note: {
        enabled: boolean;
        text: string;
    };
    infoFieldOne: {
        enabled: boolean;
        title: string;
    };
    infoFieldTwo: {
        enabled: boolean;
        title: string;
    };
    name: string;
    useCustomText: boolean;
    logo: ImageData;
    exitPassUrl: exitPassField;
    exitFailUrl: exitPassField;
    newAttemptsAllowed: number;
    accessCode: accessCodeField
    flags: string;
    disableExitParameters: boolean;
}

export const useGetDataForTest = async (
    testId: string | null,
    onData: (testData: GetDataForTestResponse) => void,
    onError: (status: string) => void
): Promise<void> => {
    try {
        const accountDomain = getAccountDomainFromTestLink();
        log.debug(`useGetDataForTest() - accountDomain: ${JSON.stringify(accountDomain)}`);
        const accountId = accountDomain === "" ? "" : `&accountId=${accountDomain}`;
        const { data } = await instance.get<GetDataForTestResponse>(`api/test?testId=${testId}${accountId}`);
        log.debug(`useGetDataForTest() - response: ${JSON.stringify(data, null, 2)}`);
        onData(data);
    } catch (error) {
        log.error(`useGetDataForTest() - ${error}`);
        if (axios.isAxiosError(error) && error.response && error.response.data && error.response.data.status) {
            log.error(`useGetDataForTest() - error response data status: ${JSON.stringify(error.response.data.status)}`);
            onError(error.response.data.status);
        }
    }
};


interface DeleteResultParams {
    resultId: string;
}

export const useDeleteResult = async (
    params: DeleteResultParams,
    onData: (data: GetTestsData[]) => void
): Promise<void> => {
    try {
        log.debug(`useDeleteResult() - deleting test: ${JSON.stringify(params.resultId, null, 2)}`);
        const { data } = await instance.delete<GetTestsResponse>(`api/testresults/${params.resultId}`);

        log.debug(`useDeleteResult() - response: ${JSON.stringify(data, null, 2)}`);
        onData(data.tests);
    } catch (error) {
        log.error(`useDeleteResult() - error, refreshing tests: ${JSON.stringify(error, null, 2)}`);
        useGetTests(onData,
            (newError) => {
                log.error(`useDeleteResult() - new error: ${JSON.stringify(newError, null, 2)}`);
            }
        );
    }
};

interface GetDeletedCandidateResponse {
    status: string
}
export const useDeleteCandidate = async (
    candidateId: string,
    onData: () => void
): Promise<void> => {
    try {
        log.debug(`useDeleteCandidate() - deleting candidate: candidateId`);
        const { data } = await instance.delete<GetDeletedCandidateResponse>(`api/candidate/${candidateId}`);
        log.debug(`useDeleteCandidate() - response: ${JSON.stringify(data, null, 2)}`);
        onData();
    } catch (error) {
        log.error(`useDeleteCandidate() - error: ${JSON.stringify(error, null, 2)}`);
    }
};

export interface CandidateProps {
    email: string;
    firstName: string;
    lastName: string;
}

interface GetCandidateResponse {
    status: string,
    candidate: CandidateProps
}
export const useGetCandidate = async (
    candidateId: string,
    onData: (data: CandidateProps) => void
): Promise<void> => {
    try {
        log.debug(`useGetCandidate() - get candidate: ${candidateId}`);
        const { data } = await instance.get<GetCandidateResponse>(`api/candidate/${candidateId}`);
        log.debug(`useGetCandidate() - response: ${JSON.stringify(data, null, 2)}`);
        onData(data.candidate);
    } catch (error) {
        log.error(`useGetCandidate() - error: ${JSON.stringify(error, null, 2)}`);
    }
};


interface GetAccessCodeResponse {
    status: string,
    valid: boolean
}
export const useGetTestAccessCode = async (
    testId: string,
    accessCode: string,
    onSuccess: () => void,
    onError: (isValid: boolean) => void,
    setAccessCodeIsValid: (isValid: boolean) => void
): Promise<void> => {
    try {
        log.debug(`useGetTestAccessCode() - get access code for test: ${testId}`);
        const { data } = await instance.get<GetAccessCodeResponse>(`api/test/${testId}/access-code/${encodeURIComponent(accessCode)}`);
        log.debug(`useGetTestAccessCode() - response: ${JSON.stringify(data, null, 2)}`);
        if (data.valid) {
            setAccessCodeIsValid(true);
            onSuccess();
        } else {
            onError(true);
            setAccessCodeIsValid(false);
        }
    } catch (error) {
        log.error(`useGetTestAccessCode() - error: ${JSON.stringify(error, null, 2)}`);
        onError(true);
        setAccessCodeIsValid(false);
    }
};

export interface TestResultProps {
    resultId: string;
    time: number;
    timePassed: boolean;
    hits: number;
    errorCount: number;
    duration: number;
    netWpm: number;
    netWpmPassed: boolean;
    grossWpm: number;
    netCpm: number;
    grossCpm: number;
    accuracy: number;
    accuracyPassed: boolean;
}
interface GetResultResponse {
    status: string;
    result: TestResultProps
}
export const useGetTestResult = async (
    resultId: string,
    onData: (data: TestResultProps) => void
): Promise<void> => {
    try {
        log.debug(`useGetTestResult() - get result: ${resultId}`);
        const { data } = await instance.get<GetResultResponse>(`api/testresults/${resultId}`);
        log.debug(`useGetTestResult() - response: ${JSON.stringify(data, null, 2)}`);
        onData(data.result);
    } catch (error) {
        log.error(`useGetTestResult() - error: ${JSON.stringify(error, null, 2)}`);
    }
};

export const useDeleteAccount = async (
    accountId: (string | null)[],
    onData: () => void
): Promise<void> => {
    try {
        log.debug(`useDeleteAccount() - deleting account: ${accountId}`);
        const { data } = await instance.post<DeletedAccountsResponse>(`api/account/delete`, { id: accountId });
        log.debug(`useDeleteAccount() - response: ${JSON.stringify(data, null, 2)}`);
        onData();
    } catch (error) {
        log.error(`useDeleteAccount() - error: ${JSON.stringify(error, null, 2)}`);
    }
};

interface ChangeAccountSubscriptionDueDateResponse {
    status: string;
}

export const useChangeAccountSubscriptionDate = async (
    accountId: string,
    newDate: string,
    onData: () => void
): Promise<void> => {
    try {
        log.debug(`useChangeAccountSubscriptionDate() - changing account subscription due date: ${accountId}`);
        const { data } = await instance.post<ChangeAccountSubscriptionDueDateResponse>(`api/account/${accountId}/update-subscription-due-date`, { newDate: newDate });
        log.debug(`useChangeAccountSubscriptionDate() - response: ${JSON.stringify(data, null, 2)}`);
        onData();
    } catch (error) {
        log.error(`useChangeAccountSubscriptionDate() - error: ${JSON.stringify(error, null, 2)}`);
    }
};

interface ExternalLink {
    enabled: boolean;
    url: string;
}

export interface AccountResponseData {
    status: string;
    companyName: string;
    timeZone: string;
    accountDomain: string;
    disableExitParameters: boolean;
    externalLink?: ExternalLink;
}

export interface AccountInfoParams {
    companyName: string;
    timeZone: string;
    accountDomain: string;
}

export const useGetAccountInfo = async (
    onData: (data: AccountResponseData) => void,
    onError: (status: string) => void
): Promise<void> => {
    try {
        log.debug(`useGetAccountInfo()`);
        const { data } = await instance.get<AccountResponseData>(`api/account`);
        log.debug(`useGetAccountInfo() - response: ${JSON.stringify(data, null, 2)}`);
        onData(data);
    } catch (error) {
        log.error(`useGetAccountInfo() - ${error}`);
        if (axios.isAxiosError(error) && error.response && error.response.data && error.response.data.status) {
            log.error(
                `useGetAccountInfo() - error response data status: ${JSON.stringify(error.response.data.status)}`
            );
            onError(error.response.data.status);
        }
    }
};

export const usePatchAccountInfo = async (
    params: AccountInfoParams,
    onData: (data: AccountResponseData) => void,
    onError: (status: string) => void
): Promise<void> => {
    try {
        log.debug(`usePatchAccountInfo() - sending values: ${JSON.stringify(params, null, 2)}`);
        const { data } = await instance.patch<AccountResponseData>(`api/account`, params);
        log.debug(`usePatchAccountInfo() - response: ${JSON.stringify(data, null, 2)}`);
        onData(data);
    } catch (error) {
        log.error(`usePatchAccountInfo() - error: ${JSON.stringify(error, null, 2)}`);
        if (axios.isAxiosError(error) && error.response && error.response.data && error.response.data.status) {
            log.error(
                `useGetAccountInfo() - error response data status: ${JSON.stringify(error.response.data.status)}`
            );
            onError(error.response.data.status);
        }
    }
};

export type GetAuthTokenDataResponse = {
    status: string;
    email: string;
    superAdmin: boolean;
    companyName: string;
    accountDomain: string;
    flags: string;
    adminId: string;
};

export const useGetAuthTokenData = async (
    updateAuthTokenData: (data: GetAuthTokenDataResponse) => void,
    onError: (data: any) => void
): Promise<void> => {
    try {
        const { data } = await instance.get<GetAuthTokenDataResponse>(`api/auth-tokens`);
        log.debug(`useGetAuthTokenData() - response: ${JSON.stringify(data, null, 2)}`);
        updateAuthTokenData(data);
    } catch (error) {
        log.error(`useGetAuthTokenData() - ${error}`);
        if (axios.isAxiosError(error) && error.response && error.response.data) {
            log.error(`useGetAuthTokenData() - error response data: ${JSON.stringify(error.response.data)}`);
            onError(error.response.data);
        }
    }
};

export type GetAccountLogoDataResponse = {
    status: string;
    logo: {
        image: ImageData;
    };
};

export const useGetAccountLogoData = async (
    onData: (data: GetAccountLogoDataResponse) => void,
    onError: (status: string) => void
): Promise<void> => {
    try {
        const { data } = await instance.get<GetAccountLogoDataResponse>(`api/account/logo`);
        onData(data);
    } catch (error) {
        log.error(`useGetAccountLogoData() - ${error}`);
        if (axios.isAxiosError(error) && error.response && error.response.data && error.response.data.status) {
            log.error(`useGetAccountLogoData() - error response data status: ${JSON.stringify(error.response.data.status)}`);
            onError(error.response.data.status);
        }
    }
};

export type PostAccountLogoDataResponse = {
    status: string;
};

export const usePostAccountLogoData = async (
    params: FormData,
    onData: (data: PostAccountLogoDataResponse) => void,
    onError: (status: MyAxiosError) => void
): Promise<void> => {
    try {
        const { data } = await instance.post<PostAccountLogoDataResponse>(`api/account/logo`, params, { headers: { "Content-Type": "multipart/form-data" } });
        onData(data);
    } catch (error) {
        log.error(`usePostAccountLogoData() - ${error}`);
        if (axios.isAxiosError(error) && error.response && error.response.data) {
            log.error(`usePostAccountLogoData() - error response data: ${JSON.stringify(error.response.data)}`);
            onError(error);
        }
    }
};

export type DeleteAccountLogoDataResponse = {
    status: string;
};

export const useDeleteAccountLogoData = async (
    onData: (data: DeleteAccountLogoDataResponse) => void,
    onError: (status: string) => void
): Promise<void> => {
    try {
        log.debug(`useDeleteAccountLogoData() - deleting logo`);
        const { data } = await instance.delete<DeleteAccountLogoDataResponse>(`api/account/logo`);

        log.debug(`useDeleteAccountLogoData() - response: ${JSON.stringify(data, null, 2)}`);
        onData(data);
    } catch (error) {
        log.error(`useDeleteAccountLogoData() - error, refreshing tests: ${JSON.stringify(error, null, 2)}`);
        if (axios.isAxiosError(error) && error.response && error.response.data && error.response.data.status) {
            log.error(`useDeleteAccountLogoData() - error response data status: ${JSON.stringify(error.response.data.status)}`);
            onError(error.response.data.status);
        }
    }
};

export interface TestResultForCandidateResponse {
    status: string,
    candidates: CandidateBestResultData[],
    totalCandidates: number,
    trialPlanExceeded: boolean
}

export const useTestSearchByUserItem = async (
    params: GetBestTestResultsForAllTestParams,
    onData: (
        data: CandidateBestResultData[],
        totalCandidates: number,
        trialPlanExceeded: boolean
    ) => void,
    onNotFound: () => void
): Promise<void> => {
    try {
        log.debug(`useUserSearch() - sending values: ${JSON.stringify(params, null, 2)}`);
        const urlParams = convertUrlParamsToString<GetBestTestResultsForAllTestParams>(params);
        const { data } = await instance.get<TestResultForCandidateResponse>(`api/testresults/best/forAllTests${urlParams}`);
        onData(data.candidates, data.totalCandidates, data.trialPlanExceeded);
    } catch (error) {
        log.error(`useUserSearch() - error: ${JSON.stringify(error, null, 2)}`);
        onNotFound();
    }
};

export const useAllResultsForCsv = async (
    onData: (data: CandidateBestResultData[]) => void,
    onNotFound: () => void
): Promise<void> => {
    try {
        log.debug(`useAllResultsForCsv()`);
        const { data } = await instance.get<TestResultForCandidateResponse>(`api/testresults/best/csv/forAllTests`);
        onData(data.candidates);
    } catch (error) {
        log.error(`useAllResultsForCsv() - error: ${JSON.stringify(error, null, 2)}`);
        onNotFound();
    }
};

export type AccountTestList = {
    name: string;
    testId: string;
    active: boolean;
}[];

export interface AccountTestListResponse {
    status: string,
    logo: ImageData,
    testList: AccountTestList,
    companyName: string
}

export interface AccountTestListReturnData {
    companyName: string;
    testList: AccountTestList;
    image?: string;
}

export const useAccountTestList = async (
    accountDomain: string,
    onData: (data: AccountTestListReturnData) => void,
    onNotFound: () => void,
): Promise<void> => {
    try {
        log.debug(`useAccountTestList()`);
        const { data } = await instance.get<AccountTestListResponse>(`api/account/${accountDomain}/test-list`);
        const returnData = {
            companyName: data.companyName,
            testList: data.testList,
            image: '',
        }
        if (data.logo) {
            const imageString = arrayBufferToBase64(data.logo.data.data);
            returnData.image = `data:${data.logo.type};base64,${imageString}`;
        }
        onData(returnData);
    } catch (error) {
        log.error(`useAccountTestList() - error: ${JSON.stringify(error, null, 2)}`);
        onNotFound();
    }
};

export const useAccountTestListForMigratedUsers = async (
    accountDomain: string,
    setImageData: (logo: string) => void,
    setTests: (list: AccountTestList) => void,
    setCompanyName: (name: string) => void,
    onNotFound: (status: string) => void
): Promise<void> => {
    try {
        log.debug(`useAccountTestListForMigratedUsers()`);
        const { data } = await instance.get<AccountTestListResponse>(`api/account/${accountDomain}/test-list/for-migrated-accounts`);
        if (data.logo) {
            const imageString = arrayBufferToBase64(data.logo.data.data);
            setImageData(`data:${data.logo.type};base64,${imageString}`);
        }
        setCompanyName(data.companyName);
        setTests(data.testList);
    } catch (error) {
        log.error(`useAccountTestListForMigratedUsers() - error: ${JSON.stringify(error, null, 2)}`);
        if (axios.isAxiosError(error) && error.response && error.response.data && error.response.data.status) {
            log.error(`useAccountTestListForMigratedUsers() - error response data status: ${JSON.stringify(error.response.data.status)}`);
            onNotFound(error.response.data.status);
        } else {
            onNotFound("UNKNOWN_ERROR");
        }
    }
};

export interface ActivePlanProps {
    name: string,
    validUntil: string,
    candidates: string,
    totalAmount: string,
    paid: boolean,
    fastSpringOrderUrl: string | null,
    fastSpringLegacyId: string,
    legacy: boolean,
    fsLegacyManageLink: string,
    planId: number
}

interface AccountSubscriptionInformationResponse {
    status: string,
    activePlan: ActivePlanProps,
}

export const useGetAccountSubscriptionInformation = async (
    onData: (data: ActivePlanProps) => void,
    onError: (status: string) => void
): Promise<void> => {
    try {
        const { data } = await instance.get<AccountSubscriptionInformationResponse>(`api/subscription/plan-info-for-user`);
        onData(data.activePlan);
    } catch (error) {
        log.error(`useGetAccountSubscriptionInformation() - ${error}`);
        if (axios.isAxiosError(error) && error.response && error.response.data && error.response.data.status) {
            log.error(`useGetAccountSubscriptionInformation() - error response data status: ${JSON.stringify(error.response.data.status)}`);
            onError(error.response.data.status);
        }
    }
};


export const useChangeAccountPlan = async (
    accountId: string,
    plan: number,
    onData: () => void,
): Promise<void> => {
    try {
        await instance.post("/api/subscription/change-user-plan", { accountId, plan });
        onData()
    } catch (error) {
        log.error(`useChangeAccountPlan() - ${error}`);
        if (axios.isAxiosError(error) && error.response && error.response.data && error.response.data.status) {
            log.error(`useChangeAccountPlan() - error response data status: ${JSON.stringify(error.response.data.status)}`);
        }
    }
};
export interface PlanProps {
    planId: number;
    displayName: string,
    displayDescription: string,
    name: string;
    validDays: number;
    active: boolean;
    testsPerMonth: number;
    admins: number;
    activeTests: number;
    planLevel: number;
    legacy: boolean;
    purchaseLink: string;
    paid: boolean;
    displayPrice: number;
    default: boolean;
    features: string[];
    order: number;
    remind_renew_expiry: boolean;
}
interface PlansListResponse {
    status: string,
    plans: PlanProps[],
    currentPlan: number,
    currentPlanIsActive: boolean,
    fastSpringLegacyId: string,
    fsLegacyManageLink: string
}

export const useGetPlans = async (
    onData: (
        plans: PlanProps[],
        currentPlanId: number,
        currentPlanActiveState: boolean,
        fastSpringLegacyId: string,
        fsLegacyManageLink: string
    ) => void,
): Promise<void> => {
    try {
        const { data } = await instance.get<PlansListResponse>("/api/subscription/plan/list");
        onData(data.plans, data.currentPlan, data.currentPlanIsActive, data.fastSpringLegacyId, data.fsLegacyManageLink)
    } catch (error) {
        log.error(`useGetPlans() - ${error}`);
        if (axios.isAxiosError(error) && error.response && error.response.data && error.response.data.status) {
            log.error(`useGetPlans() - error response data status: ${JSON.stringify(error.response.data.status)}`);
        }
    }
};

interface HideFractionalResponse {
    status: string;
}

export const useUpdateFlagsSettings = async (
    body: { showOnlyWholeNumber?: boolean, showOnlyOneResult?: boolean },
    onData: (success: boolean) => void
) => {
    try {
        const { data } = await instance.post<HideFractionalResponse>("/api/account/flags/settings", body);
        onData(data.status === 'OK')
    } catch (error) {
        log.error(`useUpdateHideFractional() - ${error}`)
        onData(false);
    }
}

interface UpdateDisableExitParametersResponse {
    status: string;
}

export const useUpdateDisableExitParameters = async (exitParametersDisabled: boolean, onData: (success: boolean) => void) => {
    try {
        const { data } = await instance.post<UpdateDisableExitParametersResponse>("/api/account/integrations/exitParameters", { exitParametersDisabled })
        onData(data.status === 'OK')
    } catch (error) {
        log.error(`useUpdateHideFractional() - ${error}`)
        onData(false);
    }
};

interface UpdateExternalLinkResponse extends ResponseBase {
    externalLink?: ExternalLink;
}

interface UpdateExternalLinkRequestData {
    enabled: boolean;
    url?: string;
}

export const useUpdateExternalLink = async (body: UpdateExternalLinkRequestData, onData: (data: { data?: ExternalLink, error?: string }) => void) => {
    try {
        const { data } = await instance.post<UpdateExternalLinkResponse>("/api/account/integrations/externalLink", body);
        onData({ data: data.externalLink });
    } catch (error) {
        log.error(`useUpdateExternalLink() - ${error}`);
        if (axios.isAxiosError(error) && error.response && error.response.data && error.response.data.status) {
            onData({ error: error.response.data.status });
        }
    }
}

interface UpgradeSubscriptionRequestData {
    newPlanId: number;
}

export interface UpgradeSubscriptionResponse extends ResponseBase {
    activeStatus: 'active' | 'inactive';
    nextPeriodDate: string;
    newPlanName: string;
}

export const useUpgradeSubscription = async (body: UpgradeSubscriptionRequestData, onData: (data: UpgradeSubscriptionResponse) => void, onError: (status: string) => void) => {
    try {
        const { data } = await instance.post<UpgradeSubscriptionResponse>("/api/subscription/upgrade", body);
        onData(data);
    } catch (error) {
        log.error(`useUpgradeSubscription() - ${error}`);
        if (axios.isAxiosError(error) && error.response && error.response.data && error.response.data.status) {
            onError(error.response.data.status);
        }
    }
}

export interface AddOrUpdateCandidateRequestData {
    testId: string;
    email: string;
    firstName?: string;
    lastName?: string;
    customField1?: string;
    customField2?: string;
}

export interface CandidatePersonalData {
    email: string;
    firstName: string;
    lastName: string;
    infoFieldOneValue: string;
    infoFieldTwoValue: string;
    candidateId: string;
    attemptsLeft: number;
}

export interface AddOrUpdateCandidateResponse extends ResponseBase {
    candidateId: string;
    attemptsLeft: number;
}

export const useAddOrUpdateCandidate = async (body: AddOrUpdateCandidateRequestData, onData: (candidateId: Omit<AddOrUpdateCandidateResponse, 'status'>) => void) => {
    try {
        const { data } = await instance.post<AddOrUpdateCandidateResponse>("/api/candidate", body);
        onData({ candidateId: data.candidateId, attemptsLeft: data.attemptsLeft });
    } catch (error) {
        log.error(`useUpgradeSubscription() - ${error}`);
    }
};

export const useGetTestInvitationText = async (testId: string, onData: (invitationText: string) => void) => {
    try {
        const { data } = await instance.get(`/api/manage-tests/${testId}/invitation`);
        if (data) {
            onData(data.invitationText || '');
        }
    } catch (error) {
        onData('');
        log.error(`useGetTestInvitationText() - ${error}`);
    }
};
