import { useEffect } from "react";

import gameSources from "./gameSources.json";

const createPreloadScript = (src: string) => {
    const preloadLink = document.createElement("link");
    preloadLink.href = src;
    preloadLink.rel = "preload";
    preloadLink.as = "script";
    return preloadLink;
};

const createPreloadFont = (src: string) => {
    const preloadLink = document.createElement("link");
    preloadLink.href = src;
    preloadLink.rel = "preload";
    preloadLink.as = "font";
    return preloadLink;
};

const usePreloadGame = () => {
    useEffect(() => {
        const preloadLibraryLink = createPreloadScript(gameSources.gameLibrary);
        const preloadGameLink = createPreloadScript(gameSources.game);

        const font1 = createPreloadFont(gameSources.font1);
        const font2 = createPreloadFont(gameSources.font2);

        document.head.appendChild(preloadLibraryLink);
        document.head.appendChild(preloadGameLink);

        document.head.appendChild(font1);
        document.head.appendChild(font2);
    }, []);
};

export default usePreloadGame;
