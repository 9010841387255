import Typography from "@mui/material/Typography";
import { makeStyles } from 'tss-react/mui';
import CheckIcon from "@mui/icons-material/Check";
import { PlanProps } from "../../hooks/useApi";
import { UpgradedStatus } from "../../pages/Plans/Plans";
import PlanCardButton from "./PlanCardButton";

const useStyles = makeStyles()((theme) => ({
    planDisplayNameTitle: {
        fontFamily: "'Lexend', sans-serif;",
        textAlign: "center",
        display: "block",
        fontSize: "1.875rem",
        margin: "20px 0 5px"
    },
    planCardSection: {
        width: "515px",
        display: "inline-block",
        marginTop: "40px",
        [theme.breakpoints.down(1650)]: {
            width: "490px",
        },
        [theme.breakpoints.down(1570)]: {
            width: "400px",
        },
        [theme.breakpoints.down(1300)]: {
            width: "370px",
        }
    },
    planDescription: {
        fontSize: "1.125rem",
        lineHeight: "1.75rem",
        fontWeight: 300,
        minHeight: "60px"
    },
    planInformation: {
        marginTop: "32px",
    },
    planPrice: {
        fontWeight: 800,
        fontSize: "1.5rem",
        lineHeight: "1",
        color: "#333d4e",
        "@media (min-width: 720px)": {
            fontSize: "3rem"
        },
    },
    planValidType: {
        marginLeft: "15px",
        color: "#a5b2c3",
        fontWeight: 400,
        fontSize: "1.125rem"
    },
    planPriceContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "end",
    },
    planCard: {
        padding: "20px",
        width: "70%",
        borderRadius: "0.5rem",
        margin: "0 auto",
        background: "#ffffff",
        color: '#212529',
        [theme.breakpoints.down(1650)]: {
            width: "90%",
        },
        [theme.breakpoints.down(1300)]: {
            width: "95%",
        }
    },
    planTestAndCandidateCount: {
        marginTop: "36px",
        textAlign: "left",
        fontSize: "1.125rem",
        fontWeight: "400",
        "& p": {
            marginBottom: "16px",
        }
    },
    subscribeSection: {
        marginTop: "50px",
        marginBottom: "40px"
    },
}));

const ACTIVE_SHADOW = "rgb(63, 81, 181,0.4) 0px 6px 22px 3px, rgba(0, 0, 0, 0.1) 0px 4px 6px -4px";
const INACTIVE_SHADOW = "rgb(245 7 7 / 40%) 0px 6px 22px 3px, rgba(0, 0, 0, 0.1) 0px 4px 6px -4px";
const ENABLE_SHADOW = "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)";
const ACTIVE_BORDER = "1px solid rgb(63, 81, 181)";
const INACTIVE_BORDER = "1px solid #ff0505";
const ENABLE_BORDER = "0px";

interface PlanCardProps {
    plan: PlanProps;
    updateUpgradedStatus: (data: Partial<UpgradedStatus>) => void;
    currentPlan?: PlanProps;
    fsLegacyManageLink?: string;
    currentPlanIsActive?: boolean;
    fastSpringLegacyId?: string;
    subscriptionCanNotBeChanged: boolean;
}

const PlanCard = ({
    plan,
    updateUpgradedStatus,
    currentPlan,
    fsLegacyManageLink,
    currentPlanIsActive,
    fastSpringLegacyId,
    subscriptionCanNotBeChanged,
}: PlanCardProps) => {
    const { classes } = useStyles();

    const currentPlanId = currentPlan?.planId;

    return (
        <div key={`plan_${plan.planId}`} className={classes.planCardSection}>
            <div className={classes.planCard}
                style={{
                    border: currentPlanId === plan.planId ? (currentPlanIsActive ? ACTIVE_BORDER : INACTIVE_BORDER) : ENABLE_BORDER,
                    boxShadow: currentPlanId === plan.planId ? (currentPlanIsActive ? ACTIVE_SHADOW : INACTIVE_SHADOW) : ENABLE_SHADOW
                }}
            >
                <Typography
                    variant="h1"
                    className={classes.planDisplayNameTitle}
                >
                    {plan.displayName}
                </Typography>
                <Typography className={classes.planDescription}>{plan.displayDescription}</Typography>
                <div className={classes.planInformation}>
                    <div className={classes.planPriceContainer}>
                        <Typography className={classes.planPrice}>${plan.displayPrice}</Typography>
                        <Typography className={classes.planValidType}>{plan.validDays < 364 ? "/month" : "/year"}</Typography>
                    </div>
                    <div className={classes.planTestAndCandidateCount}>
                        <Typography fontSize="inherit" fontWeight="inherit">
                            <CheckIcon style={{
                                color: "#37ca6d",
                                marginRight: "10px",
                                verticalAlign: "sub"
                            }} />
                            <span style={{ fontWeight: 700 }}>{plan.activeTests}</span> customizable
                            typing test(s)
                        </Typography>
                        <Typography fontSize="inherit" fontWeight="inherit">
                            <CheckIcon style={{
                                color: "#37ca6d",
                                marginRight: "10px",
                                verticalAlign: "sub"
                            }} />
                            Allows <span style={{ fontWeight: 700 }}>{plan.testsPerMonth}</span> test
                            takers per month
                        </Typography>
                        <Typography fontSize="inherit" fontWeight="inherit">
                            <CheckIcon style={{
                                color: "#37ca6d",
                                marginRight: "10px",
                                verticalAlign: "sub"
                            }} />
                            Allows <span style={{ fontWeight: 700 }}>{plan.admins}</span> administrator
                            account(s)
                        </Typography>
                    </div>
                </div>

                <div className={classes.subscribeSection}>
                    {currentPlanId === plan.planId && (
                        <div>
                            <Typography
                                style={{
                                    fontSize: "1.5rem",
                                    fontWeight: 600,
                                    color: "rgb(55, 202, 109)"
                                }}
                            >
                                CURRENT PLAN
                            </Typography>
                            {!currentPlanIsActive && (
                                <Typography style={{ color: "red", fontSize: "1.25rem" }}>(Inactive)</Typography>
                            )}
                        </div>
                    )}
                    <PlanCardButton
                        planId={plan.planId}
                        purchaseLink={plan.purchaseLink}
                        currentPlanId={currentPlanId}
                        legacy={plan.legacy}
                        remind_renew_expiry={plan.remind_renew_expiry}
                        currentPlanPrice={currentPlan?.displayPrice}
                        currentPlanTestsPerMonth={currentPlan?.testsPerMonth}
                        displayPrice={plan.displayPrice}
                        testsPerMonth={plan.testsPerMonth}
                        currentPlanIsActive={currentPlanIsActive}
                        fastSpringLegacyId={fastSpringLegacyId}
                        fsLegacyManageLink={fsLegacyManageLink}
                        updateUpgradedStatus={updateUpgradedStatus}
                        subscriptionCanNotBeChanged={subscriptionCanNotBeChanged}
                    />
                </div>
            </div>
        </div>
    );
};

export default PlanCard;
