import React, { useState } from "react";
import styles from "./Heartbeat.module.css";
import Button from "@mui/material/Button";
import axios from "axios";
import packageJson from "../../../package.json";

const { version } = packageJson;

const Heartbeat = () => {
    const [data, setData] = useState({ status: "", version: "" });
    console.log("Heartbeat");
    return (
        <div>
            <div className={styles.Heartbeat}>
                <div>Front-end version: {version}</div>
                <div>Back-end state: {data.status}</div>
                <div>Back-end version: {data.version}</div>
                <Button
                    id="send_heartbeat_button"
                    variant="contained"
                    color="secondary"
                    onClick={async () => {
                        const response = await axios.get(`${process.env.REACT_APP_BACKEND_ADDRESS}/api/heartbeat`);
                        setData(response.data);
                    }}
                >
                    Send Heartbeat Request
                </Button>
            </div>
        </div>
    );
};

export default Heartbeat;
