const itemName = "iid";
const exampleEmail = "example@typingtestpro.com";

export const usePreview = (testId: string): void => {
    sessionStorage.setItem(itemName, Math.random().toString(36).substr(2, 5));
    const { availHeight, availWidth } = window.screen;
    window.open(
        "/test?testId=" + testId + "&preview",
        "_blank",
        `toolbar=0,location=0,menubar=0,width=${availWidth},height=${availHeight}`,
    );
    sessionStorage.removeItem(itemName);
};

export const isPreviewInUse = (): boolean => {
    return !!sessionStorage.getItem(itemName);
};

export const getPreviewEmail = (): string => {
    return exampleEmail;
};

export const clearPreview = (): void => {
    sessionStorage.removeItem(itemName);
};
