import { useEffect, useState, SetStateAction, Dispatch } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { makeStyles } from 'tss-react/mui';
import {
    Accuracy, arrayBufferToBase64,
    GetCandidateTestResultsData,
    NetWpm, OrderBy, SortBy,
    useDeleteCandidate, useGetAccountLogoData, useGetCandidateTestResults,
    useGetUserInformation,
    usePatchCandidate
} from "../../hooks/useApi";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import DialogContentText from "@mui/material/DialogContentText";
import Typography from "@mui/material/Typography";
import { theme } from "../../theme/Theme";
import AddIcon from "@mui/icons-material/Add";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { NavLink as RouterNavLink } from "react-router-dom";
import MUIDataTable, { MUIDataTableColumnDef, MUIDataTableOptions } from "mui-datatables";
import { getLogger } from "../../utils/logger";
import { useFeature } from "flagged";
import ResultTableCellNetSpeed from "../ResultTableCellNetSpeed/ResultTableCellNetSpeed";
import ResultTableCellGrossSpeed from "../ResultTableCellGrossSpeed/ResultTableCellGrossSpeed";
import ResultTableCellAccuracy from "../ResultTableCellAccuracy/ResultTableCellAccuracy";
import ResultTableCellVsAvg from "../ResultTableCellVsAvg/ResultTableCellVsAvg";
import ResultTableCellStatus from "../ResultTableCellStatus/ResultTableCellStatus";
import { useAuth } from "../../context/auth";
import Loading from "../Loading/Loading";

const log = getLogger();

const EMAIL_MAX_LENGTH = 100;
const INPUT_MAX_LENGTH = 50;

const DEFAULT_SORTBY = "date";
const DEFAULT_ORDERBY = "desc";
const DEFAULT_ROWS_PER_PAGE = 5;

const TEST_RESULTS_HTML_PATH = "/testResults.html";

const LoadingCell = ({ rowIndex, count }: { rowIndex: number, count: number }) => {
    let text = "";
    let height = 60;
    let maxCountAllowed = count;

    if (count > 5) {
        maxCountAllowed = 5;
    }

    if (rowIndex === 1) {
        text = "Loading ...";
    }

    if (count > 2) {
        height = ((height + 32) * (maxCountAllowed - 1)) - 32;
    }

    return (
        <Box
            sx={{
                minHeight: `${height}px`,
                display: "flex",
                alignItems: "center"
            }}
        >
            {text}
        </Box>
    )
};

const useStyles = makeStyles()({
    root: {
        width: "100%",
        color: theme.palette.primary.dark,

        "& .MuiTypography-h6": {
            fontSize: "34px",
            minHeight: "55px"
        },
        "& .MuiDialog-paperScrollPaper": {
            width: "100%",
            overflowY: "hidden",
        },
    },


    boxField: {
        marginTop: 0,
        marginBottom: "35px",
        color: theme.palette.primary.dark
    },

    inputField: {
        height: "35px",
        color: theme.palette.primary.dark
    },

    inputFieldDisabled: {
        height: "35px",
        color: theme.palette.text.secondary
    },

    minText: {
        position: "absolute",
        top: "15px",
        right: "45px",
        color: theme.palette.primary.dark
    },

    minTextDisabled: {
        position: "absolute",
        top: "15px",
        right: "45px",
        color: theme.palette.text.secondary
    },

    darkColor: {
        color: theme.palette.primary.dark
    },
    rightSwitch: {
        marginLeft: "10px",
        marginTop: "5px"

    },
    showAllButton: {
        position: "absolute",
        top: "0px",
        left: "740px"
    },
    customText: {
        color: theme.palette.primary.dark,
        fontSize: "16px",
        lineHeight: "1.75",
        letterSpacing: "0.00938em"
    },
    testTitle: {
        fontSize: "20px",
        color: theme.palette.text.secondary,
        fontFamily: "'Lexend', sans-serif;"
    },
    nameTitle: {
        fontSize: "24px",
        fontFamily: "'Lexend', sans-serif;",
        display: "flex",
        marginRight: "16px",
    },
    header: {
        color: theme.palette.primary.dark,
        fontSize: "14px"
    },
    gridRoot: {
        minWidth: "900px",
        overflow: "auto",
        "& .MuiDataGrid-columnSeparator": {
            display: "none"
        },
        "& :hover": {},

        "& .MuiDataGrid-row:hover .MuiSvgIcon-root": {
            color: theme.palette.text.primary
        }
    },
    fieldSmall: {
        fontSize: "16px",
        color: theme.palette.primary.dark
    },
    naText: {
        color: theme.palette.text.secondary,
        marginLeft: "30px",
        fontSize: "24px"
    },
    count: {
        fontSize: "14px",
        color: theme.palette.text.secondary,
        marginTop: "16px",
        marginBottom: "25px",
        marginLeft: "20px"
    },
    navButton: {
        fontSize: "14px",
        textTransform: "none",
        display: "block"
    },
    textPrimary: {
        textTransform: "none",
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.primary.main
    },

    textSecondary: {
        textTransform: "none",
        color: theme.palette.text.secondary,
        backgroundColor: theme.palette.primary.main
    },
    deleteResultButton: {
        background: "#ff3b3b",
        marginLeft: "20px",
        fontSize: "12px",
        borderRadius: "40px",
        padding: "5px 21px"
    },
    logoSection: {
        textAlign: "center",
        display: "none",
        marginTop: "250px",
        marginBottom: "150px"
    },
    typingTextPro: {
        fontSize: "21px",
        marginRight: "30px",
        color: "#b5b3b3",
        opacity: 0.5
    },
    typingTextSection: {
        marginTop: "50px",
        textAlign: "right",
        display: "none"
    }
});

export interface CandidateDetailsProps {
    open: boolean;
    handleClose: (updated?: boolean) => void;
    candidateUpdated: () => void;
    deleteResult: (resultId: string) => void;
    candidateDetails: GetCandidateTestResultsData;
    setCandidateDetails: Dispatch<SetStateAction<GetCandidateTestResultsData>>;
    showCandidateInAnotherTest: (email: string, testId: string) => void;
    testId?: string | undefined;
    createCandidateDetails: (candidate: GetCandidateTestResultsData) => GetCandidateTestResultsData;
}

interface CandidateDetailsFormValues {
    testName: string;
    firstName: string;
    lastName: string;
    candidateStatus: string;
    email: string;
    infoFieldOneTitle: string;
    infoFieldOneValue: string;
    infoFieldTwoTitle: string;
    infoFieldTwoValue: string;
}

type CandidateDetailNetWpm = NetWpm & {
    resultId: string;
};

type RowData = {
    createdAt: string;
    status: string;
    netWpm: NetWpm;
    netWpmToAvgPercentage: string;
    accuracy: Accuracy;
    grossWpm: string;
    errorCount: number;
    hits: number;
    resultId: string;
};

type TableData =
    RowData[];

const CandidateDetails = ({
    open,
    handleClose: onClose,
    candidateUpdated,
    deleteResult,
    showCandidateInAnotherTest,
    candidateDetails,
    setCandidateDetails,
    testId,
    createCandidateDetails,
}: CandidateDetailsProps) => {
    const { classes } = useStyles();
    const hideFractional = useFeature('s_hide_fractional') as boolean;
    const oneResult = useFeature('s_one_result') as boolean;
    const { authTokens } = useAuth();

    const [printPreviewOpen, setPrintPreviewOpen] = useState(false);
    const [adminUserData, setAdminUserData] = useState({ firstName: "", lastName: "" });
    const [fetchingRest, setFetchingRest] = useState(true);
    const [updated, setUpdated] = useState(false);
    const [candidate, setCandidate] = useState();
    const [tableData, setTableData] = useState<TableData>([
        {
            createdAt: new Date(Date.now()).toString(),
            status: "unknown",
            netWpm: { netWpm: "", passed: false, resultId: "" },
            netWpmToAvgPercentage: "",
            accuracy: { accuracy: "", passed: false },
            grossWpm: "",
            errorCount: 0,
            hits: 0,
            resultId: ""
        }]);

    const formatCandidateDataForTable = (candidate: GetCandidateTestResultsData): void => {
        const newTableData: TableData = candidate.testResults.map((result) => {
            const row: RowData = {
                createdAt: result.createdAt,
                status: result.status,
                netWpm: result.netWpm,
                netWpmToAvgPercentage: result.netWpmToAvgPercentage,
                accuracy: result.accuracy,
                grossWpm: result.grossWpm,
                errorCount: result.errorCount,
                hits: result.hits,
                resultId: result.resultId,
            };
            return row;
        });
        setTableData(newTableData);
    };

    const infoFieldOneEnabled = candidateDetails.infoFieldOneEnabled;
    const infoFieldTwoEnabled = candidateDetails.infoFieldTwoEnabled;

    useEffect(() => {
        if (fetchingRest && candidateDetails.attempts > 1) {
            // eslint-disable-next-line
            // @ts-ignore
            formatCandidateDataForTable({ ...candidateDetails, testResults: [...candidateDetails.testResults, {}] });
        } else {
            formatCandidateDataForTable(candidateDetails);
        }
        setCandidate(candidate);
    }, [candidateDetails]);

    const [openDelete, setOpenDelete] = useState(false);
    const [deleteResultId, setDeleteResultId] = useState("0");
    const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);
    const [deleteUser, setDeleteUser] = useState<boolean>(false);
    const [openError, setOpenError] = useState(false);
    const [imageData, setImageData] = useState("");
    const [sorting, setSorting] = useState<{ sortBy: SortBy; orderBy: OrderBy }>({
        sortBy: DEFAULT_SORTBY,
        orderBy: DEFAULT_ORDERBY
    });

    const handleClickOpenDelete = () => {
        setOpenDelete(true);
    };
    const handleCloseDelete = () => {
        setOpenDelete(false);
    };

    const handleCloseDeleteUser = () => {
        setDeleteUser(false);
        onClose(true);
    };

    const handleClose = () => {
        const updatedAlready = updated;
        onClose(updatedAlready);
        setUpdated(false);
    }

    const handleCloseError = () => {
        setOpenError(false);
    };

    const handleDeleteResultId = (id: string) => {
        setDeleteResultId(id);
    };

    const validationSchema = Yup.object().shape({
        email: Yup.string().email().min(2).required("Email is required"),
        firstName: Yup.string().max(INPUT_MAX_LENGTH, `First Name must be at most ${INPUT_MAX_LENGTH} characters`).min(2).required("First Name is required"),
        lastName: Yup.string().max(INPUT_MAX_LENGTH, `Last Name must be at most ${INPUT_MAX_LENGTH} characters`).min(2).required("Last Name is required"),
        infoFieldOneValue: Yup.string().max(INPUT_MAX_LENGTH, `${candidateDetails.infoFieldOneTitle} must be at most ${INPUT_MAX_LENGTH} characters`),
        infoFieldTwoValue: Yup.string().max(INPUT_MAX_LENGTH, `${candidateDetails.infoFieldTwoTitle} must be at most ${INPUT_MAX_LENGTH} characters`)
    });

    const deleteUserAndResults = async (candidateId: string): Promise<void> => {
        await useDeleteCandidate(candidateId, handleCloseDeleteUser);
    };

    const getDateTimeField = (value: string) => {
        const date = new Date(value);
        const enUsYyMmDdFormatter = new Intl.DateTimeFormat("en-GB", {
            year: "numeric",
            month: "short",
            day: "numeric"
        });
        const formattedYyMmDd = enUsYyMmDdFormatter.format(date);
        const enUsHhMmFormatter = new Intl.DateTimeFormat("en-US", {
            hour: "numeric",
            minute: "2-digit"
        });
        const formattedHhMm = enUsHhMmFormatter.format(date).toLowerCase();

        return (
            <div>
                <Typography className={classes.fieldSmall}>
                    {formattedYyMmDd}
                </Typography>
                <Typography style={{ fontSize: "16px", color: theme.palette.text.secondary }}>
                    {formattedHhMm}
                </Typography>
            </div>
        );
    };

    const getErrorCount = (value: string) => {
        const cls = (String(value) === "n/a") ? classes.naText : classes.fieldSmall;
        return (

            <Typography className={cls}>
                {value}
            </Typography>
        );
    };

    const getHits = (value: string) => {
        const cls = (String(value) === "n/a") ? classes.naText : classes.fieldSmall;
        return (

            <Typography className={cls}>
                {value}
            </Typography>
        );
    };

    const getRemove = (resultId: string) => {
        return (
            <Button
                onClick={() => {
                    handleDeleteResultId(resultId);
                    handleClickOpenDelete();
                }}
                color="secondary"
                variant="contained"
                disabled={fetchingRest || resultId === candidateDetails.bestResultId}
                className={classes.deleteResultButton}
                id="btn_delete_result">
                Delete
            </Button>
        );
    };

    const objectColumns: MUIDataTableColumnDef[] = [
        {
            name: "createdAt",
            label: "Date & Time",
            options: {
                download: false,
                print: true,
                searchable: false,
                viewColumns: false,
                filter: false,
                customBodyRender: (value: string, tableMeta) => {
                    return (fetchingRest || !value) && tableMeta.rowIndex !== 0 ? "" : getDateTimeField(value);
                },
                sortThirdClickReset: true
            }
        },
        {
            name: "status",
            label: "Status",
            options: {
                download: false,
                print: true,
                searchable: false,
                viewColumns: false,
                filter: false,
                customBodyRender: (status: string, tableMeta) => {
                    return (fetchingRest || !status) && tableMeta.rowIndex !== 0 ? "" : <ResultTableCellStatus status={status} />;
                }
            }
        },
        {
            name: "netWpm",
            label: "Net Speed",
            options: {
                download: false,
                print: true,
                searchable: false,
                viewColumns: false,
                filter: false,
                customBodyRender: (netWpm: CandidateDetailNetWpm, tableMeta) => {
                    return (fetchingRest || !netWpm) && tableMeta.rowIndex !== 0 ? "" : <ResultTableCellNetSpeed netWpmObj={netWpm} hideFractional={hideFractional} />;
                }
            }
        },
        {
            name: "netWpmToAvgPercentage",
            label: "vs Avg.",
            options: {
                download: false,
                print: false,
                searchable: false,
                viewColumns: false,
                filter: false,
                customBodyRender: (value: string, tableMeta) => {
                    return (fetchingRest || !value) && tableMeta.rowIndex !== 0 ? "" : <ResultTableCellVsAvg value={value} />;
                }
            }
        },
        {
            name: "accuracy",
            label: "Accuracy",
            options: {
                download: false,
                print: true,
                searchable: false,
                viewColumns: false,
                filter: false,
                customBodyRender: (value: Accuracy, tableMeta) => {
                    return (fetchingRest || !value) && tableMeta.rowIndex !== 0 ? (fetchingRest ? <LoadingCell rowIndex={tableMeta.rowIndex} count={candidateDetails.attempts} /> : "") : <ResultTableCellAccuracy accuracyObj={value} />;
                }
            }
        },
        {
            name: "grossWpm",
            label: "Gross Speed", options: {
                download: false,
                print: true,
                searchable: false,
                viewColumns: false,
                filter: false,
                customBodyRender: (value: string, tableMeta) => {
                    return (fetchingRest || !value) && tableMeta.rowIndex !== 0 ? "" : <ResultTableCellGrossSpeed grossWpm={value} />;
                }
            }
        },
        {
            name: "errorCount",
            label: "Errors", options: {
                download: false,
                print: true,
                searchable: false,
                viewColumns: false,
                filter: false,
                customBodyRender: (value: string, tableMeta) => {
                    return (fetchingRest || !value) && tableMeta.rowIndex !== 0 ? "" : getErrorCount(value);
                }
            }
        },
        {
            name: "hits",
            label: "Hits", options: {
                download: false,
                print: true,
                searchable: false,
                viewColumns: false,
                filter: false,
                customBodyRender: (value: string, tableMeta) => {
                    return (fetchingRest || !value) && tableMeta.rowIndex !== 0 ? "" : getHits(value);
                }
            }
        },
        {
            name: "resultId",
            label: "Action",
            options: {
                download: false,
                print: false,
                sort: false,
                searchable: false,
                viewColumns: false,
                filter: false,
                customBodyRender: (value: string, tableMeta) => {
                    return (fetchingRest || !value) && tableMeta.rowIndex !== 0 ? "" : getRemove(value);
                }
            }
        }
    ];

    const sortUpdate = (newSortBy: SortBy, newOrderBy: OrderBy) => {
        log.debug(`sortUpdate() - newSortBy ${newSortBy},  newOrderBy ${newOrderBy}`);
        const finalSort = { sortBy: newSortBy, orderBy: newOrderBy };

        if (newOrderBy === "none") {
            finalSort.sortBy = DEFAULT_SORTBY;
            finalSort.orderBy = DEFAULT_ORDERBY;
        }

        setSorting(finalSort);
    };

    useEffect(() => {
        if (testId && testId !== "" && candidateDetails.email && candidateDetails.email !== "") {
            useGetCandidateTestResults({
                testId: testId,
                email: candidateDetails.email,
                sortBy: sorting.sortBy,
                orderBy: sorting.orderBy
            }, (details) => {
                log.debug(`displayCandidateDetails() - ${JSON.stringify(details, null, 2)}`);
                const newDetails: GetCandidateTestResultsData = createCandidateDetails(details);
                formatCandidateDataForTable(newDetails);
            });
        }
    }, [sorting]);

    useEffect(() => {
        useGetAccountLogoData((data) => {
            if (data.logo) {
                const imageString = arrayBufferToBase64(data.logo.image.data.data);
                setImageData(`data:${data.logo.image.type};base64,${imageString}`);
            } else {
                setImageData("");
            }
        }, (error) => {
            log.error(`getLogoImage() - ${error}`);
        });
    }, []);

    useEffect(() => {
        if (open && testId) {
            setFetchingRest(true);
            useGetCandidateTestResults({
                testId: testId,
                email: candidateDetails.email,
                sortBy: sorting.sortBy,
                orderBy: sorting.orderBy
            }, (details) => {
                log.debug(`displayCandidateDetails() - ${JSON.stringify(details, null, 2)}`);
                if (details.testResults.length > 0) {
                    const newDetails: GetCandidateTestResultsData = createCandidateDetails(details);
                    if (candidateDetails.attempts !== newDetails.attempts) {
                        setUpdated(true);
                    }
                    if (candidateDetails.testResults.length <= newDetails.testResults.length) {
                        setCandidateDetails(newDetails);
                        formatCandidateDataForTable(newDetails);
                    }
                }
                setFetchingRest(false);
            });
        } else {
            setFetchingRest(true);
            setTableData([]);
        }
    }, [open]);

    useEffect(() => {
        if (authTokens && authTokens.adminId) {
            useGetUserInformation(
                authTokens?.adminId,
                (data) => {
                    setAdminUserData({
                        firstName: data.firstName,
                        lastName: data.lastName,
                    });
                }
            );
        }
    }, [authTokens]);

    const handleClosePrintPreviewDialog = () => {
        setPrintPreviewOpen(false);
    };

    const handlePrintClick = () => {
        let candidateDetailsFiltered = {} as GetCandidateTestResultsData;
        if (oneResult) {
            const bestResult = candidateDetails.testResults.find((result) => result.resultId === candidateDetails.bestResultId);
            if (bestResult) {
                candidateDetailsFiltered = {
                    ...candidateDetails,
                    testResults: [bestResult],
                }
            }
        } else {
            candidateDetailsFiltered = candidateDetails;
        }
        sessionStorage.setItem("candidateDetails", JSON.stringify({ ...candidateDetailsFiltered, adminUserData, companyName: authTokens?.companyName, hideFractional }));
        try {
            sessionStorage.setItem("image", imageData);
        } catch (error) {
            log.debug(`handlePrintClick() - ${error}`);
        }

        window.addEventListener("focus", () => {
            handleClosePrintPreviewDialog();
        });

        setPrintPreviewOpen(true);
    };

    useEffect(() => {
        if (printPreviewOpen) {
            window.open(TEST_RESULTS_HTML_PATH, "_blank");
        }
    }, [printPreviewOpen]);

    return (
        <Formik
            initialValues={{
                testName: candidateDetails.testName,
                firstName: candidateDetails.firstName,
                lastName: candidateDetails.lastName,
                candidateStatus: candidateDetails.status,
                email: candidateDetails.email,
                infoFieldOneTitle: candidateDetails.infoFieldOneTitle,
                infoFieldOneValue: candidateDetails.infoFieldOneValue,
                infoFieldOneEnabled: candidateDetails.infoFieldOneEnabled,
                infoFieldTwoTitle: candidateDetails.infoFieldTwoTitle,
                infoFieldTwoValue: candidateDetails.infoFieldTwoValue,
                infoFieldTwoEnabled: candidateDetails.infoFieldTwoEnabled
            }}
            validationSchema={validationSchema}
            onSubmit={() => {
                // does nothing
            }}
            enableReinitialize={true}
        >
            {(formProps: any) => {
                const { isValid, values, touched, errors, handleBlur, handleChange, resetForm, dirty } = formProps;

                const isSaveButtonDisabled = (): boolean => {
                    if (dirty && isValid) {
                        return false;
                    }
                    return true;
                };

                const options: MUIDataTableOptions = {
                    selectableRows: "none",
                    responsive: "standard",
                    rowsPerPage: rowsPerPage,
                    rowsPerPageOptions: [5, 10, 25, 50, 75, 100],
                    onChangeRowsPerPage(numberOfRows) {
                        setRowsPerPage(numberOfRows);
                    },
                    pagination: true,
                    filter: false,
                    viewColumns: false,
                    download: false,
                    print: false,
                    search: false,
                    textLabels: {
                        body: {
                            noMatch: "No rows"
                        }
                    },
                    onColumnSortChange: (changedColumn: string, direction: "asc" | "desc") => {
                        const convertColumn = (changedColumn: string): SortBy => {
                            if (changedColumn === "createdAt") {
                                return "date" as SortBy;
                            }
                            if (changedColumn === "candidate") {
                                return "name" as SortBy;
                            }
                            if (changedColumn === "netWpmToAvgPercentage") {
                                return "vsAvg" as SortBy;
                            }
                            return changedColumn as SortBy;
                        };
                        const order: OrderBy = direction;

                        const sort: SortBy = convertColumn(changedColumn);
                        sortUpdate(sort, order);
                    }
                };

                return (
                    <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="form-dialog-title"
                        classes={{ root: classes.root }}
                        maxWidth="lg"
                        style={{ width: "100%", contain: 'size' }}
                    >
                        <Box className={classes.logoSection} id={"logoSectionInCandidateDialog"}>
                            <img width={"180px"} alt="TypingTest Pro logo"
                                src={imageData !== "" ? imageData : "/assets/img/logo.svg"} />
                            <p style={{ fontSize: "26px", marginTop: "100px" }}>TYPING TEST CERTIFICATE</p>
                        </Box>
                        <Box display="flex">
                            <AccountCircleIcon color="secondary"
                                style={{ marginLeft: "20px", marginTop: "16px", fontSize: "70px" }} />
                            <DialogTitle
                                id="form-dialog-title"
                                style={{ marginLeft: "-20px", maxWidth: "calc(100% - 200px)" }}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "16px"
                                    }}
                                >
                                    <Typography
                                        variant='body1'
                                        className={classes.testTitle}
                                    >
                                        {values.testName}
                                    </Typography>
                                    <Loading
                                        show={fetchingRest}
                                        size={20}
                                        delay={0}
                                    />
                                </Box>
                                <Box display="flex">
                                    <Typography variant="body1" className={classes.nameTitle}>
                                        {values.lastName}, {values.firstName}
                                    </Typography>
                                    <Box display={"flex"} style={{ marginTop: "5px" }}>
                                        <ResultTableCellStatus status={values.candidateStatus} />
                                        <Button
                                            onClick={() => setDeleteUser(true)}
                                            color="secondary"
                                            variant="contained"
                                            style={{
                                                background: "#ff3b3b",
                                                marginLeft: "20px",
                                                fontSize: "12px",
                                                borderRadius: "40px",
                                                padding: "5px 21px"
                                            }}
                                            id="btn_delete_user">
                                            {candidateDetails.resultsInOtherTests.length > 0 ? "Delete user (many tests)" : "Delete user"}
                                        </Button>
                                    </Box>
                                </Box>

                            </DialogTitle>
                            <DialogActions style={{ marginLeft: "auto", marginRight: "40px" }}>
                                <Button
                                    onClick={handlePrintClick}
                                    color="secondary"
                                    id="btn_print">
                                    Print
                                </Button>
                                <Button
                                    onClick={() => {
                                        handleClose();
                                        resetForm();
                                    }}
                                    color="secondary"
                                    id="btn_cancel">
                                    Cancel
                                </Button>

                                {isSaveButtonDisabled() ? (
                                    <Button
                                        onClick={() => {
                                            handleClose();
                                            resetForm();
                                        }}
                                        color="secondary"
                                        id="btn_save"
                                        variant="contained">
                                        Done
                                    </Button>
                                ) : (
                                    <Button type="submit"
                                        onClick={() => {
                                            log.debug(`onClick() - updating existing candidate: ${JSON.stringify(values, null, 2)}`);
                                            usePatchCandidate({
                                                testId: candidateDetails.testId,
                                                candidateId: candidateDetails.candidateId, ...values
                                            },
                                                (candidateId: string) => {
                                                    log.debug(`onClick() - response: ${candidateId}`);
                                                    candidateUpdated();
                                                }, ({ error }) => {
                                                    log.error(`onClick() - error ${error?.response?.data.status}`);
                                                    if (error && error.response?.data.status === "EMAIL_ALREADY_RESERVED") {
                                                        setOpenError(true);
                                                    }

                                                });
                                        }}
                                        color="secondary"
                                        id="btn_save"
                                        variant="contained">
                                        Save
                                    </Button>
                                )}
                            </DialogActions>
                        </Box>
                        <DialogContent>
                            <div>
                                <Form>

                                    <Box display="flex" width="100%">
                                        <Box width="50%" style={{ marginRight: "10px" }}>

                                            <Box display="block" width="100%">
                                                <TextField
                                                    value={values.email}
                                                    margin="dense"
                                                    id="email"
                                                    label="Email Address"
                                                    fullWidth
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={
                                                        errors.email && touched.email
                                                            ? errors.email
                                                            : ""
                                                    }
                                                    error={errors.email && touched.email ? true : false}
                                                    variant="outlined"
                                                    color="secondary"
                                                    className={classes.boxField}
                                                    InputProps={{ classes: { input: classes.inputField } }}
                                                    inputProps={{ maxLength: EMAIL_MAX_LENGTH }}
                                                />
                                            </Box>

                                            <Box position="relative" width="100%" display="flex">
                                                <TextField
                                                    value={values.firstName}
                                                    margin="dense"
                                                    id="firstName"
                                                    label="First Name"
                                                    fullWidth
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={
                                                        errors.firstName && touched.firstName
                                                            ? errors.firstName
                                                            : ""
                                                    }
                                                    error={errors.firstName && touched.firstName ? true : false}
                                                    variant="outlined"
                                                    color="secondary"
                                                    className={classes.boxField}
                                                    InputProps={{ classes: { input: classes.inputField } }}
                                                    inputProps={{ maxLength: INPUT_MAX_LENGTH }}
                                                    style={{ marginRight: "10px" }}
                                                />
                                                <TextField
                                                    value={values.lastName}
                                                    margin="dense"
                                                    id="lastName"
                                                    label="Last Name"
                                                    fullWidth
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={
                                                        errors.lastName && touched.lastName
                                                            ? errors.lastName
                                                            : ""
                                                    }
                                                    error={errors.lastName && touched.lastName ? true : false}
                                                    variant="outlined"
                                                    color="secondary"
                                                    className={classes.boxField}
                                                    InputProps={{ classes: { input: classes.inputField } }}
                                                    inputProps={{ maxLength: INPUT_MAX_LENGTH }}
                                                    style={{ marginLeft: "10px" }}
                                                />
                                            </Box>
                                        </Box>

                                        <Box width="50%" style={{ marginLeft: "10px" }}>
                                            {infoFieldOneEnabled && (
                                                <TextField
                                                    value={values.infoFieldOneValue}
                                                    margin="dense"
                                                    id="infoFieldOneValue"
                                                    label={candidateDetails.infoFieldOneTitle}
                                                    fullWidth
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={
                                                        errors.infoFieldOneValue && touched.infoFieldOneValue
                                                            ? errors.infoFieldOneValue
                                                            : ""
                                                    }
                                                    error={errors.infoFieldOneValue && touched.infoFieldOneValue ? true : false}
                                                    variant="outlined"
                                                    color="secondary"
                                                    className={classes.boxField}
                                                    InputProps={{ classes: { input: classes.inputField } }}
                                                    inputProps={{ maxLength: INPUT_MAX_LENGTH }}
                                                />
                                            )}
                                            {infoFieldTwoEnabled && (
                                                <TextField
                                                    value={values.infoFieldTwoValue}
                                                    margin="dense"
                                                    id="infoFieldTwoValue"
                                                    label={candidateDetails.infoFieldTwoTitle}
                                                    fullWidth
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={
                                                        errors.infoFieldTwoValue && touched.infoFieldTwoValue
                                                            ? errors.infoFieldTwoValue
                                                            : ""
                                                    }
                                                    error={errors.infoFieldTwoValue && touched.infoFieldTwoValue ? true : false}
                                                    variant="outlined"
                                                    color="secondary"
                                                    className={classes.boxField}
                                                    InputProps={{ classes: { input: classes.inputField } }}
                                                    inputProps={{ maxLength: INPUT_MAX_LENGTH }}
                                                />
                                            )}
                                        </Box>

                                    </Box>

                                </Form>
                                <MUIDataTable
                                    title={""}
                                    data={tableData}
                                    columns={objectColumns}
                                    options={options}
                                />
                                <div
                                    className={classes.count}
                                    id={"attemptsInCandidateDetails"}>{"Attempts " + tableData.length + " of " + candidateDetails.attempts}</div>
                                <Dialog
                                    open={openDelete}
                                    onClose={handleCloseDelete}
                                    aria-labelledby="delete-dialog-title"
                                    aria-describedby="delete-dialog-description"
                                >
                                    <DialogTitle id="delete-dialog-title"
                                        style={{ textAlign: "center" }}>{"Delete Result"}</DialogTitle>
                                    <DialogContent>

                                        <DialogContentText id="delete-dialog-description">
                                            Deleting this result will give the candidate a new test attempt. Do
                                            you
                                            want to proceed?
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions style={{ margin: "0 auto 20px auto" }}>
                                        <Button onClick={() => {
                                            deleteResult(deleteResultId);
                                            setUpdated(true);
                                            handleCloseDelete();
                                        }} id="delete_test" color="secondary" variant="contained">
                                            Yes, delete
                                        </Button>
                                        <Button onClick={handleCloseDelete} color="secondary" autoFocus>
                                            Cancel
                                        </Button>
                                    </DialogActions>
                                </Dialog>

                                <Dialog
                                    open={deleteUser}
                                    onClose={handleCloseDeleteUser}
                                    aria-labelledby="delete-dialog-title"
                                    aria-describedby="delete-dialog-description"
                                >
                                    <DialogTitle id="delete-user-dialog-title"
                                        style={{ textAlign: "center" }}>{"Delete User"}</DialogTitle>
                                    <DialogContent>

                                        <DialogContentText id="delete-dialog-description">
                                            {
                                                candidateDetails.resultsInOtherTests.length > 0
                                                    ? "Please notice that selected user has result in more than one test. Deleting is permanent and will remove the user and all test results. Do you want to proceed?"
                                                    : "Deleting will remove this user and all their test results from TypingTest Pro. Deletion is permanent and can't be reversed. Do you want to proceed?"
                                            }
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions style={{ margin: "0 auto 20px auto" }}>
                                        <Button id="delete_user" color="secondary" variant="contained"
                                            onClick={() => deleteUserAndResults(candidateDetails.candidateId)}>
                                            Yes, delete
                                        </Button>
                                        <Button onClick={handleCloseDeleteUser} color="secondary" autoFocus>
                                            Cancel
                                        </Button>
                                    </DialogActions>
                                </Dialog>


                                <Dialog
                                    open={openError}
                                    onClose={handleCloseError}
                                    aria-labelledby="error-dialog-title"
                                    aria-describedby="error-dialog-description"
                                >
                                    <DialogTitle id="error-dialog-title" style={{ textAlign: "center" }}>Error - Email
                                        reserved</DialogTitle>
                                    <DialogContent>

                                        <DialogContentText id="delete-dialog-description">
                                            Candidate with this email already exists, please use another email address.
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions style={{ margin: "0 auto 20px auto" }}>
                                        <Button onClick={handleCloseError} color="secondary" autoFocus>
                                            Cancel
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                                {candidateDetails.resultsInOtherTests.map((anotherTest, index) => {
                                    return (
                                        <RouterNavLink
                                            to={"#"}
                                            className={({ isActive }) => isActive ? classes.navButton : classes.navButton}
                                            key={index}
                                        >
                                            <Button
                                                variant="text"
                                                classes={{
                                                    textPrimary: classes.textPrimary,
                                                    textSecondary: classes.textSecondary
                                                }} style={{ textTransform: "none" }}
                                                disableElevation
                                                onClick={() => showCandidateInAnotherTest(candidateDetails.email, anotherTest.testId)}>
                                                <AddIcon style={{
                                                    fontSize: "20px",
                                                    marginTop: "-2px"
                                                }} /> {anotherTest.count} attempts in {anotherTest.name}
                                            </Button>
                                        </RouterNavLink>);
                                })}
                            </div>
                        </DialogContent>
                        <Box id={"typingTextSection"} className={classes.typingTextSection}>
                            <i className={classes.typingTextPro}>Typing Test Pro</i>
                        </Box>

                        <Dialog
                            id="print-preview-dialog"
                            open={printPreviewOpen}
                            onClose={handleClosePrintPreviewDialog}
                        >
                            <DialogTitle
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center"
                                }}
                            >
                                <InfoIcon />
                                <IconButton onClick={handleClosePrintPreviewDialog}>
                                    <CloseIcon />
                                </IconButton>
                            </DialogTitle>
                            <DialogContent
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    "& .MuiTypography-root": {
                                        textAlign: "center",
                                    }
                                }}
                            >
                                <Typography
                                    color="info.main"
                                >
                                    Please close the print preview
                                </Typography>
                                <Typography
                                    color="info.main"
                                >
                                    to continue.
                                </Typography>
                            </DialogContent>
                        </Dialog>
                    </Dialog>
                );
            }}
        </Formik>
    );
};

export default CandidateDetails;
