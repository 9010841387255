import "typeface-roboto";
import { useState } from "react";
import {
    BrowserRouter as Router,
    Route,
    Routes,
} from "react-router-dom";
import { FlagsProvider } from "flagged";
import "./App.css";
import EmailConfirmation from "./components/EmailConfirmation/EmailConfirmation";
import Heartbeat from "./components/Heartbeat/Heartbeat";
import LoginForm from "./components/LoginForm/LoginForm";
import RegistrationForm from "./components/RegistrationForm/RegistrationForm";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import { AuthContext, AuthTokens, FlagsContext } from "./context/auth";
import ManageTest from "./pages/ManageTest/ManageTest";
import Test from "./pages/Test/Test";
import TestResults from "./pages/TestResults/TestResults";
import Account from "./pages/Account/Account";
import Footer from "./components/Footer/Footer";
import TopNavBar from "./components/TopNavBar/TopNavBar";
import Invitation from "./pages/Invitation/Invitation";
import { theme } from "./theme/Theme";
import CustomerAccounts from "./pages/CustomerAccounts/CustomerAccounts";
import PasswordReset from "./pages/PasswordReset/PasswordReset";
import UserSearch from "./pages/UserSearch/UserSearch";
import Plans from "./pages/Plans/Plans";
import CustomerAccountsWithoutAdmin from "./pages/CustomerAccountsWithoutAdmin/CustomerAccountsWithoutAdmin";
import TestSelect from "./pages/TestSelect/TestSelect";
import TestSelectForMigrated from "./pages/TestSelectForMigrated/TestSelectForMigrated";
import { CommonContext } from "./context/common";
import InitialPage from "./components/InitialPage/InitialPage";
import CandidatesExceededDialog from "./components/CandidatesExceededDialog/CandidatesExceededDialog";

export type SetTokenAndFeatures = (redirectOnError: (path: string) => void) => void;

const App = () => {
    const [authTokens, setAuthTokens] = useState<AuthTokens>({ email: "", status: "" });
    const setTokens = (data: { email: string; status: string; superAdmin?: boolean; companyName?: string; adminId: string }) => {
        setAuthTokens(data);
    };
    const [candidatesExceeded, setCandidatesExceeded] = useState({ exceeded: false, openDialog: false });

    const closeExpiredCandidatesMessage = () => {
        setCandidatesExceeded({ ...candidatesExceeded, openDialog: false });
    }

    const [features, setFeatures] = useState<string[]>([]);

    return (
        <AuthContext.Provider value={{ authTokens, setAuthTokens: setTokens }}>
            <FlagsContext.Provider value={{ features, setFeatures }}>
                <FlagsProvider features={features}>
                    <CommonContext.Provider value={{ candidatesExceeded, setCandidatesExceeded }}>
                        <Router>
                            <div style={{
                                background: theme.palette.primary.main,
                                minWidth: "1024px",
                                minHeight: "calc(100vh - var(--footer-height))",
                                display: "flex",
                                flexDirection: "column"
                            }}>
                                <TopNavBar />
                                {candidatesExceeded && candidatesExceeded.openDialog && (
                                    <CandidatesExceededDialog
                                        open={candidatesExceeded.openDialog}
                                        candidatesExceeded={candidatesExceeded}
                                        onClose={closeExpiredCandidatesMessage}
                                    />
                                )}
                                <Routes>
                                    <Route path="/" element={<InitialPage />} />
                                    <Route path="/heartbeat" element={<Heartbeat />} />
                                    <Route path="/register" element={<RegistrationForm />} />
                                    <Route path="/emailconfirmation" element={<EmailConfirmation />} />
                                    <Route path="/login" element={<LoginForm />} />
                                    <Route path="/testresults/for-all-tests/:searchItem" element={<PrivateRoute component={UserSearch} />} />
                                    <Route path="/reset" element={<PasswordReset />} />
                                    <Route path="/customer-accounts" element={<PrivateRoute component={CustomerAccounts} />} />
                                    <Route path="/customer-accounts-without-admin" element={<PrivateRoute component={CustomerAccountsWithoutAdmin} />} />
                                    <Route path="/manage-tests" element={<PrivateRoute component={ManageTest} />} />
                                    <Route path="/test" element={<Test />} />
                                    <Route path="/start" element={<TestSelect />} />
                                    <Route path="/select" element={<TestSelect />} />
                                    <Route path="/migrated" element={<TestSelectForMigrated />} />
                                    <Route path="/testresults" element={<PrivateRoute component={TestResults} />} />
                                    <Route path="/account" element={<PrivateRoute component={Account} />} />
                                    <Route path="/plans" element={<PrivateRoute component={Plans} />} />
                                    <Route path="/invitation" element={<Invitation />} />
                                </Routes>
                            </div>
                            <Footer />
                        </Router>
                    </CommonContext.Provider>
                </FlagsProvider>
            </FlagsContext.Provider>
        </AuthContext.Provider>
    );
};

export default App;
