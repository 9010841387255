import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { makeStyles } from 'tss-react/mui';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import UserSearchTable from "../UserSearchTable/UserSearchTable";
import useSubscriptionInfo from "../../hooks/useSubscriptionInfo";
import WarningMessage from "../WarningMessage/WarningMessage";

const ALL = "all";
export const ALL_RESULTS_URL = "/testresults/for-all-tests/all";
export const FILTERED_URL = "/testresults";
export const URL_TEXT_COLOR = "#007bff"

const useStyles = makeStyles()({
    root: {
        width: "100%",
        height: "auto"
    },
    header: {
        fontFamily: "'Lexend', sans-serif;",
        margin: "8px 0 0 0"
    },
    mainBox: {
        marginLeft: "40px",
        marginBottom: "40px",
        justifyContent: "space-between",
        alignItems: "baseline",
    },
    showAll: {
        marginRight: "40px",
    },
    resultTitle: {
        fontSize: "34px",
        color: "#989898",
        marginLeft: "10px"
    },
    filterSection: {
        marginRight: "50px",
        fontSize: "20px",
        color: URL_TEXT_COLOR
    }
});

const UserSearchResult = () => {
    const { classes } = useStyles();
    const { searchItem } = useParams();
    const [resultCount, setResultCount] = useState<number>(0);
    const { candidatesExceeded, subscriptionIsAvailable } = useSubscriptionInfo();

    return (
        <>
            <div className={classes.root}>
                <WarningMessage
                    candidatesExceeded={candidatesExceeded}
                    subscriptionIsAvailable={subscriptionIsAvailable}
                />
                <Box display="flex" className={classes.mainBox}>
                    <Typography variant="h1" color="secondary" className={classes.header}>
                        Search
                        <span className={classes.resultTitle}>{resultCount} result for {searchItem}</span>
                    </Typography>
                    {searchItem && searchItem.length > 1 && (

                        <div className={classes.filterSection}>
                            {searchItem.length > 1 && searchItem !== ALL ? (
                                <Link style={{ color: URL_TEXT_COLOR }} to={ALL_RESULTS_URL}>
                                    <span>CLEAR SEARCH</span>
                                </Link>
                            ) : (
                                <Link style={{ color: URL_TEXT_COLOR }} to={FILTERED_URL}>
                                    <span>FILTERED RESULTS</span>
                                </Link>
                            )}
                            <span> | </span>
                            {searchItem === ALL ? (
                                <span style={{ fontWeight: 700 }}>DISPLAY ALL RESULTS</span>
                            ) : (
                                <Link style={{ color: URL_TEXT_COLOR }} to={FILTERED_URL}>
                                    <span>FILTERED RESULTS</span>
                                </Link>
                            )}
                        </div>

                    )}
                </Box>
                <div>
                    <UserSearchTable resultCount={setResultCount} searchItem={searchItem === "all" ? undefined : searchItem} />
                </div>
            </div>
        </>
    );
};

export default UserSearchResult;