import { useEffect, useRef, useState } from "react";
import AccountInfo from "../../components/AccountInfo/AccountInfo";
import AccountLogo from "../../components/AccountLogo/AccountLogo";
import AdminManagement from "../../components/AdminManagement/AdminManagement";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import { makeStyles } from 'tss-react/mui';
import Subscription from "../../components/Subscription/Subscription";
import { Link, useLocation } from "react-router-dom";
import { URL_TEXT_COLOR } from "../../components/UserSearch/UserSearch";
import AdvancedSettings from "../../components/AdvancedSettings/AdvancedSettings";
import AccountIntegrations from "../../components/AccountIntegrations/AccountIntegrations";
import { useFeature } from "flagged";
import { useAuthTokens } from "../../context/auth";
import useSubscriptionInfo from "../../hooks/useSubscriptionInfo";

const useStyles = makeStyles()((theme) => ({
    root: {
        margin: "0px 45px 0 45px"
    },
    title: {
        fontFamily: "'Lexend', sans-serif;",
        textAlign: "left",
        display: "block",
        fontSize: "34px",
        margin: 0,
    },
    testSelect: {
        fontSize: "18px",
        color: theme.palette.primary.dark,
        marginLeft: "15px",
        fontFamily: "'Lexend', sans-serif;",
    },
    subtitle: {
        fontFamily: "'Lexend', sans-serif;",
        textAlign: "left",
        display: "flex",
        fontSize: "29px",
        color: theme.palette.text.secondary,
        padding: '32px 0 16px 0',
    },
    readMore: {
        fontFamily: "'Lexend', sans-serif;",
        padding: '36px 0 16px 0',
        marginLeft: '16px',
        fontWeight: 'bolder',
    },
    cardContainer: {
        boxShadow: "none",
        border: "1px solid #0000001F",
        display: "flex",
    },
    leftBox: {
        width: "45%"
    },
    rightBox: {
        width: "55%"
    },
    descr: {
        fontSize: "16px",
        color: theme.palette.primary.dark
    }
}));

const Account = () => {
    const { classes } = useStyles();
    const location = useLocation();
    const [timeZone, setTimeZone] = useState<string>("");

    const organizationEl = useRef<HTMLHeadingElement>(null);
    const administratorsEl = useRef<HTMLHeadingElement>(null);
    const subscriptionsEl = useRef<HTMLSpanElement>(null);
    const advancedSettingsEl = useRef<HTMLSpanElement>(null);
    const integrationsEl = useRef<HTMLSpanElement>(null);
    const { subscriptionIsAvailable } = useSubscriptionInfo();

    const integrationsFeatureExists = useFeature('f_integrations') as boolean;
    const authTokens = useAuthTokens();
    const allowIntegrations = (integrationsFeatureExists || authTokens?.superAdmin) as boolean;

    useEffect(() => {
        const id = location.hash;
        const scrollOptions: ScrollIntoViewOptions = {
            behavior: "smooth"
        };
        if (id === '#Organization') {
            organizationEl.current?.scrollIntoView(scrollOptions);
        } else if (id === '#Administrators') {
            administratorsEl.current?.scrollIntoView(scrollOptions);
        } else if (id === '#Subscription') {
            subscriptionsEl.current?.scrollIntoView(scrollOptions);
        } else if (id === '#AdvancedSettings') {
            subscriptionsEl.current?.scrollIntoView(scrollOptions);
        } else if (id === '#Integrations') {
            integrationsEl.current?.scrollIntoView(scrollOptions);
        }
    }, [location.hash]);

    return (
        <div>
            <Box className={classes.root}>
                <Box display="flex" justifyContent="space-between" sx={{ marginBottom: '32px', alignItems: 'center' }}>
                    <Typography variant="h1" color="secondary" className={classes.title}>Account</Typography>
                    <div className={classes.testSelect}>
                        <Link style={{ color: URL_TEXT_COLOR }} to="#Organization">
                            Organization
                        </Link>
                        <span style={{ color: URL_TEXT_COLOR }}> | </span>
                        <Link style={{ color: URL_TEXT_COLOR }} to="#Administrators">
                            Administrators
                        </Link>
                        <span style={{ color: URL_TEXT_COLOR }}> | </span>
                        <Link style={{ color: URL_TEXT_COLOR }} to="#Subscription">
                            Subscription
                        </Link>
                        <span style={{ color: URL_TEXT_COLOR }}> | </span>
                        <Link style={{ color: URL_TEXT_COLOR }} to="#AdvancedSettings">
                            Advanced Settings
                        </Link>
                        <span style={{ color: URL_TEXT_COLOR }}> | </span>
                        <Link style={{ color: URL_TEXT_COLOR }} to="#Integrations">
                            Integrations
                        </Link>
                    </div>
                </Box>

                <Typography ref={organizationEl} variant="h2" color="secondary" className={classes.subtitle}>Organization</Typography>
                <Card className={classes.cardContainer} sx={{ padding: '20px' }}>
                    <Box className={classes.leftBox}>
                        <AccountInfo setTimeZone={setTimeZone} />
                    </Box>
                    <Box className={classes.rightBox}>
                        <AccountLogo />
                    </Box>
                </Card>

                <Typography ref={administratorsEl} variant="h2" color="secondary" className={classes.subtitle}>Administrators</Typography>
                <AdminManagement timeZone={timeZone} />

                <Typography ref={subscriptionsEl} variant="h2" color="secondary" className={classes.subtitle}>Subscription</Typography>
                <Card className={classes.cardContainer} sx={{ flexDirection: 'column' }}>
                    <Subscription />
                </Card>

                <Typography ref={advancedSettingsEl} variant="h2" color="secondary" className={classes.subtitle}>Advanced Settings</Typography>
                <Card className={classes.cardContainer}>
                    <AdvancedSettings />
                </Card>

                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <Typography
                        ref={integrationsEl}
                        variant="h2"
                        color="secondary"
                        className={classes.subtitle}
                    >
                        Integrations
                        <Typography component="span" sx={{ marginLeft: '12px', marginTop: '8px' }}>
                            {`${allowIntegrations ? '' : ' (Locked in this plan)'}`}
                        </Typography>
                    </Typography>
                    <Link
                        to="https://typingtestpro.zendesk.com/hc/en-us/articles/8588484371868-API-Integration-Support-to-3rd-party-systems"
                        className={classes.readMore}
                        target="_blank"
                    >
                        Read More...
                    </Link>
                </Box>
                <Card className={classes.cardContainer} sx={{ marginBottom: '48px' }}>
                    <AccountIntegrations subscriptionIsAvailable={subscriptionIsAvailable} allowIntegrations={allowIntegrations} />
                </Card>
            </Box>
        </div>
    );
};

export default Account;
