import React from "react";
import TestSelectWizard from "../../components/TestSelect/TestSelectWizard";

const TestSelectForMigrated = () => (
    <div>
        <TestSelectWizard migrated={true} />
    </div>
);

export default TestSelectForMigrated;
