import React, { useEffect, useState } from "react";
import { Navigate, PathRouteProps } from "react-router-dom";
import { useAuthTokens, useSetTokenAndFeatures } from "../../context/auth";

export interface PrivateRouteProps extends PathRouteProps {
    component: React.ElementType;
}

const PrivateRoute = (privateProps: PrivateRouteProps) => {
    const { component: Component, ...rest } = privateProps;
    const authenticated = useAuthTokens();
    const [loading, setLoading] = useState(true);
    const { setTokenAndFeatures } = useSetTokenAndFeatures();

    useEffect(() => {
        if (!authenticated) {
            setTokenAndFeatures();
        } else {
            setLoading(false);
        }
    }, [authenticated]);

    if (loading) {
        return null;
    }

    if (authenticated) {
        return (
            <Component {...rest} />
        )
    }

    return (
        <Navigate to="/login" />
    );
};

export default PrivateRoute;
