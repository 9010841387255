import { useEffect, useState } from 'react';
import { AddOrUpdateCandidateRequestData, AddOrUpdateCandidateResponse, CandidatePersonalData, useAddOrUpdateCandidate } from './useApi';
import { personalDataValidationSchemaParts } from '../utils/personalDataValidation';

interface SaveCandidate {
    testId: string | null;
    email: string | null;
    firstName: string | null;
    lastName: string | null;
    customField1: string | null;
    customField2: string | null;
}

const useSaveCandidate = ({
    testId,
    email,
    firstName,
    lastName,
    customField1,
    customField2,
}: SaveCandidate) => {
    const [candidate, setCandidate] = useState<CandidatePersonalData | undefined>();
    
    useEffect(() => {
        if (testId && email && personalDataValidationSchemaParts.email.isValidSync(email)) {
            const data: AddOrUpdateCandidateRequestData = {
                testId,
                email,
            };

            if (firstName && personalDataValidationSchemaParts.firstName.isValidSync(firstName)) {
                data.firstName = firstName;
            }
            if (lastName && personalDataValidationSchemaParts.lastName.isValidSync(lastName)) {
                data.lastName = lastName;
            }
            if (customField1) {
                data.customField1 = customField1;
            }
            if (customField2) {
                data.customField2 = customField2;
            }

            useAddOrUpdateCandidate(
                data,
                (candidate: Omit<AddOrUpdateCandidateResponse, 'status'>) => {
                    const candidateData: CandidatePersonalData = {
                        email,
                        firstName: firstName || "",
                        lastName: lastName || "",
                        infoFieldOneValue: customField1 || "",
                        infoFieldTwoValue: customField2 || "",
                        candidateId: candidate.candidateId,
                        attemptsLeft: candidate.attemptsLeft,
                    };
                    setCandidate(candidateData);
                }
            );
        }
    }, [testId, email]);

    return candidate;
};

export default useSaveCandidate;
