import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import CheckBox from "@mui/material/Checkbox";
import {
    ChangeEvent,
    useState,
    useTransition,
} from "react";
import { useUpdateExternalLink } from "../../hooks/useApi";
import type { ExternalUrlData } from "./AccountIntegrations";

interface ComponentProps {
    open: boolean;
    onClose: () => void;
    onCancel: () => void;
    externalUrlData: ExternalUrlData,
    externalUrlEnabled: boolean;
    onUrlChange: (url: string) => void;
    onExternalUrlEnabledChange: (enabled: boolean) => void;
}

const INITIAL_FETCHING = false;
const INITIAL_COMPLETED_MESSAGE = { success: '', error: '' };

const AccountIntegrationsUpdateExternalUrlDialog = ({
    open,
    onClose,
    onCancel,
    externalUrlData,
    externalUrlEnabled,
    onUrlChange,
    onExternalUrlEnabledChange,
}: ComponentProps) => {
    const [fetching, setFetching] = useState(INITIAL_FETCHING);
    const [completedMessage, setCompletedMessage] = useState(INITIAL_COMPLETED_MESSAGE);
    const [_, startTransition] = useTransition();

    const handleUrlChange = (e: ChangeEvent<HTMLInputElement>) => {
        const url = e.target.value;
        onUrlChange(url);
    };

    const handleEnabledChange = (_: any, checked: boolean) => {
        onExternalUrlEnabledChange(checked);
    };

    const handleCloseAndResetState = () => {
        onClose();
        startTransition(() => {
            handleResetData();
        });
    }

    const handleSave = () => {
        setFetching(true);
        useUpdateExternalLink(
            { url: externalUrlData.value, enabled: externalUrlEnabled },
            ({ data, error }) => {
                setFetching(false);
                if (data) {
                    setCompletedMessage({ ...completedMessage, success: 'Changes successfully saved' });
                } else if (error === 'ERROR_SUBSCRIPTION_PLAN_EXPIRED') {
                    setCompletedMessage({ ...completedMessage, error: 'Your Plan is expired. Please activate your plan!' });
                } else if (error === 'ERROR_LOCKED_IN_THIS_PLAN') {
                    setCompletedMessage({ ...completedMessage, error: 'This feature is locked in this plan' });
                } else {
                    setCompletedMessage({ ...completedMessage, error: 'Unknown error, please try again later' });
                }
            },
        );
    };

    const handleResetData = () => {
        setFetching(INITIAL_FETCHING);
        setCompletedMessage(INITIAL_COMPLETED_MESSAGE);
    };

    return (
        <Dialog
            open={open}
            onClose={completedMessage.success || completedMessage.error ? onCancel : onClose}
        >
            <DialogTitle>
                Enable Result Link
            </DialogTitle>
            <DialogContent
                sx={{
                    minWidth: '600px'
                }}
            >
                {completedMessage.success ? (
                    <Typography>
                        {completedMessage.success}
                    </Typography>
                ) : (
                    <>
                        {completedMessage.error && <Typography gutterBottom>{completedMessage.error}</Typography>}
                        <TextField
                            label="Result Link - Send Typing Test Results to External Web Server"
                            fullWidth
                            margin="dense"
                            variant="outlined"
                            color="secondary"
                            value={externalUrlData.value}
                            onChange={handleUrlChange}
                            error={Boolean(externalUrlData.error)}
                            helperText={externalUrlData.error}
                        />
                        <FormControlLabel
                            sx={{ margin: '12px 0 0 0' }}
                            control={
                                <CheckBox
                                    color="secondary"
                                    checked={externalUrlEnabled}
                                    onChange={handleEnabledChange}
                                />
                            }
                            label="Enabled"
                        />
                    </>
                )}
            </DialogContent>
            <DialogActions>
                {completedMessage.success ? (
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={handleCloseAndResetState}
                        disabled={fetching}
                    >
                        Close
                    </Button>
                ) : (
                    <>
                        <Button
                            variant="outlined"
                            color="secondary"
                            onClick={onCancel}
                            disabled={fetching}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={handleSave}
                            disabled={fetching}
                        >
                            Save
                        </Button>
                    </>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default AccountIntegrationsUpdateExternalUrlDialog;
